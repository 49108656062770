import { twMerge } from 'tailwind-merge'
import StudentImage from '../../../assets/avatar.jpg'
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg'

const StudentProfile = ({ onClickBack, name, email, classes }) => {
  return (
    <div className="relative flex flex-col items-center">
      <div
        className="absolute left-0 top-0 py-3 px-4 bg-[#F8F8F8] rounded-full cursor-pointer transition duration-200 hover:bg-nero-400"
        onClick={onClickBack}
      >
        <img src={ArrowLeftIcon} alt="Back Icon" />
      </div>
      <img src={StudentImage} alt="Student" className="mb-4" />
      <h2 className="text-nero-700 font-semibold text-2xl mb-2">{name}</h2>
      <p className="text-grey-500 mb-2">{email}</p>
      <div className="flex space-x-2">
        {classes?.map((studentClass, index) => (
          <div
            className={`py-0.5 px-2  rounded-full text-xs font-medium ${twMerge(
              index % 2 === 0 && 'bg-[#F9F5FF] text-[#6941C6]',
              index % 2 !== 0 && 'bg-[#EFF8FF] text-[#175CD3]'
            )}`}
          >
            {studentClass.name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StudentProfile
