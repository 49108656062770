import { useState } from 'react'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import {
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Card,
  Dialog,
  DialogHeader,
  DialogBody,
  CardBody,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import MultipleChoiceQuestion from './Questions/MultipleChoiceQuestion'
import EquationEditorQuestion from './Questions/EquationEditorQuestion'
import {
  calculateSatAnswerStats,
  generateSatQuestions,
  tabDataTest,
} from '../../helpers/sat'
import {
  useFetchSatReport,
  useFetchSatScore,
  useFetchSatTopic,
} from '../../hooks/useSat'
import DOMPurify from 'dompurify'
import moment from 'moment'
import Loading from '../Loading'
import SatChevronIcon from './SatChevronIcon'

const SatReport = ({ subject, account }) => {
  const [activeTabModule, setActiveTabModule] = useState('module1')
  const [activeTabTest, setActiveTabTest] = useState('readingAndWriting')
  const [activeTabTable, setActiveTabTable] = useState('allQuestions')
  const [accordionOpen, setAccordionOpen] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState({})
  const [accordionExplanationOpen, setAccordionExplanationOpen] = useState(0)
  const [moduleId, setModuleId] = useState(1)
  const {
    data: dataReport,
    isLoading: isLoadingReport,
    isError: isErrorReport,
    error: errorReport,
  } = useFetchSatReport({ subject, userId: account?.userId })
  const {
    data: dataTopic,
    isLoading: isLoadingTopic,
    isError: isErrorTopic,
    error: errorTopic,
  } = useFetchSatTopic()
  const {
    data: dataScore,
    isLoading: isLoadingScore,
    isError: isErrorScore,
    error: errorScore,
  } = useFetchSatScore({ subject, userId: account?.userId })
  const { questions } = generateSatQuestions(
    dataReport?.data.find((data) => data.module === moduleId)?.questions_sats ||
      [],
    dataReport?.data.find((data) => data.module === moduleId)
      ?.answer_details_sats[0]?.answers_sats || []
  )
  const { questions: questionsFinal } = generateSatQuestions(
    dataReport?.data.find((data) => data.module === moduleId + 1)
      ?.questions_sats || [],
    dataReport?.data.find((data) => data.module === moduleId + 1)
      ?.answer_details_sats[0]?.answers_sats || []
  )
  const handleAccordionExalanationOpen = (value) =>
    setAccordionExplanationOpen(accordionExplanationOpen === value ? 0 : value)

  const { correctAnswers, incorrectAnswers, blankAnswers } =
    calculateSatAnswerStats(questions || [])
  const {
    correctAnswers: correctFinalAnswers,
    incorrectAnswers: incorrectFinalAnswers,
    blankAnswers: blankFinalAnswers,
  } = calculateSatAnswerStats(questionsFinal || [])

  if (isLoadingReport || isLoadingTopic || isLoadingScore) {
    return <Loading />
  }

  if (
    (questions.length === 0 && questionsFinal.length === 0) ||
    errorReport?.response.status === 404 ||
    dataReport.data?.every((item) => item.answer_details_sats.length === 0)
  ) {
    return (
      <div className="mt-8">
        <Typography>Student haven&apos;t taken the test yet.</Typography>
      </div>
    )
  }

  if (isErrorReport || isErrorTopic || isErrorScore) {
    toast.error(
      `Something went wrong. Please contact our support team for assistance. Error: ${
        errorReport?.message || errorTopic?.message || errorScore?.message
      }`,
      {
        toastId: 'error-sat',
        autoClose: false,
      }
    )

    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Card>
          <CardBody className="flex flex-col space-y-4 text-center">
            <Typography variant="h3" color="red">
              Error Page
            </Typography>
            <Typography variant="h6">
              Something went wrong. Please screenshot this page and send it to
              our support team.
              <br />
              Error message:
              {errorReport?.message ||
                errorTopic?.message ||
                errorScore?.message}
              <br />
              Error code:
              {errorReport?.code || errorTopic?.code || errorScore?.code}
            </Typography>
          </CardBody>
        </Card>
      </div>
    )
  }

  const handleOpenModal = (answer) => {
    setOpenModal(!openModal)
    setCurrentSelectedAnswer(answer)
  }

  const handleAccordionOpen = (value) =>
    setAccordionOpen(accordionOpen === value ? 0 : value)

  const testCreatedAt = moment(
    dataReport?.data[0].answer_details_sats.createdAt
  )
  const formattedTestCreatedAt = testCreatedAt.format('MMMM D, YYYY')

  const createdAtAnswers = dataReport?.data.find(
    (data) => data.module === moduleId
  ).answer_details_sats[0].createdAt
  const updatedAtAnswers = dataReport?.data.find(
    (data) => data.module === moduleId
  ).answer_details_sats[0].updatedAt
  const createdAtFinalAnswers = dataReport?.data.find(
    (data) => data.module === moduleId + 1
  ).answer_details_sats[0].createdAt
  const updatedAtFinalAnswers = dataReport?.data.find(
    (data) => data.module === moduleId + 1
  ).answer_details_sats[0].updatedAt
  const scoreMath = dataScore.data.score_math
  const scoreVerbal = dataScore.data.score_math

  return (
    <>
      <div className="flex flex-col max-w-5xl text-[#000000]">
        <div className="flex items-end justify-between mt-8">
          <div>
            <Typography variant="paragraph" className="text-2xl">
              Digital SAT Pre Test
            </Typography>
            <Typography variant="paragraph" className="text-xs">
              You took this test online on: {formattedTestCreatedAt}
            </Typography>
          </div>
          <div>
            <Typography variant="paragraph" className="text-xs">
              Student Name
            </Typography>
            <Typography variant="paragraph" className="text-lg">
              {account.name}
            </Typography>
          </div>
        </div>
        <div className="relative flex flex-col space-y-6 border border-[#000000] px-2 py-6 mt-4">
          <div className="flex flex-col space-y-4 text-center">
            <Typography variant="paragraph" className="text-5xl font-bold">
              Score Summary
            </Typography>
            <div className="border-b-2 border-[#000000] w-full" />
            <Typography variant="paragraph" className="text-5xl">
              Your Total Score is:{' '}
              <span className="font-bold">{scoreMath + scoreVerbal}</span>
            </Typography>
          </div>
          <div className="flex gap-8 justify-center">
            <div className="flex flex-col items-center space-y-2">
              <Typography
                variant="paragraph"
                className="text-2xl font-semibold"
              >
                Reading and Writing
              </Typography>
              <div className="flex items-center space-x-4">
                <Typography variant="paragraph" className="text-5xl font-bold">
                  {scoreVerbal}
                </Typography>
                <div className="border-l-2 border-[#000000] h-10" />
                <Typography variant="paragraph" className="text-xs">
                  200 to 800
                </Typography>
              </div>
              <div className="w-[320px] border-x border-[#c5c5c5]">
                <div className="bg-[#c5c5c5] h-6">
                  <div className="flex items-center justify-end bg-[#003f5f] h-6 px-1 w-1/2">
                    <Typography
                      variant="paragraph"
                      className="text-sm text-white"
                    >
                      {scoreMath}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between mx-1">
                  <Typography
                    variant="paragraph"
                    className="text-sm text-[#7e817e]"
                  >
                    200
                  </Typography>
                  <Typography
                    variant="paragraph"
                    className="text-sm text-[#7e817e]"
                  >
                    800
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center space-y-2">
              <Typography
                variant="paragraph"
                className="text-2xl font-semibold"
              >
                Math
              </Typography>
              <div className="flex items-center space-x-4">
                <Typography variant="paragraph" className="text-5xl font-bold">
                  {scoreVerbal}
                </Typography>
                <div className="border-l-2 border-[#000000] h-10" />
                <Typography variant="paragraph" className="text-xs">
                  200 to 800
                </Typography>
              </div>
              <div className="w-[320px] border-x border-[#c5c5c5]">
                <div className="bg-[#c5c5c5] h-6">
                  <div className="flex items-center justify-end bg-[#003f5f] h-6 px-1 w-1/2">
                    <Typography
                      variant="paragraph"
                      className="text-sm text-white"
                    >
                      {scoreMath}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between mx-1">
                  <Typography
                    variant="paragraph"
                    className="text-sm text-[#7e817e]"
                  >
                    200
                  </Typography>
                  <Typography
                    variant="paragraph"
                    className="text-sm text-[#7e817e]"
                  >
                    800
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-[-50px] left-1/2 -translate-x-1/2 w-[100px]">
            <div className="border-[50px] border-t-black border-t-[25px] border-b-0 border-transparent"></div>
            <div className="relative bottom-[26px] border-[50px] border-t-white border-t-[25px] border-b-0 border-transparent"></div>
          </div>
        </div>
        <div className="mt-12">
          <Tabs value={activeTabTest}>
            <TabsHeader
              className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
              indicatorProps={{
                className:
                  'bg-transparent border-b-2 border-gray-900 shadow-none rounded-none',
              }}
            >
              {tabDataTest({
                scoreMath,
                scoreVerbal,
                createdAtAnswers,
                updatedAtAnswers,
                createdAtFinalAnswers,
                updatedAtFinalAnswers,
                correctAnswers,
                incorrectAnswers,
                blankAnswers,
                moduleId,
                activeTabTable,
                questions,
                setActiveTabTable,
                correctFinalAnswers,
                incorrectFinalAnswers,
                blankFinalAnswers,
                questionsFinal,
                accordionOpen,
                handleAccordionOpen,
                dataTopic,
                handleOpenModal,
                activeTabModule,
                setActiveTabModule,
              }).map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => {
                    setActiveTabTest(value)
                    setModuleId((moduleId) => (moduleId === 1 ? 3 : 1))
                  }}
                  className={classNames('justify-start p-4', {
                    'bg-gray-50': activeTabTest === value,
                  })}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {tabDataTest({
                scoreMath,
                scoreVerbal,
                createdAtAnswers,
                updatedAtAnswers,
                createdAtFinalAnswers,
                updatedAtFinalAnswers,
                correctAnswers,
                incorrectAnswers,
                blankAnswers,
                moduleId,
                activeTabTable,
                questions,
                setActiveTabTable,
                correctFinalAnswers,
                incorrectFinalAnswers,
                blankFinalAnswers,
                questionsFinal,
                accordionOpen,
                handleAccordionOpen,
                dataTopic,
                handleOpenModal,
                activeTabModule,
                setActiveTabModule,
              }).map(({ value, component }) => (
                <TabPanel key={value} value={value}>
                  {component}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
      <Dialog
        open={openModal}
        handler={handleOpenModal}
        size="xl"
        className="text-black p-4"
      >
        <XMarkIcon
          className="absolute top-6 right-6 cursor-pointer w-5 h-5"
          onClick={handleOpenModal}
        />
        <DialogHeader>
          Reviewing Question {currentSelectedAnswer.number}
        </DialogHeader>
        <DialogBody className="text-black max-h-[calc(100vh-200px)] overflow-y-auto">
          <div className="flex space-x-4">
            {currentSelectedAnswer.description && (
              <div className="flex-1">
                <Typography
                  variant="paragraph"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      currentSelectedAnswer.description
                    ),
                  }}
                />
              </div>
            )}

            <div className="flex flex-col space-y-4 flex-1">
              {currentSelectedAnswer.type === 1 && (
                <MultipleChoiceQuestion
                  question={currentSelectedAnswer.question}
                  options={currentSelectedAnswer.options}
                  userAnswer={currentSelectedAnswer.userAnswer}
                  correctAnswer={currentSelectedAnswer.answer}
                  currentNumber={currentSelectedAnswer.number}
                  eliminatedAnswers={
                    currentSelectedAnswer?.elimination
                      ? JSON.parse(currentSelectedAnswer?.elimination)
                      : []
                  }
                />
              )}
              {currentSelectedAnswer.type === 3 && (
                <EquationEditorQuestion
                  question={currentSelectedAnswer.question}
                  userAnswer={currentSelectedAnswer.userAnswer}
                  correctAnswer={currentSelectedAnswer.answer}
                  currentNumber={currentSelectedAnswer.number}
                />
              )}
              {currentSelectedAnswer.explanation && (
                <Accordion
                  open={accordionExplanationOpen === 1}
                  icon={
                    <SatChevronIcon id={1} open={accordionExplanationOpen} />
                  }
                >
                  <AccordionHeader
                    onClick={() => handleAccordionExalanationOpen(1)}
                    className="text-black"
                  >
                    Question Explanation
                  </AccordionHeader>
                  <AccordionBody>
                    <Typography
                      variant="paragraph"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          currentSelectedAnswer.explanation
                        ),
                      }}
                    />
                  </AccordionBody>
                </Accordion>
              )}
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default SatReport
