import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import IcClose from '../../../assets/ClosePack.svg'
import IcTest from '../../../assets/Avatar.svg'
import { Option, Select } from '@material-tailwind/react'
import axios from '../../../utils/axios'
import Notify from '../../Notify'
import { toast } from 'react-toastify'
import SelectReact from 'react-select'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'

const listClassCategory = [
  {
    label: 'IELTS',
    value: 'ielts',
  },
  {
    label: 'TOEFL IBT',
    value: 'tibt',
  },
  {
    label: 'English for Business',
    value: 'efb',
  },
]

const ModalSpeakingScore = ({ handleModal, isOpen, data, classCategory }) => {
  const [student, setStudent] = useState([])
  const [listSubject, setListSubject] = useState([])
  const [idStudent, setIdStudent] = useState('')
  const [idSubject, setIdSubject] = useState('')
  const [modalNotify, setModalNotify] = useState(false)
  const [selectedClassCategories, setSelectedClassCategories] =
    useState(classCategory)
  const [formData, setFormData] = useState({
    fc: '',
    lr: '',
    gra: '',
    p: '',
    grand_score: '',
  })

  const fetchStudent = async () => {
    try {
      const res = await axios.get('/users?role=3')
      const arrStudent = res.data.results.map((data) => ({
        value: data.id,
        label: data.name,
      }))
      setStudent(arrStudent)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const fetchSubject = async () => {
    try {
      const res = await axios.get('/questions/subject')
      const subjects = res.data.results.map((val) => {
        const capitalizedWords = val.name
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
        return { label: capitalizedWords, value: val.id }
      })
      setListSubject(subjects)
    } catch (error) {
      toast.error(error.message || 'Something went wrong, please try again')
    }
  }

  const handleSave = () => {
    setModalNotify(true)
  }

  const save = async () => {
    try {
      await axios.put(
        `/answers/${
          data?.testName
            ? data.testName.toLowerCase().replace(' ', '-')
            : idSubject
        }/speaking/${selectedClassCategories}/${idStudent}`,
        formData
      )
      handleModal()
      handleModalNotify()
      toast.success('Score berhasil di simpan')
    } catch (error) {
      const errorMessage =
        error.message ||
        (error.code === 'ERR_BAD_REQUEST'
          ? 'Student atau Subject tidak boleh kosong!'
          : 'Something went wrong, please try again')
      toast.error(errorMessage)
    }
  }

  const fetchClass = async () => {
    try {
      await axios.get('/profile/classes').then((res) => {
        console.log('clases:', res)
      })
    } catch (error) {}
  }

  const handleSubject = async (value) => {
    setIdSubject(value.toLowerCase().replace(' ', '-'))
    try {
      const result = await axios.get(
        `/answers/${value
          .toLowerCase()
          .replace(' ', '-')}/speaking/${selectedClassCategories}/${idStudent}`
      )
      if (result.data.result.speaking_answer) {
        setFormData(result.data.result.speaking_answer)
      }
    } catch (error) {
      setFormData({
        fc: '',
        lr: '',
        gra: '',
        p: '',
        grand_score: '',
      })
    }
  }

  const handleClassCategory = async (value) => {
    setSelectedClassCategories(value)
    try {
      const result = await axios.get(
        `/answers/${
          data?.testName
            ? data.testName.toLowerCase().replace(' ', '-')
            : idSubject
        }/speaking/${value}/${idStudent}`
      )
      if (result.data.result.speaking_answer) {
        setFormData(result.data.result.speaking_answer)
      }
    } catch (error) {
      setFormData({
        fc: '',
        lr: '',
        gra: '',
        p: '',
        grand_score: '',
      })
    }
  }

  const handleStudent = async (event) => {
    setIdStudent(event.value)
    try {
      const result = await axios.get(
        `/answers/${
          data?.testName
            ? data.testName.toLowerCase().replace(' ', '-')
            : idSubject
        }/speaking/${selectedClassCategories}/${event.value}`
      )
      if (result.data.result.speaking_answer) {
        setFormData(result.data.result.speaking_answer)
      }
    } catch (error) {
      setFormData({
        fc: '',
        lr: '',
        gra: '',
        p: '',
        grand_score: '',
      })
    }
  }

  const handleModalNotify = () => {
    setModalNotify((prevState) => !prevState)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    fetchStudent()
    fetchSubject()
    fetchClass()
  }, [])

  useEffect(() => {
    if (data && listSubject.length) {
      const testNameToLowerCase = data.testName.toLowerCase().replace(' ', '-')
      const subject = listSubject.find(
        (subject) => subject.label === data.testName
      )

      setIdStudent(data.userId)
      setIdSubject(subject ? subject.value : '')

      axios
        .get(
          `/answers/${testNameToLowerCase}/speaking/${selectedClassCategories}/${data.userId}`
        )
        .then((result) => {
          if (result.data.result.speaking_answer) {
            setFormData(result.data.result.speaking_answer)
          }
        })
        .catch((error) => {
          setFormData({
            fc: '',
            lr: '',
            gra: '',
            p: '',
            grand_score: '',
          })
        })
    }
  }, [data, listSubject, selectedClassCategories])

  return (
    <>
      <Dialog open={isOpen} className="max-h-screen overflow-auto">
        <img
          src={IcClose}
          onClick={handleModal}
          className="w-7 h-7 absolute right-10 top-5 cursor-pointer"
          alt="Close Icon"
        />
        <DialogHeader>
          <p className="text-2xl font-bold w-full text-center">
            Submit Score
            <br />
            <span className="text-sm text-[#808080]">Masukkan skor siswa</span>
          </p>
        </DialogHeader>
        <DialogBody>
          <form>
            <p className="text-[#064C85] text-lg">Select Student</p>
            <div className="w-full mt-3">
              <SelectReact
                options={student}
                onChange={handleStudent}
                defaultValue={
                  data ? { label: data.studentName, value: data.userId } : ''
                }
              />
            </div>
            <p className="mt-5 text-[#064C85] text-lg">Subject</p>
            <div className="mt-3">
              <SelectReact
                options={listSubject}
                onChange={(event) => handleSubject(event.label)}
                defaultValue={
                  data ? { label: data.testName, value: idSubject } : ''
                }
              />
            </div>
            <p className="mt-5 text-[#064C85] text-lg">Class Category</p>
            <div className="mt-3">
              <SelectReact
                options={listClassCategory}
                onChange={(event) => handleClassCategory(event.value)}
                defaultValue={
                  data
                    ? {
                        label: listClassCategory.find(
                          (classCategory) =>
                            classCategory.value === data.classCategory
                        )?.label,
                        value: selectedClassCategories,
                      }
                    : ''
                }
              />
            </div>
            <p className="mt-5 text-[#064C85] text-lg mb-2">Input Score</p>
            <div className="flex space-x-4">
              <input
                name="fc"
                placeholder="FC"
                className="w-full rounded-lg bg-[#FAFAFA]"
                value={formData.fc}
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                name="lr"
                placeholder="LR"
                className="w-full rounded-lg bg-[#FAFAFA]"
                value={formData.lr}
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                name="gra"
                placeholder="GRA"
                className="w-full rounded-lg bg-[#FAFAFA]"
                value={formData.gra}
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                name="p"
                placeholder="P"
                className="w-full rounded-lg bg-[#FAFAFA]"
                value={formData.p}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>

            <p className="mt-5 text-[#064C85] text-lg mb-2">Overall Score</p>
            <input
              name="grand_score"
              className="w-full rounded-lg bg-[#FAFAFA]"
              placeholder="Grand Score"
              value={formData.grand_score}
              onChange={handleChange}
              autoComplete="off"
            />
            <div>
              <button
                type="button"
                className="mt-7 p-2 w-full bg-[#064C85] text-white rounded-md"
                onClick={handleSave}
              >
                Submit
              </button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
      <Notify
        save={save}
        title="submit"
        isOpen={modalNotify}
        handleModal={handleModalNotify}
      />
    </>
  )
}

export default ModalSpeakingScore
