import WritingReminderIcon from '../../../assets/icons/writing-reminder.svg'
import UserIcon from '../../../assets/user.svg'

import { remainingTime } from '../../../utils/remainingDate'

const Deadlines = ({ data }) => {
  return (
    <div className="px-12">
      <table className="table-auto border-separate border-spacing-y-2 w-full">
        <tbody>
          {data?.map((item) => (
            <tr key={item.id}>
              <td className="p-4 border border-[#CCCCCC] border-r-0 rounded-l-lg">
                <div className="flex space-x-3">
                  <img
                    src={WritingReminderIcon}
                    alt="Writing Reminder Icon"
                    className="w-8 h-8"
                  />
                  <div className="space-y-1">
                    <p className="text-neromid text-[10px] font-bold">
                      Reminder : Review Writing Text
                    </p>
                    <p className="text-priblue-500 text-sm font-semibold font-Montserrat">
                      Writing {item.testName}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-nero-700 text-xs font-bold p-4 border-y border-[#CCCCCC]">
                {item.testName}
              </td>
              <td className="p-4 border-y border-[#CCCCCC] border-r-0">
                <div className="flex space-x-2 items-center">
                  <img src={UserIcon} alt="User Icon" />
                  <span className="text-nero-700 text-xs font-base">
                    by {item.studentName}
                  </span>
                </div>
              </td>
              <td className="p-4 border border-[#CCCCCC] border-l-0 rounded-r-lg">
                <div className="flex space-x-2 items-center  justify-end">
                  <p className="text-priblue-500 text-xs font-bold">
                    {remainingTime(item.createdAt, 7)}
                  </p>
                  <button
                    className="bg-[#EC6A2A] py-2 px-6 rounded-lg text-white text-xs"
                    onClick={item.onClickDetail}
                  >
                    Detail
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Deadlines
