import React, { useEffect, useState } from "react";
import useQuestionAnswer from "../../../hooks/useQuestionAnswer";
import { data } from "autoprefixer";

/*
  @@params{
    number : int
    question : string
    choices : array of string
  }
*/

const MultipleTypeQuestion = ({
  number,
  question,
  choices,
  id,
  answer,
  userAnswer,
}) => {
  choices = JSON.parse(choices);

  const right = "text-[#064C85] font-bold underline";

  return (
    <div className="flex my-4">
      <span className="mr-3" id={`q${number}`}>
        {number}.
      </span>
      <div className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        {typeof choices != "string"
          ? choices.map((val, idx) => (
              <section key={idx}>
                <input
                  type="radio"
                  id={`q${number}${val}`}
                  name={`question${number}`}
                  value={val}
                  className={`cursor-pointer`}
                  defaultChecked={userAnswer == val ? true : false}
                  disabled
                />
                <label
                  htmlFor={`q${number}${val}`}
                  className={`ms-2 cursor-pointer ${
                    val == answer ? right : null
                  }`}
                >
                  {val}
                </label>
              </section>
            ))
          : null}
      </div>
    </div>
  );
};

export default MultipleTypeQuestion;
