import {
  CheckIcon,
  StopCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Chip, Tooltip } from '@material-tailwind/react'
import SatReportAllQuestions from '../components/Sat/SatReportAllQuestions'
import SatReportTopicArea from '../components/Sat/SatReportTopicArea'
import SatReportFormatQuestions from '../components/Sat/SatReportFormatQuestions'
import SatReportModuleHeader from '../components/Sat/SatReportModuleHeader'
import SatReportModuleBody from '../components/Sat/SatReportModuleBody'
import SatReportTab from '../components/Sat/SatReportTab'

export const generateSatQuestions = (dataQuestions, dataAnswers) => {
  if (dataQuestions && dataAnswers) {
    const latestUserAnswers = dataAnswers

    dataQuestions.sort((a, b) => a.sort - b.sort)

    const questions = dataQuestions.map((question, index) => {
      const detailAnswer = latestUserAnswers.find(
        (answer) => answer.questionId === question.id
      )

      return {
        ...question,
        userAnswerId: detailAnswer?.id,
        userAnswer: detailAnswer?.answer,
        answer:
          Number(question.type) === 3 &&
          /^[\],:{}\s]*$/.test(
            question.answer
              .replace(/\\["\\/bfnrtu]/g, '@')
              .replace(
                /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
                ']'
              )
              .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
          )
            ? JSON.parse(question.answer)?.[0]
            : question?.answer,
        options: JSON.parse(question.choices),
        type: Number(question.type),
        first_time: detailAnswer?.first_time || '00:00:00',
        total_time: detailAnswer?.total_time || '00:00:00',
        time_view: 0,
        number: index + 1,
        elimination: detailAnswer?.elimination,
      }
    })

    return { questions, userAnswers: latestUserAnswers }
  }
}
export const renderSatEvaluateAnswer = (correctAnswer, userAnswer, type) => {
  let result = ''

  if (type === 1) {
    if (correctAnswer === userAnswer) {
      result = 'correct'
    } else if (!userAnswer) {
      result = 'blank'
    } else if (correctAnswer !== userAnswer) {
      result = 'incorrect'
    }
  }

  if (type === 3) {
    if (correctAnswer.find((answer) => answer === userAnswer)) {
      result = 'correct'
    } else if (!userAnswer) {
      result = 'blank'
    } else if (!correctAnswer.find((answer) => answer === userAnswer)) {
      result = 'incorrect'
    }
  }

  switch (result) {
    case 'correct':
      return (
        <Tooltip content="Correct">
          <CheckIcon className="w-4 h-4 text-[#a3da09]" />
        </Tooltip>
      )
    case 'incorrect':
      return (
        <Tooltip content="Incorrect">
          <XMarkIcon className="w-4 h-4 text-[#ff3e2a]" />
        </Tooltip>
      )
    default:
      return (
        <Tooltip content="Blank">
          <StopCircleIcon className="w-4 h-4 text-[#c5c5c5]" />
        </Tooltip>
      )
  }
}

export const renderSatDifficulty = (correctAnswers) => {
  switch (correctAnswers >= 0) {
    case correctAnswers <= 7:
      return <Chip value="Easier" color="green" />
    case correctAnswers <= 15:
      return <Chip value="Medium" color="amber" />
    case correctAnswers > 15:
      return <Chip value="Harder" color="red" />
    default:
      return <Chip value="Unknown" color="gray" />
  }
}

export const SAT_TABLE_HEAD_ALL_QUESTIONS = [
  'No',
  'Topic Area',
  'Result',
  'Total Time',
  'Time 1st Visit',
  'Times Viewed',
  '',
]
export const SAT_TABLE_HEAD_TOPIC_AREA = [
  'Topic Area',
  <Tooltip content="Correct" key="correct">
    <CheckIcon className="w-4 h-4 text-[#a3da09]" />
  </Tooltip>,
  <Tooltip content="Incorrect" key="incorrect">
    <XMarkIcon className="w-4 h-4 text-[#ff3e2a]" />
  </Tooltip>,
  <Tooltip content="Blank" key="blank">
    <StopCircleIcon className="w-4 h-4 text-[#c5c5c5]" />
  </Tooltip>,
]
export const SAT_TABLE_HEAD_FORMAT = [
  'Question Format',
  <Tooltip content="Correct" key="correct">
    <CheckIcon className="w-4 h-4 text-[#a3da09]" />
  </Tooltip>,
  <Tooltip content="Incorrect" key="incorrect">
    <XMarkIcon className="w-4 h-4 text-[#ff3e2a]" />
  </Tooltip>,
  <Tooltip content="Blank" key="blank">
    <StopCircleIcon className="w-4 h-4 text-[#c5c5c5]" />
  </Tooltip>,
]

export const generateSatQuestionByKey = (data, key) => {
  let generateQuestions = data.reduce((acc, item) => {
    const { topicId } = item
    const { type } = item
    const isCorrect =
      item.type === 3
        ? item.answer.includes(item.userAnswer)
        : item.userAnswer === item.answer
    const isIncorrect =
      item.type === 3
        ? !item.answer.includes(item.userAnswer)
        : item.userAnswer === item.answer
    const isBlank = !item.userAnswer

    if (!acc[key] && key === 'topic') {
      acc[key] = {
        topicId,
        correct: 0,
        incorrect: 0,
        blank: 0,
        answers: [],
      }
    }

    if (!acc[key] && key === 'type') {
      acc[key] = {
        type: Number(type),
        correct: 0,
        incorrect: 0,
        blank: 0,
        answers: [],
      }
    }

    if (isCorrect) {
      acc[key].correct += 1
    }

    if (isIncorrect) {
      acc[key].incorrect += 1
    }

    if (isBlank) {
      acc[key].blank += 1
    }

    acc[key].answers = [...acc[key].answers, item]

    return acc
  }, {})

  generateQuestions = Object.keys(generateQuestions).map(
    (key) => generateQuestions[key]
  )

  return generateQuestions
}

export const calculateSatAnswerStats = (questions) => {
  const correctAnswers = questions.filter((question) => {
    return question.type === 3
      ? question.answer.includes(question.userAnswer)
      : question.userAnswer === question.answer
  }).length

  const incorrectAnswers = questions.filter((question) => {
    return question.type === 3
      ? !question.answer.includes(question.userAnswer)
      : question.userAnswer !== question.answer
  }).length

  const blankAnswers = questions.filter(
    (question) => !question.userAnswer
  ).length

  return { correctAnswers, incorrectAnswers, blankAnswers }
}

export const tabDataTable = ({
  questions,
  accordionOpen,
  handleAccordionOpen,
  dataTopic,
  handleOpenModal,
}) => [
  {
    label: 'All Questions',
    value: 'allQuestions',
    component: (
      <SatReportAllQuestions
        questions={questions}
        dataTopic={dataTopic}
        handleOpenModal={handleOpenModal}
      />
    ),
  },
  {
    label: 'Topic Area',
    value: 'topicArea',
    component: (
      <SatReportTopicArea
        questions={questions}
        dataTopic={dataTopic}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        handleOpenModal={handleOpenModal}
      />
    ),
  },
  {
    label: 'Format',
    value: 'format',
    component: (
      <SatReportFormatQuestions
        questions={questions}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        handleOpenModal={handleOpenModal}
      />
    ),
  },
]
export const tabDataModule = ({
  createdAtAnswers,
  updatedAtAnswers,
  createdAtFinalAnswers,
  updatedAtFinalAnswers,
  correctAnswers,
  incorrectAnswers,
  blankAnswers,
  moduleId,
  activeTabTable,
  questions,
  setActiveTabTable,
  correctFinalAnswers,
  incorrectFinalAnswers,
  blankFinalAnswers,
  questionsFinal,
  accordionOpen,
  handleAccordionOpen,
  dataTopic,
  handleOpenModal,
}) => [
  {
    label: (
      <SatReportModuleHeader
        moduleNumber={1}
        moduleId={moduleId}
        correctAnswers={correctAnswers}
        incorrectAnswers={incorrectAnswers}
        blankAnswers={blankAnswers}
        createdAt={createdAtAnswers}
        updatedAt={updatedAtAnswers}
      />
    ),
    value: 'module1',
    component: (
      <SatReportModuleBody
        questions={questions}
        activeTabTable={activeTabTable}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        dataTopic={dataTopic}
        handleOpenModal={handleOpenModal}
        setActiveTabTable={setActiveTabTable}
      />
    ),
  },
  {
    label: (
      <SatReportModuleHeader
        moduleNumber={2}
        moduleId={moduleId}
        correctAnswers={correctFinalAnswers}
        incorrectAnswers={incorrectFinalAnswers}
        blankAnswers={blankFinalAnswers}
        createdAt={createdAtFinalAnswers}
        updatedAt={updatedAtFinalAnswers}
      />
    ),
    value: 'module2',
    component: (
      <SatReportModuleBody
        questions={questionsFinal}
        activeTabTable={activeTabTable}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        dataTopic={dataTopic}
        handleOpenModal={handleOpenModal}
        setActiveTabTable={setActiveTabTable}
      />
    ),
  },
]
export const tabDataTest = ({
  scoreMath,
  scoreVerbal,
  createdAtAnswers,
  updatedAtAnswers,
  createdAtFinalAnswers,
  updatedAtFinalAnswers,
  correctAnswers,
  incorrectAnswers,
  blankAnswers,
  moduleId,
  activeTabTable,
  questions,
  setActiveTabTable,
  correctFinalAnswers,
  incorrectFinalAnswers,
  blankFinalAnswers,
  questionsFinal,
  accordionOpen,
  handleAccordionOpen,
  dataTopic,
  handleOpenModal,
  activeTabModule,
  setActiveTabModule,
}) => [
  {
    label: 'Reading and Writing',
    value: 'readingAndWriting',
    component: (
      <SatReportTab
        title="Your Reading and Writing Score"
        correctAnswers={correctAnswers}
        incorrectAnswers={incorrectAnswers}
        blankAnswers={blankAnswers}
        moduleId={moduleId}
        activeTabTable={activeTabTable}
        questions={questions}
        setActiveTabTable={setActiveTabTable}
        correctFinalAnswers={correctFinalAnswers}
        incorrectFinalAnswers={incorrectFinalAnswers}
        blankFinalAnswers={blankFinalAnswers}
        questionsFinal={questionsFinal}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        dataTopic={dataTopic}
        handleOpenModal={handleOpenModal}
        activeTabModule={activeTabModule}
        setActiveTabModule={setActiveTabModule}
        createdAtAnswers={createdAtAnswers}
        updatedAtAnswers={updatedAtAnswers}
        createdAtFinalAnswers={createdAtFinalAnswers}
        updatedAtFinalAnswers={updatedAtFinalAnswers}
        scoreMath={scoreMath}
        scoreVerbal={scoreVerbal}
      />
    ),
  },
  {
    label: 'Math',
    value: 'math',
    component: (
      <SatReportTab
        title="Your Math Score"
        correctAnswers={correctAnswers}
        incorrectAnswers={incorrectAnswers}
        blankAnswers={blankAnswers}
        moduleId={moduleId}
        activeTabTable={activeTabTable}
        questions={questions}
        setActiveTabTable={setActiveTabTable}
        correctFinalAnswers={correctFinalAnswers}
        incorrectFinalAnswers={incorrectFinalAnswers}
        blankFinalAnswers={blankFinalAnswers}
        questionsFinal={questionsFinal}
        accordionOpen={accordionOpen}
        handleAccordionOpen={handleAccordionOpen}
        dataTopic={dataTopic}
        handleOpenModal={handleOpenModal}
        activeTabModule={activeTabModule}
        setActiveTabModule={setActiveTabModule}
        createdAtAnswers={createdAtAnswers}
        updatedAtAnswers={updatedAtAnswers}
        createdAtFinalAnswers={createdAtFinalAnswers}
        updatedAtFinalAnswers={updatedAtFinalAnswers}
        scoreMath={scoreMath}
        scoreVerbal={scoreVerbal}
      />
    ),
  },
]
