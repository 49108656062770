import Table from '../../../components/Table'
import Toolbar from './Toolbar'
import Deadlines from './Deadlines'

const TestResult = ({
  onClickTab,
  activeTab,
  categories,
  tableTitle,
  pendingData,
  deadlineData,
}) => {
  const columns = [
    { slug: 'no', name: 'No', orderable: true },
    { slug: 'student', name: 'Student', orderable: true },
    { slug: 'class', name: 'Class', orderable: true },
    { slug: 'test', name: 'Test', orderable: true },
    { slug: 'email', name: 'Email', orderable: true },
    { slug: 'score', name: 'Score', orderable: true },
    { slug: 'status', name: 'Status', orderable: true },
    { slug: 'detail_score', name: 'Detail Score', orderable: false },
  ]

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg py-6">
      <div className="px-12">
        <h2 className="text-2xl pb-1 text-priblue-500 mb-4">Jobs for you</h2>
        <Toolbar
          onClickTab={onClickTab}
          activeTab={activeTab}
          categories={categories}
        />
        <div className="flex flex-col space-y-2">
          <h2 className="text-lg pb-1 text-priblue-500">
            {tableTitle === 'Deadlines' ? 'Writing Deadlines' : tableTitle}
          </h2>
          <p className="text-sm font-normal text-[#808080]">
            Lihat writing test yang telah dikerjakan siswamu disini
          </p>
        </div>
      </div>
      {activeTab === 'pending_review' && (
        <Table columns={columns} data={pendingData} />
      )}
      {activeTab === 'deadlines' && <Deadlines data={deadlineData} />}
    </div>
  )
}

export default TestResult
