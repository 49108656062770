import React, { useState } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import AudioForm from '../../form/audio'

const RecordingQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    question,
    number,
    recording_duration,
    preparation_time,
  } = data

  const handleChangeRecordingDuration = (e) => {
    setBuilderDataById(idx, containerId, {
      ...data,
      recording_duration: e.target.value,
    })
  }

  const handleChangePreparationTime = (e) => {
    setBuilderDataById(idx, containerId, {
      ...data,
      preparation_time: e.target.value,
    })
  }

  const handleChangeAudioFile = (e) => {
    addAudioFiles(e.target.files[0])
  }

  const addAudioFiles = (audioFile) => {
    setBuilderDataById(idx, containerId, { ...data, files: audioFile })
  }

  // Handler
  const deleteHandler = () => {
    deleteBuilder(idx, containerId, classCategory)
  }
  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  // Question
  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const getAudioName = () => {
    if (data.audio_name && !data.files) {
      return data.audio_name
    }

    if (data.files) {
      return data.files.name
    }
  }

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Recording Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={deleteHandler}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">Number : {number}</section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      <section className="mb-4">
        <label>Preparation Time (hh:mm:ss)</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="duration"
          title="hh:mm:ss"
          pattern="\d\d:\d\d:\d\d"
          placeholder="hh:mm:ss format"
          value={preparation_time}
          onChange={handleChangePreparationTime}
        />
      </section>
      <section className="mb-4">
        <label>Maximum Recording Duration (hh:mm:ss)</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="duration"
          title="hh:mm:ss"
          pattern="\d\d:\d\d:\d\d"
          placeholder="hh:mm:ss format"
          value={recording_duration}
          onChange={handleChangeRecordingDuration}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={question}
          setValue={setQuestion}
          idx={`${containerId}-${idx}`}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Audio</span>
        <AudioForm
          id={idx}
          title="Audio"
          audioName={getAudioName()}
          onChangeAudio={handleChangeAudioFile}
          noAudioText
        />
      </section>

      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default RecordingQuestionBuilder
