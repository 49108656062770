import React, { useState, useEffect } from 'react'
import axios from '../../../utils/axios'

import Loading from '../../../components/Loading'
import Stats from '../../../components/Stats'

import StudyIcon from '../../../assets/study.svg'
import AcceptIcon from '../../../assets/accept.svg'
import FileIcon from '../../../assets/file.svg'

const Statistic = () => {
  const [countStats, setCountStats] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`/count/all`)
      .then((res) => {
        setCountStats(res.data.results)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg py-6 px-12">
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-2xl pb-1 text-priblue-500">
            Speaking Test Master
          </h2>
          <p className="text-sm font-normal text-[#808080]">
            {/* Lorem ipsum dolor sit amet lorem ipsum dolor */}
          </p>
        </div>
        <button className="bg-[#EC6A2A] py-3 px-20 rounded-lg text-white text-xs">
          Submit
        </button>
      </div>
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        <Stats
          icon={StudyIcon}
          title="Total Students Active"
          total={countStats.students}
        />
        <Stats
          icon={StudyIcon}
          title="Total Appliciant Assigned"
          total={countStats.appliciant}
        />
        <Stats
          icon={AcceptIcon}
          title="Review Completed"
          total={countStats.completed}
        />
        <Stats
          icon={FileIcon}
          title="Review Pending"
          total={countStats.pending}
        />
      </div>
    </div>
  )
}

export default Statistic
