import React, { useState } from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'
import AudioPlayer from '../../../AudioPlayer'
import { Url } from '../../../../global'
import RecordingPlayer from '../../../RecordingPlayer'
import moment from 'moment/moment'

const RecordingQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question,
    choices = [],
    answer = '',
    point,
    number,
    files,
    audio_name,
    recording_duration,
  } = getBuilderDataById(idx, containerId)

  const timeRecordingDuration = moment.duration(recording_duration)

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">{number}.</span>
        <div className="flex flex-col gap-4 flex-1">
          <div dangerouslySetInnerHTML={{ __html: question }} />
          {files ? (
            <AudioPlayer audioUrl={URL.createObjectURL(files)} />
          ) : (
            <></>
          )}
          {audio_name && !files ? (
            <AudioPlayer audioUrl={`${Url}/media/${audio_name}`} />
          ) : (
            <></>
          )}
          <RecordingPlayer
            maximumDuration={timeRecordingDuration.asSeconds()}
          />
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default RecordingQuestionPreview
