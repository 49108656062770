import React, { useState } from 'react'
import moment from 'moment'
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from '@material-tailwind/react'

import StudentImage from '../../../../assets/avatar.jpg'
import DotsVerticalcon from '../../../../assets/icons/dots-vertical.svg'

const Comment = ({
  highlight,
  writingType,
  currentUser,
  onDeleteComment,
  onEditComment,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState(highlight.text)

  const handleEditComment = (event) => setEditedComment(event.target.value)
  const openEditComment = () => setIsEditing(true)
  const closeEditComment = () => {
    setIsEditing(false)
    setEditedComment(highlight.text)
  }

  const handleComment = (commentData) => {
    onEditComment(writingType, highlight.highlight, commentData)
    closeEditComment()
  }

  return (
    <div>
      <div className="flex space-x-4 mb-4">
        <img src={StudentImage} alt="Student" className="w-14 h-14" />
        <div className="grow">
          <p className="text-nero-700 text-lg font-semibold font-Montserrat">
            {highlight.name}
          </p>
          <p className="text-neromid text-sm">
            {moment(highlight.createdAt).format('MMM D, YYYY, h:mm A')}
          </p>
        </div>
        {highlight.userId === currentUser.userId && (
          <Popover placement="bottom-end">
            <PopoverHandler>
              <img
                src={DotsVerticalcon}
                alt="Dots Icon"
                className="cursor-pointer"
              />
            </PopoverHandler>
            <PopoverContent className="p-0">
              <div
                className="text-sm text-nero-700 py-3 px-4 text-center hover:bg-[#F9FAFB] cursor-pointer"
                onClick={openEditComment}
              >
                Edit
              </div>
              <div
                className="text-sm text-nero-700 py-3 px-4  text-center hover:bg-[#F9FAFB] cursor-pointer"
                onClick={() =>
                  onDeleteComment(
                    writingType,
                    highlight.highlight,
                    highlight.id
                  )
                }
              >
                Hapus
              </div>
            </PopoverContent>
          </Popover>
        )}
      </div>
      {!isEditing && (
        <p className="text-nero-700 text-sm leading-7 ml-[4.5rem] text-justify">
          {highlight.text}
        </p>
      )}
      {isEditing && (
        <>
          <textarea
            value={editedComment}
            onChange={handleEditComment}
            className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full min-h-[45px] focus:outline-none focus:ring-0"
            rows={4}
          />
          <div className="flex space-x-4">
            <button
              className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)] min-h-[45px]"
              onClick={() =>
                handleComment({
                  id: highlight.id,
                  text: editedComment,
                })
              }
            >
              Edit Comment
            </button>
            <button
              className="px-8 py-3 rounded-[4px] border border-[#CCC] text-xs text-nero-700 min-h-[45px]"
              onClick={closeEditComment}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default Comment
