import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStatusMentor } from '../../../../slice/status'
import axios from '../../../../utils/axios'
import ModalDetailStudent from '../../../ModalDetailStudent'
import { format } from 'date-fns'
import Notify from '../../../Notify'
import { toast } from 'react-toastify'

const TABLE_HEAD = [
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    maxWidth: '300px',
  },
  {
    name: 'Mulai Kelas',
    selector: (row) => format(new Date(row.start_date), 'dd MMMM yyyy'),
  },
  {
    name: 'Selesai Kelas',
    selector: (row) => format(new Date(row.end_date), 'dd MMMM yyyy'),
  },
  {
    name: 'Action',
    selector: (row) => row.action,
  },
]

let TABLE_ROWS = []

export default function ListClass() {
  const navigate = useNavigate()
  const status = useSelector((state) => state.status.listMentor)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [dataModal, setDataModal] = useState('')
  const [modalDelete, setModalDelete] = useState(false)
  const [classSelected, setClassSelected] = useState('')

  const cancel = () => setModal(false)

  useEffect(() => {
    // if (status == true) {
    fetchData()
    // }
  }, [])

  const addClass = () => {
    navigate('/data/addclass')
  }

  const editClass = (item) => {
    navigate('/data/editclass', {
      state: {
        dataclass: item,
      },
    })
  }

  const handleDelete = (classId) => {
    alert(classId)
    setModalDelete(true)
    setClassSelected(classId)
  }

  const handleModalDelete = () => {
    setModalDelete((prevState) => !prevState)
  }

  const deleteClass = async () => {
    await axios
      .delete(`classes/${classSelected}`)
      .then(() => {
        fetchData()
        toast.success('Berhasil delete user')
        setModalDelete(false)
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const fetchData = async () => {
    setLoading(true)
    await axios
      .get(`/classes`)
      .then((res) => {
        res.data.results.forEach((item) => {
          if (res.data.results.length != TABLE_ROWS.length) {
            TABLE_ROWS.push({
              name: item.name,
              start_date: item.start_date,
              end_date: item.end_date,
              action: (
                <div className="flex">
                  <Tooltip content="Delete Class">
                    <IconButton
                      variant="text"
                      className="text-[#667085] flex justify-center items-center"
                    >
                      <TrashIcon
                        className="h-4 w-4"
                        onClick={() => handleDelete(item.id)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Edit Class">
                    <IconButton
                      variant="text"
                      className="text-[#667085] flex justify-center items-center"
                    >
                      <PencilIcon
                        className="h-4 w-4"
                        onClick={() => editClass(item)}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              ),
            })
          }
        })
        dispatch(setStatusMentor(true))
        setLoading(false)
      })
      .catch((err) => {
        dispatch(setStatusMentor(false))
        setLoading(false)
      })
  }

  // filter
  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false)
  const filteredItems = TABLE_ROWS.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  const handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state

    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
    }
  }

  const FilterComponent = ({ filterText, onFilter }) => (
    <div className="flex items-center">
      <MagnifyingGlassIcon className="h-4 w-4 absolute ml-2" />
      <input
        type="text"
        autoFocus="autoFocus"
        className="border-2 px-7 w-72 py-2"
        placeholder="Search"
        value={filterText}
        onChange={onFilter}
      />
    </div>
  )

  const getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value
          setFilterText(newFilterText)
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }

  return (
    <Card className="h-full w-full">
      {console.log('status delete:', modalDelete)}

      {modal ? (
        <ModalDetailStudent form={`class`} close={cancel} data={dataModal} />
      ) : (
        ''
      )}
      <Notify
        handleModal={handleModalDelete}
        save={deleteClass}
        title="delete"
        isOpen={modalDelete}
      />

      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Class
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              See information about all members
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            <Button
              className="flex items-center gap-3 bg-[#064C85]"
              size="sm"
              onClick={addClass}
            >
              <PlusIcon strokeWidth={2} className="h-4 w-4" /> Add Class
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <DataTable
          columns={TABLE_HEAD}
          data={filteredItems}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="400px"
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={getSubHeaderComponent()}
          persistTableHead
        />
      </CardBody>
    </Card>
  )
}
