import React from 'react';
import '../../index.css';

const HighlightButtonsGroup =  ({ saveAndRestoreSelection, hidden, layout }) => (
  <div hidden={hidden}
    style={{
      position: layout.position,
      left: layout.left,
      top: layout.top,
      width: `${layout.widthInPixel}px`,
      height: `${layout.heightInPixel}px`
    }}
  >
    <div className="buttons-group">
      <button
        onClick={saveAndRestoreSelection} style={{ padding: 5, background: '#FB8818', color: '#FFF' }}
      >
        comment
      </button>
      <div className="buttons-group__down-arrow-tip"></div>
    </div>
  </div>
)

export default HighlightButtonsGroup;
