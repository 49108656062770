import React from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const MultipleQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    id,
    question,
    choices = [],
    answer = '',
    point,
    number,
  } = getBuilderDataById(idx, containerId)

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">{number}.</span>
        <div className="flex flex-col gap-1">
          <div dangerouslySetInnerHTML={{ __html: question }} />
          {choices?.map((val, idx) => (
            <section key={`${containerId}-${idx}-${id}`}>
              <input
                type="radio"
                id={`${containerId}-${idx}-${id}`}
                checked={val === answer}
                disabled
                name={`answer-${containerId}-${idx}-${id}`}
              />
              <label htmlFor={`${containerId}-${idx}-${id}`} className="ms-2 ">
                {val}
              </label>
            </section>
          ))}
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default MultipleQuestionPreview
