import { configureStore } from '@reduxjs/toolkit'
import accountReducer from './slice/account'
import questionReducer from './slice/question'
import questionDescReducer from './slice/questionDesc'
import statusReducer from './slice/status'

export default configureStore({
  reducer: {
    account: accountReducer,
    question: questionReducer,
    questionDesc: questionDescReducer,
    status: statusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
