import { useState } from 'react'
import { useSelector } from 'react-redux'
import Drop from '../Question/widgets/drop'
import Mark from '../Question/widgets/mark'
import Preview from '../Question/widgets/preview'
import DescriptionReading from '../Question/widgets/description/reading'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import ChevronUpDarkIcon from '../../assets/icons/chevron-up-dark.svg'

const IconDark = ({ open }) => (
  <img
    src={ChevronUpDarkIcon}
    className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    alt="Chevron Dark Colored Icon"
  />
)

const TabsReading = ({
  selectedClassCategories,
  setSelectedClassCategories,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const description = useSelector((s) => s.questionDesc)
  const [openAcc1, setOpenAcc1] = useState(true)
  const [openAcc2, setOpenAcc2] = useState(false)
  const [openAcc3, setOpenAcc3] = useState(false)

  const handleOpenAcc1 = () => setOpenAcc1((cur) => !cur)
  const handleOpenAcc2 = () => setOpenAcc2((cur) => !cur)
  const handleOpenAcc3 = () => setOpenAcc3((cur) => !cur)

  const setTabIndex = (idx) => {
    // TODO: ui
    if (!description.id && idx > 0)
      return alert('Pilih subject terlebih dahulu')

    setActiveTabIndex(idx)
  }

  const handleClassCategories = (event) =>
    setSelectedClassCategories(event.target.value)

  const tabsData = [
    {
      label: 'Description',
      content: (
        <DescriptionReading
          onChangeClassCategories={handleClassCategories}
          selectedClassCategories={selectedClassCategories}
        />
      ),
    },
    {
      label: 'Add Items',
      content:
        selectedClassCategories === 'tibt' ? (
          <>
            <Accordion open={openAcc1} icon={<IconDark open={openAcc1} />}>
              <AccordionHeader onClick={handleOpenAcc1}>
                Section 1
              </AccordionHeader>
              <AccordionBody>
                <Drop id={1} classCategory={selectedClassCategories} />
              </AccordionBody>
            </Accordion>
            <Accordion open={openAcc2} icon={<IconDark open={openAcc2} />}>
              <AccordionHeader onClick={handleOpenAcc2}>
                Section 2
              </AccordionHeader>
              <AccordionBody>
                <Drop id={2} classCategory={selectedClassCategories} />
              </AccordionBody>
            </Accordion>
            <Accordion open={openAcc3} icon={<IconDark open={openAcc3} />}>
              <AccordionHeader onClick={handleOpenAcc3}>
                Section 3
              </AccordionHeader>
              <AccordionBody>
                <Drop id={3} classCategory={selectedClassCategories} />
              </AccordionBody>
            </Accordion>
          </>
        ) : (
          <Drop id={1} classCategory={selectedClassCategories} />
        ),
    },
    {
      label: 'Preview',
      content: <Preview />,
    },
    {
      label: 'Explanations',
      content: <Mark />,
    },
  ]

  return (
    <div>
      <div className="flex space-x-1">
        {tabsData.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                idx === activeTabIndex
                  ? 'bg-[#FFFFFF] border-b-0'
                  : 'border-transparen'
              }`}
              onClick={() => setTabIndex(idx)}
            >
              {tab.label}
            </button>
          )
        })}
      </div>
      <div className="py-4">
        <section>{tabsData[activeTabIndex].content}</section>
      </div>
    </div>
  )
}

export default TabsReading
