import React from "react";
import useQuestionAnswer from "../../../hooks/useQuestionAnswer";
import check from "../../../assets/check.svg";
import wrong from "../../../assets/wrong.svg";

/*
    @@params{
        number : int
        postText : string
        choices : array of string
    }

*/

const SelectTypeQuestion = ({
  number,
  postText = "",
  choices = [],
  type,
  id,
  answer,
  userAnswer,
}) => {
  const { setAnswer } = useQuestionAnswer();

  const inputHandler = (e) => {
    setAnswer(number, e.target.value, id);
  };

  return (
    <div className="my-4 flex flex-row">
      <span id={`q${number}`}> {number}. </span>
      <div className={answer ? answer == userAnswer ? `bg-[#E2FCF3]` : `bg-[#FDE0E9]` : null}>
        <select
          className={answer ? answer == userAnswer ? `bg-[#27AE60]` : `bg-[#EB5757]` : null `px-4 py-2 mx-2`}
          name={`question${number}`}
          onChange={inputHandler}
          defaultValue={userAnswer}
        >
          <option value="none" disabled hidden></option>
          {choices.map((val, idx) => (
            <option value={val} key={idx} selected={val == userAnswer ? true : false}>
              {parseInt(type) === 5 ? val : String.fromCharCode(65 + idx)}
            </option>
          ))}
        </select>
        <span> {postText}</span>
        <span> {postText}</span>
      </div>
      {
        answer ? 
          answer == userAnswer ? 
          (<img src={check} className="bg-[#E2FCF3]" />) : (<img src={wrong} className="bg-[#FDE0E9]" />) :
        null
      }
      
    </div>
  );
};

export default SelectTypeQuestion;
