import React, { useState, useEffect } from 'react'
import axios from '../../../utils/axios'

import DefaultStudentImage from '../../../assets/default-student.png'
import Sidebar from '../../../components/Sidebar'
import Tabs from '../../../components/Tabs'
import Breadcrumb from '../../../components/Breadcrumb'
import Loading from '../../../components/Loading'

import TestResult from './TestResult'
import Statistic from './Statistic'
import Drawer from '../Drawer'
import { debounce } from 'lodash'

const TodoSpeaking = () => {
  const [tabActive, setTabActive] = useState('ielts')
  const [tabActiveSpeaking, setTabActiveSpeaking] = useState()
  const [testResultTableTitle, setTestResultTableTitle] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [testResultData, setTestResultData] = useState([])
  const [selectedUserAnswer, setSelectedUserAnswer] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [listSubject, setListSubject] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const tabItems = [
    {
      slug: 'ielts',
      name: 'IELTS',
    },
    {
      slug: 'tibt',
      name: 'TOEFL IBT',
    },
    {
      slug: 'efb',
      name: 'English for Business',
    },
  ]

  const handleTab = (item) => {
    setTabActive(item.slug)
  }

  const handleSpeakingTab = (item) => {
    setTabActiveSpeaking(item.slug)
    setTestResultTableTitle(item.title)
  }

  const handleDetail = (userAnswer) => {
    setSelectedUserAnswer(userAnswer)
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => setIsDrawerOpen(false)

  const breadcrumbData = [
    { name: 'Home', link: '/' },
    { name: 'To Do', link: '/todo' },
    { name: 'Speaking Test', link: '/todo/writing' },
  ]

  useEffect(() => {
    const fetchData = () => {
      if (tabActiveSpeaking && tabActive) {
        setIsLoadingUsers(true)
        axios
          .get(
            `/answers/${tabActiveSpeaking}/writing/${tabActive}/all?search=${searchValue}`
          )
          .then((res) => {
            const results = res.data.results?.map((result, index) => {
              const renderStatus = () => {
                if (Number(result.writing_answer?.grand_score ?? 0) > 0) {
                  return (
                    <div className="flex items-center space-x-2">
                      <div className="w-3 h-3 bg-[#1DB954] rounded-full" />
                      <span>Completed</span>
                    </div>
                  )
                }

                return (
                  <div className="flex items-center space-x-2">
                    <div className="w-3 h-3 bg-[#FF9800] rounded-full" />
                    <span>Pending</span>
                  </div>
                )
              }

              const onClickDetail = () =>
                handleDetail({
                  createdAt: result.created_at,
                  studentName: result.name,
                  testName: testResultTableTitle,
                  category: result.category,
                  totalScore: Number(result.grand_score_writing ?? 0),
                  writingAnswer: result.writing_answer,
                  userId: result.userId,
                  classCategory: result.type_class,
                })

              return {
                no: index + 1,
                id: result.id,
                student: (
                  <div className="flex items-center space-x-3">
                    <img src={DefaultStudentImage} alt="Student" />
                    <span>{result.name}</span>
                  </div>
                ),
                class: <p className="capitalize">{result.classes}</p>,
                test: (
                  <span className="capitalize whitespace-nowrap">{`${result.subject_name.replace(
                    /-/g,
                    ' '
                  )}`}</span>
                ),
                email: result.email,
                score: Number(result.writing_answer?.grand_score ?? 0),
                status: renderStatus(),
                detail_score: (
                  <button
                    className="bg-[#EC6A2A] py-2 px-6 rounded-lg text-white text-xs"
                    onClick={onClickDetail}
                  >
                    Detail
                  </button>
                ),
              }
            })
            setTestResultData(results)
            setIsLoadingUsers(false)
          })
          .catch((err) => {
            setIsLoadingUsers(false)
          })
      }
    }

    const debouncedFetchData = debounce(fetchData, 300)

    debouncedFetchData()

    return () => {
      // Cleanup function
      debouncedFetchData.cancel()
    }
  }, [searchValue, tabActive, tabActiveSpeaking, testResultTableTitle])

  useEffect(() => {
    let subject = []
    setIsLoading(true)
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.slug = val.name
          val.value = val.name
          val.title = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
        setTabActiveSpeaking(subject[0].slug)
        setTestResultTableTitle(subject[0].title)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex bg-[#FAFAFB]">
        <Sidebar />
        <div className="flex flex-col space-y-6 p-7 max-w-full overflow-hidden">
          <Breadcrumb data={breadcrumbData} />
          <div className="flex flex-col space-y-2 mb-6">
            <h2 className="text-2xl pb-1 text-priblue-500">Speaking Test</h2>
            <p className="text-sm font-normal text-[#808080]">
              Lorem ipsum dolor sit ametasdas lorem ipsum dolor
            </p>
          </div>
          <Statistic />
          <Tabs
            tabItems={tabItems}
            activeTab={tabActive}
            handleClickTab={handleTab}
          />
          <TestResult
            onClickTab={handleSpeakingTab}
            activeTab={tabActiveSpeaking}
            categories={listSubject}
            tableTitle={testResultTableTitle}
            data={testResultData}
            searchValue={searchValue}
            onChangeSearchValue={handleSearchValue}
            isLoading={isLoadingUsers}
          />
        </div>
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        data={selectedUserAnswer}
        onClose={handleDrawerClose}
      />
    </>
  )
}

export default TodoSpeaking
