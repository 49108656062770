import React from 'react'
import { Url } from '../../../global'
import AudioPlayer from '../../AudioPlayer'
import RecordingPlayer from '../../RecordingPlayer'
import moment from 'moment'

/*
  @@params{
    number : int
    question : string
    choices : array of string
  }
*/

const RecordingTypeQuestion = ({
  number,
  question,
  audio_name,
  recording_duration,
  userAnswer,
}) => {
  const timeRecordingDuration = moment.duration(recording_duration)

  return (
    <div className="flex my-4">
      <span className="mr-3" id={`q${number}`}>
        {number}.
      </span>
      <div className="flex flex-col gap-4 w-full">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        <AudioPlayer audioUrl={`${Url}/media/${audio_name}`} />
        <RecordingPlayer
          maximumDuration={timeRecordingDuration.asSeconds()}
          audioUrl={userAnswer ? `${Url}/media/answers/${userAnswer}` : ''}
          disabledRecording
        />
      </div>
    </div>
  )
}

export default RecordingTypeQuestion
