import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { Link, useLocation } from "react-router-dom";

const CardList = (props) => {
  const item = props.item;
  const [openDropdown, setOpenDropdown] = useState(false);
  const options = ["Edit", "Delete"];
  const [titleUrl, setTitleUrl] = useState("");

  const location = useLocation();

  useEffect(() => {
    let title;
    title = item.title;
    title = title.replace(/\s+/g, "-").toLowerCase();
    setTitleUrl(title);
  }, []);

  return (
    <div className="flex flex-col items-start h-auto">
      <img
        src={item.image}
        className="w-full h-auto  rounded-lg overflow-hidden bg-blue-100"
      />
      <div className="flex flex-col items-start justify-start px-2 py-4 w-full">
        <div className="relative flex flex-row justify-between items-center w-full">
          <p className="text-nero-700">{item.title}</p>
          <BsThreeDotsVertical onClick={() => setOpenDropdown(!openDropdown)} />
          {openDropdown && (
            <Dropdown
              options={options}
              top={"25px"}
              right={"0px"}
              mt={"0px"}
              align={"center"}
            />
          )}
        </div>
        <p className="text-neromid text-sm mt-1">{item.subject}</p>
        <div className="flex flex-row justify-between items-center w-full mt-8">
          <p className="text-[#4318FF] text-sm">{item.status}</p>
          <Link
            to={`assignment/student`}
            state={{
              subject: item.subject,
              category: item.category,
            }}
          >
            <button className="bg-priblue-500 py-2 px-4 text-sm text-white rounded-lg font-normal">
              Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardList;
