import { useEffect, useState } from "react";
import CateogryAssignment from "../../pages/Report/assignmentCategory";
import ListAssignment from "../Assignment/ListAssignment";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import CardClass from "../Dashboard/CardClass";
import { useNavigate } from "react-router";
import { BiSearch } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import Dropdown from "../Dropdown";

const MyClass = () => {
  const [classes, setClasses] = useState(null);
  const account = useSelector(s => s.account);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClass()
  }, [])

  const fetchClass = async() => {
    axios.get(`/users/${account.userId}/classes`)
      .then((res) => {
        const result = res.data.results;
        setClasses(result);
      })
      .catch(console.error)
  }

  const handleClass = (idClass, jadwalstart, jadwalend) => {
    navigate('/myreport/student', {
      state: {
        idClass: idClass,
        jadwalstart: jadwalstart,
        jadwalend: jadwalend
      }
    })
  }

  return (
    <>
      <div className='mt-10'>
        <div className="grid grid-flow-col auto-cols-max gap-2 absolute right-20">
          <div className="flex items-center w-full rounded-lg focus-within:shadow-lg bg-white overflow-hidden border-[1px] border-grey-300">
            <div className="grid place-items-center h-full text-gray-300 px-2">
              <BiSearch />
            </div>
            <input
              className="peer h-full outline-none text-sm text-gray-700 pr-2"
              type="text"
              id="search"
              placeholder="Search"
            />
          </div>
        </div>
        <p className='text-xl text-[#064C85]'>My Class</p>
        <p className='text-xs text-[#808080] mt-3'>Daftar kelasmu sebelumnya</p>
      </div>

      <div className='flex flex-row mt-10'>
      {
        classes? classes.map((data, idx) => (
          <div key={idx}>
            <CardClass name={data.name} start={data.start_date} end={data.end_date} student={data.users} mentor={data.users} onClick={() => handleClass(data.id, data.start_date, data.end_date)} />
          </div>
        )) 
        : null
      }
      </div>
    </>
  );
};

export default MyClass;
