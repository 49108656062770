import { createSlice, current } from '@reduxjs/toolkit'

export const questionSlice = createSlice({
  name: 'question',
  initialState: {
    builders: [],
    number: 1,
    answers: Array(10).fill(''),
  },
  reducers: {
    resetBuilderData: (state) => {
      state.builders = []
    },
    setBuilderData: (state, action) => {
      const { data, id } = action.payload
      let curNumber = 1

      let newData = [
        ...state.builders.filter((builder) => builder.section_code !== id),
        ...data.map((val) => {
          return { ...val, section_code: id }
        }),
      ]
        .sort((a, b) => Number(a.sort) - Number(b.sort))
        .map((val) => {
          const newVal = { ...val, number: curNumber }

          if (typeof newVal.post_text === 'string') {
            if (
              !JSON.parse(newVal.post_text) ||
              JSON.parse(newVal.post_text)?.length === 0
            ) {
              newVal.post_text = []
            } else {
              newVal.post_text = JSON.parse(newVal.post_text)
            }
          }

          if (typeof newVal.pre_text === 'string') {
            if (
              !JSON.parse(newVal.pre_text) ||
              JSON.parse(newVal.pre_text)?.length === 0
            ) {
              newVal.pre_text = []
            } else {
              newVal.pre_text = JSON.parse(newVal.pre_text)
            }
          }

          if (Number(val.type) !== 9) {
            curNumber += 1 + (val.multi > 0 ? val.multi : 0)
          }

          return newVal
        })

      state.builders = newData
    },

    setQuestionNumber: (state, action) => {
      state.number = action.payload.data
    },

    setQuestionMaxLength: (state, action) => {
      state.answers = Array(action.payload.max).fill('')
    },
    setQuestionAnswer: (state, action) => {
      state.answers[action.payload.index - 1] = action.payload.value
    },
    setQuestionId: (state, action) => {
      state.questionId[action.payload.index - 1] = action.payload.value
    },
  },
})

export const {
  resetBuilderData,
  setBuilderData,
  setQuestionNumber,
  setQuestionAnswer,
  setQuestionMaxLength,
  setQuestionId,
} = questionSlice.actions
export default questionSlice.reducer
