import React from 'react'
import icVerify from '../assets/IcVerify.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import axios from '../utils/axios'

const FormAddOverviewAnalysis = ({ cancel }) => {
  const submit = () => {
    let analysisName = document.querySelector('input[name=analysis]').value

    axios
      .post(`/questions/sat/analysis`, {
        analysis_name: analysisName,
      })
      .then((res) => {
        console.log(res)
        cancel()
      })
      .catch((e) => {
        console.error('error', e)
      })
  }

  return (
    <div className="h-screen flex justify-center items-center bg-neutral-500 fixed inset-0 z-50 overflow-auto bg-opacity-80">
      <motion.div
        initial={{ opacity: 1 }}
        whileInView={{ opacity: 1 }}
        animate={{ y: 100 }}
        transition={{
          type: 'spring',
          stiffness: 100,
          duration: 2,
          x: { duration: 1 },
        }}
        className="w-11/12 md:w-6/12 flex flex-col justify-center items-center bg-white rounded-lg -mt-[200px]"
      >
        <p className="text-center text-2xl text-[#064C85] mt-10">
          Add Overview Analysis
        </p>
        <p className="text-center text-xs text-[#064C85] mt-5">
          Example : Reading
        </p>
        <form className="w-1/2">
          <div className="flex flex-col text-grey-400 w-full">
            <input
              type="text"
              className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
              name="analysis"
              placeholder="Enter overview analysis name"
            />
          </div>
        </form>
        <div className="w-full flex-row px-32 py-5 flex justify-between">
          <button
            onClick={cancel}
            className="my-5 w-52 mb-6 py-2 bg-[#FAFAFA] text-[#292929] rounded-lg border border-[#064C85]"
          >
            Cancel
          </button>
          <button
            onClick={submit}
            className="my-5 w-52 mb-6 py-2 bg-[#064C85] text-white rounded-lg"
          >
            Submit
          </button>
        </div>
      </motion.div>
    </div>
  )
}

export default FormAddOverviewAnalysis
