import { useNavigate } from 'react-router-dom'
import WritingIcon from '../../assets/icons/writing.svg'
import SpeakingIcon from '../../assets/icons/speaking.svg'
import ReminderImage from '../../assets/reminder.png'

import CardHorizontal from '../../components/CardHorizontal'

const Reminder = () => {
  const navigate = useNavigate()
  const handleDetailWriting = () => navigate('/todo/writing')
  const handleDetailSpeaking = () => navigate('/todo/speaking')

  return (
    <div className="flex items-center space-x-32">
      <div className="space-y-4">
        <h3 className="text-2xl pb-1 text-priblue-500 font-semibold max-w-sm">
          Come on, check your students' assignments
        </h3>
        <div className="flex flex-col space-y-4 items-start md:flex-row md:space-y-0 md:space-x-4">
          <div className="md:max-w-xs">
            <CardHorizontal
              imageIcon={WritingIcon}
              iconBgColor="bg-[#FBA63C]"
              title="Writing Test Master"
              subtitle="Lihat writing test yang telah dikerjakan siswamu disini"
              buttonText="Detail"
              onClickButton={handleDetailWriting}
            />
          </div>
          <div className="md:max-w-xs">
            <CardHorizontal
              imageIcon={SpeakingIcon}
              iconBgColor="bg-[#F74B4B]"
              title="Speaking Test Master"
              subtitle="Lihat speaking test yang telah dikerjakan siswamu disini"
              buttonText="Detail"
              onClickButton={handleDetailSpeaking}
            />
          </div>
        </div>
      </div>
      <div>
        <img src={ReminderImage} alt="Reminder" />
      </div>
    </div>
  )
}

export default Reminder
