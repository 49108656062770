import React from 'react'

/*
    @@params{
        lists : array of string
    }
*/

const Radio = ({ label, name, value, checked, onChange }) => {
  return (
    <>
      <label className="cursor-pointer">
        <input
          className="me-2"
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          required
        />
        {label}
      </label>
    </>
  )
}

export default Radio
