import React, { Fragment, useEffect, useRef, useState } from 'react'
import Notify from '../../../components/Notify'
import axios from '../../../utils/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { MultiSelect } from 'react-multi-select-component'
import { useDispatch } from 'react-redux'
import { setStatusStudent } from '../../../slice/status'
import { toast } from 'react-toastify'
import Radio from '../../../components/Register/Radio'
import SelectReact from 'react-select'

const AddStudent = () => {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const form = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const [listClass, setListClass] = useState('')
  const [classId, setClassId] = useState([])
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const onSubmit = (e) => {
    e.preventDefault()
    setModal(true)
  }

  const handleModal = () => setModal((prevState) => !prevState)

  const save = () => {
    const { name, email, gender, phone_number } = form.current.elements

    axios
      .post('/auth/student', {
        name: name.value,
        email: email.value,
        gender: gender.value,
        phone_number: phone_number.value,
        password: 123,
        classes: classId,
        products:
          selectedProducts.length > 0
            ? selectedProducts
            : [products.find((data) => data.label === 'ielts')?.value],
      })
      .then((res) => {
        // TODO
        toast.success('Berhasil simpan data student')
        setModal(false)
        dispatch(setStatusStudent(false))
      })
      .catch((err) => {
        alert('Error: ' + err.message)
      })
  }

  const update = () => {
    const { name, email, gender, phone_number } = form.current.elements

    alert(classId)

    axios
      .put(`/users/${location.state.datastudent.id}`, {
        name: name.value,
        email: email.value,
        gender: gender.value,
        phone_number: phone_number.value,
        classes: classId,
      })
      .then((res) => {
        toast.success('Berhasil Update Data Student')
        setModal(false)
        dispatch(setStatusStudent(false))
        // reload after navigate
        navigate('/data')
        navigate(0)
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const [selected, setSelected] = useState([])
  const [valGender, setValGender] = useState('')

  let options = []

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products')
        const result = response.data
        const products = result.product.map((data) => ({
          value: data.id,
          label: data.name,
        }))

        setProducts(products)
      } catch (error) {
        console.log('error:', error)
      }
    }
    fetchProducts()
    fetchClass()
    // location.state?
    // setValGender(location.state.datastudent.gender)
    // : null
  }, [])

  const fetchClass = async () => {
    await axios
      .get('/classes')
      .then((res) => {
        if (options.length != res.data.results.length) {
          res.data.results.map((data) => {
            options.push({
              label: data.name,
              value: data.id,
            })
          })
          setListClass(options)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const genderoptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]

  const handleGender = (e) => {
    setValGender(e.currentTarget.value)
  }

  const handleSelect = (e) => {
    setSelected(e)

    let class_val = []
    e.map((item, idx) => {
      class_val.push(item.value)
    })
    setClassId(class_val)
  }

  const handleProduct = async (event) => {
    const productIds = event.map((e) => e.value)

    setSelectedProducts(productIds)
  }

  return (
    <Fragment>
      <form ref={form} className="grid grid-cols-12 m-10" onSubmit={onSubmit}>
        <Notify
          isOpen={modal}
          handleModal={handleModal}
          save={location.state != null ? update : save}
          title={location.state != null ? 'update' : 'submit'}
        />
        <div className="ml-10">
          <ArrowLeftIcon
            className="h-12 w-16 bg-[#F8F8F8] p-3 rounded-r-full rounded-l-full cursor-pointer hover:bg-[#D0D5DD]"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="mt-3">
          <p>Add Student</p>
        </div>
        <div className="col-start-4 col-span-7 px-10 mt-16">
          <p className="text-[#064C85] text-lg">Personal Information</p>
          <div className="mt-3">
            <p>Full Name</p>
            <input
              className="border py-2 mt-2 w-full"
              type="text"
              name="name"
              defaultValue={
                location.state != null ? location.state.datastudent.name : null
              }
              placeholder="Enter full name"
              required
            />
          </div>
          <div className="mt-4">
            <p className="mb-1">Gender</p>
            <div className="flex flex-row">
              {genderoptions.map((data, idx) => (
                <div className="mr-5" key={idx}>
                  <Radio
                    label={data.label}
                    name="gender"
                    value={data.value}
                    checked={valGender === data.value}
                    onChange={handleGender}
                  />
                </div>
              ))}
            </div>

            {/* <label className="ms-4 cursor-pointer">
              <input
                className="me-2"
                type="radio"
                name="gender"
                defaultValue="female"
                checked={location.state != null ? location.state.datastudent.gender == "female" ? true: false : null}
                required
              />
              Female
            </label> */}
          </div>
          <div className="mt-4">
            <p className="mb-1">Class</p>
            <MultiSelect
              options={listClass}
              value={selected}
              onChange={(e) => handleSelect(e)}
              labelledBy="Select"
            />
          </div>
          <div className="mt-4">
            <p className="mb-1">Products</p>
            <div className="flex flex-row">
              {products.length > 0 && (
                <SelectReact
                  options={products}
                  onChange={(event) => handleProduct(event)}
                  isMulti
                  className="w-full"
                  defaultValue={products.find((data) => data.label === 'ielts')}
                  required
                />
              )}
            </div>
          </div>
          <p className="text-[#064C85] text-lg mt-10">Contact Information</p>
          <div className="mt-3">
            <p>Email</p>
            <input
              className="border py-2 mt-2 w-full"
              type="email"
              name="email"
              placeholder="Enter email"
              defaultValue={
                location.state != null ? location.state.datastudent.email : null
              }
              required
            />
          </div>
          <div className="mt-3">
            <p>Mobile Number</p>
            <div className="flex">
              <input
                type="text"
                className="border py-2 mt-2 w-10"
                value="+62"
                required
                readOnly
              />
              <input
                type="tel"
                className="border py-2 mt-2 ml-2 w-full"
                name="phone_number"
                placeholder="Enter mobile number (8xxx)"
                title="Format 8xxx"
                pattern="8\d+"
                defaultValue={
                  location.state != null
                    ? location.state.datastudent.phone_number
                    : null
                }
                required
              />
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <button
              className="my-5 w-40 py-2 bg-[#064C85] text-white rounded-lg"
              type="submit"
            >
              {location.state != null ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default AddStudent
