import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import CustomToolbar from './toolbar'

var Embed = Quill.import('blots/embed')
class MyCustomTag extends Embed {
  static create(paramValue) {
    let node = super.create()
    node.innerHTML = paramValue
    return node
  }

  static value(node) {
    return node.innerHTML
  }
}

MyCustomTag.blotName = 'span'
MyCustomTag.className = 'fill-input'
MyCustomTag.tagName = 'span'
Quill.register(MyCustomTag)

function insert(quillEditor, props) {
  const cursorPosition = quillEditor.getSelection().index
  props.option.addAnswer()
  setTimeout(() => {
    quillEditor.insertEmbed(
      cursorPosition,
      'span',
      `input#${
        props.number + (props.option.multi > 0 ? props.option.multi : 0)
      }`
    )
    quillEditor.setSelection(cursorPosition + 2)
  }, 5)
}

class TextEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editorHtml: '' }
    this.handleChange = this.handleChange.bind(this)
    this.reactQuillRef = null
  }

  handleChange(html) {
    this.setState({ editorHtml: html })
  }

  modules = {
    toolbar: {
      container: `#toolbar-${this.props.idx}`,
      handlers: {
        input: () => insert(this.reactQuillRef.getEditor(), this.props),
      },
    },

    clipboard: {
      matchVisual: false,
    },
  }

  render() {
    return (
      <div className="text-editor">
        <CustomToolbar idx={this.props.idx} fill={this.props.fill} />
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el
          }}
          value={this.props.value}
          onChange={this.props.setValue}
          placeholder="Start writing here . . ."
          modules={this.modules}
          formats={TextEditor.formats}
          theme={'snow'}
          id={`quill-${this.props.idx}`}
        />
      </div>
    )
  }
}
TextEditor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'image',
  'input',
  'span',
  'align',
]

export default TextEditor
