import React, { useState, useRef, useEffect } from 'react'
import { FaPlay, FaPause, FaCircle, FaSquare } from 'react-icons/fa'
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs'
import { FiHeadphones } from 'react-icons/fi'
import classNames from 'classnames'

const RecordingPlayer = ({
  className,
  onStopRecording,
  audioUrl,
  maximumDuration = 0,
  disabledRecording,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef(null)
  const [isRecording, setIsRecording] = useState(false)
  const [audioURL, setAudioURL] = useState(audioUrl || '')
  const [mediaRecorder, setMediaRecorder] = useState(null)
  let chunks = []

  const startRecording = () => {
    setCurrentTime(0)
    setDuration(0)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream)

        const durationTimer = setInterval(() => {
          setDuration((prevDuration) => {
            if (prevDuration >= maximumDuration) {
              recorder.stop()
              setIsRecording(false)
            }
            return prevDuration + 1
          })
        }, 1000)

        setTimeout(() => {
          recorder.stop()
          setIsRecording(false)
        }, maximumDuration * 1000)

        recorder.ondataavailable = (e) => {
          chunks.push(e.data)
        }
        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
          chunks = []
          const audioObjectURL = URL.createObjectURL(blob)
          onStopRecording(blob)
          setAudioURL(audioObjectURL)
          clearInterval(durationTimer)
        }
        recorder.start()
        setIsRecording(true)
        setMediaRecorder(recorder)
      })
      .catch((err) => console.error('Error recording: ', err))
  }

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop()
      setIsRecording(false)
    }
  }

  const togglePlay = () => {
    const audio = audioRef.current
    if (isPlaying) {
      audio.currentTime = currentTime
      audio.pause()
    } else {
      audio.play()
    }
    setIsPlaying(!isPlaying)
  }

  const toggleMute = () => {
    const audio = audioRef.current
    audio.muted = !isMuted
    setIsMuted(!isMuted)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const handleTimeUpdate = () => {
    const audio = audioRef.current
    setCurrentTime(audio.currentTime)
  }

  useEffect(() => {
    const audio = audioRef.current
    audio.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [])

  useEffect(() => {
    const audio = audioRef.current
    const handleEnded = () => {
      setIsPlaying(false)
    }
    audio.addEventListener('ended', handleEnded)
    return () => {
      audio.removeEventListener('ended', handleEnded)
    }
  }, [])

  const playButtonClasses = classNames(
    'play-button pl-1 pr-1 py-1 rounded-full text-[#064C85] border-2 border-[#064C85] font-semibold',
    {
      'opacity-50 cursor-not-allowed': isRecording || !audioURL,
    }
  )

  const stopButtonClasses = classNames(
    'play-button pl-1 pr-1 py-1 rounded-full text-[#064C85] border-2 border-[#064C85] font-semibold',
    {
      'opacity-50 cursor-not-allowed': !isRecording,
    }
  )

  const recordingButtonClasses = classNames(
    'play-button p-1 rounded-full text-[#e11d48] border-2 border-[#e11d48] font-semibold',
    {
      'opacity-50 cursor-not-allowed': isRecording || disabledRecording,
    }
  )

  const listenButtonClasses = classNames(
    'mx-auto t w-1/2 lg:w-[40%] py-2 bg-[#e11d48] rounded-lg text-white flex justify-center items-center gap-2',
    {
      'opacity-50 cursor-not-allowed': isRecording || disabledRecording,
    }
  )

  const muteButtonClasses = classNames(
    'mute-button pl-1.5 pr-1 py-1 rounded-full text-[#064C85] border-2 border-[#064C85] font-semibold',
    {
      'opacity-50 cursor-not-allowed': isPlaying || !audioURL,
    }
  )

  return (
    <div className={className}>
      <div className="w-full audio-player flex items-center justify-center bg-white shadow-[0px_20px_40px_0px_rgba(102,102,102,0.16),20px_0px_40px_0px_rgba(102,102,102,0.16)] py-2 px-3 xl:px-5 rounded">
        <div className="flex gap-2">
          <button
            type="button"
            className={recordingButtonClasses}
            onClick={startRecording}
            disabled={isRecording || disabledRecording}
          >
            <FaCircle size={10} />
          </button>
          <button
            type="button"
            className={playButtonClasses}
            onClick={togglePlay}
            disabled={isRecording || !audioURL}
          >
            {isPlaying ? <FaPause size={10} /> : <FaPlay size={10} />}
          </button>
          <button
            type="button"
            className={stopButtonClasses}
            onClick={stopRecording}
            disabled={!isRecording}
          >
            <FaSquare size={10} />
          </button>
        </div>

        <div className="w-full audio-controls ml-2 xl:ml-4 flex items-center justify-center">
          <div className="text-xs time mr-2">
            <span className="current-time">{formatTime(currentTime)}</span> /{' '}
            <span className="duration">{formatTime(duration)}</span>
          </div>
          <audio ref={audioRef} src={audioURL} />
          <div className="progress-bar w-[50%] xl:w-[70%] h-2 bg-gray-200 rounded overflow-hidden">
            <div
              className="progress h-full bg-[#FB8818] transition-all"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            />
          </div>
        </div>
        <button
          type="button"
          className={muteButtonClasses}
          onClick={toggleMute}
          disabled={isPlaying || !audioURL}
        >
          {isMuted ? <BsFillVolumeMuteFill /> : <BsFillVolumeUpFill />}
        </button>
      </div>
      <br />
      <button
        className={listenButtonClasses}
        onClick={startRecording}
        disabled={isRecording || disabledRecording}
      >
        <FaCircle size={12} />
        <p className="text-xs">Click here to record your voice</p>
      </button>
    </div>
  )
}

export default RecordingPlayer
