import React from 'react'
import RecordingTypeQuestion from '../type/recording'

/*
    @@params = {
        questions : array of RecordingTypeQuestion
    }
*/

const RecordingQuestionContainer = (data) => {
  return (
    <div className="mb-12">
      <RecordingTypeQuestion {...data} />
    </div>
  )
}

export default RecordingQuestionContainer
