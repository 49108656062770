export const customTheme = {
  input: {
    defaultProps: {
      className: 'focus:ring-0',
    },
  },
  button: {
    valid: {
      colors: ['dark-blue'],
    },
    styles: {
      variants: {
        filled: {
          'dark-blue': {
            backgroud: 'bg-priblue-500',
            color: 'text-white',
            shadow: 'shadow-md shadow-priblue-500/20',
            hover: 'hover:shadow-lg hover:shadow-priblue-500/40',
            focus: 'focus:opacity-[0.85] focus:shadow-none',
            active: 'active:opacity-[0.85] active:shadow-none',
          },
        },
        gradient: {
          'dark-blue': {
            backgroud: 'bg-gradient-to-tr from-priblue-600 to-priblue-400',
            color: 'text-white',
            shadow: 'shadow-md shadow-priblue-500/20',
            hover: 'hover:shadow-lg hover:shadow-priblue-500/40',
            active: 'active:opacity-[0.85]',
          },
        },
        outlined: {
          'dark-blue': {
            border: 'border border-priblue-500',
            color: 'text-priblue-500',
            hover: 'hover:opacity-75',
            focus: 'focus:ring focus:ring-priblue-200',
            active: 'active:opacity-[0.85]',
          },
        },
        text: {
          'dark-blue': {
            color: 'text-priblue-500',
            hover: 'hover:bg-priblue-500/10',
            active: 'active:bg-priblue-500/30',
          },
        },
      },
    },
  },
}
