import { Link } from 'react-router-dom'

import DefaultStudentImage from '../../assets/default-student.png'
import ChevronRighticon from '../../assets/icons/chevron-right.svg'

const CardStudent = ({ data }) => {
  return (
    <div className="flex flex-col space-y-4 bg-white p-4 border border-[#CCCCCC] rounded-lg">
      <div className="flex space-x-4 items-center">
        <img
          src={DefaultStudentImage}
          alt="Student"
          className="flex-none w-10 h-10"
        />
        <div>
          <h3 className="text-nero-700 font-semibold font-Montserrat">
            {data.name}
          </h3>
          <p className="text-orange-500 text-xs ">Detail Student</p>
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        {/* <div className="flex justify-between">
          <p className="text-neromid text-sm">Band Score</p>
          <p className="text-orange-500 font-semibold font-Montserrat">80%</p>
        </div>
        <p className="text-nero-700 font-Montserrat">8</p> */}
        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            <div className="bg-positive w-[30px] h-[8px]" />
            <div className="bg-positive w-[30px] h-[8px]" />
            <div className="bg-positive w-[30px] h-[8px]" />
            <div className="bg-positive w-[30px] h-[8px]" />
            {/* <div className="bg-nerolight w-[30px] h-[8px]" /> */}
          </div>
          <Link
            to={`/myreport/assignment/student/report`}
            state={{
              studentId: data.id,
              studentName: data.name,
            }}
          >
            <div className="flex items-center space-x-1">
              <p className="text-neromid">View Results</p>
              <img src={ChevronRighticon} alt="Chevron Right Icon" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CardStudent
