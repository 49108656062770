import React from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const SelectQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)

  const {
    question,
    answer,
    point,
    number,
    list,
    multi = -1,
  } = getBuilderDataById(idx, containerId)

  let { choices = [], post_text: postText = [] } = getBuilderDataById(
    idx,
    containerId
  )

  if (!choices) {
    choices = []
  }

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">
          {number} {multi > 0 ? '- ' + (number + multi) : ''}.
        </span>
        <div className="flex flex-col gap-1">
          <div dangerouslySetInnerHTML={{ __html: question }} />
          {choices.map((data, index) => (
            <div className="mb-2" key={index}>
              <span className="w-8 h-8 me-2 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
                {String.fromCharCode(65 + index)}
              </span>
              <span>{data}</span>
            </div>
          ))}
          {postText.map((val, idx) => (
            <section key={idx} className="mb-2">
              <select
                className="px-4 py-2 bg-gray-100 mx-2"
                defaultValue={answer[idx]}
              >
                <option value={answer[idx]} hidden>
                  {answer[idx]}
                </option>
              </select>
              <label htmlFor={idx} className="ms-2 ">
                {val}
              </label>
            </section>
          ))}
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default SelectQuestionPreview
