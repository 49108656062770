import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/Sidebar'
import Students from './Students'
import Classes from './Classes'
import Tabs from '../../components/Tabs'
import Loading from '../../components/Loading'
import axios from '../../utils/axios'
import Mentors from './Mentors'

const Data = () => {
  const [listClasses, setListClasses] = useState([])
  const [listStudents, setListStudents] = useState([])
  const [listMentors, setListMentors] = useState([])
  const [tabActive, setTabActive] = useState('my_class')
  const [tabActiveStudent, setTabActiveStudent] = useState('active')
  const [studentPageTitle, setStudentPageTitle] = useState('Active')
  const [tabActiveClass, setTabActiveClass] = useState('active')
  const [classPageTitle, setClassPageTitle] = useState('Active')
  const [tabActiveMentor, setTabActiveMentor] = useState('active')
  const [mentorPageTitle, setMentorPageTitle] = useState('Active')
  const [isLoading, setIsLoading] = useState(false)

  const tabItems = [
    { slug: 'my_class', name: 'My Class' },
    { slug: 'my_mentors', name: 'My Mentors' },
    { slug: 'my_students', name: 'My Students' },
  ]

  const handleTab = (item) => {
    setTabActive(item.slug)

    const fetchData = (role, setter) => {
      setIsLoading(true)
      axios
        .get(`/users?role=${role}`)
        .then((res) => {
          const results = res.data.results
          setter(results)
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (item.slug === 'my_students') {
      fetchData(3, setListStudents)
    }

    if (item.slug === 'my_mentors') {
      fetchData(2, setListMentors)
    }
  }

  const studentTabItems = [
    {
      slug: 'active',
      value: 'active',
      title: 'Active',
    },
    {
      slug: 'not_active',
      value: 'not_active',
      title: 'Inactive',
    },
  ]

  const classTabItems = [
    {
      slug: 'active',
      value: 'active',
      title: 'Active',
    },
    {
      slug: 'not_active',
      value: 'not_active',
      title: 'Inactive',
    },
  ]

  const mentorTabItems = [
    {
      slug: 'active',
      value: 'active',
      title: 'Active',
    },
    {
      slug: 'not_active',
      value: 'not_active',
      title: 'Inactive',
    },
  ]

  const handleTabs = (item, setTabFunction, setTitleFunction) => {
    setTabFunction(item.slug)
    setTitleFunction(item.title)
  }

  const handleStudentTabs = (item) => {
    handleTabs(item, setTabActiveStudent, setStudentPageTitle)
  }

  const handleClassTabs = (item) => {
    handleTabs(item, setTabActiveClass, setClassPageTitle)
  }

  const handleMentorTabs = (item) => {
    handleTabs(item, setTabActiveMentor, setMentorPageTitle)
  }

  useEffect(() => {
    const fetchClasses = () => {
      setIsLoading(true)
      axios
        .get(`/classes`)
        .then((res) => {
          const results = res.data.results
          setListClasses(results)
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchClasses()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex bg-[#FAFAFB]">
        <Sidebar />
        <div className="flex flex-col space-y-6 p-7 w-full">
          <div className="flex flex-col space-y-2 mb-6">
            <h2 className="text-2xl pb-1 text-priblue-500">Speaking Test</h2>
            <p className="text-sm font-normal text-[#808080]">
              Lihat informasi kelas dan siswa yang kamu bimbing
            </p>
          </div>
          <Tabs
            tabItems={tabItems}
            activeTab={tabActive}
            handleClickTab={handleTab}
          />
          {tabActive === 'my_students' && (
            <Students
              title="My Students"
              subtitle={`${studentPageTitle} Students`}
              subtitleDescription="Daftar siswa yang kamu bimbing sekarang"
              onClickTab={handleStudentTabs}
              activeTab={tabActiveStudent}
              categories={studentTabItems}
              listStudents={listStudents}
            />
          )}
          {tabActive === 'my_mentors' && (
            <Mentors
              title="My Mentors"
              subtitle={`${mentorPageTitle} Mentors`}
              subtitleDescription="Daftar mentor yang tengah aktif sekarang"
              onClickTab={handleMentorTabs}
              activeTab={tabActiveMentor}
              categories={mentorTabItems}
              listMentors={listMentors}
            />
          )}
          {tabActive === 'my_class' && (
            <Classes
              title="My Class"
              subtitle={`${classPageTitle} Class`}
              subtitleDescription="Daftar kelasmu yang tengah aktif sekarang"
              onClickTab={handleClassTabs}
              activeTab={tabActiveClass}
              categories={classTabItems}
              listClasses={listClasses}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Data
