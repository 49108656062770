import React from "react";

/*
    @@params{
        lists : array of string
    }
*/

const SelectQuestionList = ({lists}) => {
    return(
        <>
        {lists?.map((data, idx) => (
            <div className="mb-3" key={idx}>
              <span className="w-8 h-8 me-2 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">{String.fromCharCode(65 + idx)}</span>
              <span>{data}</span>
            </div>
        ))}
        </>
    )
}

export default SelectQuestionList;