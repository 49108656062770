import React from "react";
import SelectTypeQuestion from "../../type/select";
import SelectQuestionList from "./list";
import SelectQuestionTruth from "./truth";

/*
    @@params{
        desc : string
        choices : array of string
        questions : array of SelectTypeQuestion
        image : string
    }
*/

const SelectQuestionContainer = (data) => {
  const { question, choices, number, list, postText, type } = data;
  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: question }} className="mb-4" />
      {parseInt(type) === 4 ? (
        // list && 
        <SelectQuestionList lists={choices} />
      ) : (
        <SelectQuestionTruth />
      )}
      {postText?.map((val, idx) => (
        <SelectTypeQuestion
          postText={val}
          key={idx}
          choices={choices}
          number={number + idx}
          type={type}
        />
      ))}
    </div>
  );
};

export default SelectQuestionContainer;
