import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

const token = sessionStorage.getItem('token');
const decoded = token ? jwtDecode(token) : null;
const initialState = {
  userId: 0,
  name: '',
  email: ''
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: token ? decoded : initialState,
  reducers: {
    setAccount: (state, action) => {
      const payload = {
        userId: action.payload.userId,
        name: action.payload.name,
        email: action.payload.email,
      };

      state = Object.assign(state, payload);
    }
  },
});

export const { setAccount } = accountSlice.actions;
export default accountSlice.reducer;
