import React from 'react'

import TabPills from '../../../components/TabPills'

const Toolbar = ({ onClickTab, activeTab, categories }) => {
  return (
    <div className="flex flex-col space-y-4 justify-between mb-8 md:flex-row md:space-y-0">
      <TabPills
        categories={categories}
        active={activeTab}
        onClickTab={onClickTab}
      />
    </div>
  )
}

export default Toolbar
