import React from 'react'
import useQuestionAnswer from '../../../hooks/useQuestionAnswer'

/*
  @@params{
    number : int
    preText : string
    postText : string
    limit : int
  }
*/

const FillTypeQuestion = ({
  number,
  preText = '',
  postText = '',
  limit = 99,
}) => {
  const { getAnswer, setAnswer } = useQuestionAnswer()

  const inputHandler = (e) => {
    let val = e.target.value
    val = val.split(' ')
    if (val.length <= limit) {
      setAnswer(number, e.target.value)
    }
  }

  return (
    <span className="input-text inline-block">
      <span id={`q${number}`} className="relative">
        {' '}
        {number}.{' '}
      </span>
      <span>{preText} </span>
      <input
        type="text"
        name={`question${number}`}
        value={getAnswer(number) || ''}
        onChange={inputHandler}
      />
      <span> {postText}</span>
    </span>
  )
}

export default FillTypeQuestion
