import DefaultMentorImage from '../../assets/default-student.png'

const CardMentor = ({ data }) => {
  return (
    <div className="flex flex-col space-y-4 bg-white p-4 border border-[#CCCCCC] rounded-lg">
      <div className="flex space-x-4 items-center">
        <img
          src={DefaultMentorImage}
          alt="Mentor"
          className="flex-none w-10 h-10"
        />
        <div>
          <h3 className="text-nero-700 font-semibold font-Montserrat">
            {data.name}
          </h3>
          <p className="text-orange-500 text-xs ">Detail Mentor</p>
        </div>
      </div>
    </div>
  )
}

export default CardMentor
