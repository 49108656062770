import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

import axios from '../../../utils/axios'

import StudentProfile from './StudentProfile'
import SelectTest from './SelectTest'
import TestStats from './TestStats'
import Loading from '../../../components/Loading'
import SelectClassCategory from './SelectClassCategory'
import Result from './Result'
import { IoMdArrowBack } from 'react-icons/io'
import { toast } from 'react-toastify'

const ReportSpeaking = ({ testName, userId }) => {
  let navigate = useNavigate()
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [questionsData, setQuestionsData] = useState([])
  const [speakingResultData, setSpeakingResultData] = useState({})
  const [speakingScoredata, setSpeakingScoreData] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isModalScoreOpen, setModalScore] = useState(false)
  const [selectedClassCategories, setSelectedClassCategories] = useState('tibt')
  const [selectedSubject, setSelectedSubject] = useState('pre-test')

  const handleOpenModalScore = () => {
    setModalScore((open) => !open)
  }

  const modifyQuestionsNumber = (data) => {
    let curNumber = 1
    let copyQuestions = [...data]

    copyQuestions = copyQuestions
      .sort((a, b) => a.sort - b.sort)
      .map((val) => {
        const newVal = { ...val, number: curNumber }

        return newVal
      })
      .filter((val) => Number(val.type) <= 8)

    return copyQuestions
  }

  const fetchQuestions = async (subject, classCategory) => {
    let dataQuestion = []
    let dataAnswer = []
    setQuestions([])
    try {
      const questionsResponse1 = await axios.get(
        `/questions/${subject}/speaking/${classCategory}/1`
      )
      const questionsData1 = questionsResponse1.data
      let questionsResponse2
      let questionsData2
      let questionsResponse3
      let questionsData3

      if (classCategory === 'tibt') {
        questionsResponse2 = await axios.get(
          `/questions/${subject}/speaking/${classCategory}/2`
        )
        questionsData2 = questionsResponse2.data
        questionsResponse3 = await axios.get(
          `/questions/${subject}/speaking/${classCategory}/3`
        )
        questionsData3 = questionsResponse3.data
      }

      if (classCategory === 'tibt') {
        dataQuestion = [
          ...questionsData1.results[1],
          ...questionsData2.results[1],
          ...questionsData3.results[1],
        ]
      } else {
        dataQuestion = [...questionsData1.results[1]]
      }

      dataQuestion.sort((a, b) => a.sort - b.sort)

      setQuestions(dataQuestion)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchAnswers = async (value, classCategory) => {
    let dataAnswer = []
    await axios
      .get(`/answers/${value}/speaking/${classCategory}/${userId}`)
      .then((res) => {
        if (res.status === 204) {
          navigate('/myreport/assignment/student/report', {
            state: {
              studentId: userId,
            },
          })
          toast.error('Student not yet taken this test', {
            toastId: 'not-yet',
          })
          return
        }

        const result = res.data.result
        setSpeakingResultData(result)
        setSpeakingScoreData(result.speaking_answer)
        setIsLoading(false)

        if (res.data.result) {
          dataAnswer = res.data.result.answers

          setAnswers(dataAnswer)
        }
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchQuestions(selectedSubject, selectedClassCategories)
    fetchAnswers(selectedSubject, selectedClassCategories)
  }, [isModalScoreOpen, selectedClassCategories, selectedSubject, userId])

  useEffect(() => {
    const dataQuestion = questions.map((question) => {
      const findById = answers.findIndex(
        (answer) => answer.questionId === question.id
      )

      if (findById !== -1) {
        question.userAnswer = answers[findById].record_answer
      } else {
        question.userAnswer = ''
      }

      return question
    })

    setQuestionsData(dataQuestion)
  }, [answers, questions])

  const handleSubject = async (value) => {
    setSelectedSubject(value)
  }

  const handleChangeSelectedClassCategories = (value) => {
    setSelectedClassCategories(value)
  }

  if (isLoading && isEmpty(speakingResultData)) {
    return <Loading />
  }

  return (
    <div className="flex-col px-10 py-5 mx-auto w-screen">
      <div className="py-12 px-5">
        <div className="flex space-x-12 justify-between">
          <div className="flex flex-col space-y-10 grow">
            <div className="flex space-x-10">
              <div className="w-1/4">
                <SelectTest
                  value={selectedSubject}
                  onChange={(value) => handleSubject(value)}
                />
              </div>
              <div className="w-1/4">
                <SelectClassCategory
                  onChange={(value) =>
                    handleChangeSelectedClassCategories(value)
                  }
                  value={selectedClassCategories}
                />
              </div>
            </div>
            {questionsData.find((question) => question.userAnswer !== '') ? (
              <Result questions={questionsData} />
            ) : (
              <label>No Data</label>
            )}
          </div>
          <TestStats
            speakingAnswer={speakingScoredata}
            data={speakingResultData}
            testName={selectedSubject}
            isModalScoreOpen={isModalScoreOpen}
            handleModalScore={handleOpenModalScore}
            classCategory={selectedClassCategories}
          />
        </div>
      </div>
    </div>
  )
}

export default ReportSpeaking
