import React, { useState, useRef, useEffect } from 'react'
import { FaPlay, FaPause } from 'react-icons/fa'
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs'
import { FiHeadphones } from 'react-icons/fi'
import classNames from 'classnames'

const AudioPlayer = ({ audioUrl, startTime = 0, noPause, className }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState(startTime)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef(null)

  const togglePlay = () => {
    const audio = audioRef.current
    if (isPlaying) {
      audio.currentTime = currentTime
      audio.pause()
    } else {
      audio.play()
    }
    setIsPlaying(!isPlaying)
  }

  const toggleMute = () => {
    const audio = audioRef.current
    audio.muted = !isMuted
    setIsMuted(!isMuted)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const handleTimeUpdate = () => {
    const audio = audioRef.current
    setCurrentTime(audio.currentTime)
    setDuration(audio.duration)
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = startTime
    }
  }, [startTime])

  const playButtonClasses = classNames(
    'play-button pl-1.5 pr-1 py-1 rounded-full text-[#064C85] border-2 border-[#064C85] font-semibold',
    {
      'opacity-50 cursor-not-allowed': noPause && isPlaying,
    }
  )

  const listenButtonClasses = classNames(
    'mx-auto cursor-default w-1/2 lg:w-[40%] py-2  bg-[#FB8818] rounded-lg text-white flex justify-center items-center gap-2',
    {
      'opacity-50 cursor-not-allowed': noPause && isPlaying,
    }
  )

  const muteButtonClasses = classNames(
    'mute-button pl-1.5 pr-1 py-1 rounded-full text-[#064C85] border-2 border-[#064C85] font-semibold',
    {
      'opacity-50 cursor-not-allowed': noPause && isPlaying,
    }
  )

  return (
    <div className={className}>
      <div className="w-full audio-player flex items-center justify-center bg-white shadow-[0px_20px_40px_0px_rgba(102,102,102,0.16),20px_0px_40px_0px_rgba(102,102,102,0.16)] py-2 px-3 xl:px-5 rounded">
        <button
          type="button"
          className={playButtonClasses}
          onClick={togglePlay}
          disabled={noPause && isPlaying}
        >
          {isPlaying ? <FaPause size={10} /> : <FaPlay size={10} />}
        </button>

        <div className="w-full audio-controls ml-2 xl:ml-4 flex items-center justify-center">
          <div className="text-xs time mr-2">
            <span className="current-time">{formatTime(currentTime)}</span> /{' '}
            <span className="duration">{formatTime(duration)}</span>
          </div>
          <audio
            ref={audioRef}
            src={audioUrl}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleTimeUpdate}
            currentTime={currentTime}
          />
          <div className="progress-bar w-[50%] xl:w-[70%] h-2 bg-gray-200 rounded overflow-hidden">
            <div
              className="progress h-full bg-[#FB8818] transition-all"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            />
          </div>
        </div>
        <button
          type="button"
          className={muteButtonClasses}
          onClick={toggleMute}
          disabled={noPause && isPlaying}
        >
          {isMuted ? <BsFillVolumeMuteFill /> : <BsFillVolumeUpFill />}
        </button>
      </div>
      <br />
      <button
        className={listenButtonClasses}
        onClick={togglePlay}
        disabled={noPause && isPlaying}
      >
        <FiHeadphones size={12} />
        <p className="text-xs">Click here for the audio</p>
      </button>
    </div>
  )
}

export default AudioPlayer
