import { useDispatch, useSelector } from "react-redux";
import { setQuestionAnswer } from "../slice/question";

const useQuestionAnswer = () => {
  const dispatch = useDispatch();
  const answers = useSelector((state) => state.question.answers);

  const setAnswer = (index, value) => {
    dispatch(setQuestionAnswer({ index, value }));
  };

  const getAnswer = (number) => {
    return answers[number - 1];
  };

  const getAllAnswer = () => {
    return answers;
  };

  return { getAnswer, setAnswer, getAllAnswer };
};

export default useQuestionAnswer;
