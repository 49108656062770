import React from "react";
import { Icon } from "@iconify/react";

const CustomButton = () => (
  <Icon icon="iconoir:input-field" className="scale-150" />
);

const CustomToolbar = ({ idx = 0, fill = false }) => {
  return (
    <div id={`toolbar-${idx}`}>
      <span className="ql-formats">
        <select
          className="ql-header"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>
      <span className="ql-formats">
        <button className="ql-align" value="" />
        <button className="ql-align" value="center" />
        <button className="ql-align" value="right" />
        <button className="ql-align" value="justify" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-list" value="ordered" />
        <button type="button" className="ql-list" value="bullet" />
      </span>
      <span className="ql-formats">
        <button className="ql-image" />
        {fill && (
          <button className="ql-input">
            <CustomButton />
          </button>
        )}
      </span>
    </div>
  );
};

export default CustomToolbar;
