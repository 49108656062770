import React from 'react'

import {
  getProficiencyLevel,
  scoreIndicator,
} from '../../../utils/scoreIndicators'
import ModalSpeakingScore from '../../../components/Dashboard/ModalSpeakingScore'

const TestStats = ({
  speakingAnswer,
  data,
  testName,
  isModalScoreOpen,
  handleModalScore,
  classCategory,
}) => {
  const convertToTitleCase = (str) =>
    str.replace(/\b\w/g, (match) => match.toUpperCase()).replace('-', ' ')

  return (
    <>
      <div className="basis-72 shrink-0">
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="py-2 px-3 rounded-[4px] bg-[#F9F5FF] text-[#6941C6] font-semibold text-center">
              Speaking Test Master
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col space-y-4 grow">
                <div className="flex flex-col space-y-1 items-center">
                  <p className="text-neromid text-xs">F&C</p>
                  <p className="text-nero-700 font-bold">
                    {speakingAnswer?.fc || 0}
                  </p>
                </div>
                <div className="flex flex-col space-y-1 items-center">
                  <p className="text-neromid text-xs">LR</p>
                  <p className="text-nero-700 font-bold">
                    {speakingAnswer?.lr || 0}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-4 grow">
                <div className="flex flex-col space-y-1 items-center">
                  <p className="text-neromid text-xs">GRA</p>
                  <p className="text-nero-700 font-bold">
                    {speakingAnswer?.gra || 0}
                  </p>
                </div>
                <div className="flex flex-col space-y-1  items-center">
                  <p className="text-neromid text-xs">P</p>
                  <p className="text-nero-700 font-bold">
                    {speakingAnswer?.p || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-4 grow">
              <div className="flex flex-col space-y-1  items-center">
                <p className="text-neromid text-xs">Grand Score</p>
                <p className="text-nero-700 font-bold">
                  {speakingAnswer?.grand_score || 0} (
                  {classCategory === 'ielts' &&
                    scoreIndicator(speakingAnswer?.grand_score || 0)}
                  {classCategory === 'tibt' &&
                    getProficiencyLevel(
                      'speaking',
                      speakingAnswer?.grand_score || 0
                    )}
                  )
                </p>
              </div>
            </div>
            <button
              className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)]"
              onClick={handleModalScore}
            >
              {speakingAnswer?.grand_score > 0
                ? 'Edit Score Speaking'
                : 'Submit Score Speaking'}
            </button>
          </div>
        </div>
      </div>
      <ModalSpeakingScore
        handleModal={handleModalScore}
        isOpen={isModalScoreOpen}
        data={{
          createdAt: data.created_at,
          studentName: data.user.name,
          testName: convertToTitleCase(testName),
          category: data.category,
          totalScore: data.score,
          writingAnswer: data.writingAnswer,
          userId: data.userId,
          classCategory: classCategory,
        }}
        classCategory={classCategory}
      />
    </>
  )
}

export default TestStats
