import IcProofreading from '../../../assets/proofreading.svg';
import IcUser from '../../../assets/user.svg';
import {format} from 'date-fns';
import moment from 'moment/moment';

const ListDeadlines = ({data, subject, onClick}) => {
  const remainingTime = (date) => {
    const targetDate = moment(date, 'YYYY-MM-DD HH:mm:ss').add(7, 'days')
    const createAt = moment(date)
    const duration = moment.duration(targetDate.diff(createAt))
    const days = Math.floor(duration.asDays())
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    return `${days} day : ${hours.toString().padStart(2, '0')} : ${minutes
      .toString()
      .padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
  }

  return (
    <div className='bg-white border rounded-lg flex w-full flex-col p-2'>
      <div className='flex flex-row mt-1 items-center'>
        <img src={IcProofreading} />
        <div className='flex flex-col ml-3'>
          <p className='text-[#808080] text-xs'>Reminder : Review Writing Text</p>
          <p className='text-sm mt-1 text-[#064C85]'>Writing Test </p>
        </div>
        <p className='text-[#292929] font-bold text-xs ml-10 w-[15%]'>{data.test}</p>
        <img src={IcUser} className='w-5 h-5 ml-10'/>
        <p className='text-[#292929] text-xs ml-1 w-[20%]'>by {data.studentName}</p>
        <p className='text-[#064C85] font-bold text-xs ml-10'>{remainingTime(data.created_at)}</p>
        
        <button className='ml-5 bg-[#EC6A2A] p-2 px-5 rounded-lg text-white text-xs' onClick={onClick}>Detail</button>
      </div>
    </div>
  );
}

export default ListDeadlines;