import {
  CheckIcon,
  StopCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from '@material-tailwind/react'
import { tabDataModule } from '../../helpers/sat'
import classNames from 'classnames'

const SatReportTab = ({
  title,
  correctAnswers,
  incorrectAnswers,
  blankAnswers,
  moduleId,
  activeTabTable,
  questions,
  setActiveTabTable,
  correctFinalAnswers,
  incorrectFinalAnswers,
  blankFinalAnswers,
  questionsFinal,
  accordionOpen,
  handleAccordionOpen,
  dataTopic,
  handleOpenModal,
  activeTabModule,
  setActiveTabModule,
  createdAtAnswers,
  updatedAtAnswers,
  createdAtFinalAnswers,
  updatedAtFinalAnswers,
  scoreMath,
  scoreVerbal,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex space-x-4 w-full items-start text-black">
        <div className="border border-black p-2 text-center w-[250px]">
          <Typography variant="paragraph">{title}</Typography>
          <Typography variant="paragraph" className="text-7xl font-bold">
            {moduleId <= 2 ? scoreVerbal : scoreMath}
          </Typography>
          <Typography variant="paragraph">Out of 800</Typography>
        </div>
        <div className="flex-1 flex flex-col space-y-2">
          <div className="flex justify-between items-center">
            <Typography variant="lead" className="text-2xl">
              Analysis Overview
            </Typography>
            <div className="flex space-x-2">
              <Typography className="flex space-x-1 text-sm items-center">
                <CheckIcon className="w-4 h-4 text-[#a3da09]" />
                <span>{correctAnswers + correctFinalAnswers} Correct</span>
              </Typography>
              <Typography className="flex space-x-1 text-sm items-center">
                <XMarkIcon className="w-4 h-4 text-[#ff3e2a]" />{' '}
                <span>
                  {incorrectAnswers + incorrectFinalAnswers} Incorrect
                </span>
              </Typography>
              <Typography className="flex space-x-1 text-sm items-center">
                <StopCircleIcon className="w-4 h-4 text-[#c5c5c5]" />{' '}
                <span>{blankAnswers + blankFinalAnswers} Blank</span>
              </Typography>
            </div>
          </div>
          <div className="flex-1 flex space-x-2">
            <Typography
              variant="paragraph"
              className="text-sm whitespace-nowrap"
            >
              Problem-Solving and Data Analysis
            </Typography>
            <div className="flex bg-[#c5c5c5] h-6 w-full">
              <div className="flex items-center justify-center bg-[#a3da09] h-6 px-1 w-1/3">
                <Typography variant="paragraph" className="text-sm text-white">
                  1
                </Typography>
              </div>
              <div className="flex items-center justify-center bg-[#ff3e2a] h-6 px-1 w-1/3">
                <Typography variant="paragraph" className="text-sm text-white">
                  1
                </Typography>
              </div>
              <div className="flex items-center justify-center bg-[#c5c5c5] h-6 px-1 w-1/3">
                <Typography variant="paragraph" className="text-sm text-white">
                  1
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs value={activeTabModule}>
        <TabsHeader
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-gray-900 shadow-none rounded-none',
          }}
        >
          {tabDataModule({
            createdAtAnswers,
            updatedAtAnswers,
            createdAtFinalAnswers,
            updatedAtFinalAnswers,
            correctAnswers,
            incorrectAnswers,
            blankAnswers,
            moduleId,
            activeTabTable,
            questions,
            setActiveTabTable,
            correctFinalAnswers,
            incorrectFinalAnswers,
            blankFinalAnswers,
            questionsFinal,
            accordionOpen,
            handleAccordionOpen,
            dataTopic,
            handleOpenModal,
          }).map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTabModule(value)}
              className={classNames('justify-start p-4', {
                'bg-gray-50': activeTabModule === value,
              })}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {tabDataModule({
            createdAtAnswers,
            updatedAtAnswers,
            createdAtFinalAnswers,
            updatedAtFinalAnswers,
            correctAnswers,
            incorrectAnswers,
            blankAnswers,
            moduleId,
            activeTabTable,
            questions,
            setActiveTabTable,
            correctFinalAnswers,
            incorrectFinalAnswers,
            blankFinalAnswers,
            questionsFinal,
            accordionOpen,
            handleAccordionOpen,
            dataTopic,
            handleOpenModal,
          }).map(({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default SatReportTab
