import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabsListening } from '../../../components/Tabs/listening'
import Drag from '../../../components/Question/widgets/drag'
import soal1 from '../../../assets/soal1.svg'
import soal2 from '../../../assets/soal2.svg'
import soal3 from '../../../assets/soal3.svg'
import soal4 from '../../../assets/soal4.svg'
import soal5 from '../../../assets/soal5.svg'
import soal6 from '../../../assets/soal6.svg'
import soal7 from '../../../assets/soal7.svg'
import soal8 from '../../../assets/soal8.svg'
import soal9 from '../../../assets/soal9.jpg'
import Sidebar from '../../../components/Sidebar'
import axios from '../../../utils/axios'
import { groupBy } from 'lodash'
import { TabsSat } from '../../../components/Tabs/sat'
import { setBuilderData } from '../../../slice/question'

const Sat = () => {
  const dispatch = useDispatch()
  const selector = useSelector((s) => s)
  const questionDesc = useSelector((s) => s.questionDesc)
  const subject = useSelector((s) => s.questionDesc.subject)
  const [activeTabIndexModule, setActiveTabIndexModule] = useState(0)

  const setTabIndexModule = (idx) => {
    setActiveTabIndexModule(idx)
  }

  const submitAll = () => {
    const id = selector.questionDesc.id

    // TODO
    if (!id) return alert('tambah deskripsi dahulu')

    const questions = selector.question.builders
    const groupQuestions = groupBy(questions, 'section_code')
    const bodyRequest = {
      sectionsItems: Object.keys(groupQuestions).map((key) => ({
        section: key,
        questionDescriptionId: groupQuestions[key][0]?.questionDescriptionId,
        files: {},
      })),
      items: questions,
    }

    const processBodyRequest = async (bodyRequest) => {
      try {
        const processedItems = await Promise.all(
          bodyRequest.items.map(async (item) => {
            const files = []

            const readFileAsDataURLPromise = (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
              })
            }

            if (item.type === '9') {
              try {
                const base64String = await readFileAsDataURLPromise(item.files)
                files.push(base64String)
              } catch (error) {
                console.error('Error reading file:', error)
              }
            } else {
              return item
            }

            return { ...item, files: files[0] }
          })
        )

        return processedItems
      } catch (error) {
        console.error('Error processing body request:', error)
        throw error
      }
    }

    processBodyRequest(bodyRequest)
      .then((result) => {
        const modifyResult = result
          .map((item, index) => {
            return {
              ...item,
              sort: index + 1,
            }
          })
          .filter((item) => +item.section_code === activeTabIndexModule + 1)

        axios
          .post(`/questions/sat/${subject}/${activeTabIndexModule + 1}`, {
            items: [...modifyResult],
          })
          .then((res) => {
            // TODO
            alert('success')
            let data = []

            if (res.data && res.data.data) {
              res.data.data.forEach((val) => {
                val.choices = JSON.parse(val.choices)
                if (val.type == 1) {
                  val.answer = val.answer
                } else if (val.type == 2) {
                  val.answer = JSON.parse(val.answer)
                } else if (val.type == 3) {
                  val.answer = val.answer
                } else if (val.type == 4) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 5) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 6) {
                  val.preText = JSON.parse(val.pre_text)
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 7) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 8) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                  val.preText = JSON.parse(val.pre_text)
                  val.label = JSON.parse(val.label)
                } else {
                  val.answer = val.answer
                }

                data.push(val)
              })
            }

            dispatch(setBuilderData({ data, id: activeTabIndexModule + 1 }))
          })
          .catch((e) => {
            alert('error')
          })
      })
      .catch((error) => {
        console.error('Error processing body request:', error)
      })
  }

  return (
    <div>
      <div className="flex">
        <div className="min-w-[280px] max-h-[calc(100vh-56px)] overflow-hidden">
          <Sidebar />
        </div>

        <div className="h-[calc(100vh-56px)] overflow-y-auto flex-auto py-6 px-4">
          <TabsSat
            setTabIndexModule={setTabIndexModule}
            activeTabIndexModule={activeTabIndexModule}
          />
        </div>

        <div className="min-w-[280px] h-[calc(100vh-56px)] overflow-y-auto py-6 px-4">
          <button
            className={`py-2 rounded-md text-xs text-white bg-[#064C85] px-5 transition-colors duration-300`}
            onClick={submitAll}
          >
            <span className="px-5">Submit</span>
          </button>
          <h1 className="mt-10">Pilih Type Soal</h1>
          <Drag display={soal1} value={1} title={`Multiple Choices Type`} />
          <Drag display={soal3} value={3} title={`Answer Type`} />
        </div>
      </div>
    </div>
  )
}

export default Sat
