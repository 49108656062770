import React from 'react'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'

import VerifiedIcon from '../../assets/icons/verified.svg'

const ModalAddScore = ({ isOpen, onClose, onSubmit, onCancel }) => {
  return (
    <Dialog open={isOpen} size="lg">
      <DialogBody className="pt-24 px-24">
        <div className="flex flex-col items-center text-center space-y-8">
          <img src={VerifiedIcon} alt="Verified Icon" />
          <div className="flex flex-col space-y-4">
            <h6 className="text-nero-700 text-lg font-semibold">
              Are you sure you want to submit?
            </h6>
            <p className="text-nero-mid text-sm">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
            </p>
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="flex justify-center space-x-4 pb-24 px-24">
        <Button
          variant="outlined"
          color="dark-blue"
          onClick={onCancel}
          className="w-60 focus:ring-0"
        >
          <span>Cancel</span>
        </Button>
        <Button color="dark-blue" className="w-60" onClick={onSubmit}>
          <span>Submit</span>
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default ModalAddScore
