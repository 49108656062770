import React from 'react'
import useQuestionAnswer from '../../../hooks/useQuestionAnswer'
import check from '../../../assets/check.svg'
import wrong from '../../../assets/wrong.svg'

/*
  @@params{
    number : int
    question : string
    limit = int
  }
*/

const AnswerTypeQuestion = ({
  number,
  question,
  limit = 99,
  id,
  userAnswer,
  answer,
}) => {
  return (
    <div className="flex my-4" id={`q${number}`}>
      <span className="mr-3">{number}.</span>
      <div className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        <section className="input-text mt-3">
          <span>Your Answer : </span>
          <label className="relative">
            <input
              type="text"
              className={`!text-[#064C85] ${
                answer instanceof Array && userAnswer
                  ? answer.find(
                      (value) =>
                        value.toLowerCase() === userAnswer.toLowerCase().trim()
                    )
                    ? '!bg-[#E2FCF3]'
                    : '!bg-[#FDE0E9]'
                  : userAnswer
                  ? answer.toLowerCase() === userAnswer.toLowerCase().trim()
                    ? '!bg-[#E2FCF3]'
                    : '!bg-[#FDE0E9]'
                  : '!bg-[#FDE0E9]'
              }`}
              name={`question${number}`}
              defaultValue={userAnswer}
              readOnly
            />
            {answer instanceof Array && userAnswer ? (
              answer.find(
                (value) =>
                  value.toLowerCase() === userAnswer.toLowerCase().trim()
              ) ? (
                <img src={check} className="absolute right-1 top-1" />
              ) : (
                <img src={wrong} className="absolute right-1 top-1" />
              )
            ) : userAnswer ? (
              answer.toLowerCase() === userAnswer.toLowerCase().trim() ? (
                <img src={check} className="absolute right-1 top-1" />
              ) : (
                <img src={wrong} className="absolute right-1 top-1" />
              )
            ) : (
              <img src={wrong} className="absolute right-1 top-1" />
            )}
          </label>
        </section>
        <div className="flex flex-row gap-3">
          <p>Answer : </p>
          <input
            type="text"
            className="!text-[#fb8818]"
            defaultValue={answer}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default AnswerTypeQuestion
