import React, { useState } from 'react'

const Drag = ({ display, value, title }) => {
  const handleOnDrag = (e, widgetType) => {
    e.dataTransfer.setData('widgetType', widgetType)
  }

  return (
    <div>
      <div
        draggable
        onDragStart={(e) => handleOnDrag(e, value)}
        className="cursor-grab"
      >
        <div className="shadow" style={{ width: '100%', marginTop: 20 }}>
          <img src={display} width={250} />
          <div>{title}</div>
        </div>
      </div>
    </div>
  )
}

export default Drag
