import { Icon } from "@iconify/react";
import { usePopup } from ".";

const AlertPopup = ({ text }) => {
  const popup = usePopup();

  return (
    <main className="bg-black fixed top-0 left-0 bg-opacity-50 h-screen w-screen z-50 flex justify-center items-center">
      <div className="bg-white py-4 px-24 rounded-lg flex flex-col items-center">
        <Icon icon="mdi:alert-circle" className="w-16 h-16" />
        <h1 className="flex items-center gap-1 text-2xl font-bold">
          Peringatan!
        </h1>
        <p>{text}</p>
        <button
          className="bg-priblue-400 px-4 py-1 rounded-md text-white mt-6 hover:bg-priblue-500 active:bg-priblue-600 transition-all"
          type="button"
          onClick={popup.closeHandle}
        >
          OK
        </button>
      </div>
    </main>
  );
};

export default AlertPopup;
