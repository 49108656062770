import React, { useState } from 'react'
import moment from 'moment'
import { twMerge } from 'tailwind-merge'
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from '@material-tailwind/react'

import DotsVerticalcon from '../../../assets/icons/dots-vertical.svg'
import DefaultStudentImage from '../../../assets/default-student.png'

const Comment = ({
  comment,
  currentUser,
  isLastCommment,
  onDeleteComment,
  onEditComment,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState(comment.text)

  const handleEditComment = (event) => setEditedComment(event.target.value)
  const openEditComment = () => setIsEditing(true)
  const closeEditComment = () => setIsEditing(false)

  const handleComment = (commentData) => {
    onEditComment(commentData)
    closeEditComment()
  }

  return (
    <div
      className={twMerge(
        'flex space-x-4 pb-4',
        !isLastCommment && 'border-b border-[#cccccc]'
      )}
      key={comment.id}
    >
      <img
        src={DefaultStudentImage}
        alt="Student"
        className="w-[54px] h-[54px] rounded-full"
      />
      <div className="flex flex-col space-y-4 grow">
        <div className="flex justify-between">
          <div>
            <h6 className="text-nero-700 font-Montserrat font-semibold text-lg">
              {comment.name}
            </h6>
            <p className="text-neromid text-sm">
              {moment(comment.created_at).format('MMM D, YYYY, h:mm A')}
            </p>
          </div>
          {comment.userId === currentUser.userId && (
            <Popover placement="bottom-end">
              <PopoverHandler>
                <img
                  src={DotsVerticalcon}
                  alt="Dots Vertical Icon"
                  className="cursor-pointer"
                />
              </PopoverHandler>
              <PopoverContent className="p-0">
                <div
                  className="text-sm text-nero-700 py-3 px-4 text-center hover:bg-[#F9FAFB] cursor-pointer"
                  onClick={openEditComment}
                >
                  Edit
                </div>
                <div
                  className="text-sm text-nero-700 py-3 px-4  text-center hover:bg-[#F9FAFB] cursor-pointer"
                  onClick={() => onDeleteComment(comment.id)}
                >
                  Hapus
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
        {!isEditing && <p className="text-nero-700 text-sm">{comment.text}</p>}
        {isEditing && (
          <>
            <textarea
              value={editedComment}
              onChange={handleEditComment}
              className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full min-h-[45px] focus:outline-none focus:ring-0"
              rows={4}
            />
            <div className="flex space-x-4">
              <button
                className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)] min-h-[45px]"
                onClick={() =>
                  handleComment({
                    id: comment.id,
                    text: editedComment,
                  })
                }
              >
                Edit Comment
              </button>
              <button
                className="px-8 py-3 rounded-[4px] border border-[#CCC] text-xs text-nero-700 min-h-[45px]"
                onClick={closeEditComment}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Comment
