export default function Badge({ badge }) {
  return (
    <div className="flex flex-row items-center justify-center  rounded-full px-2 py-1 gap-4">
      {badge.map((item, idx) => (
        <p key={idx} className="text-xs text-[#6941C6] mx-2 bg-[#F8F8F8] py-1 px-2 rounded-xl font-semibold">
          {item.name}
        </p>
      ))}
    </div>
  );
}
