import React, { useState } from "react";
import TextEditor from "../Question/widgets/textEditor";

const Content = (props) => {
  const [desc, setDesc] = useState("");
  const [edit, setEdit] = useState(false);

  const contentSectionStyles = {
    textAlign: "justify",
    background: "#fff",
    padding: "20px",
  };

  const bubbleUpEditableSelectedRegion = (e) => {
    const arrowEvents = ["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"];
    if (arrowEvents.includes(e.key) && e.shiftKey) {
      bubbleUpSelectedRegion(e);
    }
  };

  const bubbleUpSelectedRegion = (e) => {
    const { setBtnsGroupPosition, showButtonsGroup, showCurrentContent } =
      props;

    const selection = window.getSelection();

    if (selection.toString()) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setBtnsGroupPosition(rect);
      showButtonsGroup();

      // get Updated Content
      const element = document.getElementById("content");
      showCurrentContent({ content: element });
    }
  };

  return (
    <div>
      {/* <button className='w-20 my-5 py-2 bg-[#064C85] text-white rounded-lg' onClick={onClick}>Save</button> */}
      {edit ? (
        <section>
          <TextEditor value={desc} setValue={setDesc} idx={0} />
          <button
            className="bg-priblue-400 px-4 py-2 rounded-md mt-2 text-white"
            onClick={() => setEdit(!edit)}
          >
            Save
          </button>
        </section>
      ) : (
        <>
          <button
            className="bg-priblue-400 px-4 py-2 rounded-md mt-2 text-white"
            onClick={() => setEdit(!edit)}
          >
            Edit
          </button>
          <div id="content">
            <section
              contentEditable={false}
              style={contentSectionStyles}
              onMouseUp={bubbleUpSelectedRegion}
              onMouseMove={bubbleUpSelectedRegion}
              onKeyUp={bubbleUpEditableSelectedRegion}
              dangerouslySetInnerHTML={{ __html: desc }}
            >
              {/* <div  /> */}
            </section>
          </div>
        </>
      )}
    </div>
  );
};

// export default class Content extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { content: "" };
//   }

//   setContent = (val) => {
//     console.log(val);
//     this.setState({
//       content: val,
//     });
//   };

//   render() {

//     return (

//     );
//   }
// }

export default Content;
