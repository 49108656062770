import React, { useEffect, useState } from 'react'
import useQuestionBuilder from '../../../hooks/useQuestionBuilder'
import QuestionBuilder from '../builder'
import axios from '../../../utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setStatusQuestion } from '../../../slice/status'
import { setBuilderData } from '../../../slice/question'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const Drop = ({ id, classCategory, handleAudio }) => {
  const { addBuilder, getAllBuilders, getCurrentNumber } = useQuestionBuilder()
  const builders = useSelector((state) => state.question.builders)
  const status = useSelector((state) => state.status.listQuestions)
  const subject = useSelector((state) => state.questionDesc.subject)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const location = useLocation()

  var query = location.pathname
  const lastSegment = query.substring(query.lastIndexOf('/') + 1)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await axios
        .get(
          classCategory === 'sat'
            ? `/questions/sat/${subject}/${id}`
            : `/questions/${subject}/${lastSegment}/${classCategory}/${id}`
        )
        .then((res) => {
          let data = []

          if (res.data && res.data.results) {
            res.data.results[1]
              .slice()
              .reverse()
              .forEach((val) => {
                val.choices = JSON.parse(val.choices)
                if (val.type == 1) {
                  val.answer = val.answer
                } else if (val.type == 2) {
                  val.answer = JSON.parse(val.answer)
                } else if (val.type == 3) {
                  val.answer = val.answer
                } else if (val.type == 4) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 5) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 6) {
                  val.preText = JSON.parse(val.pre_text)
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 7) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                } else if (val.type == 8) {
                  val.answer = JSON.parse(val.answer)
                  val.postText = JSON.parse(val.post_text)
                  val.preText = JSON.parse(val.pre_text)
                  val.label = JSON.parse(val.label)
                } else {
                  val.answer = val.answer
                }

                data.push(val)
              })

            const audioName = res.data.results[0].find(
              (val) => val.section === id
            )?.audio_name

            if (audioName) {
              handleAudio?.(audioName)
            }
          }

          if (res.data && res.data.data) {
            res.data.data.forEach((val) => {
              val.choices = JSON.parse(val.choices)
              if (val.type == 1) {
                val.answer = val.answer
              } else if (val.type == 2) {
                val.answer = JSON.parse(val.answer)
              } else if (val.type == 3) {
                val.answer = val.answer
              } else if (val.type == 4) {
                val.answer = JSON.parse(val.answer)
                val.postText = JSON.parse(val.post_text)
              } else if (val.type == 5) {
                val.answer = JSON.parse(val.answer)
                val.postText = JSON.parse(val.post_text)
              } else if (val.type == 6) {
                val.preText = JSON.parse(val.pre_text)
                val.answer = JSON.parse(val.answer)
                val.postText = JSON.parse(val.post_text)
              } else if (val.type == 7) {
                val.answer = JSON.parse(val.answer)
                val.postText = JSON.parse(val.post_text)
              } else if (val.type == 8) {
                val.answer = JSON.parse(val.answer)
                val.postText = JSON.parse(val.post_text)
                val.preText = JSON.parse(val.pre_text)
                val.label = JSON.parse(val.label)
              } else {
                val.answer = val.answer
              }

              data.push(val)
            })
          }

          dispatch(setBuilderData({ data, id }))
        })
        .catch((err) => {
          toast.error(err.response.data.message)
          dispatch(setBuilderData({ data: [], id }))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (status == false) {
      fetchData()
      dispatch(setStatusQuestion(true))
    }
  }, [classCategory, dispatch, handleAudio, id, lastSegment, status, subject])

  const handleOnDrop = (e) => {
    const widgetType = e.dataTransfer.getData('widgetType')
    addBuilder(
      {
        type: widgetType,
        editor: true,
        number: getCurrentNumber(),
        section_code: id,
      },
      id
    )
  }

  const handleDragOver = (e) => e.preventDefault()

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className="overflow-auto w-full p-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none hover:border-gray-400 focus:outline-none h-[calc(100vh-130px)]"
          onDrop={handleOnDrop}
          onDragOver={handleDragOver}
        >
          {id
            ? builders
                .filter((builder) => builder.section_code === id)
                ?.map((val, index) => (
                  <QuestionBuilder
                    {...val}
                    idx={index}
                    key={index}
                    containerId={id}
                    classCategory={classCategory}
                  />
                ))
            : builders.map((val, index) => (
                <QuestionBuilder
                  {...val}
                  idx={index}
                  key={index}
                  classCategory={classCategory}
                />
              ))}
        </div>
      )}
    </div>
  )
}

export default Drop
