import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchSatAnswer,
  fetchSatAnswers,
  fetchSatDescription,
  fetchSatQuestions,
  fetchSatReport,
  fetchSatScore,
  fetchSatTopic,
  postSatAnswer,
  updateSatAnswers,
  updateSatScore,
} from '../services/apiSat'
import { toast } from 'react-toastify'

export const useFetchSatDescription = ({ subject, moduleId }) => {
  const query = useQuery({
    queryKey: ['satDescription', { subject, moduleId }],
    queryFn: () => fetchSatDescription({ subject, moduleId }),
  })

  return query
}

export const useFetchSatReport = ({ subject, userId }) => {
  const query = useQuery({
    queryKey: ['satReport', { subject, userId }],
    queryFn: () => fetchSatReport({ subject, userId }),
    enabled: !!subject && !!userId,
  })

  return query
}

export const useFetchSatTopic = () => {
  const query = useQuery({
    queryKey: ['satTopic'],
    queryFn: () => fetchSatTopic(),
  })

  return query
}

export const useFetchSatAnswers = ({ subject, moduleId, enabled }) => {
  const query = useQuery({
    queryKey: ['satAnswers', { subject, moduleId }],
    queryFn: () => fetchSatAnswers({ subject, moduleId }),
    enabled,
  })

  return query
}

export const useFetchSatQuestions = ({ subject, moduleId }) => {
  const query = useQuery({
    queryKey: ['satQuestions', { subject, moduleId }],
    queryFn: () => fetchSatQuestions({ subject, moduleId }),
  })

  return query
}

export const useFetchSatAnswer = ({ subject, moduleId, enabled }) => {
  const query = useQuery({
    queryKey: [
      'satAnswer',
      {
        subject,
        moduleId,
      },
    ],
    queryFn: () =>
      fetchSatAnswer({
        subject,
        moduleId,
      }),
    enabled,
  })

  return query
}

export const usePostSatAnswer = () => {
  const queryClient = useQueryClient()

  const query = useMutation({
    mutationFn: postSatAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['satAnswers', 'satAnswer'],
      })
    },
  })

  return query
}

export const useUpdateSatAnswers = () => {
  const queryClient = useQueryClient()

  const query = useMutation({
    mutationFn: updateSatAnswers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['satAnswers', 'satAnswer'],
      })
    },
    onError: (err) => toast.error(err.message),
  })

  return query
}

export const useUpdateSatScore = () => {
  const queryClient = useQueryClient()

  const query = useMutation({
    mutationFn: updateSatScore,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['satScore'],
      })
    },
    onError: (err) => toast.error(err.message),
  })

  return query
}

export const useFetchSatScore = ({ subject, userId }) => {
  const query = useQuery({
    queryKey: ['satScore', { subject }],
    queryFn: () => fetchSatScore({ subject, userId }),
  })

  return query
}
