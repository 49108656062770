import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabsListening } from '../../../components/Tabs/listening'
import Drag from '../../../components/Question/widgets/drag'
import soal1 from '../../../assets/soal1.svg'
import soal2 from '../../../assets/soal2.svg'
import soal3 from '../../../assets/soal3.svg'
import soal4 from '../../../assets/soal4.svg'
import soal5 from '../../../assets/soal5.svg'
import soal6 from '../../../assets/soal6.svg'
import soal7 from '../../../assets/soal7.svg'
import soal8 from '../../../assets/soal8.svg'
import soal9 from '../../../assets/soal9.jpg'
import Sidebar from '../../../components/Sidebar'
import axios from '../../../utils/axios'
import { groupBy } from 'lodash'

const Listening = () => {
  const dispatch = useDispatch()
  const selector = useSelector((s) => s)
  const questionDesc = useSelector((s) => s.questionDesc)
  const subject = useSelector((s) => s.questionDesc.subject)
  const [textaudio1, setTextaudio1] = useState('')
  const [selectedAudioFile1, setSelectedAudioFile1] = useState('')
  const [textaudio2, setTextaudio2] = useState('')
  const [selectedAudioFile2, setSelectedAudioFile2] = useState('')
  const [textaudio3, setTextaudio3] = useState('')
  const [selectedAudioFile3, setSelectedAudioFile3] = useState('')
  const [selectedClassCategories, setSelectedClassCategories] = useState('')
  const [selectedFile, setSelectedFile] = useState('')

  const submitAll = () => {
    const id = selector.questionDesc.id

    // TODO
    if (!id) return alert('tambah deskripsi dahulu')

    const questions = selector.question.builders
    const groupQuestions = groupBy(questions, 'section_code')
    const bodyRequestFormData = new FormData()
    const bodyRequest = {
      sectionsItems: Object.keys(groupQuestions).map((key) => ({
        section: key,
        questionDescriptionId: groupQuestions[key][0]?.questionDescriptionId,
        files: {},
      })),
      items: questions,
    }

    if (selectedFile) {
      bodyRequestFormData.append('files', selectedFile)
    }

    if (selectedAudioFile1 && typeof selectedAudioFile1 !== 'string') {
      bodyRequestFormData.append('files', selectedAudioFile1)
    }

    if (selectedAudioFile2 && typeof selectedAudioFile2 !== 'string') {
      bodyRequestFormData.append('files', selectedAudioFile2)
    }

    if (selectedAudioFile3 && typeof selectedAudioFile3 !== 'string') {
      bodyRequestFormData.append('files', selectedAudioFile3)
    }

    const processBodyRequest = async (bodyRequest) => {
      try {
        const processedItems = await Promise.all(
          bodyRequest.items.map(async (item) => {
            const files = []

            const readFileAsDataURLPromise = (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
              })
            }

            if (item.type === '9') {
              try {
                const base64String = await readFileAsDataURLPromise(item.files)
                files.push(base64String)
              } catch (error) {
                console.error('Error reading file:', error)
              }
            } else {
              return item
            }

            return { ...item, files: files[0] }
          })
        )

        return processedItems
      } catch (error) {
        console.error('Error processing body request:', error)
        throw error
      }
    }

    processBodyRequest(bodyRequest)
      .then((result) => {
        const modifyResult = result.map((item, index) => {
          return {
            ...item,
            sort: index + 1,
          }
        })
        bodyRequestFormData.append('items', JSON.stringify(modifyResult))

        axios
          .post(
            `/questions/${subject}/listening/${selectedClassCategories}`,
            bodyRequestFormData
          )
          .then((res) => {
            // TODO
            alert('success')
            console.log(res)
          })
          .catch((e) => {
            alert('error')
          })
      })
      .catch((error) => {
        console.error('Error processing body request:', error)
      })
  }

  const handleChangeAudioFile1 = (e) => {
    const file = typeof e === 'string' ? e : e.target.files[0]
    if (file) setSelectedAudioFile1(file)
  }

  const handleChangeAudioFile2 = (e) => {
    const file = typeof e === 'string' ? e : e.target.files[0]
    if (file) setSelectedAudioFile2(file)
  }

  const handleChangeAudioFile3 = (e) => {
    const file = typeof e === 'string' ? e : e.target.files[0]
    if (file) setSelectedAudioFile3(file)
  }

  return (
    <div>
      <div className="flex">
        <div className="min-w-[280px] max-h-[calc(100vh-56px)] overflow-hidden">
          <Sidebar />
        </div>

        <div className="h-[calc(100vh-56px)] overflow-y-auto flex-auto py-6 px-4">
          <TabsListening
            selectedAudioFile1={selectedAudioFile1}
            textaudio1={textaudio1}
            setTextaudio1={setTextaudio1}
            handleChangeAudioFile1={handleChangeAudioFile1}
            selectedAudioFile2={selectedAudioFile2}
            textaudio2={textaudio2}
            setTextaudio2={setTextaudio2}
            handleChangeAudioFile2={handleChangeAudioFile2}
            selectedAudioFile3={selectedAudioFile3}
            textaudio3={textaudio3}
            setTextaudio3={setTextaudio3}
            handleChangeAudioFile3={handleChangeAudioFile3}
            selectedClassCategories={selectedClassCategories}
            setSelectedClassCategories={setSelectedClassCategories}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </div>

        <div className="min-w-[280px] h-[calc(100vh-56px)] overflow-y-auto py-6 px-4">
          <button
            className={`py-2 rounded-md text-xs text-white bg-[#064C85] px-5 transition-colors duration-300`}
            onClick={submitAll}
          >
            <span className="px-5">Submit</span>
          </button>
          <h1 className="mt-10">Pilih Type Soal</h1>
          <Drag display={soal9} value={9} title={`Audio Type`} />
          <Drag display={soal1} value={1} title={`Multiple Choices Type`} />
          <Drag display={soal2} value={2} title={`Checkbox Type`} />
          <Drag display={soal3} value={3} title={`Answer Type`} />
          <Drag display={soal4} value={4} title={`Select Default Type`} />
          <Drag display={soal5} value={5} title={`Select Truth Type`} />
          <Drag display={soal6} value={6} title={`Fill Default Type`} />
          <Drag display={soal7} value={7} title={`Fill Card Type`} />
          <Drag display={soal8} value={8} title={`Fill Table Type`} />
        </div>
      </div>
    </div>
  )
}

export default Listening
