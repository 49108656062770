import { IconButton, Tooltip, Typography } from '@material-tailwind/react'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useState } from 'react'

function MultipleChoiceQuestion({
  question,
  options,
  onChange,
  onElimination,
  userAnswer,
  correctAnswer,
  currentNumber,
  eliminatedAnswers,
}) {
  const [showEliminateAnswers, setShowEliminateAnswers] = useState(false)

  const handleOptionChange = (value) => {
    if (onChange) {
      onChange(value)
    }

    if (eliminatedAnswers?.includes(value)) {
      onElimination(value)
    }
  }

  const handleEliminateAnswers = () => {
    setShowEliminateAnswers(!showEliminateAnswers)
  }

  const handleElimination = (value) => {
    onElimination(value)
  }

  return (
    <div>
      {!correctAnswer && (
        <div className="flex justify-between">
          <Typography variant="h5" className="mb-4">
            {currentNumber}.{' '}
          </Typography>
          <Tooltip content="Eliminate Answers" placement="bottom-end">
            <IconButton
              variant="outlined"
              size="sm"
              onClick={handleEliminateAnswers}
            >
              <s>ABC</s>
            </IconButton>
          </Tooltip>
        </div>
      )}

      <div className="flex space-x-2">
        <Typography
          variant="h5"
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
        />
      </div>

      <ul className="flex flex-col space-y-4">
        {options.map((option, index) => (
          <li key={option} className="flex space-x-2 items-center">
            <div
              className={classNames(
                'relative flex-1 flex space-x-2 items-center p-2 rounded border transition',
                {
                  'border-[#fb8818]': userAnswer === option,
                  'cursor-pointer': !correctAnswer,
                  'before:absolute before:left-0 before:top-1/2 before:w-full before:h-0.5 before:bg-black before:transform before:-translate-y-1/2':
                    showEliminateAnswers &&
                    eliminatedAnswers?.indexOf(option) !== -1,
                }
              )}
              htmlFor={`option-${index}`}
              onClick={() => !correctAnswer && handleOptionChange(option)}
            >
              <div
                className={classNames(
                  'flex items-center justify-center flex-none w-6 h-6 border-2 dashed rounded-full transition',
                  {
                    'border-[#fb8818] bg-[#fb8818] text-white':
                      userAnswer === option,
                  }
                )}
                value={option}
              >
                <Typography variant="small" className="font-bold">
                  {String.fromCharCode(65 + index)}
                </Typography>
              </div>
              <Typography variant="paragraph">
                {option}
                {((correctAnswer === option && userAnswer === option) ||
                  correctAnswer === option) && (
                  <span className="text-[#a3da09] text-sm">
                    {' '}
                    (Correct Answer)
                  </span>
                )}
                {correctAnswer && userAnswer === option && (
                  <span
                    className={classNames('text-sm', {
                      'text-[#ff3e2a]': correctAnswer !== option,
                    })}
                  >
                    {' '}
                    (Your Answer)
                  </span>
                )}
                {!userAnswer && correctAnswer === option && (
                  <span className="text-sm"> (Blank)</span>
                )}
              </Typography>
            </div>
            {correctAnswer && eliminatedAnswers?.indexOf(option) !== -1 && (
              <Tooltip
                content="You struck out this choice"
                className="z-[9999]"
              >
                <Typography
                  variant="paragraph"
                  className="text-lg cursor-pointer"
                >
                  <s>S</s>
                </Typography>
              </Tooltip>
            )}
            {showEliminateAnswers &&
              eliminatedAnswers?.indexOf(option) === -1 && (
                <IconButton
                  variant="outlined"
                  size="sm"
                  onClick={() => !correctAnswer && handleElimination(option)}
                >
                  <s>{String.fromCharCode(65 + index)}</s>
                </IconButton>
              )}
            {showEliminateAnswers &&
              eliminatedAnswers?.indexOf(option) !== -1 && (
                <Typography
                  variant="paragraph"
                  onClick={() => !correctAnswer && handleElimination(option)}
                  className="text-sm cursor-pointer"
                >
                  Undo
                </Typography>
              )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MultipleChoiceQuestion
