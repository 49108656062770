import { htmlToSlate } from '@slate-serializers/html'

export const countWords = (answer) => {
  const slateAnswer = htmlToSlate(answer)
  let wordCount = 0

  if (slateAnswer) {
    slateAnswer.forEach((paragraph) => {
      if (paragraph.children && paragraph.children.length > 0) {
        paragraph.children.forEach((child) => {
          if (child.text) {
            const words = child.text.split(/\s+/)
            wordCount += words.filter((word) => word !== '').length
          }
        })
      }
    })
  }

  return wordCount
}
