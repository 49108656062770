import React from 'react';
import RingLoader from "react-spinners/RingLoader";

const Loading = () => {
  return(
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-200">
      <RingLoader
        size={50}
        color={"#064C85"}
        loading={true}
      />
    </div>
  )
}

export default Loading
