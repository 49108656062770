import React, { useState, useEffect } from 'react';
import { MdCloudUpload } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { Input } from '@material-tailwind/react'
import CloseIcon from '../../assets/icons/close.svg'
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg'
import clsx from "clsx";
import { useSelector } from 'react-redux';
import ImgDocs from '../../assets/google-docs 1.svg';
import axios from '../../utils/axios';

const ModalUploadReports = ({
  isOpen,
  onClose,
  userId,
  datareports
}) => {
  const [selectedFile, setSelectedFile] = useState("");

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (file) setSelectedFile(file);
  };

  const submit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.append("userId", userId);

    
    axios
        .post("/report", data)
        .then((res) => {
          // TODO: ui
          alert("Sukses ditambahkan");
          onClose();
        })
        .catch((err) => {
          alert("Gagal ditambahkan");
          console.error(err.message);
        });
  }

  return (
    <Dialog open={isOpen}>
      <form className="max-w-full" onSubmit={submit}>

        <div
          className="absolute right-8 top-8 flex justify-center items-center w-6 h-6 bg-[#0000000A] rounded-full cursor-pointer"
          onClick={onClose}
        >
          <img src={CloseIcon} alt="Close Icon" />
        </div>
        <DialogHeader className="flex pt-10 px-24 text-[#064C85]">
            Add Reports
          </DialogHeader>
          <DialogBody className="px-24">
            <label
              className={clsx(
                "flex justify-center w-full h-36 px-4 transition bg-white border-2",
                "border-gray-300 border-dashed rounded-md appearance-none",
                "cursor-pointer hover:border-gray-400 focus:outline-none relative"
              )}
            >
              <div className='flex flex-col items-center justify-center'>
                <img src={ImgDocs} style={{ width: 50 }}/>
                <div className='flex'>
                  <span className="flex items-center gap-1">
                    <span className="font-medium text-">
                      Select a Doc file to upload
                    </span>
                  </span>
                  <span className="absolute mt-5 text-[#3276FA]">
                    {
                      datareports ? 
                        datareports.length > 0 ?
                        datareports : selectedFile.name
                      : selectedFile.name
                    }
                    {/* { datareports[0].pdf_file === ""
                      ? selectedFile.name : datareports[0].pdf_file} */}
                  </span>

                  <input
                    className="hidden"
                    type="file"
                    name="files"
                    accept="application/pdf,application/vnd.ms-excel"
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </label>
          </DialogBody>
          <DialogFooter className="pb-24 px-24">
            <Button
              className="w-full bg-[#EC6A2A]"
              size="lg"
              type="submit"
            >
              <span>Upload</span>
            </Button>
          </DialogFooter>

      </form>
    </Dialog>
  )
}

export default ModalUploadReports
