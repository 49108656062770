import React, { useEffect } from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const FillTableQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question = '',
    answer = [],
    point,
    number,
    post_text: postText = [],
    pre_text: preText = [],
    label = [],
    multi = -1,
  } = getBuilderDataById(idx, containerId)

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">
          {number} {multi > 0 ? '- ' + (number + multi) : ''}.
        </span>
        <div className="flex flex-col gap-1">
          <div
            dangerouslySetInnerHTML={{ __html: question }}
            className="mb-2"
          />
          <table className="fill-table">
            {answer.length != 0 ? (
              answer.map((val, idx) => (
                <tr key={idx}>
                  <td>{label == null ? 'No Label' : label[idx]}</td>
                  <td className="flex">
                    <span className="py-2">{preText ? preText[idx] : ''}</span>
                    <span className="ms-1 py-2">{number + idx}. </span>
                    <div className="flex flex-row gap-1 items-center mx-2">
                      {typeof val === 'string' ? (
                        <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">
                          {val}
                        </span>
                      ) : (
                        val.map((v, i) => (
                          <>
                            <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">
                              {v}
                            </span>
                            <span>
                              {i !== val.length - 1 ? (
                                <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">
                                  /
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </>
                        ))
                      )}
                    </div>{' '}
                    <span className="py-2">{preText ? postText[idx] : ''}</span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No answer
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default FillTableQuestionPreview
