import { Url } from '../../../global'
import AudioPlayer from '../../AudioPlayer'

const AudioQuestionContainer = ({ number, audio_name }) => {
  return (
    <div className="mb-12">
      <div className="flex flex-col my-4 space-y-4">
        <span className="mr-3">Listen for the next question</span>
        <div className="w-full">
          <AudioPlayer audioUrl={`${Url}/media/${audio_name}`} />
        </div>
      </div>
    </div>
  )
}

export default AudioQuestionContainer
