import CardList from "../CardList";
import Listening from "../../assets/Listening.svg";
import Reading from "../../assets/Reading.svg";
import Writing from "../../assets/Writing.svg";
import Speaking from "../../assets/Speaking.svg";

const ListAssignment = ({ selected }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5">
      {List.filter((data) => {
        if (selected != "all") {
          // console.log('test',selected)
          return data.subject == selected;
        } else {
          return data;
        }
      }).map((item, idx) => (
        <CardList item={item} key={idx} />
      ))}
    </div>
  );
};

export default ListAssignment;

const List = [
  {
    title: "Listening IELTS Test",
    subject: "pre-test",
    category: "listening",
    image: Listening,
  },
  {
    title: "Reading IELTS Test",
    subject: "pre-test",
    category: "reading",
    image: Reading,
  },
  {
    title: "Writing IELTS Test",
    subject: "pre-test",
    category: "writing",
    image: Writing,
  },
  {
    title: "Speaking IELTS Test",
    subject: "pre-test",
    category: "speaking",
    image: Speaking,
  },
  {
    title: "Listening IELTS Test",
    subject: "post-test",
    category: "listening",
    image: Listening,
  },
  {
    title: "Reading IELTS Test",
    subject: "post-test",
    category: "reading",
    image: Reading,
  },
  {
    title: "Writing IELTS Test",
    subject: "post-test",
    category: "writing",
    image: Writing,
  },
  {
    title: "Speaking IELTS Test",
    subject: "post-test",
    category: "speaking",
    image: Speaking,
  },
];
