const Dropdown = ({ options, top, right, mt, align }) => {
  return (
    <>
      <div
        id="dropdownDivider"
        className={`absolute right-[${right ? right : "0px"}] top-[${
          top ? top : "0px"
        }] w-auto z-10 mt-[${mt}]  overflow-hidden bg-white divide-y divide-grey-300 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
      >
        {options.map((option, idx) => (
          <div key={idx}>
            <div
              className={`block py-4 text-sm px-5 text-${align}  text-gray-700  hover:grey-50 dark:hover:bg-grey-50 dark:text-gray-200 dark:hover:text-white hover:cursor-pointer`}
            >
              {option}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Dropdown;
