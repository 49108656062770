import { Typography } from '@material-tailwind/react'
import MultipleChoiceQuestion from './Questions/MultipleChoiceQuestion'
import EquationEditorQuestion from './Questions/EquationEditorQuestion'
import classNames from 'classnames'
import DOMPurify from 'dompurify'

const SatExam = ({ question, onChangeUserAnswer, currentNumber, activeStatus }) => {
  const handleAnswerChange = (answer) => {
    if (activeStatus === 1) {
      onChangeUserAnswer(question.id, answer)
    }
  }

  return (
    <>
      {question.description && (
        <>
          <div className="flex-1 p-8 max-h-[calc(100vh-200px)] overflow-auto">
            <Typography
              variant="paragraph"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.description) }}
            />
          </div>
          <div className="border-r" />
        </>
      )}

      <div
        className={classNames('flex-1 max-w-[50%] p-8 max-h-[calc(100vh-200px)] overflow-auto', {
          'mx-auto': !question.description,
        })}
      >
        {question.type === 3 && (
          <EquationEditorQuestion
            question={question.question}
            onChange={handleAnswerChange}
            userAnswer={question.userAnswer}
            currentNumber={currentNumber}
          />
        )}
        {question.type === 1 && (
          <MultipleChoiceQuestion
            question={question.question}
            options={question.options}
            onChange={handleAnswerChange}
            userAnswer={question.userAnswer}
            currentNumber={currentNumber}
          />
        )}
      </div>
    </>
  )
}

export default SatExam
