import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { Select, Option } from '@material-tailwind/react'

const SelectSubject = ({ onChange, value }) => {
  const [listSubject, setListSubject] = useState([])

  useEffect(() => {
    fetchSubject()
  }, [])

  const fetchSubject = async () => {
    let subject = []

    await axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results

        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.value = val.name
          val.name = capitalizedWords
          subject.push(val)
        })
        setListSubject(result)
      })
      .catch((err) => {
        alert('Gagal get list subject')
        console.error(err.message)
      })
  }

  return listSubject.length > 0 ? (
    <Select
      size="lg"
      label="Select Subject"
      name="subject"
      onChange={onChange}
      value={value}
    >
      {listSubject?.map((data, idx) => (
        <Option
          key={idx}
          value={data.value}
          className="flex items-center gap-2"
        >
          {data.name}
        </Option>
      ))}
    </Select>
  ) : (
    <div />
  )
}

export default SelectSubject
