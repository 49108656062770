import React, { Fragment, useEffect, useRef, useState } from 'react'
import Notify from '../../../components/Notify'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import Calendar from 'react-calendar'
import { format } from 'date-fns'
import 'react-calendar/dist/Calendar.css'
import { useDispatch } from 'react-redux'
import { setStatusClass } from '../../../slice/status'
import axios from '../../../utils/axios'
import { toast } from 'react-toastify'

const EditClass = () => {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [startSchedule, setStartSchedule] = useState(
    format(new Date(location.state.dataclass.start_date), 'dd MMMM yyyy')
  )
  const [endSchedule, setEndSchedule] = useState(
    format(new Date(location.state.dataclass.end_date), 'dd MMMM yyyy')
  )
  const [showStartSchedule, setShowStartSchedule] = useState('hidden')
  const [showEndSchedule, setShowEndSchedule] = useState('hidden')
  const form = useRef(null)
  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()
    setModal(true)
  }

  const handleModal = () => {
    setModal((prevState) => !prevState)
  }

  const save = () => {
    const { name, start_date, end_date } = form.current.elements

    axios
      .post('/classes', {
        name: name.value,
        start_date: start_date.value,
        end_date: end_date.value,
      })
      .then((res) => {
        // TODO
        toast.success('Berhasil Simpan')
        setModal(false)
        dispatch(setStatusClass(false))
        // reload after navigate
        navigate('/data')
        navigate(0)
      })
      .catch((err) => {
        toast.error('Error: ' + err.message)
        setModal(false)
      })
  }

  const onChangeStartSchedule = (value) => {
    setStartSchedule(format(value, 'dd MMMM yyyy'))
    setShowStartSchedule('hidden')
  }

  const onChangeEndSchedule = (value) => {
    setEndSchedule(format(value, 'dd MMMM yyyy'))
    setShowEndSchedule('hidden')
  }

  useEffect(() => {
    console.log('data:', location.state.dataclass)
  }, [])

  return (
    <Fragment>
      <form ref={form} onSubmit={onSubmit}>
        <Notify
          handleModal={handleModal}
          isOpen={modal}
          save={save}
          title={`submit`}
        />
        <div className="grid grid-cols-12 m-10">
          <div className="ml-10">
            <ArrowLeftIcon
              className="h-12 w-16 bg-[#F8F8F8] p-3 rounded-r-full rounded-l-full cursor-pointer hover:bg-[#D0D5DD]"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="mt-3">
            <p>Edit Class</p>
          </div>
          <div className="col-start-4 col-span-7 px-10 mt-16">
            <p className="text-[#064C85] text-lg">Edit Class</p>
            <div className="mt-3">
              <p>Class Name</p>
              <input
                type="text"
                name="name"
                className="border py-2 mt-2 w-full"
                placeholder="Enter test name"
                defaultValue={location.state.dataclass.name}
                required
              />
            </div>
            <div className="mt-4">
              <p className="mb-1">Schedule</p>
              <div className="flex">
                <div>
                  <input
                    type="text"
                    name="start_date"
                    className="border py-2 mt-2 w-48 p-2"
                    placeholder="dd MM"
                    value={startSchedule}
                    required
                    onClick={() => setShowStartSchedule('block')}
                  />
                  <Calendar
                    onChange={onChangeStartSchedule}
                    value={startSchedule}
                    className={`absolute ${showStartSchedule}`}
                  />
                </div>
                <div className="ml-10">
                  <input
                    type="text"
                    name="end_date"
                    className="border py-2 mt-2 w-48 p-2"
                    placeholder="dd MM"
                    value={endSchedule}
                    required
                    onClick={() => setShowEndSchedule('block')}
                  />
                  <Calendar
                    onChange={onChangeEndSchedule}
                    value={endSchedule}
                    className={showEndSchedule}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-16">
              <button className="my-5 w-40 py-2 bg-[#064C85] text-white rounded-lg">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default EditClass
