import React, { useEffect, useState } from 'react'
import axios from '../../utils/axios'
import { toast } from 'react-toastify'
import { motion } from 'framer-motion'

const SatReportLayout = ({ children }) => {
  const [subject, setSubject] = useState('')
  const [listSubject, setListSubject] = useState([])

  const handleChangeSubject = (event) => {
    setSubject(event.target.value)
  }

  useEffect(() => {
    const fetchSubject = async () => {
      try {
        const response = await axios.get('/questions/subject', {
          withCredentials: true,
        })
        const subjects = response.data.results.map((val) => {
          const capitalizedWords = val.name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

          return {
            ...val,
            value: val.name,
            name: capitalizedWords,
          }
        })

        setListSubject(subjects)
      } catch (err) {
        toast.error(err.message)
      }
    }

    fetchSubject()
  }, [])

  const childWithProps = React.cloneElement(children, { subject })

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ type: 'spring', delay: 0.5 }}
    >
      <div>
        <div className="w-full flex flex-col mt-2 lg:mt-10">
          <div className="w-full text-nero-700">
            <label className="text-3xl text-[#18181B] font-bold">
              Scholastic Assessment Test Result
            </label>
            <div className="mt-10">
              <label className="text-medium font-bold text-[#18181B]">
                Your Score
              </label>
              <br />
              <label className="text-xs">
                Which test result do you want to see?
              </label>
            </div>
            <div className="mt-5">
              <select
                className="px-4 py-2 bg-gray-100 mx-2 rounded-lg w-72"
                value={subject}
                onChange={handleChangeSubject}
              >
                <option value="">Choose</option>
                {listSubject &&
                  listSubject.map((val) => (
                    <option key={val.value} value={val.value}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>
            {subject && childWithProps}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default SatReportLayout
