import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../../../components/Sidebar'
import IcAproval from '../../../assets/approval.svg'
import IcCertificate from '../../../assets/certificate.svg'
import IcAward from '../../../assets/award.svg'
import IcBadge from '../../../assets/badge.svg'
import IcEmail from '../../../assets/email.svg'
import IcPhone from '../../../assets/phone.svg'
import IcProofeading from '../../../assets/proofreading.svg'
import IcListening from '../../../assets/Listening2.svg'
import IcReading from '../../../assets/Reading2.svg'
import IcWriting from '../../../assets/Writing2.svg'
import IcSpeaking from '../../../assets/Speaking2.svg'
import IcSat from '../../../assets/Sat2.svg'
import IcArrow from '../../../assets/Arrow - Right.svg'
import IcDownload from '../../../assets/download.svg'
import IcC from '../../../assets/IcC.svg'
import axios from '../../../utils/axios'
import { toast } from 'react-toastify'
import moment from 'moment'
import CardComment from '../../../components/Result/Writing/CardComment'
import TableScore from '../../../components/Result/Writing/TableScore'
import MenuTable from '../../../components/Result/Writing/MenuTable'
import IcSpeechBubble from '../../../assets/speech-bubble 1.svg'
import Loading from '../../../components/Loading'
import Drawer from '../../Todo/Drawer'
import UploadReports from '../../../components/Reports/ModalUploadReports'

const DashboardReport = () => {
  const account = useSelector((s) => s.account)
  const [isLoggedIn, setIsLogedIn] = useState('')
  const [picture, setPicture] = useState(null)
  const [user, setUser] = useState(null)
  const [active, setActive] = useState('')
  const [tableScore, setTableScore] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUserAnswer, setSelectedUserAnswer] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isUploadReportsOpen, setUploadReportsOpen] = useState(false)
  const [datareports, setDataReports] = useState('')

  const location = useLocation()

  const navigate = useNavigate()
  useEffect(() => {
    onLoad()
    getUser()
  }, [])

  const onLoad = () => {
    setIsLoading(true)
    setIsLogedIn(localStorage.getItem('loggedIn'))
    setIsLoading(false)
  }

  const getUser = async () => {
    setIsLoading(true)
    try {
      await axios.get(`/users/${location.state.studentId}`).then((e) => {
        const result = e.data.result
        setUser(result)
        setIsLoading(false)
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const openDetail = (item) => {
    // console.log('item:', item)
    setSelectedUserAnswer({
      createdAt: item.created_at,
      studentName: item.studentName,
      testName: item.test,
      category: 'writing',
      totalScore: item.score,
      writingAnswer: item.writingAnswer,
      userId: item.userId,
    })
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => setIsDrawerOpen(false)

  const fetchAnswerByCategory = async (category) => {
    setIsLoading(true)
    try {
      await axios.get(`/answers/${category}`).then((res) => {
        const result = res.data.results

        let arrListAnswer = []
        result.map((data) => {
          data.answer_details.map((data2) => {
            if (data2.writing_answer && data2.user.id == user.id) {
              arrListAnswer.push({
                category: data.category,
                test: data.subject,
                score: data2.writing_answer.grand_score,
                studentName: data2.user.name,
                writingAnswer: data2.writing_answer,
                userId: data2.user.id,
                created_at: data2.created_at,
              })
            }
          })

          setTableScore(arrListAnswer)
        })
        // const dataresult = res.data.results.find((data) => {
        //   const filteruser = data.answer_details.find((item) => {
        //     return item.userId == user.id
        //   })
        //   console.log('uuser1:', filteruser.userId)
        //   console.log('uuser2:', user.id)

        //   return filteruser.userId == user.id
        // })

        setIsLoading(false)
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleMenuTable = async (title, category) => {
    setActive(title)
    fetchAnswerByCategory(category)
  }

  const menuItems = [
    {
      title: 'Speaking Test',
      category: 'speaking',
      icon: IcSpeaking,
    },
    {
      title: 'Writing Test',
      category: 'writing',
      icon: IcWriting,
    },
  ]

  const handleUploadReports = () => {
    setUploadReportsOpen(true)
    fetchUploadReports()
  }

  const handleCloseUploadReports = () => {
    setUploadReportsOpen(false)
  }

  const fetchUploadReports = async () => {
    try {
      await axios.get(`/report/${user.id}`).then((res) => {
        const result = res.data.results
        if (result != '') {
          let lastElement = result[result.length - 1]
          setDataReports(lastElement.pdf_file)
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (isLoggedIn == 'true') {
    return (
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', delay: 0.5 }}
            >
              <div className="flex flex-row">
                <div className="w-1/4 flex">
                  <Sidebar name={account.name} />
                </div>
                <div className="w-3/4 flex justify-center mt-2 lg:mt-10">
                  <div className="w-2/5 flex flex-col">
                    <p className="text-[#064C85] text-xl">Reports</p>
                    <p className="text-[#292929] text-xs mt-3">
                      Lihat hasil progress siswamu!
                    </p>
                    <div className="bg-white border rounded-lg flex flex-col items-center shadow-md mt-3 p-8">
                      <div className="bg-white border rounded-lg flex items-center shadow-lg w-full">
                        <div className="flex flex-row p-5">
                          <img
                            src={
                              picture
                                ? picture
                                : 'https://via.placeholder.com/150.png?text=profile'
                            }
                            className="w-16 h-16 rounded-full shadow-md"
                          />
                          <div className="flex flex-col ml-5">
                            <p className="font-bold">{user.name}</p>
                            {user
                              ? user.classes.map((data) => (
                                  <p className="text-xs">{data.name}</p>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                      {/* <div className='flex justify-center mt-5 space-x-10 w-full'> */}
                      <div className="flex mt-5 space-x-10 w-full">
                        {/* <Link to={'/report/resulttest'} className='flex flex-col items-center hover:bg-[#F5FFF8] p-2'>
                        <img src={IcAproval} className='w-5 h-5'/>
                        <p className='text-xs font-bold'>Test Results</p>
                      </Link> */}
                        <button
                          className="flex flex-col items-center hover:bg-[#F5FFF8] p-2"
                          onClick={handleUploadReports}
                        >
                          <img src={IcCertificate} className="w-5 h-5" />
                          <p className="text-xs font-bold">Reports</p>
                        </button>
                        {/* <button className='flex flex-col items-center hover:bg-[#F5FFF8] p-2'>
                        <img src={IcAward} className='w-5 h-5'/>
                        <p className='text-xs font-bold'>Certificate</p>
                      </button> */}
                      </div>
                    </div>

                    {/* <div className='bg-white border rounded-lg flex flex-row mt-3 p-8'>
                    <div className='md:w-2/3 flex flex-col justify-center'>
                      <p className='text-sm font-bold'>Student band score</p>
                      <p className='text-xs text-[#667085] mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem</p>
                    </div>
                    <div className='md:w-1/3 flex flex-col items-center'>
                      <img src={IcBadge} className='h-16 w-20' />
                      <p className='text-xs font-bold'>Overall Band 6.5</p>
                    </div>
                  </div> */}

                    <div className="bg-white border rounded-lg flex flex-row mt-3 p-4">
                      <div className="w-full flex flex-col">
                        <div className="flex flex-row">
                          <div className="md:w-2/5 flex flex-row items-center">
                            <img src={IcPhone} className="w-10 h-10" />
                            <div className="flex flex-col ml-1">
                              <p className="text-xs text-[#808080]">
                                Contact number
                              </p>
                              <p className="text-xs font-bold mt-1">
                                {user ? user.phone_number : null}
                              </p>
                            </div>
                          </div>
                          <div className="md:w-3/5 flex flex-row items-center">
                            <img src={IcEmail} className="w-10 h-10" />
                            <div className="flex flex-col ml-1">
                              <p className="text-xs text-[#808080]">Email</p>
                              <p className="text-xs font-bold mt-1">
                                {user ? user.email : null}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row mt-5">
                          <div className="md:w-1/2 flex flex-col">
                            <p className="text-xs">Gender</p>
                            <p className="text-xs font-bold mt-1">
                              {user ? user.gender : null}
                            </p>
                          </div>
                          <div className="md:w-1/2 flex flex-col">
                            <p className="text-xs">Tanggal Bergabung</p>
                            <p className="text-xs font-bold mt-1">-</p>
                          </div>
                        </div>

                        <div className="flex flex-row mt-5">
                          <div className="md:w-1/2 flex flex-col">
                            <p className="text-xs">Class</p>
                            {user
                              ? user.classes.map((data) => (
                                  <p className="text-xs font-bold mt-1">
                                    {data.name}
                                  </p>
                                ))
                              : null}
                          </div>
                          <div className="md:w-1/2 flex flex-col">
                            <p className="text-xs">Status</p>
                            <p className="text-xs font-bold mt-1">Active</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-3/5 flex flex-col ml-2 pr-5 mt-[48px]">
                    <div className="bg-white border rounded-lg flex w-full flex-col p-5 mt-5 relative">
                      <p className="text-sm text-[#292929]">
                        Pre Test (Default)
                      </p>
                      <p className="text-xs mt-2 text-[#667085]">
                        Cek kemampuan awal mereka dan tingkatkan sekarang!
                      </p>
                      <hr className="mt-3" />
                      <div className="mt-5 flex flex-row">
                        <img src={IcListening} className="w-14 h-14" />
                        <div className="flex flex-col justify-center ml-3">
                          <p className="text-sm text-[#292929]">
                            Listening Test
                          </p>
                          <p className="text-[#808080] text-xs">
                            Lihat seberapa paham siswa dalam listening
                          </p>
                        </div>
                        <button
                          className="bg-[#98c2e4] rounded-full flex items-center justify-center h-10 w-10 absolute right-5"
                          onClick={() =>
                            navigate('/report/result', {
                              state: {
                                student: location.state.studentId,
                                subject: 'pre-test',
                                category: 'listening',
                              },
                            })
                          }
                        >
                          <img src={IcArrow} className="w-6 h-6" />
                        </button>
                      </div>
                      <div className="mt-5 flex flex-row">
                        <img src={IcReading} className="w-14 h-14" />
                        <div className="flex flex-col justify-center ml-3">
                          <p className="text-sm text-[#292929]">Reading Test</p>
                          <p className="text-[#808080] text-xs">
                            Lihat kemampuan siswa dalam reading
                          </p>
                        </div>
                        <button
                          className="bg-[#98c2e4] rounded-full flex items-center justify-center h-10 w-10 absolute right-5"
                          onClick={() =>
                            navigate('/report/result', {
                              state: {
                                student: location.state.studentId,
                                subject: 'pre-test',
                                category: 'reading',
                              },
                            })
                          }
                        >
                          <img src={IcArrow} className="w-6 h-6" />
                        </button>
                      </div>
                      <div className="mt-5 flex flex-row">
                        <img src={IcWriting} className="w-14 h-14" />
                        <div className="flex flex-col justify-center ml-3">
                          <p className="text-sm text-[#292929]">Writing Test</p>
                          <p className="text-[#808080] text-xs">
                            Cek kemampuan siswa dalam writing{' '}
                          </p>
                        </div>
                        <button
                          className="bg-[#98c2e4] rounded-full flex items-center justify-center h-10 w-10 absolute right-5"
                          onClick={() =>
                            navigate('/report/result', {
                              state: {
                                student: location.state.studentId,
                                subject: 'pre-test',
                                category: 'writing',
                              },
                            })
                          }
                        >
                          <img src={IcArrow} className="w-6 h-6" />
                        </button>
                      </div>
                      <div className="mt-5 flex flex-row">
                        <img src={IcSpeaking} className="w-14 h-14" />
                        <div className="flex flex-col justify-center ml-3">
                          <p className="text-sm text-[#292929]">
                            Speaking Test
                          </p>
                          <p className="text-[#808080] text-xs">
                            Sebelum practice, lihat kemampuan siswa dalam
                            speaking
                          </p>
                        </div>
                        <button
                          className="bg-[#98c2e4] rounded-full flex items-center justify-center h-10 w-10 absolute right-5"
                          onClick={() =>
                            navigate('/report/result', {
                              state: {
                                student: location.state.studentId,
                                subject: 'pre-test',
                                category: 'speaking',
                              },
                            })
                          }
                        >
                          <img src={IcArrow} className="w-6 h-6" />
                        </button>
                      </div>
                      <div className="mt-5 flex flex-row">
                        <img src={IcSat} className="w-14 h-14" />
                        <div className="flex flex-col justify-center ml-3">
                          <p className="text-sm text-[#292929]">
                            Scholastic Aptitude Test
                          </p>
                          <p className="text-[#808080] text-xs">
                            Sebelum practice, lihat kemampuan siswa dalam SAT
                          </p>
                        </div>
                        <button
                          className="bg-[#98c2e4] rounded-full flex items-center justify-center h-10 w-10 absolute right-5"
                          onClick={() =>
                            navigate('/report/result', {
                              state: {
                                student: location.state.studentId,
                                studentName: location.state.studentName,
                                subject: 'pre-test',
                                category: 'sat',
                              },
                            })
                          }
                        >
                          <img src={IcArrow} className="w-6 h-6" />
                        </button>
                      </div>
                    </div>

                    {/* <div className='bg-white border rounded-lg flex w-full flex-row p-5 mt-5 relative'>
                        <div className='flex flex-col ml-3'>
                          <p className='text-[#292929] text-sm'>Hasil Results yang lain</p>
                          <p className='text-xs mt-1 text-[#667085]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit </p>
                        </div>
                        <Link to={'/report/resulttest'} className='flex flex-col items-center hover:bg-[#F5FFF8] p-2'>
                          <button className='ml-5 bg-[#EC6A2A] p-2 px-10 rounded-lg text-white text-xs absolute right-5'>Button</button>
                        </Link>
                    </div> */}

                    <div className="bg-white border rounded-lg flex w-full flex-col p-5 mt-5 animate-content-slide-direction--left">
                      <p className="text-[#292929] text-sm">Penilaian tutor</p>
                      <p className="text-xs mt-1 text-[#667085]">
                        Hasil test yang langsung dinilai oleh mu
                      </p>
                      <div className="flex flex-row">
                        {menuItems.map((tab) => (
                          <MenuTable
                            icon={tab.icon}
                            onClick={() =>
                              handleMenuTable(tab.title, tab.category)
                            }
                            title={tab.title}
                            active={active}
                            description={
                              tab.category == 'speaking'
                                ? 'Cek progress speaking skills siswa'
                                : 'Cek progress writing skills siswa'
                            }
                          />
                        ))}
                      </div>

                      <div>
                        <p className="text-[#292929] text-sm mt-5">{active}</p>
                        <table class="table-auto w-full mt-7">
                          <thead>
                            <tr className="text-[#808080] text-left text-sm line">
                              <th className="p-4">Category</th>
                              <th>Test</th>
                              <th>Score</th>
                              <th>Status</th>
                              <th>Detail Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableScore
                              ? tableScore.map((item) => (
                                  <tr className="text-[#292929] text-sm line">
                                    <td className="p-4">{item.test}</td>
                                    <td>{item.category}</td>
                                    <td>{item.score ? item.score : '0'}</td>
                                    <td>
                                      <div className="flex flex-row items-center">
                                        <div
                                          className={`w-3 h-3 rounded-full ${
                                            item.score > 0
                                              ? 'bg-[#1DB954]'
                                              : 'bg-[#FF9800]'
                                          } mr-2`}
                                        ></div>
                                        {item.score > 0
                                          ? 'Completed'
                                          : 'Pending'}
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="ml-5 bg-[#EC6A2A] p-2 px-10 rounded-lg text-white text-xs"
                                        onClick={() => openDetail(item)}
                                      >
                                        <div className="flex flex-row">
                                          <img src={IcSpeechBubble} />
                                          Detail
                                        </div>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {/* <TableScore onClick={openDetail} data={tableScore} title={active}/> */}
                    </div>
                  </div>
                </div>
              </div>
              <UploadReports
                isOpen={isUploadReportsOpen}
                onClose={handleCloseUploadReports}
                userId={user.id}
                datareports={datareports}
              />
            </motion.div>
            <Drawer
              isOpen={isDrawerOpen}
              data={selectedUserAnswer}
              onClose={handleDrawerClose}
            />
          </>
        )}
      </div>
    )
  } else {
    navigate('/')
  }
}

export default DashboardReport
