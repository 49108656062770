import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import loginImg from '../assets/img-login.svg';
import logo from '../assets/logo-white.svg';
import { motion } from 'framer-motion';

export default function Layout(props) {
  return (
    <motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
        <div className='hidden sm:block'>
          <div className='bg-login-blue w-7/12 h-full object-cover'>
            <img className='pt-10' src={logo} alt=''/>
            <p className='pl-11 text-3xl text-white font-Montserrat break-words'>Prepare with Kobi, achieve your best IELTS score and the Dream of Going Abroad</p>
            <img className='ml-32' src={loginImg} alt=''/>
          </div>
        </div>
        <div className='flex flex-col justify-center'>
          {props.children}
        </div>
      </div>
    </motion.div>
  )
}
