import { createSlice } from "@reduxjs/toolkit";

export const questionDescSlice = createSlice({
  name: "questionDesc",
  initialState: {},
  reducers: {
    setQuestionDesc: (state, action) => {
      state = Object.keys(action.payload).length
        ? Object.assign(state, action.payload)
        : {};

      return state;
    },
  }
});

export const { setQuestionDesc } = questionDescSlice.actions;
export default questionDescSlice.reducer;
