import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { useState } from 'react'
import classNames from 'classnames'
import SatCheckQuestion from './SatCheckQuestion'
import { useSelector } from 'react-redux'

const SatFooter = ({
  title,
  totalQuestion,
  currentNumber,
  onNext,
  textNext,
  onPrevious,
  textPrevious,
  onGoToNumber,
  questions,
  onFinish,
  hideModal,
}) => {
  const [openPopover, setOpenPopover] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const account = useSelector((s) => s.account)

  const handleOpenModal = () => setOpenModal(!openModal)

  const handleNext = () => {
    if (currentNumber < totalQuestion || hideModal) {
      onNext()
    } else {
      handleOpenModal()
    }
  }

  const handleFinish = () => {
    onFinish()
    handleOpenModal()
  }

  return (
    <div className="flex items-center justify-between w-full bg-white py-6 px-8 border-t  max-h-[100px]">
      <div className="flex-1">
        <Typography variant="paragraph" className="capitalize">
          {account.name}
        </Typography>
      </div>
      {totalQuestion && (
        <div className="flex flex-col items-center">
          <Popover
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0, y: 25 },
            }}
            open={openPopover}
            handler={setOpenPopover}
          >
            <PopoverHandler>
              <Button size="sm" className="flex space-x-2">
                <span>
                  Question {currentNumber} of {totalQuestion}
                </span>
                <ChevronUpIcon
                  className={classNames('w-4 h-4 transition', {
                    'rotate-180': openPopover,
                  })}
                />
              </Button>
            </PopoverHandler>
            <PopoverContent>
              <div className=" w-[500px]">
                <SatCheckQuestion
                  title={title}
                  totalQuestion={totalQuestion}
                  questions={questions}
                  onGoToNumber={onGoToNumber}
                  setOpenModal={setOpenModal}
                  setOpenPopover={setOpenPopover}
                  currentNumber={currentNumber}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      )}

      <div className="flex flex-1 justify-end space-x-2">
        {onPrevious && textPrevious && (
          <Button
            size="sm"
            color="blue"
            className="flex space-x-2"
            onClick={onPrevious}
            disabled={currentNumber === 1}
          >
            <ChevronLeftIcon className="w-4 h-4" />
            <span>{textPrevious}</span>
          </Button>
        )}
        {onNext && textNext && (
          <Button
            size="sm"
            color="blue"
            className="flex space-x-2"
            onClick={handleNext}
          >
            <span>{textNext}</span>
            <ChevronRightIcon className="w-4 h-4" />
          </Button>
        )}
      </div>

      <Dialog open={hideModal ? false : openModal} handler={handleOpenModal}>
        <DialogHeader>
          <div className="flex flex-col justify-center w-full">
            <Typography variant="h3" className="text-center w-full">
              Check Your Work
            </Typography>
            <Typography variant="paragraph" className="text-center">
              You can resume this practice test as soon as you&apos;re ready to
              move on. On test day, you&apos;ll wait until the clock counts
              down.
            </Typography>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col space-y-4">
            <SatCheckQuestion
              title={title}
              totalQuestion={totalQuestion}
              questions={questions}
              onGoToNumber={onGoToNumber}
              setOpenModal={setOpenModal}
              setOpenPopover={setOpenPopover}
              currentNumber={currentNumber}
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="blue" onClick={handleFinish}>
            <span>Finish</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default SatFooter
