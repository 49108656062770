import React from 'react'
import CheckboxTypeQuestion from '../type/checkbox'

/*
    @@params{
        desc : string,
        data : CheckboxTypeQuestion
    }
*/

const CheckboxQuestionContainer = (data) => {
  let { question, choices, number, multi, userAnswer, id, answer } = data
  choices = JSON.parse(choices)
  if (userAnswer != null) {
    userAnswer = JSON.parse(userAnswer)
  }
  return (
    <div className="flex mb-12">
      <span className="mr-3" id={`q${number}`}>
        {number}
        {multi ? `-${number + multi}` : ''}.
      </span>
      <div>
        <div dangerouslySetInnerHTML={{ __html: question }} />
        <CheckboxTypeQuestion
          choices={choices}
          start={number}
          end={number + multi}
          userAnswer={userAnswer}
          id={id}
          multi={multi}
          answer={answer}
        />
      </div>
    </div>
  )
}

export default CheckboxQuestionContainer
