import React, { useEffect, useState } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import { FaPlus } from 'react-icons/fa'
import FormAddTopicArea from '../../../FormAddTopicArea'
import { Switch } from '@material-tailwind/react'
import { toast } from 'react-toastify'
import axios from '../../../../utils/axios'
import FormAddOverviewAnalysis from '../../../FormAddOverviewAnalysis'

const MultipleQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const [selectedOverviewAnalysis, setSelectedoverviewAnalysis] = useState('')
  const [topicArea, setTopicArea] = useState([])
  const [overviewAnalysis, setOverviewAnalysis] = useState([])
  const [modalFormTopicArea, setModalFormTopicArea] = useState(false)
  const [modalFormoverviewAnalysis, setModalFormoverviewAnalysis] =
    useState(false)
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    choices = [],
    answer = '',
    question,
    number,
    description,
    topicId = '',
    explanation,
  } = data
  const [selectedTopicArea, setSelectedTopicArea] = useState(topicId)

  const selectTopicArea = (event) => {
    setSelectedTopicArea(event.target.value)
    setBuilderDataById(idx, containerId, {
      ...data,
      topicId: event.target.value,
    })
  }

  const selectOverviewAnalysis = (event) => {
    setSelectedoverviewAnalysis(event.target.value)
    setBuilderDataById(idx, containerId, {
      ...data,
      analysisId: event.target.value,
    })
  }

  const cancelTopicAreaModal = () => {
    setModalFormTopicArea(false)
  }

  const cancelOverviewAnalysisModal = () => {
    setModalFormoverviewAnalysis(false)
  }
  // Handler
  const deleteHandler = () => {
    deleteBuilder(idx, containerId, classCategory)
  }
  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  // Choices
  const addChoices = () => {
    const newChoices = [...choices, 'New Option']
    setBuilderDataById(idx, containerId, { ...data, choices: newChoices })
  }
  const deleteChoices = (delIdx) => {
    const newChoices = choices.filter((_, idx) => idx !== delIdx)
    setBuilderDataById(idx, containerId, { ...data, choices: newChoices })
  }

  // Question
  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const setDescription = (val) => {
    setBuilderDataById(idx, containerId, { ...data, description: val })
  }

  const setExplanation = (val) => {
    setBuilderDataById(idx, containerId, { ...data, explanation: val })
  }

  // Answer
  const setAnswer = (answer) => {
    setBuilderDataById(idx, containerId, { ...data, answer })
  }

  // Option
  const setOption = (e, optIdx) => {
    let newAnswer = answer

    const newChoices = choices?.map((val, idx) => {
      if (idx === optIdx) {
        if (answer === val && newAnswer === answer) {
          newAnswer = e.target.value
        }

        return e.target.value
      } else return val
    })
    setBuilderDataById(idx, containerId, {
      ...data,
      choices: newChoices,
      answer: newAnswer,
    })
  }

  useEffect(() => {
    const fetchTopicArea = () => {
      let topic = []
      axios
        .get('/questions/sat/topic')
        .then((res) => {
          const result = res.data.result
          result.forEach((val) => {
            let temp = val.topic.split('-')
            const capitalizedWords = temp
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
            val.value = val.topic
            val.name = capitalizedWords
            topic.push(val)
          })
          setTopicArea(topic)
          const selected = topic.find((val) => val.id === data.topicId)?.id
          if (selected) {
            setSelectedTopicArea(selected)
            setBuilderDataById(idx, containerId, {
              ...data,
              topicId: selected,
            })
          }
        })
        .catch((err) => {
          toast.error('Gagal get list topic')
        })
    }

    const fetchOverviewAnalysis = () => {
      axios
        .get('/questions/sat/analysis')
        .then((res) => {
          const result = res.data.result
          setOverviewAnalysis(result)
          const selected = result.find((val) => val.id === data.analysisId)?.id
          if (selected) {
            setSelectedoverviewAnalysis(selected)
            setBuilderDataById(idx, containerId, {
              ...data,
              analysisId: selected,
            })
          }
        })
        .catch((err) => {
          toast.error('Gagal get overview analysis')
        })
    }

    fetchTopicArea()
    fetchOverviewAnalysis()
  }, [modalFormTopicArea, modalFormoverviewAnalysis])

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Multple Choices Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={deleteHandler}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">Number : {number}</section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      {classCategory === 'sat' && (
        <section>
          <div className="flex flex-col text-grey-400 mt-5 mb-5">
            <label>Topic Area</label>
            <div className="flex items-center mt-2">
              <select
                className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
                name="topic_area"
                required
                value={selectedTopicArea}
                onChange={selectTopicArea}
              >
                <option>Choose</option>
                {topicArea &&
                  topicArea.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
              </select>
              <div
                className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
                onClick={() => setModalFormTopicArea(true)}
              >
                <FaPlus size="1rem" />
              </div>
            </div>
          </div>
        </section>
      )}
      {classCategory === 'sat' && (
        <section>
          <div className="flex flex-col text-grey-400 mt-5 mb-5">
            <label>Overview Analysis</label>
            <div className="flex items-center mt-2">
              <select
                className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
                name="overview_analysis"
                required
                value={selectedOverviewAnalysis}
                onChange={selectOverviewAnalysis}
              >
                <option>Choose</option>
                {overviewAnalysis &&
                  overviewAnalysis.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.analysis_name}
                    </option>
                  ))}
              </select>
              <div
                className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
                onClick={() => setModalFormoverviewAnalysis(true)}
              >
                <FaPlus size="1rem" />
              </div>
            </div>
          </div>
        </section>
      )}
      {classCategory === 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Description</span>
          <TextEditor
            value={description}
            setValue={setDescription}
            idx={`${containerId}-${idx}-description`}
          />
        </section>
      )}
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={question}
          setValue={setQuestion}
          idx={`${containerId}-${idx}-question`}
        />
      </section>
      {classCategory === 'sat' && (
        <section className="mb-4">
          <span className="block mb-2">Explanation</span>
          <TextEditor
            value={explanation}
            setValue={setExplanation}
            idx={`${containerId}-${idx}-explanation`}
          />
        </section>
      )}
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        {choices?.map((val, idx) => (
          <div className="mb-1 flex justify-between" key={idx}>
            <section className="flex gap-1">
              <input
                type="radio"
                name="option"
                id="option"
                className="cursor-pointer"
                value={val}
                onChange={() => setAnswer(val)}
                checked={answer === val}
              ></input>
              <input
                type="text"
                value={val}
                onChange={(e) => setOption(e, idx)}
              ></input>
            </section>
            <button
              className="flex gap-1 items-center text-gray-400"
              onClick={() => deleteChoices(idx)}
            >
              <Icon icon="ion:trash-outline" />
              <span>Delete</span>
            </button>
          </div>
        ))}
        <button
          className="px-3 py-2 rounded-md border-[1px] border-blue-600 mt-4 hover:border-blue-300"
          onClick={addChoices}
        >
          + Add Option
        </button>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
      {modalFormTopicArea && <FormAddTopicArea cancel={cancelTopicAreaModal} />}
      {modalFormoverviewAnalysis && (
        <FormAddOverviewAnalysis cancel={cancelOverviewAnalysisModal} />
      )}
    </div>
  )
}

export default MultipleQuestionBuilder
