import React from 'react';
import imgOwl from '../assets/owl.svg';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const NotifyLogin = () => {
  const navigate = useNavigate();

  return(
    <div className="h-screen flex justify-center items-center bg-neutral-500">
        <motion.div initial={{ opacity: 1}} whileInView={{ opacity: 1 }} animate={{ y: 100 }} transition={{ type: "spring", stiffness: 100, duration: 2, x: { duration: 1 } }} className="w-11/12 md:w-6/12 flex flex-col justify-center items-center bg-white rounded-lg -mt-[200px]">
          <img className='pt-14' src={imgOwl} alt=''/>
          <p className='text-center text-2xl text-[#064C85] mt-5'>Password Update</p>
          <p className='text-center text-xs text-zinc-600 mt-3'>Your password has been updates</p>
          <button onClick={() => navigate("/")} className='w-6/12 my-5 mb-6 py-2 bg-[#064C85] text-white rounded-lg'>Login</button>
        </motion.div>
    </div>
    // <div className="h-screen flex justify-center items-center">
    //   <svg className="w-10 h-10 animate-spin fill-blue-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>
    // </div>
  )
}

export default NotifyLogin