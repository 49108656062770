import axios from '../utils/axios'

export const fetchSatQuestions = async ({ subject, moduleId }) => {
  const questionsResponse = await axios.get(
    `/questions/sat/${subject}/${moduleId}`
  )
  const { data } = questionsResponse.data

  return { data }
}

export const postSatAnswer = async ({ subject, moduleId }) => {
  const res = await axios.post(`/answers/sat/${subject}/${moduleId}`)
  const { data } = res.data

  return {
    data,
  }
}

export const fetchSatAnswers = async ({ subject, moduleId }) => {
  const answersResponse = await axios.get(
    `/answers/sat/${subject}/${moduleId}/answers`
  )

  const dataAnswer = answersResponse.data.data

  return { data: dataAnswer }
}

export const fetchSatAnswer = async ({ subject, moduleId }) => {
  const res = await axios.get(`/answers/sat/${subject}/${moduleId}`)
  const { data } = res.data

  return {
    data,
  }
}

export const fetchSatDescription = async ({ subject, moduleId }) => {
  const res = await axios.get(
    `/questions/sat/${subject}/${moduleId}/description`
  )
  const { data } = res.data

  return {
    data,
  }
}

export const fetchSatReport = async ({ subject, userId }) => {
  const res = await axios.get(`/report/sat/${subject}/${userId}`)
  const { data } = res.data

  return {
    data,
  }
}

export const fetchSatTopic = async () => {
  const res = await axios.get(`/questions/sat/topic`)
  const { result } = res.data

  return {
    data: result,
  }
}

export const updateSatAnswers = async ({
  updatedQuestions = [],
  subject,
  moduleId,
  activeStatus,
  correct,
  incorrect,
  blank,
  ...props
}) => {
  const modifyQuestions = updatedQuestions?.map((question) => {
    return {
      ...question,
      answer: question.userAnswer,
      id: question.userAnswerId,
      questionId: question.id,
    }
  })

  const answersResponse = await axios.put(
    `/answers/sat/${subject}/${moduleId}/answers`,
    {
      items: modifyQuestions,
      active: activeStatus,
      correct,
      incorrect,
      blank,
      ...props,
    }
  )
  const dataAnswer = answersResponse.data.results

  return { data: dataAnswer, activeStatus }
}

export const updateSatScore = async ({ items, subject }) => {
  const res = await axios.put(`/answers/sat/${subject}/answers/score`, {
    items,
  })
  const { data } = res.data

  return { data }
}

export const fetchSatScore = async ({ subject, userId }) => {
  const res = await axios.get(`/answers/sat/${subject}/${userId}/answers/score`)
  const { data } = res.data

  return {
    data,
  }
}
