import React from "react";
import AnswerTypeQuestion from "../type/answer";

/*
    @@params{
        fields : array of AnswerTypeQuestion
    }
*/

const AnswerQuestionContainer = (data) => {
  return (
    <div className="mb-12">
      <AnswerTypeQuestion {...data} />
    </div>
  );
};

export default AnswerQuestionContainer;
