import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import Layout from '../layout/Layout';
import Loading from './Loading';
import { Url } from '../global';

const CodeEmail = () => {
  const [verify, setVerify] = useState('');
  const [msg, setMsg] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validCode = "w-full bg-[#FAFAFA] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA]";
  const invalidCode = "w-full mt-2 p-2 rounded-lg placeholder-red-700 border border-red-500 outline-none focus:ring-red-500 focus:border-red-500 focus:ring-1";

  useEffect(() => {
    setEmail(location.state.email);
  }, []);

  const Register = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const verifycode = await axios.post(`${Url}/verifycode`,{
        verifycode: verify
      });
      if(verifycode.status == 200) {
        navigate("/register", {
          state: {
            email: email,
            verify: verify
          }
        });
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    } catch (error) {
      if(error.response){
        setMsg(error.response.data.msg);
        setStatus(error.response.status);
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    }
  }

  return (
    <div>
    {loading ? (
      <Loading/>
    ) : (
      <Layout>
        <form onSubmit={Register} className='max-w-[400px] w-full mx-auto px-8'>
          <div className='flex flex-col text-grey-400 py-2'>
            <p className='text-center text-2xl font-Montserrat'>Code Verification</p>
            <h1 className='text-xs text-[#52525B] mt-3'>Enter OTP sent to {email}</h1>
            <input className={status == 404 ? invalidCode : validCode}  type="text" placeholder="Verification Code" value={verify} onChange={(e) => setVerify(e.target.value)} />
            <span className="font-Montserrat text-xs text-red-600">{msg}</span>
            <button className='w-full my-5 py-2 bg-[#064C85] text-white rounded-lg'>Verify Code</button>
          </div>
        </form>
      </Layout>
    )
    }
    </div>
  )
}

export default CodeEmail