import {
  CheckIcon,
  StopCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Typography } from '@material-tailwind/react'
import moment from 'moment'

const SatReportModuleHeader = ({
  moduleId,
  correctAnswers,
  incorrectAnswers,
  blankAnswers,
  moduleNumber,
  createdAt,
  updatedAt,
}) => {
  const createdAtDate = moment(createdAt)
  const updatedAtDate = moment(updatedAt)
  const diffInSeconds = updatedAtDate.diff(createdAtDate, 'seconds')

  const duration = moment.duration(diffInSeconds, 'seconds')
  const formattedDiff = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')

  return (
    <div className="flex flex-col space-y-2 text-left">
      <Typography variant="paragraph" className="text-xl">
        {moduleId <= 2 ? 'Reading and Writing' : 'Math'} {moduleNumber}
      </Typography>
      <div className="flex space-x-2">
        <Typography className="flex space-x-1 text-xl items-center  text-[#a3da09]">
          <CheckIcon className="w-5 h-5" />{' '}
          <span>{correctAnswers} Correct</span>
        </Typography>
        <Typography className="flex space-x-1 text-xl items-center text-[#ff3e2a]">
          <XMarkIcon className="w-5 h-5" />{' '}
          <span>{incorrectAnswers} Incorrect</span>
        </Typography>
        <Typography className="flex space-x-1 text-xl items-center text-[#c5c5c5]">
          <StopCircleIcon className="w-5 h-5" />{' '}
          <span>{blankAnswers} Blank</span>
        </Typography>
      </div>
      <div>
        <Typography variant="paragraph">
          <span className="font-bold">Time on Section:</span> {formattedDiff}
        </Typography>
      </div>
    </div>
  )
}

export default SatReportModuleHeader
