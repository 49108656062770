import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'

const toastRoot = document.getElementById('toast-root')

export class ToastPortal extends React.PureComponent {
  render() {
    return ReactDOM.createPortal(<ToastContainer />, toastRoot)
  }
}
