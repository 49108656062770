import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from '@material-tailwind/react'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import classNames from 'classnames'

const Timer = ({ initialTime, onComplete }) => {
  const [time, setTime] = useState(moment.duration(initialTime))
  const [isLessThanFiveMinutes, setLessThanFiveMinutes] = useState(false)

  useEffect(() => {
    setTime(moment.duration(initialTime))
  }, [initialTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.asSeconds() === 300) {
          toast.info(
            <div>
              <Typography variant="h6">
                5 Minutes left in this modules
              </Typography>
              <Typography variant="paragraph">
                If you&apos;re still working when time runs out, your work will
                be automatically saved.
              </Typography>
            </div>,
            {
              hideProgressBar: true,
              autoClose: false,
              theme: 'dark',
              toastId: 'reminder',
            }
          )
        }
        if (prevTime.asSeconds() <= 300 && !isLessThanFiveMinutes) {
          setLessThanFiveMinutes(true)
        }
        if (prevTime.asSeconds() <= 0) {
          clearInterval(interval)
          if (onComplete) {
            onComplete()
          }
          return moment.duration(0)
        }
        return moment.duration(prevTime.asSeconds() - 1, 'seconds')
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [isLessThanFiveMinutes, onComplete])

  const formatTime = (time) => {
    return moment.utc(time.asMilliseconds()).format('mm:ss')
  }

  return (
    <Typography
      variant="h3"
      className={classNames('size-4', {
        'text-red-500': isLessThanFiveMinutes,
      })}
    >
      {formatTime(time)}
    </Typography>
  )
}

const SatHeader = ({
  countdownElement,
  title,
  onTimerComplete,
  directionElement,
  initialTime,
}) => {
  const [openModal, setOpenModal] = useState(false)

  const handleOpen = () => setOpenModal(!openModal)

  return (
    <>
      <div className="flex items-center justify-between w-full bg-white py-6 px-8 border-b max-h-[100px]">
        <div className="flex-1">
          <Typography variant="h4">{title}</Typography>
          {directionElement && (
            <div
              className="flex space-x-2 items-center cursor-pointer"
              onClick={handleOpen}
            >
              <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
              <Typography variant="paragraph">Directions</Typography>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          {countdownElement}
          <Timer initialTime={initialTime} onComplete={onTimerComplete} />
        </div>
        <div className="flex-1" />
      </div>
      <Dialog open={openModal} handler={handleOpen}>
        <DialogHeader>Directions</DialogHeader>
        <DialogBody className="pt-0">
          <Typography variant="paragraph" as="div">
            {directionElement}
          </Typography>
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="blue" onClick={handleOpen}>
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default SatHeader
