import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Drop from '../Question/widgets/drop'
import DescriptionListening from '../Question/widgets/description/listening'
import Mark from '../Question/widgets/mark'
import Preview from '../Question/widgets/preview'
import { usePopup } from '../Popup'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import ChevronUpDarkIcon from '../../assets/icons/chevron-up-dark.svg'
import AudioForm from '../Question/form/audio'
import DescriptionSat from '../Question/widgets/description/sat'
import { toast } from 'react-toastify'
import { setBuilderData } from '../../slice/question'
import PreviewSat from '../Question/widgets/previewSat'

const IconDark = ({ open }) => (
  <img
    src={ChevronUpDarkIcon}
    className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    alt="Chevron Dark Colored Icon"
  />
)

export const TabsSat = ({ setTabIndexModule, activeTabIndexModule }) => {
  const questionDesc = useSelector((s) => s.questionDesc)
  const [direction, setDirection] = useState(questionDesc.direction)
  const [reference, setReference] = useState(questionDesc.reference)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const dispatch = useDispatch()

  const description = useSelector((s) => s.questionDesc)

  const setTabIndex = (idx) => {
    // TODO: ui
    if (!description.id && idx > 0)
      toast.warning('Pilih subject terlebih dahulu')
    else setActiveTabIndex(idx)
  }

  useEffect(() => {
    setActiveTabIndex(0)
  }, [activeTabIndexModule])

  const tabsModule = [
    {
      label: 'Module 1',
      value: 1,
    },
    {
      label: 'Module 2',
      value: 2,
    },
    {
      label: 'Module 3',
      value: 3,
    },
    {
      label: 'Module 4',
      value: 4,
    },
  ]

  const tabsData = [
    {
      label: 'Description',
      content: (
        <DescriptionSat
          moduleId={tabsModule[activeTabIndexModule].value}
          direction={direction}
          setDirection={setDirection}
          questionDesc={questionDesc}
          reference={reference}
          setReference={setReference}
        />
      ),
    },
    {
      label: 'Add Items',
      content: (
        <Drop id={tabsModule[activeTabIndexModule].value} classCategory="sat" />
      ),
    },
    {
      label: 'Preview',
      content: (
        <PreviewSat
          moduleId={tabsModule[activeTabIndexModule].value}
          direction={direction}
        />
      ),
    },
    {
      label: 'Explanations',
      content: <Mark />,
    },
  ]

  return (
    <div>
      <div className="flex space-x-1">
        {tabsModule.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                idx === activeTabIndexModule
                  ? 'bg-[#FFFFFF]'
                  : 'border-transparent'
              }`}
              onClick={() => setTabIndexModule(idx)}
            >
              {tab.label}
            </button>
          )
        })}
      </div>
      <div className="flex flex-col space-y-4 p-4 border-2 border-solid">
        <div className="flex space-x-1">
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                  idx === activeTabIndex ? 'bg-[#FFFFFF]' : 'border-transparent'
                }`}
                onClick={() => {
                  if (idx === 1) {
                    dispatch(
                      setBuilderData({ data: [], id: activeTabIndexModule })
                    )
                  }
                  setTabIndex(idx)
                }}
              >
                {tab.label}
              </button>
            )
          })}
        </div>
        <div>
          <section>{tabsData[activeTabIndex].content}</section>
        </div>
      </div>
    </div>
  )
}
