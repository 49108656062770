import React from "react";
import icVerify from "../assets/IcVerify.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { HiMail } from "react-icons/hi";

const ModalDetailStudent = ({ form, close, data }) => {
  switch(form) {
    case "class":
    return (
      <div className="h-screen flex justify-center items-center bg-neutral-500 fixed inset-0 z-50 overflow-auto bg-opacity-80">
        <motion.div
          initial={{ opacity: 1 }}
          whileInView={{ opacity: 1 }}
          animate={{ y: 100 }}
          transition={{
            type: "spring",
            stiffness: 100,
            duration: 2,
            x: { duration: 1 },
          }}
          className="w-11/12 md:w-5/12 flex flex-col justify-center items-center bg-white rounded-lg -mt-[200px] py-10"
        >
          <div>
            Class
            <button onClick={close} className="absolute right-5 top-5">
              <IoCloseOutline className="text-3xl text-[#064C85]" />
            </button>
          </div>
        </motion.div>
      </div>
    );
    
    default:
    return (
      <div className="h-screen flex justify-center items-center bg-neutral-500 fixed inset-0 z-50 overflow-auto bg-opacity-80">
        <motion.div
          initial={{ opacity: 1 }}
          whileInView={{ opacity: 1 }}
          animate={{ y: 100 }}
          transition={{
            type: "spring",
            stiffness: 100,
            duration: 2,
            x: { duration: 1 },
          }}
          className="w-11/12 md:w-5/12 flex flex-col justify-center items-center bg-white rounded-lg -mt-[200px] py-10"
        >
          <div>
            <button onClick={close} className="absolute right-5 top-5">
              <IoCloseOutline className="text-3xl text-[#064C85]" />
            </button>
          </div>
          <img
            className="w-20 rounded-full"
            src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg"
            alt=""
          />
          <p className="text-center text-xl text-[#064C85] mt-5">{data.name}</p>
          <div className="w-full flex-row px-20 py-2 flex justify-center">
            {data.class_name ? (
              data.class_name.map((item, idx) => {
                return (
                  <div key={idx} className="bg-[#EFF8FF] text-sm text-[#175CD3] p-1 rounded-lg mb-2">
                    {item}
                  </div>
                );
              })
            ) : (
              <div className="bg-[#EFF8FF] text-sm text-[#175CD3] p-1 rounded-lg mb-2">
                -
              </div>
            )}
          </div>
          <div className="w-[80%] flex-row px-20 py-5 flex justify-between">
            <div className="flex gap-2 items-center">
              <BsFillTelephoneFill size={14} />
              <p className="text-center text-sm text-grey-500">
                {data.phone_number ? data.phone_number : "+62"}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <HiMail size={20} />
              <p className="text-center text-sm text-grey-500">{data.email}</p>
            </div>
          </div>
        </motion.div>
      </div>
    );
  }
};

export default ModalDetailStudent;
