import { MdCloudUpload } from 'react-icons/md'
import TextEditor from '../widgets/textEditor'

const AudioForm = ({
  id,
  title,
  audioName,
  onChangeAudio,
  audioText,
  setAudioText,
  noAudioText,
}) => {
  return (
    <div className="mb-5">
      {!noAudioText && <p className="mb-2">{title}</p>}
      <label className="flex justify-center w-full h-36 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none relative">
        <span className="flex items-center gap-1">
          <MdCloudUpload className="text-gray-500" size="1.5rem" />
          <span className="font-medium text-gray-600">
            Browse or drop an audio file to attach
          </span>
        </span>
        <span className="absolute mt-24 text-blue-600">{audioName}</span>
        <input
          className="hidden"
          type="file"
          name="files"
          accept="audio/*"
          onChange={onChangeAudio}
        />
      </label>

      {!noAudioText && (
        <div className="flex flex-col text-grey-400 mt-5">
          <label className="mb-2">{title} Text</label>
          <TextEditor value={audioText} setValue={setAudioText} idx={id} />
        </div>
      )}
    </div>
  )
}

export default AudioForm
