import React, { useState, useEffect } from 'react'
import axios from '../../../utils/axios'
import { Option, Select } from '@material-tailwind/react'
import Loading from '../../../components/Loading'
import { useParams } from 'react-router-dom'

const SelectTest = () => {
  let { testName } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [listSubject, setListSubject] = useState([])
  const [selectValue, setSelectValue] = useState(testName)

  useEffect(() => {
    let subject = []
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.slug = val.name
          val.value = val.name
          val.title = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col space-y-4 max-w-[340px]">
      <div>
        <h6 className="text-nero-700 font-bold">Result Subject</h6>
        <p className="text-neromid text-sm">
          Lorem ipsum dolor sit amet, consectetur adipis.
        </p>
      </div>

      <Select
        label="Select Test"
        onChange={(val) => setSelectValue(val)}
        value={selectValue}
        disabled
      >
        {listSubject?.map((subject) => (
          <Option value={subject.value}>{subject.title}</Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectTest
