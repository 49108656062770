import React, { useEffect } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'

const SelectTruthQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    answer = [],
    question,
    number,
    choices = [],
    multi = -1,
  } = data

  let { post_text: postText = [] } = data

  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  const addPostText = () => {
    const newPostText = [...postText, 'New PostText']
    setBuilderDataById(idx, containerId, {
      ...data,
      post_text: newPostText,
      answer: [...answer, ' '],
      multi: multi + 1,
    })
  }

  const deletePostText = (delIdx) => {
    const newPostText = postText.filter((_, idx) => idx !== delIdx)
    const newAnswer = answer.filter((_, idx) => idx !== delIdx)

    setBuilderDataById(idx, containerId, {
      ...data,
      post_text: newPostText,
      answer: newAnswer,
      multi: multi - 1,
    })
  }

  const setPostText = (e, optIdx) => {
    const newPostText = postText.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })

    setBuilderDataById(idx, containerId, { ...data, post_text: newPostText })
  }

  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const setAnswer = (e, optIdx) => {
    const newAnswers = answer.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })
    setBuilderDataById(idx, containerId, { ...data, answer: newAnswers })
  }

  useEffect(() => {
    setBuilderDataById(idx, containerId, {
      ...data,
      choices: ['True', 'False', 'Not given'],
    })
  }, [])

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Select Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={() => deleteBuilder(idx, containerId, classCategory)}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">
        Number : {number} {multi > 0 ? '- ' + (number + multi) : ''}{' '}
      </section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={question}
          setValue={setQuestion}
          idx={`${containerId}-${idx}`}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Choices for Responses</span>
        <div className="my-4">
          {choices?.map((val, idx) => (
            <div className="flex items-center mb-2" key={idx}>
              <section className="flex gap-1 items-center w-full border-[1px] p-2 border-gray-300">
                <Icon icon="ci:hamburger-lg" />
                <span>{val}</span>
              </section>
            </div>
          ))}
        </div>
      </section>
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        <div className="my-4">
          {postText.map((val, idx) => (
            <div key={idx} className="flex items-center mb-2">
              <span> {number + idx}. </span>
              <select
                className="px-4 py-2 bg-gray-100 mx-2"
                defaultValue={answer[idx]}
                onChange={(e) => setAnswer(e, idx)}
              >
                <option value={answer[idx]} hidden>
                  {answer[idx]}
                </option>
                {choices?.map((val, choiceIdx) => (
                  <option value={val} key={choiceIdx}>
                    {val}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={val}
                onChange={(e) => setPostText(e, idx)}
                className="w-full"
              ></input>
              <button
                className="flex gap-1 items-center text-gray-400"
                onClick={() => deletePostText(idx)}
              >
                <Icon icon="ion:trash-outline" />
                <span>Delete</span>
              </button>
            </div>
          ))}
        </div>
        <button
          className="px-3 py-2 rounded-md border-[1px] border-blue-600 mt-4 hover:border-blue-300"
          onClick={addPostText}
        >
          + Add Question
        </button>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default SelectTruthQuestionBuilder
