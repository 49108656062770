import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { isEmpty } from 'lodash'
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from '@material-tailwind/react'

import StudentImage from '../../../../assets/avatar.jpg'
import DefaultStudentImage from '../../../../assets/default-student.png'
import DotsVerticalcon from '../../../../assets/icons/dots-vertical.svg'
import Comment from './Comment'

const Comments = ({
  highlights,
  onSubmitComment,
  onDeleteComment,
  onEditComment,
  writingType,
}) => {
  const account = useSelector((s) => s.account)
  const [commentValue, setCommentValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState(highlights[0].text)

  const handleEditComment = (event) => setEditedComment(event.target.value)
  const openEditComment = () => setIsEditing(true)
  const closeEditComment = () => {
    setEditedComment(highlights[0].text)
    setIsEditing(false)
  }

  const handleComment = (commentData) => {
    onEditComment(writingType, highlights[0].highlight, commentData)
    closeEditComment()
  }

  const handleChangeComment = (event) => setCommentValue(event.target.value)

  const handleSubmitComment = () => {
    onSubmitComment(1, highlights[0].highlight, commentValue)
    setCommentValue('')
  }

  const handleRemoveHighlightClick = () => {
    const articleElement = document.querySelector(
      `#article-writing-${writingType} p`
    )

    if (articleElement) {
      const highlightedText = articleElement.querySelector('.highlight-element')

      if (highlightedText) {
        const textContent = highlightedText.textContent
        highlightedText.outerHTML = textContent
      }
    }
  }

  const handleHighlightText = (targetText) => {
    handleRemoveHighlightClick()
    const articleElement = document.querySelector(
      `#article-writing-${writingType} p`
    )

    const articleContainerElement = document.querySelector(
      `#container-writing-${writingType}`
    )

    if (articleElement) {
      const articleText = articleElement.innerHTML
      const startIndex = articleText.indexOf(targetText)

      if (startIndex !== -1) {
        const beforeText = articleText.substring(0, startIndex)
        const afterText = articleText.substring(startIndex + targetText.length)

        const highlightedText = `<span class="highlight-element">${targetText}</span>`

        articleElement.innerHTML = beforeText + highlightedText + afterText

        articleContainerElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  return (
    <div className="px-8 py-10 bg-white flex flex-col space-y-10">
      <div>
        <div className="flex space-x-4 mb-8">
          <img src={StudentImage} alt="Student" className="w-14 h-14" />
          <div className="grow">
            <p className="text-nero-700 text-lg font-semibold font-Montserrat">
              {highlights[0].name}
            </p>
            <p className="text-neromid text-sm">
              {moment(highlights[0].createdAt).format('MMM D, YYYY, h:mm A')}
            </p>
          </div>
          {highlights[0].userId === account.userId && (
            <Popover placement="bottom-end">
              <PopoverHandler>
                <img
                  src={DotsVerticalcon}
                  alt="Dots Icon"
                  className="cursor-pointer"
                />
              </PopoverHandler>
              <PopoverContent className="p-0">
                <div
                  className="text-sm text-nero-700 py-3 px-4 text-center hover:bg-[#F9FAFB] cursor-pointer"
                  onClick={openEditComment}
                >
                  Edit
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
        <div className="flex space-x-4 items-center">
          <p
            className="text-nero-700 text-base ml-[4.5rem] font-medium font-Montserrat cursor-pointer"
            onClick={() => handleHighlightText(highlights[0].highlight)}
          >
            {highlights[0].highlight}
          </p>
        </div>
        <hr className="my-4" />
        {!isEditing && (
          <p className="text-nero-700 text-sm leading-7 text-justify ml-[4.5rem]">
            {highlights[0].text}
          </p>
        )}
        {isEditing && (
          <>
            <textarea
              value={editedComment}
              onChange={handleEditComment}
              className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full min-h-[45px] focus:outline-none focus:ring-0"
              rows={4}
            />
            <div className="flex space-x-4">
              <button
                className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)] min-h-[45px]"
                onClick={() =>
                  handleComment({
                    id: highlights[0].id,
                    text: editedComment,
                  })
                }
              >
                Edit Comment
              </button>
              <button
                className="px-8 py-3 rounded-[4px] border border-[#CCC] text-xs text-nero-700 min-h-[45px]"
                onClick={closeEditComment}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
      {highlights.slice(1).map((highlight) => (
        <div key={highlight.id}>
          <Comment
            highlight={highlight}
            currentUser={account}
            onDeleteComment={onDeleteComment}
            onEditComment={onEditComment}
            writingType={writingType}
          />
        </div>
      ))}
      <div className="flex space-x-4 items-center">
        <img
          src={DefaultStudentImage}
          alt="Student"
          className="w-[54px] h-[54px] rounded-full"
        />
        <input
          className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full min-h-[45px] focus:outline-none focus:ring-0"
          type="text"
          placeholder="Reply"
          value={commentValue}
          onChange={handleChangeComment}
        />
        <button
          className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)] min-h-[45px]"
          onClick={handleSubmitComment}
        >
          Comment
        </button>
      </div>
    </div>
  )
}

export default Comments
