import React, { useCallback, useEffect, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import axios from '../../../../utils/axios'
import { setQuestionDesc } from '../../../../slice/questionDesc'
import TextEditor from '../textEditor'
import { setStatusQuestion } from '../../../../slice/status'
import { useNavigate } from 'react-router-dom'

const DescriptionReading = ({
  selectedClassCategories,
  onChangeClassCategories,
}) => {
  const dispatch = useDispatch()
  const questionDesc = useSelector((s) => s.questionDesc)
  const [description, setDescription] = useState(questionDesc.description)
  const [textIndex, setTextIndex] = useState(0)
  const [text1, setText1] = useState('<p><br></p>')
  const [text2, setText2] = useState('<p><br></p>')
  const [text3, setText3] = useState('<p><br></p>')
  const [selectedFile, setSelectedFile] = useState('')
  const navigate = useNavigate()
  const [listSubject, setListSubject] = useState([])
  const [selectedListSubject, setSelectedListSubject] = useState('')

  const selectSubject = (event) => {
    setSelectedListSubject(event.target.value)
  }

  const tabsData = useCallback(
    () => [
      {
        label: 'Text Reading 1',
        content: (
          <TextEditor
            value={text1}
            setValue={setText1}
            idx={`text-reading-1`}
          />
        ),
      },
      {
        label: 'Text Reading 2',
        content: (
          <TextEditor
            value={text2}
            setValue={setText2}
            idx={`text-reading-2`}
          />
        ),
      },
      {
        label: 'Text Reading 3',
        content: (
          <TextEditor
            value={text3}
            setValue={setText3}
            idx={`text-reading-3`}
          />
        ),
      },
    ],
    [text1, text2, text3]
  )

  useEffect(() => {
    dispatch(
      setQuestionDesc({
        audio_name: '',
        listening: '',
        description: '',
        duration: '',
        id: '',
        subject: '',
        test_name: '',
        audio_text: '',
        updatedAt: '',
      })
    )
    setDescription('')
    setListSubject([])
    fetchSubject()
  }, [])

  const submit = (e) => {
    e.preventDefault()

    const data = new FormData(e.target)

    data.append('description', description)
    data.append('category', 'reading')
    data.append('text_reading1', text1)
    data.append('text_reading2', text2)
    data.append('text_reading3', text3)

    // update jika sudah disubmit dan mengklik tombol lagi
    if (questionDesc.id) {
      // data.append("id", questionDesc.id);
      axios
        // .put("/questions/description", data)
        .put(
          `/questions/${questionDesc.subject}/reading/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses update')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal update')
          console.error(err.message)
        })
    } else {
      axios
        // .post("/questions/description", data)
        .post(
          `/questions/${questionDesc.subject}/reading/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses ditambahkan')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal ditambahkan')
        })
    }
  }

  useEffect(() => {
    if (selectedListSubject && selectedClassCategories) {
      axios
        .get(
          `/questions/${selectedListSubject}/reading/${selectedClassCategories}/description`
        )
        .then((res) => {
          // TODO: ui
          if (res.data.result) {
            dispatch(setQuestionDesc(res.data.result))
            setDescription(res.data.result.description)
            setText1(
              res.data.result.text_reading1
                ? res.data.result.text_reading1
                : '<p><br></p>'
            )
            setText2(
              res.data.result.text_reading2
                ? res.data.result.text_reading2
                : '<p><br></p>'
            )
            setText3(
              res.data.result.text_reading3
                ? res.data.result.text_reading3
                : '<p><br></p>'
            )
            dispatch(setStatusQuestion(false))
          } else {
            dispatch(
              setQuestionDesc({
                audio_name: '',
                listening: '',
                description: '',
                duration: '',
                id: '',
                subject: selectedListSubject,
                test_name: '',
                updatedAt: '',
              })
            )
            setDescription('')
            setText1('<p><br></p>')
            setText2('<p><br></p>')
            setText3('<p><br></p>')
          }
        })
        .catch((err) => {
          alert('Gagal get subject')
          dispatch(
            setQuestionDesc({
              audio_name: '',
              listening: '',
              description: '',
              duration: '',
              id: '',
              subject: selectedListSubject,
              test_name: '',
              updatedAt: '',
            })
          )
          setDescription('')
          setText1('<p><br></p>')
          setText2('<p><br></p>')
          setText3('<p><br></p>')
          console.error(err.message)
        })
    }
  }, [selectedListSubject, selectedClassCategories])

  const listClassCategories = [
    {
      id: 1,
      name: 'IELTS',
      value: 'ielts',
    },
    {
      id: 2,
      name: 'TOEFL IBT',
      value: 'tibt',
    },
    {
      id: 3,
      name: 'English for Business',
      value: 'efb',
    },
  ]

  const fetchSubject = () => {
    let subject = []
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.value = val.name
          val.name = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
      })
      .catch((err) => {
        alert('Gagal get list subject')
        console.error(err.message)
      })
  }

  return (
    <form className="max-w-full" onSubmit={submit}>
      {questionDesc.id && (
        <div className="flex flex-col text-grey-400 mb-5">
          <label>Question Description ID</label>
          <input
            type="text"
            className="w-full bg-[#eee] rounded-lg mt-2 p-2 border"
            value={questionDesc.id}
            disabled={true}
          />
        </div>
      )}

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Text Reading</label>
        <div className="flex">
          {tabsData()
            .slice(0, selectedClassCategories === 'tibt' ? 2 : 3)
            .map((tab, idx) => {
              return (
                <button
                  key={idx}
                  type="button"
                  className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                    idx === textIndex
                      ? 'bg-[#FFFFFF] border-b-0'
                      : 'border-transparent'
                  }`}
                  onClick={() => setTextIndex(idx)}
                >
                  {tab.label}
                </button>
              )
            })}
        </div>

        {tabsData().map(
          (tab, idx) =>
            textIndex === idx && (
              <div className="py-4">
                <section>{tab.content}</section>
              </div>
            )
        )}
      </div>

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Subject</label>
        <select
          className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="subject"
          required
          defaultValue={questionDesc?.subject}
          key={questionDesc?.subject}
          onChange={selectSubject}
        >
          <option>Choose</option>
          {listSubject &&
            listSubject.map((val, idx) => (
              <option key={idx} value={val.value}>
                {val.name}
              </option>
            ))}
        </select>
      </div>

      <div className="flex flex-col text-grey-400 mt-5 mb-5">
        <label>Class Category</label>
        <div className="flex items-center mt-2">
          <select
            className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="classCategory"
            required
            value={selectedClassCategories}
            onChange={onChangeClassCategories}
          >
            <option>Choose</option>
            {listClassCategories &&
              listClassCategories.map((val, idx) => (
                <option key={idx} value={val.value}>
                  {val.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Test Name</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="test_name"
          placeholder="enter test name"
          defaultValue={questionDesc?.test_name}
          key={questionDesc?.test_name}
        />
      </div>

      <div className="flex flex-col text-grey-400 mt-5">
        <label>Duration (hh:mm:ss)</label>
        <input
          type="text"
          className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
          name="duration"
          title="hh:mm:ss"
          pattern="\d\d:\d\d:\d\d"
          placeholder="duration in hh:mm:ss format"
          defaultValue={questionDesc?.duration}
          key={questionDesc?.duration}
        />
      </div>
      <div className="flex flex-col text-grey-400 mt-5">
        <label>Description/Instruction</label>
        <TextEditor
          value={description}
          setValue={setDescription}
          idx={`instruction-1`}
        />
      </div>
      <div className="flex justify-end">
        <button
          className={`bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600 mt-10`}
          type="submit"
        >
          <span className="px-5">{questionDesc.id ? 'Update' : 'Submit'}</span>
        </button>
      </div>
    </form>
  )
}

export default DescriptionReading
