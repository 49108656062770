import React from 'react'
import useQuestionBuilder from '../../../hooks/useQuestionBuilder'
import QuestionContainer from '../container'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import ChevronUpDarkIcon from '../../../assets/icons/chevron-up-dark.svg'

const IconDark = ({ open }) => (
  <img
    src={ChevronUpDarkIcon}
    className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    alt="Chevron Dark Colored Icon"
  />
)

const Preview = () => {
  const { getAllBuilders } = useQuestionBuilder()
  const builders = getAllBuilders()

  return (
    <div className="max-w-full">
      {builders.map((val, index) => (
        <QuestionContainer data={val} idx={index} key={index} />
      ))}
    </div>
  )
}

export default Preview
