import { useEffect, useState } from 'react'
import CateogryAssignment from '../../pages/Report/assignmentCategory'
import ListAssignment from './ListAssignment'
import TabPills from '../TabPills'
import axios from '../../utils/axios'

const Assignment = () => {
  const [activeVal, setActiveVal] = useState('pre-test')
  const [listSubject, setListSubject] = useState('')

  useEffect(() => {
    fetchSubject();
  }, []);

  const handleTab = (item) => {
    setActiveVal(item.value)
  }

  const fetchSubject = () => {
    let subject = [];
    axios
      .get("/questions/subject")
      .then((res) => {
        const result = res.data.results;
        result.forEach((val) => {
          let temp = val.name.split("-");
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          val.value = val.name;
          val.title = capitalizedWords;
          subject.push(val);
        });
        setListSubject(subject);
      })
      .catch((err) => {
        alert("Gagal get list subject");
        console.error(err.message);
      });
  };

  return (
    <>
      <div className="mt-5 w-full">
        {listSubject ?
          <TabPills
            categories={listSubject}
            active={activeVal}
            onClickTab={handleTab}
          /> :
          null
        }
      </div>
      <div className="mb-[50px]">
        <ListAssignment selected={activeVal} />
      </div>
    </>
  )
}

export default Assignment
