import React from "react";
import FillTypeQuestion from "../../type/fill";
import useQuestionAnswer from "../../../../hooks/useQuestionAnswer";

/*
    @@params = {
        title : string
        fields : array of field
    }

    field = {
        text : string
        data : FillTypeQuestion
    }
*/

const convertInput = (html, answer) => {
  const regex = /<\/?span.+?>/;
  let str = html.split(regex);
  str = str.filter((val) => val.length > 1);
  str = str.reduce((total, current) => {
    if (current.startsWith("input#")) {
      let idx = parseInt(current.split("#")[1]);
      console.log(answer[idx - 1]);
      const i = `  ${idx}. <input type="text" class="fill-input"></input>`;
      return (total += i);
    } else {
      return (total += current);
    }
  }, "");

  return str;
};

const FillOnCardQuestionContainer = (data) => {
  const { question, answer } = data;
  const { setQuestionIdEach } = useQuestionAnswer();

  setQuestionIdEach(data.number, data.id);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: convertInput(question, answer) }}
      className="mb-4 bg-gray-50 p-8"
    />
  );
};

export default FillOnCardQuestionContainer;
