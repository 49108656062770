import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import Assignment from './Assigment'
import StudentProfile from './StudentProfile'
import Comments from './Comments'
import Loading from '../../../components/Loading'

import axios from '../../../utils/axios'

const WritingExplanations = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const account = useSelector((s) => s.account)
  let {
    state: { testName, userId, classCategory },
  } = useLocation()
  const [writingData, setWritingData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [commentData, setCommentData] = useState({})
  const [commentList1, setCommentList1] = useState([])
  const [commentList2, setCommentList2] = useState([])
  const newestList1Ref = useRef(null)
  const newestList2Ref = useRef(null)

  const handleGoBack = () => {
    navigate(location.pathname.replace('/explanations', ''), {
      state: {
        category: 'writing',
        student: userId,
      },
    })
  }

  const handleSubmitComment = (writingType, highlightText, commentValue) => {
    setIsLoading(true)
    axios
      .post(
        `/answers/${testName}/writing/${classCategory}/${userId}/comments`,
        {
          type: writingType,
          text: commentValue,
          highlight: highlightText,
        }
      )
      .then((res) => {
        const comment = res.data.result
        const copyCommentData = JSON.parse(JSON.stringify(commentData))

        if (!copyCommentData[writingType]) {
          copyCommentData[writingType] = {}
        }

        if (!copyCommentData[writingType][highlightText]) {
          copyCommentData[writingType][highlightText] = []
        }

        copyCommentData[writingType][highlightText].push({
          id: comment.id,
          name: account.name,
          createdAt: new Date(comment.created_at),
          text: comment.text,
          highlight: comment.highlight,
          userId: comment.userId,
          type: comment.type,
        })

        setCommentData(copyCommentData)

        setTimeout(() => {
          if (writingType === 1 && newestList1Ref.current) {
            newestList1Ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }, 100)

        setTimeout(() => {
          if (writingType === 2 && newestList2Ref.current) {
            newestList2Ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }, 100)
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  const handleDeleteComment = (writingType, highlightText, commentId) => {
    setIsLoading(true)
    axios
      .delete(
        `/answers/${testName}/writing/${classCategory}/comments/${commentId}`
      )
      .then((res) => {
        const copyCommentData = { ...commentData }
        copyCommentData[writingType][highlightText] = copyCommentData[
          writingType
        ][highlightText].filter((comment) => comment.id !== commentId)
        setCommentData(copyCommentData)
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  const handleEditComment = (writingType, highlightText, comment) => {
    setIsLoading(true)
    axios
      .put(
        `/answers/${testName}/writing/${classCategory}/comments/${comment.id}`,
        {
          text: comment.text,
        }
      )
      .then((res) => {
        const copyCommentData = { ...commentData }
        const index = copyCommentData[writingType][highlightText].findIndex(
          (data) => data.id === comment.id
        )

        if (index !== -1) {
          copyCommentData[writingType][highlightText][index].text = comment.text
          setCommentData(copyCommentData)
        }
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  const renderCommentsByType = (type) => {
    const commentsUI = []

    for (const highlight in commentData[type]) {
      if (commentData[type].hasOwnProperty(highlight)) {
        commentsUI.push(
          <Comments
            highlights={commentData[type][highlight]}
            key={highlight}
            onSubmitComment={handleSubmitComment}
            onDeleteComment={handleDeleteComment}
            onEditComment={handleEditComment}
            writingType={type}
          />
        )
      }
    }

    if (type === 1) {
      setCommentList1(commentsUI)
    }

    if (type === 2) {
      setCommentList2(commentsUI)
    }
  }

  useEffect(() => {
    axios
      .get(`/answers/${testName}/writing/${classCategory}/${userId}`)
      .then((res) => {
        setWritingData(res.data.result)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })

    axios
      .get(`/answers/${testName}/writing/${classCategory}/${userId}/comments`)
      .then((res) => {
        const result = res.data.results.map((comment) => ({
          id: comment.id,
          name: comment.user.name,
          createdAt: new Date(comment.created_at),
          text: comment.text,
          highlight: comment.highlight,
          userId: comment.userId,
          type: comment.type,
        }))

        const separatedByTypeAndHighlight = result.reduce((result, obj) => {
          if (!result[obj.type]) {
            result[obj.type] = {}
          }

          if (!result[obj.type][obj.highlight]) {
            result[obj.type][obj.highlight] = []
          }

          result[obj.type][obj.highlight].push(obj)

          result[obj.type][obj.highlight].sort(
            (a, b) => a.createdAt - b.createdAt
          )

          return result
        }, {})

        setCommentData(separatedByTypeAndHighlight)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    renderCommentsByType(1)
    renderCommentsByType(2)
  }, [commentData])

  if (isLoading && isEmpty(writingData) && isEmpty(commentData)) {
    return <Loading />
  }

  return (
    <div className="flex flex-col space-y-12 py-8 bg-[#EDF2F8] min-h-screen">
      <StudentProfile
        studentName={writingData.user?.name}
        onClickBack={handleGoBack}
      />
      <Assignment
        writingAnswer={writingData.writing_answer?.answer_1}
        title="Writing 1 Assigment"
        writingType={1}
        onSubmitComment={handleSubmitComment}
      />
      <div className="flex flex-col space-y-4 px-32">
        {commentList1.map((component, index) => (
          <div
            key={index}
            ref={index === commentList1.length - 1 ? newestList1Ref : null}
          >
            {component}
          </div>
        ))}
      </div>
      <Assignment
        writingAnswer={writingData.writing_answer?.answer_2}
        title="Writing 2 Assigment"
        writingType={2}
        onSubmitComment={handleSubmitComment}
      />
      <div className="flex flex-col space-y-4 px-32">
        {commentList2.map((component, index) => (
          <div
            key={index}
            ref={index === commentList2.length - 1 ? newestList2Ref : null}
          >
            {component}
          </div>
        ))}
      </div>
    </div>
  )
}

export default WritingExplanations
