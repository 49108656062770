const TestStats = ({ totalScore, timeTaken, correct, incorrect, result }) => {
  return (
    <div className="basis-80 shrink-0">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-4">
          <div className="py-2 px-3 rounded-[4px] bg-[#F9F5FF] text-[#6941C6] font-semibold">
            Listening Test Master
          </div>
          <div className="flex justify-between gap-2">
            <div className="flex flex-col space-y-4 grow">
              <div className="flex flex-col space-y-1">
                <p className="text-neromid text-xs">Total Score</p>
                <p className="text-nero-700 font-bold">{totalScore}</p>
              </div>
              <div className="flex flex-col space-y-1">
                <p className="text-neromid text-xs">Correct</p>
                <p className="text-nero-700 font-bold">{correct}</p>
              </div>
            </div>
            <div className="flex flex-col space-y-4 grow">
              <div className="flex flex-col space-y-1">
                <p className="text-neromid text-xs">Time Taken</p>
                <p className="text-nero-700 font-bold">{timeTaken}</p>
              </div>
              <div className="flex flex-col space-y-1">
                <p className="text-neromid text-xs">Incorrect</p>
                <p className="text-nero-700 font-bold">{incorrect}</p>
              </div>
            </div>
          </div>
          {result && (
            <div className="flex flex-col space-y-4 grow">
              <div className="flex flex-col space-y-1">
                <p className="text-neromid text-xs">Result</p>
                <p className="text-nero-700 font-bold">{result}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestStats
