import { twMerge } from 'tailwind-merge'
import SortIcon from '../assets/sort.svg'
import { Spinner } from '@material-tailwind/react'

const Table = ({ columns, data, isLoading = false }) => {
  const columnSlugs = columns.map((column) => column.slug)
  const expandPadding = (column, totalColumn) =>
    twMerge(column === 0 && 'pl-12', column === totalColumn && 'pr-12')

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                className={`text-sm text-neromid p-4 text-left border-b border-[##85858526] ${expandPadding(
                  index,
                  columns - 1
                )}`}
                key={column.slug}
              >
                <div className="flex space-x-3">
                  <span>{column.name}</span>
                  {column.orderable && <img src={SortIcon} alt="Sort Icon" />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data?.map((item) => (
              <tr key={item.id}>
                {columnSlugs.map((slug, index) => (
                  <td
                    className={`text-sm text-neromid p-4 border-b border-[##85858526] ${expandPadding(
                      index,
                      columnSlugs - 1
                    )}`}
                    key={`${item.id}-${slug}`}
                  >
                    {item[slug]}
                  </td>
                ))}
              </tr>
            ))}
          <tr>
            {data?.length === 0 && !isLoading && (
              <td
                colSpan={columns.length}
                className="text-sm text-neromid border-b border-[##85858526] p-12 text-center"
              >
                No data found
              </td>
            )}
            {isLoading && (
              <td
                colSpan={columns.length}
                className="text-sm text-neromid border-b border-[##85858526] p-12 text-center"
              >
                <div className="flex justify-center w-full">
                  <Spinner />
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Table
