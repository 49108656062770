import CardStudent from "./CardStudent";

const ListStudent = ({ list, subject, category }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
      {list.map((item, idx) => (
        <CardStudent
          key={idx}
          item={item}
          list={list}
          subject={subject}
          category={category}
        />
      ))}
    </div>
  );
};

export default ListStudent;

const List = [
  {
    name: "John Doe",
    performance: "50%",
    point: "20",
    fromPoint: "40",
    status: "Published",
    image: "../../assets/dummy2.svg",
  },
];
