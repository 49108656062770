const MenuTable = ({ icon, title, onClick, active, description }) => {
    return (
      <div onClick={onClick} className={`mt-5 flex flex-row lg:w-1/2 hover:bg-[#F5FFF8] p-3 border rounded-xl m-1 cursor-pointer ${active == title ? "bg-[#F5FFF8]" : "bg-white"}`}>
        <img src={icon} className='w-14 h-14'/>
        <div className='flex flex-col justify-center ml-3'>
          <p className='text-sm text-[#292929]'>{title}</p>
          <p className='text-[#808080] text-xs'>{description}</p>
        </div>
      </div>
    )
}

export default MenuTable;