import { useEffect, useState } from "react";
import AlertPopup from "./Alert";

const usePopup = () => {
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState(<AlertPopup />);
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    console.log(open);
  }, [open]);

  const alert = (text) => {
    setAlertText(text);
    setOpen(true);
  };

  const closeHandle = () => {
    setOpen(false);
  };

  return { open, alert, element, closeHandle, alertText };
};

const PopupRoot = ({ children }) => {
  const popup = usePopup();

  // useEffect(() => {
  //   console.log(open);
  // }, [open]);

  console.log(popup.open);

  return (
    <>
      <AlertPopup />
      {children}
    </>
  );
};

export { PopupRoot, usePopup };
