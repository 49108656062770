import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import store from '../store'
import { setAccount } from '../slice/account'
import { Url } from '../global'

const defaultConfig = {
  baseURL: `${Url}`,
  withCredentials: true,
}

const axios = Axios.create(defaultConfig)

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token')
    config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (err) => Promise.reject(err)
)

axios.interceptors.response.use(
  (res) => {
    if (res.data.accessToken) {
      const decoded = jwtDecode(res.data.accessToken)

      sessionStorage.removeItem('noRefresh')
      sessionStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('loggedIn', true)
      store.dispatch(setAccount(decoded))
    }

    return res
  },
  (err) => {
    err.message = err.response?.data?.message ?? err.response?.data?.msg
    err.data = err.response?.data
    err.status = err.response?.status

    if (err.status === 401 && !sessionStorage.getItem('noRefresh')) {
      sessionStorage.setItem('noRefresh', 'true')

      return axios
        .get('/auth/refresh')
        .then(() => axios(err.config))
        .catch((err2) => {
          // TODO: buat route proteksi khusus user yg sudah login
          if (err2.status === 401) {
            alert('Silahkan login terlebih dahulu')
            window.location.replace('/')
          }

          return Promise.reject(err)
        })
    }

    // if (process.env.NODE_ENV === 'development') console.error(err);

    return Promise.reject(err)
  }
)

export default axios
