import { Input, Typography } from '@material-tailwind/react'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import DOMPurify from 'dompurify'

const EquationEditorQuestion = ({
  question,
  userAnswer = '',
  onChange,
  correctAnswer,
  currentNumber,
}) => {
  const config = {
    loader: { load: ['input/asciimath'] },
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value

    // Allow only numbers, negative sign, points, and slashes
    const regex = /^-?[0-9./]*$/

    if (regex.test(inputValue) && inputValue.length <= 7) {
      onChange(inputValue)
    }
  }

  const generateCorrectAnswers = (correctAnswer) => {
    if (correctAnswer.length === 1) {
      return correctAnswer[0]
    }

    return correctAnswer.map((answer, index) => {
      if (index === correctAnswer.length - 1) {
        return answer
      }

      if (index === correctAnswer.length - 2) {
        return `${answer} or `
      }

      return `${answer}, `
    })
  }

  return (
    <div>
      <div className="flex space-x-2">
        <Typography variant="h5" className="mb-4">
          {currentNumber}.{' '}
        </Typography>
        <Typography
          variant="h5"
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
        />
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-4">
          {!correctAnswer && (
            <Input
              variant="static"
              value={userAnswer}
              onChange={handleInputChange}
              color="black"
              disabled={correctAnswer}
              className="!text-lg !text-black"
              placeholder="Enter your answer here"
            />
          )}
          {correctAnswer && (
            <div className="flex space-x-2 items-center">
              <Typography variant="paragraph">Your answer: </Typography>
              {userAnswer?.split('').map((char, index) => (
                <div
                  className="flex items-center justify-center text-white w-8 h-8 border bg-black rounded-full"
                  key={`char-${index + 1}`}
                >
                  {char}
                </div>
              ))}
              {correctAnswer &&
                !!correctAnswer.find((answer) => answer === userAnswer) && (
                  <Typography
                    variant="paragraph"
                    className="text-[#a3da09] text-sm"
                  >
                    Correct Answer
                  </Typography>
                )}
              {correctAnswer &&
                userAnswer &&
                !correctAnswer.find((answer) => answer === userAnswer) && (
                  <Typography
                    variant="paragraph"
                    className="text-[#ff3e2a] text-sm"
                  >
                    Incorrect Answer
                  </Typography>
                )}
              {correctAnswer && !userAnswer && (
                <Typography variant="paragraph" className="text-sm">
                  Blank
                </Typography>
              )}
            </div>
          )}
          <Typography>
            <MathJaxContext config={config} inline dynamic>
              <Typography
                variant="paragraph"
                className="flex items-center text-md"
              >
                <span>Answer Preview:</span>
                <MathJax inline dynamic>{`\`${userAnswer}\``}</MathJax>
              </Typography>
            </MathJaxContext>
          </Typography>
        </div>

        {correctAnswer && (
          <div className="flex flex-col space-y-2">
            <Typography>
              <Typography
                variant="paragraph"
                className="flex items-center text-md"
              >
                <span>Correct Answer:</span>
                <span>{generateCorrectAnswers(correctAnswer)}</span>
              </Typography>
            </Typography>
            <Typography>
              <MathJaxContext config={config} inline dynamic>
                <Typography
                  variant="paragraph"
                  className="flex items-center text-md space-x-2"
                >
                  <span>Correct Answer Preview:</span>
                  {correctAnswer.map((answer, index) => (
                    <span
                      className="border py-1 px-2"
                      key={`answer-${index + 1}`}
                    >
                      <MathJax inline dynamic>{`\`${answer}\``}</MathJax>
                    </span>
                  ))}
                </Typography>
              </MathJaxContext>
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default EquationEditorQuestion
