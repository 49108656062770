import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  Chip,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import classNames from 'classnames'
import SatChevronIcon from './SatChevronIcon'
import {
  generateSatQuestionByKey,
  SAT_TABLE_HEAD_FORMAT,
} from '../../helpers/sat'

const SatReportFormatQuestions = ({
  questions,
  accordionOpen,
  handleAccordionOpen,
  handleOpenModal,
}) => {
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {SAT_TABLE_HEAD_FORMAT.map((head, index) => (
              <th
                key={`format-question-${index + 1}`}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {generateSatQuestionByKey(questions, 'type').map(
            (question, index) => {
              const isLast =
                index === generateSatQuestionByKey(questions, 'type').length - 1
              const classes = isLast
                ? 'p-4'
                : 'p-4 border-b border-blue-gray-50'

              return (
                <tr key={`${question.type + 1}`}>
                  <td className={classes}>
                    <div>
                      <Accordion
                        open={accordionOpen === 1}
                        icon={<SatChevronIcon id={1} open={accordionOpen} />}
                        className="w-auto"
                      >
                        <AccordionHeader
                          onClick={() => handleAccordionOpen(1)}
                          className="border-0 pb-0 w-auto"
                        >
                          <Typography variant="h6" className="font-normal">
                            {question.type === 1
                              ? 'Multiple Choice'
                              : 'Fill in the Blank'}
                          </Typography>
                        </AccordionHeader>
                        <AccordionBody>
                          <div className="flex flex-wrap gap-2 max-w-[400px]">
                            {question.answers.map((detail) => (
                              <Tooltip
                                key={detail.id}
                                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                                content={
                                  <div className="flex flex-col space-y-3">
                                    <Typography
                                      color="black"
                                      className="font-medium"
                                    >
                                      Question {detail.number}
                                    </Typography>
                                    <div>
                                      <Typography
                                        variant="small"
                                        className={classNames(
                                          'font-normal text-[#ff3e2a]',
                                          {
                                            'text-[#ff3e2a]':
                                              detail.type === 3
                                                ? !detail.answer.find(
                                                    (answer) =>
                                                      answer ===
                                                      detail.userAnswer
                                                  )
                                                : detail.userAnswer !==
                                                  detail.answer,
                                            'text-[#a3da09]':
                                              detail.type === 3
                                                ? !!detail.answer.find(
                                                    (answer) =>
                                                      answer ===
                                                      detail.userAnswer
                                                  )
                                                : detail.userAnswer ===
                                                  detail.answer,
                                            'text-[#c5c5c5]':
                                              !detail.userAnswer,
                                          }
                                        )}
                                      >
                                        Your Answer:{' '}
                                        {detail.userAnswer
                                          ? detail.userAnswer
                                          : '-'}
                                      </Typography>
                                      <Typography
                                        variant="small"
                                        className="font-normal text-[#a3da09]"
                                      >
                                        Correct Answer: {detail.answer}
                                      </Typography>
                                    </div>
                                    <Typography
                                      color="black"
                                      variant="small"
                                      className="font-normal opacity-80"
                                    >
                                      Click question to review
                                    </Typography>
                                  </div>
                                }
                                color
                              >
                                <Chip
                                  value={detail.number}
                                  variant="ghost"
                                  className={classNames(
                                    'text-center w-7 h-7 p-0 flex items-center justify-center cursor-pointer border',
                                    {
                                      'border-[#ff3e2a] bg-[#ff3e2a] text-white':
                                        detail.type === 3
                                          ? !detail.answer.find(
                                              (answer) =>
                                                answer === detail.userAnswer
                                            )
                                          : detail.userAnswer !== detail.answer,
                                      'border-[#a3da09] bg-[#a3da09] text-white':
                                        detail.type === 3
                                          ? !!detail.answer.find(
                                              (answer) =>
                                                answer === detail.userAnswer
                                            )
                                          : detail.userAnswer === detail.answer,
                                      'border-[#c5c5c5] bg-[#c5c5c5] text-white':
                                        !detail.userAnswer,
                                    }
                                  )}
                                  onClick={() => handleOpenModal(detail)}
                                />
                              </Tooltip>
                            ))}
                          </div>
                        </AccordionBody>
                      </Accordion>
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.correct}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.incorrect}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.blank}
                    </Typography>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </Card>
  )
}

export default SatReportFormatQuestions
