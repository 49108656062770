import React, { useState } from 'react'
import SatReportLayout from '../../../components/Sat/SatReportLayout'
import SatReport from '../../../components/Sat/SatReport'

const ReportSatPage = ({ testName, userId, userName }) => {
  return (
    <div className="max-w-5xl mx-auto">
      <SatReportLayout>
        <SatReport
          account={{
            userId,
            name: userName,
          }}
        />
      </SatReportLayout>
    </div>
  )
}

export default ReportSatPage
