import React from 'react'

import RecordingQuestionContainer from '../../../components/View/container/recording'

const Result = ({ questions }) => {
  // window.location.reload();

  return (
    <>
      {questions.length > 0 ? (
        <>
          {questions.map((question, index) => {
            switch (question.type) {
              case '10':
                return <RecordingQuestionContainer {...question} />
            }
          })}
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  )
}

export default Result
