import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from '@material-tailwind/react'
import { tabDataTable } from '../../helpers/sat'

const SatReportModuleBody = ({
  activeTabTable,
  questions,
  accordionOpen,
  handleAccordionOpen,
  dataTopic,
  handleOpenModal,
  setActiveTabTable,
}) => {
  return (
    <Tabs value={activeTabTable}>
      <div className="flex items-center justify-end space-x-2">
        <Typography>View by: </Typography>
        <TabsHeader>
          {tabDataTable({ questions, accordionOpen, handleAccordionOpen, dataTopic, handleOpenModal }).map(
            ({ label, value }) => (
              <Tab key={value} value={value} onClick={() => setActiveTabTable(value)} className="whitespace-nowrap">
                {label}
              </Tab>
            ),
          )}
        </TabsHeader>
      </div>

      <TabsBody>
        {tabDataTable({ questions, accordionOpen, handleAccordionOpen, dataTopic, handleOpenModal }).map(
          ({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
          ),
        )}
      </TabsBody>
    </Tabs>
  )
}

export default SatReportModuleBody
