import React from 'react'
import { useNavigate } from 'react-router-dom'
const CardMenu = ({ title, image, subject }) => {
  const navigate = useNavigate()

  const coursesPage = () => {
    navigate(`/test/${subject}`)
  }

  return (
    <div className="card bg-white flex flex-col items-center justify-center p-4 shadow-lg rounded-2xl w-96">
      <img src={image} className="w-[60px] h-[60px] mt-10" />
      <p className="text-gray-900 mt-5">{title}</p>
      <p className="w-[320px] text-center font-thin mt-5 text-sm">
        Tambahkan atau edit soal test yang akan dikerjakan siswa{' '}
      </p>
      <button
        className="py-2 rounded-md text-xs text-white bg-[#064C85] px-14 transition-colors duration-300 mt-5 mb-8"
        onClick={coursesPage}
      >
        Start
      </button>
    </div>
  )
}

export default CardMenu
