const Stats = ({ icon, title, total }) => {
  return (
    <div className="flex items-center space-x-3 px-4 py-5 rounded-lg border border-[#CCCCCC] w-full">
      <div className="flex items-center justify-center rounded-full w-11 h-11 bg-[#DFECFD] border border-[#094A78]/[.4]">
        <img src={icon} alt="Study Icon" />
      </div>
      <div>
        <p className="font-bold text-xs text-nero-700">{title}</p>
        <h5 className="font-bold text-lg text-priblue-500">{total}</h5>
      </div>
    </div>
  )
}

export default Stats
