import React from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const CheckboxQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question,
    choices = [],
    answer = '',
    point,
    number,
    multi,
  } = getBuilderDataById(idx, containerId)

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <section className="mb-4 me-2">
          {number} {multi > 0 ? '- ' + (number + multi) : ''}.
        </section>
        <div className="flex flex-col gap-1">
          <div dangerouslySetInnerHTML={{ __html: question }} />
          {choices?.map((val, idx) => (
            <section className="flex gap-1 items-center my-1" key={idx}>
              <span className="w-8 h-8 me-4 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
                {String.fromCharCode(65 + idx)}
              </span>
              <input
                type="checkbox"
                className="w-5 h-5 cursor-pointer me-1"
                value={val}
                checked={answer[idx]}
                disabled
                name={`answer${idx}`}
              ></input>
              <label className="ms-2 ">{val}</label>
            </section>
          ))}
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default CheckboxQuestionPreview
