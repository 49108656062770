import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'

import TabPills from '../../../components/TabPills'
import Dropdown from '../../../components/Dropdown'

const Toolbar = ({
  onClickTab,
  activeTab,
  categories,
  searchValue,
  onChangeSearchValue,
}) => {
  return (
    <div className="flex flex-col space-y-4 justify-between mb-8 space-x-4 max-w-full md:flex-row md:space-y-0">
      <div className="flex-1 overflow-auto">
        <TabPills
          categories={categories}
          active={activeTab}
          onClickTab={onClickTab}
        />
      </div>
      <div className="relative flex flex-col space-y-2 w-[300px] md:flex-row md:space-x-2 md:space-y-0">
        <div className="flex items-center space-x-2 w-full rounded-lg bg-white overflow-hidden border border-grey-300  transition duration-200 py-3 px-4 focus-within:shadow-lg">
          <div className="flex items-center justify-center h-full text-gray-300">
            <BiSearch className="text-xl" />
          </div>
          <input
            className="peer h-full outline-none text-sm text-gray-700 p-0 border-none focus:outline-none focus:ring-0"
            type="text"
            id="search"
            placeholder="Search"
            value={searchValue}
            onChange={onChangeSearchValue}
          />
        </div>
      </div>
    </div>
  )
}

export default Toolbar
