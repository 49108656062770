import { MagnifyingGlassIcon, ChevronUpDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import {useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setStatusMentor } from "../../../../slice/status";
import ModalDetailStudent from "../../../ModalDetailStudent";

const TABLE_HEAD = [
  {
    name: "Name", 
    selector: row => row.name
  },
  {
    name:"Email address", 
    selector: row => row.email

  },
  {
    name:"Mentor Class", 
    selector: row => row.class
  },
  {
    name:"Status", 
    selector: row => row.status
  },  
  {
    name:"Action",
    selector: row => row.action
  }
];

let TABLE_ROWS = [];


export default function ListMentor() {
  const navigate = useNavigate();
  const status = useSelector((state) => state.status.listMentor);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [userSelected, setUserSelected] = useState('');

  const cancel = () => setModal(false);

  useEffect(() => {
    // if (status == false) {
      fetchData();
    // }
  }, []);
  
  const addMentor = () => {
    navigate("/test/addmentor");
  }

  const editMentor = (item) => {
    navigate("/test/editmentor", {
        state: {
          datamentor: item
        }
    });
  }

  const handleDelete = (userId) => {
    setModalDelete(true);
    setUserSelected(userId);
  }

  const cancelDelete = () => {
    setModalDelete(false);
  }

  const fetchData = async () => {
    setLoading(true);
    await axios
      .get(`/users?role=2`)
      .then((res) => {
        res.data.results.forEach((item) => {
        if(res.data.results.length != TABLE_ROWS.length) {
          TABLE_ROWS.push({
            name: item.name,
            email: item.email,
            class: (
              <div className="py-2">
                {item.classes ? (
                  item.classes.map((item2, idx) => (
                    <div key={idx} className="bg-[#EFF8FF] text-[#175CD3] p-2 rounded-lg mb-2">
                      {item2.name} {console.log('item2:', item2)}
                    </div>
                  ))
                ) : (
                  <div className="bg-[#EFF8FF] text-[#175CD3] p-2 rounded-lg mb-2">
                    -
                  </div>
                )}
              </div>
            ),
            status: (
              <Button
                className="flex items-center gap-3 bg-priblue-500 text-white"
                size="sm"
                onClick={() => {
                  setDataModal(item);
                  setModal(true);
                }}
              >
                Detail
              </Button>
            ),
            action: (
              <div className="flex">
                <Tooltip content="Delete User">
                  <IconButton
                    variant="text"
                    className="text-[#667085] flex justify-center items-center"
                  >
                    <TrashIcon className="h-4 w-4" onClick={() => handleDelete(item.id)} />
                  </IconButton>
                </Tooltip>
                <Tooltip content="Edit User">
                  <IconButton
                    variant="text"
                    className="text-[#667085] flex justify-center items-center"
                  >
                    <PencilIcon className="h-4 w-4" onClick={() => editMentor(item)} />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          });
        }
      });
      dispatch(setStatusMentor(true));
      setLoading(false);
    })
    .catch((err) => {
      dispatch(setStatusMentor(false));
      setLoading(false);
    });
  };
  

  // filter
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const filteredItems = TABLE_ROWS.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

  const handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state;

    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('');
    };
  }

  const FilterComponent = ({ filterText, onFilter }) => (
    <div className="flex items-center">
      <MagnifyingGlassIcon className="h-4 w-4 absolute ml-2" />
      <input type="text" autoFocus="autoFocus" className="border-2 px-7 w-72 py-2" placeholder="Search" value={filterText} onChange={onFilter} />
    </div>
  );

	const getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          setFilterText(newFilterText);
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  };
  return (
    <Card className="h-full w-full">
      {modal ? <ModalDetailStudent close={cancel} data={dataModal} /> : ""}
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Kobi's Mentors
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              See information about all mentor
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            {/* <Button variant="outlined" color="blue-gray" size="sm">
              view all
            </Button> */}
            <Button className="flex items-center gap-3 bg-[#064C85]" size="sm" onClick={addMentor}>
              <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add Mentor
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
      {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
        <DataTable
            columns={TABLE_HEAD}
            data={filteredItems}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="400px"
            paginationResetDefaultPage={resetPaginationToggle} 
            subHeader
            subHeaderComponent={getSubHeaderComponent()}
            persistTableHead
        />
      )}
      </CardBody>
    </Card>
  );
}