import DOMPurify from 'dompurify'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

import axios from '../../../utils/axios'

const QuestionText = ({ subject, category, classCategory }) => {
  const [reading1, setReading1] = useState('')
  const [reading2, setReading2] = useState('')
  const [reading3, setReading3] = useState('')

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const tabsData = [
    {
      label: 'Reading 1',
      content: (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reading1) }}
        />
      ),
    },
    {
      label: 'Reading 2',
      content: (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reading2) }}
        />
      ),
    },
    {
      label: 'Reading 3',
      content: (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reading3) }}
        />
      ),
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`/questions/${subject}/${category}/${classCategory}/description`)
          .then((res) => {
            const data = res.data.result
            // dispatch(setTimer(duration));
            setReading1(data.text_reading1)
            setReading2(data.text_reading2)
            setReading3(data.text_reading3)
          })
      } catch (err) {
        toast.error(err.message)
      }
    }
    fetchData()
  }, [category, classCategory, subject])

  return (
    <div className="text-justify">
      <div className="flex">
        {tabsData.map((tab, idx, row) => (
          <button
            type="button"
            key={tab.label}
            className={twMerge(
              'py-2 text-xs border border-solid border-[#D0D5DD] bg-white px-5 transition-colors duration-300',
              idx === activeTabIndex && 'bg-[#94f7f7]',
              idx === 0 && 'rounded-l-lg',
              idx + 1 === row.length && 'rounded-r-lg'
            )}
            onClick={() => setActiveTabIndex(idx)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <br />
      <section>{tabsData[activeTabIndex].content}</section>
      <br />
    </div>
  )
}

export default QuestionText
