import { Icon } from '@iconify/react'
import React from 'react'
import useQuestionBuilder from '../../../hooks/useQuestionBuilder'

const PreviewMenu = ({ point, idx, containerId, classCategory, noPoint }) => {
  const { deleteBuilder, toggleEdit } = useQuestionBuilder()

  return (
    <div className="flex w-full mb-4 text-gray-600">
      {!noPoint && (
        <section className="border-[1px] border-gray-300 text-center py-2 w-full">
          Point : <b>{point}</b>
        </section>
      )}

      <button
        className="border-[1px] border-gray-300 py-2 flex justify-center items-center gap-1 hover:bg-gray-100 w-full"
        onClick={() => toggleEdit(idx, containerId)}
      >
        <Icon icon="lucide:edit" />
        <span>Edit</span>
      </button>
      <button
        className="border-[1px] border-gray-300 py-2 flex justify-center items-center gap-1 hover:bg-gray-100 w-full"
        onClick={() => deleteBuilder(idx, containerId, classCategory)}
      >
        <Icon icon="ion:trash-outline" />
        <span>Remove</span>
      </button>
    </div>
  )
}

export default PreviewMenu
