import React from 'react'
import AnswerTypeQuestion from '../type/answer'

/*
    @@params{
        fields : array of AnswerTypeQuestion
    }
*/

const AnswerQuestionContainer = (data) => {
  const copyData = { ...data }
  if (copyData?.answer) {
    copyData.answer = JSON.parse(data.answer)[0][0]
  }

  return (
    <div className="mb-12">
      <AnswerTypeQuestion {...copyData} />
    </div>
  )
}

export default AnswerQuestionContainer
