import { Avatar } from '@material-tailwind/react'
import { useSelector } from 'react-redux'

const CardClass = ({ data }) => {
  const account = useSelector((s) => s.account)

  return (
    <div className="flex flex-col space-y-4 bg-white p-4 border border-[#CCCCCC] rounded-lg min-h-full">
      <div className="bg-[#F3BCC3] border border-[#CCCCCC] rounded w-[46px] h-[46px]" />
      <h2 className="text-nero-700 text-lg font-semibold grow">{data.name}</h2>
      {/* <div className="flex justify-between">
        <div className="flex flex-col space-y-2">
          <p className="text-neromid text-xs">Participant:</p>
          <div className="flex items-center -space-x-4">
            <Avatar
              variant="circular"
              alt="user 1"
              className="border-2 border-white w-7 h-7 hover:z-20 focus:z-20"
              src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
            />
            <Avatar
              variant="circular"
              alt="user 2"
              className="border-2 border-white w-7 h-7 hover:z-20 focus:z-20"
              src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
            />
            <Avatar
              variant="circular"
              alt="user 3"
              className="border-2 border-white w-7 h-7 hover:z-20 focus:z-20"
              src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
            />
            <Avatar
              variant="circular"
              alt="user 4"
              className="border-2 border-white w-7 h-7 hover:z-20 focus:z-20"
              src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80"
            />
            <div className="flex justify-center items-center bg-[#E0E5F2] border-2 border-white rounded-full w-7 h-7 text-[#4318FF] text-[10px] font-bold z-10 hover:z-20 focus:z-20">
              18+
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-neromid text-xs">Progress:</p>
          <p className="text-priblue-500 text-lg font-semibold">85%</p>
        </div>
      </div> */}
      <hr />
      <p className="text-[#4318FF]">Tutor by {account.name}</p>
    </div>
  )
}

export default CardClass
