import React, { Fragment, useEffect, useRef, useState } from 'react'
import Notify from '../../../components/Notify'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { useLocation, useNavigate } from 'react-router-dom'
import { MultiSelect } from 'react-multi-select-component'
import axios from '../../../utils/axios'
import { useDispatch } from 'react-redux'
import { setStatusMentor } from '../../../slice/status'
import Radio from '../../../components/Register/Radio'
import { toast } from 'react-toastify'

const AddMentor = () => {
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  const form = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const [valGender, setValGender] = useState(
    location.state ? location.state.datamentor.gender : ''
  )
  const [listClass, setListClass] = useState('')
  const [classId, setClassId] = useState([])

  const onSubmit = (e) => {
    e.preventDefault()
    setModal(true)
  }

  const showNotify = () => {
    setModal(true)
  }

  const handleModal = () => {
    setModal((prevState) => !prevState)
  }

  const save = () => {
    const { name, email, gender, password, conf_password, phone_number } =
      form.current.elements

    axios
      .post('/auth/mentor', {
        name: name.value,
        email: email.value,
        password: password.value,
        confPassword: conf_password.value,
        gender: gender.value,
        phone_number: phone_number.value,
        classes: classId,
      })
      .then((res) => {
        // TODO
        alert('sukses')
        setModal(false)
        console.log('new user:', res.data.result)
        dispatch(setStatusMentor(false))
      })
      .catch((err) => {
        alert('Error: ' + err.message)
      })
  }

  const update = () => {
    const { name, email, gender, phone_number, password, conf_password } =
      form.current.elements

    if (password.value) {
      axios
        .put(`/users/${location.state.datamentor.id}`, {
          name: name.value,
          email: email.value,
          password: password.value,
          confPassword: conf_password.value,
          gender: gender.value,
          phone_number: phone_number.value,
          classes: classId,
        })
        .then((res) => {
          toast.success('Berhasil Update Data Mentor')
          setModal(false)
          dispatch(setStatusMentor(false))
          // reload after navigate
          navigate('/data')
          navigate(0)
        })
        .catch((err) => {
          toast.error(err)
        })
    } else {
      axios
        .put(`/users/${location.state.datamentor.id}`, {
          name: name.value,
          email: email.value,
          gender: gender.value,
          phone_number: phone_number.value,
          classes: classId,
        })
        .then((res) => {
          toast.success('Berhasil Update Data Mentor')
          setModal(false)
          dispatch(setStatusMentor(false))
          // reload after navigate
          navigate('/data')
          navigate(0)
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }

  const [selected, setSelected] = useState([])

  let options = []

  useEffect(() => {
    fetchClass()
  }, [])

  const fetchClass = async () => {
    await axios
      .get('/classes')
      .then((res) => {
        if (options.length != res.data.results.length) {
          res.data.results.map((data) => {
            options.push({
              label: data.name,
              value: data.id,
            })
          })
          setListClass(options)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const genderoptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]

  const handleGender = (e) => {
    setValGender(e.currentTarget.value)
  }

  const handleSelect = (e) => {
    setSelected(e)

    let class_val = []
    e.map((item, idx) => {
      class_val.push(item.value)
    })
    setClassId(class_val)
  }

  return (
    <Fragment>
      <form ref={form} className="grid grid-cols-12 m-10" onSubmit={onSubmit}>
        <Notify
          handleModal={handleModal}
          isOpen={modal}
          save={location.state != null ? update : save}
          title={location.state != null ? 'update' : 'submit'}
        />

        <div className="ml-10">
          <ArrowLeftIcon
            className="h-12 w-16 bg-[#F8F8F8] p-3 rounded-r-full rounded-l-full cursor-pointer hover:bg-[#D0D5DD]"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="mt-3">
          <p>Add Mentor</p>
        </div>
        <div className="col-start-4 col-span-7 px-10 mt-16">
          <p className="text-[#064C85] text-lg">Personal Information</p>
          <div className="mt-3">
            <p>Full Name</p>
            <input
              type="text"
              name="name"
              defaultValue={
                location.state != null ? location.state.datamentor.name : null
              }
              className="border py-2 mt-2 w-full"
              placeholder="Enter test name"
              required
            />
          </div>
          <div className="mt-4">
            <p className="mb-1">Gender</p>
            <div className="flex flex-row">
              {genderoptions.map((data, idx) => (
                <div className="mr-5" key={idx}>
                  <Radio
                    label={data.label}
                    name="gender"
                    value={data.value}
                    onChange={handleGender}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <p className="mb-1">Class</p>
            <MultiSelect
              options={listClass}
              value={selected}
              onChange={(e) => handleSelect(e)}
              labelledBy="Select"
            />
            {/* <input type="text" className="border py-2 mt-1 w-full" placeholder="Enter test name" required/> */}
          </div>

          <div className="mt-3">
            <p>Password</p>
            <input
              type="text"
              name="password"
              className="border py-2 mt-2 w-full"
              placeholder="Enter password"
            />
          </div>

          <div className="mt-3">
            <p>Confirm Password</p>
            <input
              type="text"
              name="conf_password"
              className="border py-2 mt-2 w-full"
              placeholder="Enter confirm password"
            />
          </div>

          <p className="text-[#064C85] text-lg mt-10">Contact Information</p>
          <div className="mt-3">
            <p>Email</p>
            <input
              type="text"
              name="email"
              className="border py-2 mt-2 w-full"
              defaultValue={
                location.state ? location.state.datamentor.email : null
              }
              placeholder="Enter test name"
              required
            />
          </div>
          <div className="mt-3">
            <p>Mobile Number</p>
            <div className="flex">
              <input
                type="text"
                className="border py-2 mt-2 w-10"
                value="+62"
                readOnly
              />
              <input
                type="tel"
                className="border py-2 mt-2 ml-2 w-full"
                name="phone_number"
                placeholder="Enter mobile number (8xxx)"
                title="Format 8xxx"
                pattern="8\d+"
                defaultValue={
                  location.state ? location.state.datamentor.phone_number : null
                }
                required
              />
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <button
              className="my-5 w-40 py-2 bg-[#064C85] text-white rounded-lg"
              onClick={showNotify}
            >
              {location.state != null ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default AddMentor
