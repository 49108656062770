import { useEffect, useState } from 'react'

import { ClockIcon } from '@heroicons/react/24/outline'
import SatFooter from './SatFooter'
import SatHeader from './SatHeader'
import SatExam from './SatExam'
import SatBody from './SatBody'
import moment from 'moment'
import DOMPurify from 'dompurify'

const SatTest = ({
  initialTime,
  onTimerComplete,
  onFinish,
  questions: data,
  direction,
  subject,
  moduleId,
  updateAnswers,
  activeStatus,
}) => {
  const [questions, setQuestions] = useState(data)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentNumber = currentQuestionIndex + 1
  const totalQuestion = questions.length
  const currentQuestion = questions[currentQuestionIndex]
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (activeStatus === 3 || activeStatus === 2) {
      onFinish()
    }
  }, [])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === currentQuestion.id
            ? {
                ...question,

                time_view: question.time_view + 1,
              }
            : question,
        ),
      )
    }
  }, [currentQuestion.id, isMounted])

  useEffect(() => {
    const updateQuestionTime = () => {
      const time = moment.duration(currentQuestion.total_time).add(1, 'seconds')
      const totalTime = time
      const firstTime = currentQuestion.time_view > 1 ? moment.duration(currentQuestion.first_time) : time

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === currentQuestion.id
            ? {
                ...question,
                first_time: moment.utc(firstTime.asMilliseconds()).format('HH:mm:ss'),
                total_time: moment.utc(totalTime.asMilliseconds()).format('HH:mm:ss'),
              }
            : question,
        ),
      )
    }

    const interval = setInterval(() => {
      updateQuestionTime()
    }, 1000)

    return () => clearInterval(interval)
  }, [currentQuestion.first_time, currentQuestion.id, currentQuestion.time_view, currentQuestion.total_time])

  useEffect(() => {
    const oneMinuteInterval = setInterval(() => {
      setQuestions((prevQuestions) => {
        updateAnswers({ updatedQuestions: prevQuestions, subject, moduleId, activeStatus })
        return prevQuestions
      })
    }, 60000)

    return () => clearInterval(oneMinuteInterval)
  }, [activeStatus, moduleId, subject, updateAnswers])

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
  }

  const handleGoToNumber = (number) => {
    setCurrentQuestionIndex(number - 1)
  }

  const handleUserAnswerChange = (questionId, answer) => {
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          userAnswer: answer,
        }
      }
      return question
    })

    setQuestions(updatedQuestions)
    updateAnswers({ updatedQuestions, subject, moduleId, activeStatus: 1 })
  }

  const handleFinish = () => {
    updateAnswers({ updatedQuestions: questions, subject, moduleId, activeStatus: 2 })
    onFinish()
  }

  const handleModuleTitle = () => {
    switch (moduleId) {
      case 1:
        return 'Reading and Writing Module 1'
      case 2:
        return 'Reading and Writing Module 2'
      case 3:
        return 'Math Module 1'
      case 4:
        return 'Math Module 2'
      default:
        return 'Invalid module number'
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      <SatHeader
        title={handleModuleTitle()}
        countdownElement={<ClockIcon className="w-4 h-4" />}
        onTimerComplete={onTimerComplete}
        directionElement={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(direction) }} />}
        initialTime={initialTime}
      />
      <SatBody>
        <SatExam
          question={currentQuestion}
          onChangeUserAnswer={handleUserAnswerChange}
          currentNumber={currentNumber}
          activeStatus={activeStatus}
        />
      </SatBody>
      <SatFooter
        title={handleModuleTitle()}
        onNext={handleNext}
        onPrevious={handlePrevious}
        currentNumber={currentNumber}
        totalQuestion={totalQuestion}
        onGoToNumber={handleGoToNumber}
        questions={questions}
        currentQuestionIndex={currentQuestionIndex}
        onFinish={handleFinish}
        textPrevious="Back"
        textNext={totalQuestion === currentNumber ? 'Finish' : 'Next'}
      />
    </div>
  )
}

export default SatTest
