import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsFilter } from 'react-icons/bs'
import moment from 'moment'
import { Drawer } from '@material-tailwind/react'

import DefaultStudentImage from '../../../assets/default-student.png'
import OutIcon from '../../../assets/icons/out.svg'
import axios from '../../../utils/axios'
import { scoreIndicator } from '../../../utils/scoreIndicators'

import Comment from './Comment'
import ModalSpeakingScore from '../../../components/Dashboard/ModalSpeakingScore'

const DrawerTodo = ({ isOpen, data, onClose }) => {
  let navigate = useNavigate()
  const account = useSelector((s) => s.account)
  const [isLoading, setIsLoading] = useState(true)
  const [replyValue, setReplyValue] = useState('')
  const [commentData, setCommentData] = useState([])
  const testSlug = data?.testName.replace(' ', '-').toLowerCase()
  const [modalScore, setModalScore] = useState(false)
  const isWritingOrSpeaking = () => {
    if (data.category === 'writing') {
      return 'writing'
    }

    return 'speaking'
  }

  const handleOpenModalScore = () => {
    setModalScore(true)
    onClose()
  }

  const handleModalScore = () => {
    setModalScore((prevState) => !prevState)
  }

  const handleDetail = () => {
    navigate(`/todo/${isWritingOrSpeaking()}/${testSlug}/${data.userId}`)
  }

  const handleReplyChange = (event) => {
    setReplyValue(event.target.value)
  }

  const handleComment = () => {
    setIsLoading(true)
    axios
      .post(
        `/answers/${testSlug}/${isWritingOrSpeaking()}/${data.userId}/comments`,
        {
          type: 0,
          text: replyValue,
        }
      )
      .then((res) => {
        setReplyValue('')
        const comment = res.data.result
        setCommentData((prevState) => [
          ...prevState,
          {
            id: comment.id,
            name: account.name,
            createdAt: comment.created_at,
            text: comment.text,
            userId: comment.userId,
          },
        ])
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  const handleDeleteComment = (commentId) => {
    setIsLoading(true)
    axios
      .delete(
        `/answers/${testSlug}/${isWritingOrSpeaking()}/comments/${commentId}`
      )
      .then((res) => {
        setCommentData((prevState) =>
          prevState.filter((comment) => comment.id !== commentId)
        )
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  const handleUpdateComment = (comment) => {
    setIsLoading(true)
    axios
      .put(
        `/answers/${testSlug}/${isWritingOrSpeaking()}/comments/${comment.id}`,
        {
          text: comment.text,
        }
      )
      .then((res) => {
        const index = commentData.findIndex((data) => data.id === comment.id)

        if (index !== -1) {
          const updateCommentData = [...commentData]
          updateCommentData[index].text = comment.text
          setCommentData(updateCommentData)
        }
      })
      .catch((err) => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (data && isOpen) {
      axios
        .get(
          `/answers/${testSlug}/${isWritingOrSpeaking()}/${
            data?.userId
          }/comments`
        )
        .then((res) => {
          setCommentData(
            res.data.results.map((comment) => ({
              id: comment.id,
              name: comment.user.name,
              createdAt: comment.created_at,
              text: comment.text,
              userId: comment.userId,
            }))
          )
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
  }, [data, isOpen])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('drawer-open')
    } else {
      document.body.classList.remove('drawer-open')
    }

    return () => {
      document.body.classList.remove('drawer-open')
    }
  }, [isOpen])

  return (
    data && (
      <>
        <Drawer
          placement="right"
          open={isOpen}
          onClose={onClose}
          size={800}
          className="overflow-auto"
        >
          {/* Drawer */}
          <div className="absolute top-0 right-0 h-full w-full bg-[#FAFAFB] overflow-auto">
            {/* Header */}
            <div className="flex justify-between bg-white p-8">
              <img
                src={OutIcon}
                alt="Out Icon"
                className="cursor-pointer"
                onClick={onClose}
              />
              {isWritingOrSpeaking() === 'speaking' && (
                <button
                  className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)]"
                  onClick={handleOpenModalScore}
                >
                  {data.totalScore > 0
                    ? 'Edit Score Speaking'
                    : 'Submit Score Speaking'}
                </button>
              )}
              {data.writingAnswer && (
                <button
                  className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)]"
                  onClick={handleDetail}
                >
                  More Detail
                </button>
              )}
            </div>
            {/* Body */}
            <div className="flex flex-col space-y-4 py-4 px-6">
              {/* Student profile */}
              <div className="bg-white rounded-lg p-7">
                <div className="flex space-x-4 items-center mb-6">
                  <img
                    src={DefaultStudentImage}
                    alt="Student"
                    className="w-6 h-6 rounded-full"
                  />
                  <p className="text-nero-700 text-xl font-bold">
                    {data.studentName}
                  </p>
                </div>
                <div className="flex justify-between">
                  <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-7">
                    <tbody>
                      <tr>
                        <td className="text-neromid text-base">Category</td>
                        <td className="text-nero-700 text-base capitalize">
                          {data.category}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-neromid text-base">
                          Class Category
                        </td>
                        <td className="text-nero-700 text-base capitalize">
                          {data.classCategory}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-neromid text-base">Test</td>
                        <td className="text-nero-700 text-base capitalize">
                          {data.testName}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-neromid text-base">
                          Tanggal Dibuat
                        </td>
                        <td className="text-nero-700 text-base">
                          {moment(data.createdAt).format('DD MMM YYYY')}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-neromid text-base">Status</td>
                        <td className="text-nero-700 text-base">
                          {data.totalScore > 0 && (
                            <div className="flex space-x-2 items-center">
                              <div className="w-2 h-2 bg-[#1DB954] rounded-full"></div>
                              <p className="text-nero-700 text-xs">Completed</p>
                            </div>
                          )}
                          {data.totalScore === 0 && (
                            <div className="flex space-x-2 items-center">
                              <div className="w-2 h-2 bg-[#FF9800] rounded-full"></div>
                              <p className="text-nero-700 text-xs">Pending</p>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex flex-col justify-center items-center border-[24px] border-[#DEE9FF] rounded-full w-40 h-40">
                    <h5 className="text-[#18181B] font-Montserrat text-[28px] font-bold">
                      {data.totalScore}
                    </h5>
                    <p className="text-[#18181B] text-sm ">
                      {scoreIndicator(data.totalScore)}
                    </p>
                  </div>
                </div>
              </div>
              {/* Student Score */}
              {data.writingAnswer && (
                <div className="bg-white rounded-lg p-7">
                  <h6 className="text-nero-700 text-lg font-semibold">
                    Writing Score
                  </h6>
                  <table className="table-auto w-full border-separate border-spacing-y-6 border-spacing-x-6">
                    <thead>
                      <tr>
                        <th className="text-nero-700 text-base text-left">
                          Evaluation score
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          TA
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          CC
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          RR
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          GRA
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          Overall
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-nero-700 text-base text-left">
                          Task 1
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.ta}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.cc}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.rr}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.gra}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.overall}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-nero-700 text-base text-left">
                          Task 2
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.ta_2}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.cc_2}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.rr_2}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.gra_2}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.writingAnswer.overall_2}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {data.speakingAnswer && (
                <div className="bg-white rounded-lg p-7">
                  <h6 className="text-nero-700 text-lg font-semibold">
                    Speaking Score
                  </h6>
                  <table className="table-auto w-full border-separate border-spacing-y-6 border-spacing-x-6">
                    <thead>
                      <tr>
                        <th className="text-nero-700 text-base text-left">
                          Evaluation score
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          F&C
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          LR
                        </th>
                        <th className="text-nero-700 text-base text-left">
                          GRA
                        </th>
                        <th className="text-nero-700 text-base text-left">P</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-nero-700 text-base text-left">
                          Score
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.speakingAnswer.fc}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.speakingAnswer.lr}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.speakingAnswer.gra}
                        </td>
                        <td className="text-nero-700 text-base text-left">
                          {data.speakingAnswer.p}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {/* Comments */}
              <div className="bg-white rounded-lg p-7">
                <div className="flex justify-between mb-4">
                  <div className="flex flex-col space-y-2">
                    <h6 className="text-nero-700 font-Montserrat font-bold text-lg">
                      Comments ({commentData.length})
                    </h6>
                    <p className="text-neromid text-sm">
                      Start a discussion. Post with kindness.
                    </p>
                  </div>
                  <div
                    className={` py-2.5 px-4 border-[1px] border-grey-300 rounded-lg flex items-center gap-2 hover:bg-[#F9FAFB] hover:cursor-pointer`}
                  >
                    <BsFilter size={20} />
                    <p className="leading-7 text-sm select-none whitespace-nowrap">
                      Sort By
                    </p>
                  </div>
                </div>
                <input
                  className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full focus:outline-none focus:ring-0"
                  type="text"
                  placeholder="Add a new comment"
                />
                <div className="flex flex-col space-y-8 mt-14">
                  {commentData.map((comment, index) => (
                    <Comment
                      comment={comment}
                      isLastCommment={index + 1 === commentData.length}
                      currentUser={account}
                      onDeleteComment={handleDeleteComment}
                      onEditComment={handleUpdateComment}
                    />
                  ))}
                  {commentData.length === 0 && (
                    <h6 className="text-nero-700 font-Montserrat font-semibold text-lg text-center">
                      No comments at this time.
                    </h6>
                  )}

                  <div className="flex space-x-4 items-center">
                    <img
                      src={DefaultStudentImage}
                      alt="Student"
                      className="w-[54px] h-[54px] rounded-full"
                    />
                    <input
                      className="peer h-full outline-none text-sm text-gray-700 p-0 border-[#cccccc] py-3 px-6 rounded-lg w-full min-h-[45px] focus:outline-none focus:ring-0"
                      type="text"
                      placeholder="Reply"
                      value={replyValue}
                      onChange={handleReplyChange}
                    />
                    <button
                      className="px-8 py-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)] min-h-[45px]"
                      onClick={handleComment}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        <ModalSpeakingScore
          handleModal={handleModalScore}
          isOpen={modalScore}
          data={data}
          classCategory={data.classCategory}
        />
      </>
    )
  )
}

export default DrawerTodo
