import React, { useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import Avatar from '../../../assets/Avatar.svg'
import Badge from '../../../components/Badge'
import axios from '../../../utils/axios'
import ReportListening from '../ReportListening'
import ReportReading from '../ReportReading'
import ReportWriting from '../ReportWriting'
import ReportSpeaking from '../ReportSpeaking'
import ReportSatPage from '../ReportSat'

const ResultReport = () => {
  const location = useLocation()
  const [student, setStudent] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.state.student) {
      navigate(location.state.back)
    } else {
      fetchStudent()
    }

    let category = location.state.category
    category = category.replace(/-/g, ' ').toLowerCase()
    category = category.replace(/\b\w/g, (l) => l.toUpperCase())
  }, [])
  const fetchStudent = async () => {
    await axios
      .get(`/users/${location.state.student}`)
      .then((res) => {
        setStudent(res.data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="flex-col px-10 py-5 mx-auto min-w-screen">
        <button
          className="flex text-[#667085] items-center gap-2  px-4 py-2 rounded-full bg-[#F8F8F8]"
          onClick={() =>
            navigate('/myreport/assignment/student/report', {
              state: {
                studentId: student.id,
                studentName: student.name,
              },
            })
          }
        >
          <IoMdArrowBack />
        </button>

        <div className="flex flex-col items-center">
          <img
            src={Avatar}
            alt="dummy"
            className="w-[100px] rounded-full aspect-square mb-4"
          />
          <h3 className="text-base text-nero-700 font-semibold">
            {student?.name}
          </h3>
          <p className="text-[#667085] text-sm font-normal mt-3 mb-1">
            {student?.email}
          </p>
          {student ? <Badge badge={student.classes} /> : null}
        </div>

        <div className="flex flex-nowrap">
          <div className="w-full">
            {location.state.category === 'listening' ? (
              <>
                <ReportListening
                  testName={'pre-test'}
                  userId={location.state.student}
                />
              </>
            ) : location.state.category === 'reading' ? (
              <>
                <ReportReading
                  testName={'pre-test'}
                  userId={location.state.student}
                />
              </>
            ) : location.state.category === 'writing' ? (
              <>
                <ReportWriting
                  userId={location.state.student}
                  testName={'pre-test'}
                />
              </>
            ) : location.state.category === 'speaking' ? (
              <>
                <ReportSpeaking
                  testName={'pre-test'}
                  userId={location.state.student}
                  userName={location.state.studentName}
                />
              </>
            ) : location.state.category === 'sat' ? (
              <>
                <ReportSatPage
                  testName={'pre-test'}
                  userId={location.state.student}
                  userName={location.state.studentName}
                />
              </>
            ) : (
              <p className="text-nero-500 text-sm text-center">No data</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResultReport
