import React from "react";
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import mainLogo from '../assets/main-logo.svg';

function Navbar() {
  const navigate = useNavigate();
  const Logout = () => {
    axios.delete('/auth')
      .then(() => navigate('/'))
      .catch(console.error);
  }

  return (
    <>
      <motion.nav
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ type: "spring", delay: 0.5 }}
        className='p-3 shadow-lg md:flex md:justify-between bg-white'
      >
        <ul className='md:flex sm:ml-6 z-50'>
          <li className='mx-4 my-2 md:my-0'>
            <span className='text-2x1 cursor-pointer'>
              <a href="/dashboard">
                <img className='h-8 inline' src={mainLogo} alt="logo"/>
              </a>
            </span>
          </li>
        </ul>
        <ul className='ml-10 hidden md:block'>
          <li className='mx-4 my-2 md:my-0'>
            <button
              className="text-md text-[#212121] hover:text-blue-500 duration-500"
              onClick={Logout}
            >
              Log Out
            </button>
          </li>
        </ul>
      </motion.nav>
      <Outlet/>
    </>
  )
}

export default Navbar;
