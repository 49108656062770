import React, {useEffect, useState} from 'react';
import axios from '../utils/axios';
import { useNavigate } from "react-router-dom";
import Layout from '../layout/Layout';
import Loading from '../components/Loading';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

function Login() {
  const [msg, setMsg] = useState('');
  const [type, setType] = useState('password');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState("Admin");


  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const validInput = "w-full bg-[#FAFAFA] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA]";
  const invalidInput = `w-full mt-2 p-2 rounded-lg placeholder-red-700 border
                        border-red-500 outline-none focus:ring-red-500
                        focus:border-red-500 focus:ring-1`;

  const Auth = (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;

    if(tabActive == "Admin") {
      setLoading(true);
      axios.post('/auth/admin/login', {
        email: email.value,
        password: password.value
      })
        .then(() => navigate("/dashboard"))
        .catch((err) => setMsg(err.message))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      axios.post('/auth/mentor/login', {
        email: email.value,
        password: password.value
      })
        .then(() => navigate("/dashboard"))
        .catch((err) => setMsg(err.message))
        .finally(() => setLoading(false));
    }
  };

  const Show = (e) => {
    e.preventDefault();
    setType((type === 'password') ? 'text' : 'password');
  }

  const onHover = "hover:bg-[#37A0F6] hover:text-white hover:cursor-pointer";

  return(
    <div>
      {loading && (<Loading/>)}
      <Layout>
        <div>
          <form onSubmit={Auth} className='max-w-[400px] w-full mx-auto px-8'>
            <p className='text-center text-2xl font-Montserrat font-bold'>Hallo {tabActive} 👋</p>
            <h1 className='text-xs text-[#52525B] mt-3'>Continue to study intensively since studying abroad is getting closer.</h1>
            <div className="flex mb-3 lg:mb-0 mt-4">
              <div
                className={`flex justify-center items-center py-2 border border-[#D0D5DD] rounded-l-lg w-full ${onHover} 
                ${
                  tabActive === "Admin" &&
                  "bg-[#37A0F6] text-white"
                }`}
                onClick={() => setTabActive("Admin")}
              >
                Admin
              </div>
              <div
                className={`flex justify-center items-center border-[1px] border-grey-300 rounded-r-lg w-full ${onHover}
                ${
                  tabActive === "Tutor" &&
                  "bg-[#37A0F6] text-white"
                }`}
                onClick={() => setTabActive("Tutor")}
              >
                Mentor/Tutor
              </div>
            </div>
            <div className='flex flex-col text-grey-400 py-2 mt-5'>
              <label className={msg ? 'text-red-600' : ''}>Email</label>
              <input
                className={msg ? invalidInput : validInput}
                type="email"
                name="email"
                placeholder="Enter email to get started"
                required
              />
            </div>
            <div className='flex flex-col text-grey-400 py-2'>
              <label className={msg ? 'text-red-600' : ''}>Password</label>
              <div className="relative">
                <input
                  className={msg ? invalidInput : validInput}
                  type={type}
                  name="password"
                  placeholder="Enter Password"
                  required
                />
                <button
                  className="absolute top-4 right-3"
                  type="button"
                  onClick={Show}
                >
                  {(type === 'password')
                    ? (<MdVisibilityOff size="1.5rem" />)
                    : (<MdVisibility size="1.5rem" />)
                  }
                </button>
              </div>
              {msg && (
                <span className="font-Montserrat text-xs text-red-600">{msg}</span>
              )}
            </div>
            <div className='flex justify-between text-gray-400 py-2'>
              <p className='flex items-center'><input className='mr-2' type="checkbox"/> Remember Me</p>
              <a href="#forgot" className='text-sm text-[#0886ED]'>Forgot Password?</a>
            </div>
            <button
              className='w-full my-5 py-2 bg-[#064C85] text-white rounded-lg'
              type="submit"
            >
              Login
            </button>
          </form>
        </div>
      </Layout>
    </div>
  )
}

export default Login;
