import { useDispatch, useSelector } from 'react-redux'
import { setBuilderData } from '../slice/question'
import axios from '../utils/axios'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const useQuestionBuilder = () => {
  const dispatch = useDispatch()
  const builders = useSelector((state) => state.question.builders)
  const number = useSelector((state) => state.question.number)
  const [subject, setSubject] = useState(
    useSelector((state) => state.questionDesc.subject)
  )
  const location = useLocation()

  var query = location.pathname
  const lastSegment = query.substring(query.lastIndexOf('/') + 1)

  const addBuilder = (value, id) => {
    let data = []

    if (id) {
      data = [
        ...builders.filter((builder) => builder.section_code === id),
        value,
      ]
      dispatch(setBuilderData({ data, id }))
    } else {
      data = [...builders, value]
      dispatch(setBuilderData({ data }))
    }
  }

  const getAllBuilders = () => {
    return builders
  }

  const deleteBuilder = (index, id, classCategory) => {
    let data
    if (id) {
      data = builders
        .filter((builder) => builder.section_code === id)
        .filter((_, idx) => idx !== index)
      dispatch(setBuilderData({ data, id }))
    } else {
      data = builders.filter((_, idx) => idx !== index)
      dispatch(setBuilderData({ data }))
    }

    let removedData = builders.filter((builder) => builder.section_code === id)[
      index
    ]
    if (removedData?.id) {
      const removedDataId = {
        id: removedData.id,
      }

      axios
        .delete(
          classCategory === 'sat'
            ? `/questions/sat/${subject}/${id}`
            : `/questions/${subject}/${lastSegment}/${classCategory}`,
          {
            data: removedDataId,
          }
        )
        .then((res) => {
          // TODO
          alert('success delete')
          // alert(subject);
          console.log(res)
          //dispatch(setQuestionDesc({}));
        })
        .catch((e) => {
          alert('error')
          console.error(e)
        })
    } else {
      console.log('tidak ada data di hapus')
    }
  }

  const getCurrentNumber = () => {
    return number
  }

  const setBuilderDataById = (index, containerId, builderData) => {
    let data = builders
      .filter((builder) => builder.section_code === containerId)
      ?.map((val, idx) => {
        return idx === index ? builderData : val
      })

    dispatch(setBuilderData({ data, id: containerId }))
  }

  const getBuilderDataById = (id, containerId) => {
    if (containerId) {
      return builders.filter((builder) => builder.section_code === containerId)[
        id
      ]
    }

    return builders[id]
  }

  const toggleEdit = (index, id) => {
    let data
    if (id) {
      data = builders
        .filter((builder) => builder.section_code === id)
        .map((val, idx) => {
          return { ...val, editor: index === idx ? !val.editor : val.editor }
        })
      dispatch(setBuilderData({ data, id }))
    } else {
      data = builders.map((val, idx) => {
        return { ...val, editor: index === idx ? !val.editor : val.editor }
      })
      dispatch(setBuilderData({ data }))
    }
  }

  return {
    addBuilder,
    getAllBuilders,
    deleteBuilder,
    getCurrentNumber,
    getBuilderDataById,
    setBuilderDataById,
    toggleEdit,
  }
}

export default useQuestionBuilder
