import React, { useEffect, useState } from 'react'
import FillTypeQuestion from '../../type/fill'
import { useDispatch, useSelector } from 'react-redux'
import { setLastNumber } from '../../../../slice/status'
import { setQuestionMaxLength } from '../../../../slice/question'
import useQuestionAnswer from '../../../../hooks/useQuestionAnswer'

/*
    @@params = {
        desc : string
        fields : array of field
    }

    field = {
        row : string
        text : string
        data : FillTypeQuestion
    }
*/

const FillOnTableQuestionContainer = (data) => {
  const [preText, setPreText] = useState(JSON.parse(data.pre_text))
  const [postText, setPostText] = useState(JSON.parse(data.post_text))
  const [userAnswer, setUserAnswer] = useState(
    data.userAnswer ? JSON.parse(data.userAnswer) : []
  )

  const [answer, setAnswer] = useState(
    data.answer ? JSON.parse(data.answer) : []
  )

  const [label, setLabel] = useState([])

  const lastNumber = useSelector((state) => state.status.lastNumber)

  useEffect(() => {
    let temp = []
    let dataLabel = JSON.parse(data.label)
    for (let i = 0; i <= data.multi; i++) {
      temp.push({
        number: data.number + i,
        pre_text: preText[i],
        post_text: postText[i],
        label: dataLabel[i],
        userAnswer: userAnswer[i],
        answer: answer[i],
      })
    }
    setLabel(temp)
  }, [lastNumber])

  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: data.question }}></div>
      <table className="fill-table">
        <tbody>
          {label.map((val, idx) => (
            <tr key={idx}>
              <td>{val.label}</td>
              <td>
                <span className="me-2">{val.preText}</span>
                <FillTypeQuestion
                  postText={val.postText}
                  number={val.number}
                  id={data.id}
                  userAnswer={val.userAnswer}
                  answer={val.answer}
                  table={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FillOnTableQuestionContainer
