import React, { useState } from 'react'

import ToolbarViews from './Toolbar/ToolbarViews'
import Toolbar from './Toolbar'
import CardClass from '../../components/Card/Class'
import ListClass from '../../components/Question/widgets/table/listClass'

const Classes = ({
  title,
  subtitle,
  subtitleDescription,
  onClickTab,
  activeTab,
  categories,
  listClasses,
}) => {
  const [tabActiveViews, setTabActiveViews] = useState('grid')

  const handleActiveView = (view) => setTabActiveViews(view)

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg py-6">
      <div className="px-12">
        <h2 className="text-2xl pb-1 text-priblue-500 mb-4">{title}</h2>
        <Toolbar
          onClickTab={onClickTab}
          activeTab={activeTab}
          categories={categories}
        />
        <div className="flex flex-col space-y-6">
          <ToolbarViews
            subtitle={subtitle}
            subtitleDescription={subtitleDescription}
            onClickView={handleActiveView}
            tabActiveViews={tabActiveViews}
          />
          {tabActiveViews === 'grid' && (
            <div className="grid grid-cols-3 gap-4">
              {listClasses.map((classes) => (
                <CardClass data={classes} />
              ))}
            </div>
          )}
          {tabActiveViews === 'table' && <ListClass />}
        </div>
      </div>
    </div>
  )
}

export default Classes
