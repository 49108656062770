import moment from 'moment/moment'

export const remainingTime = (date, daysProp) => {
  const targetDate = moment(date, 'YYYY-MM-DD HH:mm:ss').add(daysProp, 'days')
  const createAt = moment(date)
  const duration = moment.duration(targetDate.diff(createAt))
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return `${days} day : ${hours.toString().padStart(2, '0')} : ${minutes
    .toString()
    .padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
}
