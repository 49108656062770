import { twMerge } from 'tailwind-merge'

const CardHorizontal = ({
  imageIcon,
  iconBgColor,
  title,
  subtitle,
  buttonText,
  onClickButton,
}) => {
  const iconClass = twMerge(
    'flex items-center justify-center w-16 h-16 p-3 rounded-2xl flex-none',
    iconBgColor && iconBgColor
  )

  return (
    <div className="flex flex-col space-y-3 p-4 bg-white border border-[#CCCCCC] rounded-[10px] md:flex-row md:space-y-0 md:space-x-3">
      <div className={iconClass}>
        <img src={imageIcon} alt="Icon" width="w-full h-full" />
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <h6 className="text-nero-700 text-sm font-semibold">{title}</h6>
        <p className="text-neromid text-xs leading-7">{subtitle}</p>
        {buttonText && (
          <button
            className="rounded py-1 px-4 bg-[#EC6A2A] text-white text-xs font-Montserrat ml-auto"
            onClick={onClickButton}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  )
}

export default CardHorizontal
