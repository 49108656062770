export const scoreIndicator = (score) => {
  if (score >= 6.6) {
    return 'Advance'
  }

  if (score >= 4) {
    return 'Intermediate'
  }

  return 'Basic'
}

export const getProficiencyLevel = (type, score) => {
  switch (type) {
    case 'reading':
      switch (true) {
        case score >= 24 && score <= 30:
          return 'Advanced'
        case score >= 18 && score <= 23:
          return 'High-Intermediate'
        case score >= 4 && score <= 17:
          return 'Low-Intermediate'
        case score >= 0 && score <= 3:
          return 'Below Low-Intermediate'
        default:
          return 'Invalid score'
      }
    case 'listening':
      switch (true) {
        case score >= 22 && score <= 30:
          return 'Advanced'
        case score >= 17 && score <= 21:
          return 'High-Intermediate'
        case score >= 9 && score <= 16:
          return 'Low-Intermediate'
        case score >= 0 && score <= 8:
          return 'Below Low-Intermediate'
        default:
          return 'Invalid score'
      }
    case 'speaking':
      switch (true) {
        case score >= 25 && score <= 30:
          return 'Advanced'
        case score >= 20 && score <= 24:
          return 'High-Intermediate'
        case score >= 16 && score <= 19:
          return 'Low-Intermediate'
        case score >= 10 && score <= 15:
          return 'Basic'
        case score >= 0 && score <= 9:
          return 'Below Basic'
        default:
          return 'Invalid score'
      }
    case 'writing':
      switch (true) {
        case score >= 24 && score <= 30:
          return 'Advanced'
        case score >= 17 && score <= 23:
          return 'High-Intermediate'
        case score >= 13 && score <= 16:
          return 'Low-Intermediate'
        case score >= 7 && score <= 12:
          return 'Basic'
        case score >= 0 && score <= 6:
          return 'Below Basic'
        default:
          return 'Invalid score'
      }
    default:
      return 'Invalid type'
  }
}

export const convertScoreToBandScore = (score) => {
  switch (true) {
    case score >= 39:
      return 9
    case score >= 37:
      return 8.5
    case score >= 35:
      return 8
    case score >= 33:
      return 7.5
    case score >= 30:
      return 7
    case score >= 27:
      return 6.5
    case score >= 23:
      return 6
    case score >= 19:
      return 5.5
    case score >= 15:
      return 5
    case score >= 13:
      return 4.5
    case score >= 10:
      return 4
    case score >= 8:
      return 3.5
    case score >= 6:
      return 3
    case score >= 4:
      return 2.5
    default:
      return 0
  }
}
