import Table from '../../../../components/Table'
import Toolbar from './Toolbar'

const TestResult = ({
  onClickTab,
  activeTab,
  categories,
  tableTitle,
  data,
  searchValue,
  onChangeSearchValue,
  isLoading,
}) => {
  const columns = [
    { slug: 'no', name: 'No', orderable: true },
    { slug: 'student', name: 'Student', orderable: true },
    { slug: 'class', name: 'Class', orderable: true },
    { slug: 'test', name: 'Test', orderable: true },
    { slug: 'email', name: 'Email', orderable: true },
    { slug: 'score', name: 'Score', orderable: true },
    { slug: 'status', name: 'Status', orderable: true },
    { slug: 'detail_score', name: 'Detail Score', orderable: false },
  ]

  return (
    <div className="flex flex-col space-y-5 bg-white rounded-lg pt-6">
      <div className="px-12">
        <h2 className="text-2xl pb-1 text-priblue-500 mb-4">Speaking Test</h2>
        <Toolbar
          onClickTab={onClickTab}
          activeTab={activeTab}
          categories={categories}
          searchValue={searchValue}
          onChangeSearchValue={onChangeSearchValue}
        />
        <div className="flex flex-col space-y-2">
          <h2 className="text-lg pb-1 text-priblue-500">{tableTitle}</h2>
          <p className="text-sm font-normal text-[#808080]">
            {/* Lorem ipsum dolor sit amet lorem ipsum dolor */}
          </p>
        </div>
      </div>
      <Table columns={columns} data={data} isLoading={isLoading} />
    </div>
  )
}

export default TestResult
