import React from "react";
import useQuestionAnswer from "../../../hooks/useQuestionAnswer";

/*
  @@params{
    number : int
    question : string
    limit = int
  }
*/

const AnswerTypeQuestion = ({ number, question, limit = 99 }) => {
  const { getAnswer, setAnswer } = useQuestionAnswer();

  const inputHandler = (e) => {
    let val = e.target.value;
    val = val.split(" ");
    if (val.length <= limit) {
      setAnswer(number, e.target.value);
    }
  };

  return (
    <div className="flex my-4" id={`q${number}`}>
      <span className="mr-3">{number}.</span>
      <div className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        <section className="input-text">
          <span>Answer </span>
          <input
            type="text"
            name={`question${number}`}
            value={getAnswer(number) || ""}
            onChange={inputHandler}
          />
        </section>
      </div>
    </div>
  );
};

export default AnswerTypeQuestion;
