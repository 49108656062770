import React from 'react'
import FillTypeQuestion from '../../type/fill'

/*
    @@params = {
        desc : string
        fields : array of field
    }

    field = {
        row : string
        text : string
        data : FillTypeQuestion
    }
*/

const FillOnTableQuestionContainer = (data) => {
  const { question, label, number, postText, preText } = data

  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: question }} className="mb-4" />
      <table className="fill-table">
        <tbody>
          {label?.map((val, idx) => (
            <tr key={idx}>
              <td>{val}</td>
              <td>
                <span className="me-2">{preText ? preText[idx] : ''}</span>
                <FillTypeQuestion
                  postText={postText ? postText[idx] : ''}
                  number={number + idx}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FillOnTableQuestionContainer
