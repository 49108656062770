import React from "react";
import Sidebar from "../../../components/Sidebar";
import { TabsWriting } from "../../../components/Tabs/writing";

const Writing = () => {
  return (
    <div>
      <div className="grid grid-cols-10">
        <div>
          <Sidebar />
        </div>

        <div className="col-start-3 col-span-6 px-10">
          <TabsWriting />
        </div>
      </div>
    </div>
  );
};

export default Writing;
