import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Assignment from '../../components/Assignment/Assignment'
import { BiSearch } from 'react-icons/bi'
import Dropdown from '../../components/Dropdown'
import Tabs from '../../components/Tabs'
import { BsFilter } from 'react-icons/bs'
import axios from '../../utils/axios'
import MyClass from '../../components/MyClass'

const Report = () => {
  const onHover = 'hover:bg-[#F9FAFB] hover:cursor-pointer'

  const [isFilterClicked, setIsFilterClicked] = useState(false)
  const [tabActive, setTabActive] = useState('assignment')

  const tabItems = [
    {
      slug: 'assignment',
      name: 'Assignments',
    },
    {
      slug: 'myclass',
      name: 'My Class',
    },
  ]

  const handleClickTab = (item) => {
    setTabActive(item.slug)
  }

  return (
    <div>
      <div className="flex lg:grid lg:grid-cols-10">
        <div>
          <Sidebar />
        </div>
        <div className="w-full flex lg:col-start-3 lg:col-end-12 mt-2 lg:mt-10">
          <div className="min-h-screen px-0 md:px-10 text-nero-700">
            <h2 className="text-lg pb-1">Reports</h2>
            <p className="text-sm font-normal text-[#808080]">
              Ini adalah test yang dikerjakan para siswa
            </p>
            <div className="flex lg:flex-row flex-col justify-between mt-5 w-full">
              <Tabs
                tabItems={tabItems}
                activeTab={tabActive}
                handleClickTab={handleClickTab}
              />
            </div>
            {tabActive == 'assignment' ? <Assignment /> : <MyClass />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
