import React from 'react'
import SelectTypeQuestionAnswer from '../../type/select'
import SelectTypeQuestion from '../../../Question/type/select'
import SelectQuestionList from './list'
import SelectQuestionTruth from './truth'
import useQuestionAnswer from '../../../../hooks/useQuestionAnswer'

/*
    @@params{
        desc : string
        choices : array of string
        questions : array of SelectTypeQuestion
        image : string
    }
*/

const SelectQuestionContainer = (data) => {
  const { setQuestionIdEach } = useQuestionAnswer()
  let {
    question,
    choices,
    number,
    list,
    post_text,
    type,
    id,
    multi,
    userAnswer,
    answer,
  } = data
  post_text = JSON.parse(post_text)
  choices = JSON.parse(choices)
  if (userAnswer) {
    userAnswer = JSON.parse(userAnswer)
  }
  answer = JSON.parse(answer)
  // setQuestionIdEach(number, id);
  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: question }} className="mb-4" />
      {parseInt(type) === 4 ? (
        list && <SelectQuestionList lists={choices} />
      ) : (
        <SelectQuestionTruth />
      )}
      {post_text.map((val, idx) => (
        <div>
          {userAnswer == undefined ? (
            <SelectTypeQuestionAnswer
              postText={val}
              key={idx}
              choices={choices}
              number={number + idx}
              type={type}
              id={id}
              multi={multi}
              answer={answer[idx]}
            />
          ) : (
            <SelectTypeQuestionAnswer
              postText={val}
              key={idx}
              choices={choices}
              number={number + idx}
              type={type}
              id={id}
              multi={multi}
              answer={answer[idx]}
              userAnswer={userAnswer[idx]}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default SelectQuestionContainer
