import {IoIosArrowDown} from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Fragment, useState } from "react";

const SubMenu = ({data, depthLevel}) => {
  const { pathname } = useLocation();
  const [SubMenuOpen, setSubMenuOpen] = useState(false);
  return (
    <>
      <li className={`link ${pathname.includes(data.name) && 'text-blue-600'}`}
      onClick={()=> setSubMenuOpen(!SubMenuOpen)}
      >
        {
          data.menus != "" ?
          <Fragment>
            <p className="capitalize flex-1 p-2">{data.name}</p>
            <IoIosArrowDown className={`${SubMenuOpen && 'rotate-180'} duration-200`} />
          </Fragment>
          : 
          <NavLink to={`/${data.link}`} className={({ isActive }) => (isActive ? 'w-full bg-[#F0F7FF] p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 text-[#064C85] font-bold' : 'w-full p-2.5 flex gap-6')}>{data.name}</NavLink>
        }

      </li>
      <motion.ul 
      animate={
        SubMenuOpen ? {
          height: "fit-content"
        }:{
          height: 0
        }
      }
      
      className="flex flex-col pl-5 text-[0.8rem] font-normal overflow-hidden h-0">
        {
          data.menus.map((submenu, idx)=>{
            return depthLevel > 0 ?
            <li key={idx} className={`link ${pathname.includes(data.name) && 'text-blue-600'}`}>
              <p className="capitalize flex-1">{data.name}</p>
              <IoIosArrowDown />
            </li>
            :
            <li key={idx}>
              <NavLink to={`/${data.link}/${submenu.link}`} className={({ isActive }) => (isActive ? 'w-full bg-[#F0F7FF] p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 text-[#064C85] font-bold' : 'w-full p-2.5 flex gap-6')}>{submenu.name}</NavLink>
            </li>
          })
        }
      </motion.ul>
    </>
  );
};

export default SubMenu;