import { twMerge } from 'tailwind-merge'
import GridIcon from '../../../assets/icons/grid.svg'
import RowsIcon from '../../../assets/icons/rows.svg'

const ToolbarViews = ({
  subtitle,
  subtitleDescription,
  tabActiveViews,
  onClickView,
}) => {
  return (
    <div className="flex justify-between">
      <div>
        <h2 className="text-lg pb-1 text-priblue-500">{subtitle}</h2>
        <p className="text-sm font-normal text-[#808080]">
          {subtitleDescription}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <p className="text-nero-700 text-sm">View ips</p>
        <div className="flex space-x-2 border border-[#cccccc] rounded-lg py-2 px-4">
          <div
            className={twMerge(
              'p-1 rounded border border-[#cccccc] cursor-pointer transition duration-200 hover:bg-[#EAECF0]',
              tabActiveViews === 'grid' && 'bg-[#EAECF0]'
            )}
            onClick={() => onClickView('grid')}
          >
            <img src={GridIcon} alt="Grid Icon" />
          </div>
          <div
            className={twMerge(
              'p-1 rounded border border-[#cccccc] cursor-pointer transition duration-200 hover:bg-[#EAECF0]',
              tabActiveViews === 'table' && 'bg-[#EAECF0]'
            )}
            onClick={() => onClickView('table')}
          >
            <img src={RowsIcon} alt="Table Icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolbarViews
