import { useEffect, useState } from 'react'
import AudioForm from '../../form/audio'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'
import { Icon } from '@iconify/react'

const AudioBuilder = ({ idx, containerId, classCategory }) => {
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)

  const [textaudio, setTextaudio] = useState('')

  const handleChangeAudioFile = (e) => {
    addAudioFiles(e.target.files[0])
  }

  const deleteHandler = () => {
    deleteBuilder(idx, containerId, classCategory)
  }

  const addAudioFiles = (audioFile) => {
    setBuilderDataById(idx, containerId, { ...data, files: audioFile })
  }

  const getAudioName = () => {
    if (data.audio_name && !data.files) {
      return data.audio_name
    }

    if (data.files) {
      return data.files.name
    }
  }

  return (
    <div className="border-[1px] border-gray-300 p-4 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Audio Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={deleteHandler}
        >
          Hapus
        </button>
      </header>
      <AudioForm
        id={idx}
        title="Audio"
        audioName={getAudioName()}
        onChangeAudio={handleChangeAudioFile}
        audioText={textaudio}
        setAudioText={setTextaudio}
        noAudioText
      />
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default AudioBuilder
