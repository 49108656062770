import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import Layout from '../layout/Layout';
import Loading from './Loading';
import { Url } from '../global';

const Register = () => {
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validPassword = "w-full bg-[#FAFAFA] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA]";
  const invalidPassword = "w-full mt-2 p-2 rounded-lg placeholder-red-700 border border-red-500 outline-none focus:ring-red-500 focus:border-red-500 focus:ring-1";

  const Register = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${Url}/regbyemail`, {
        email: location.state.email,
        password: password, 
        confPassword: confPassword,
        verify_code: location.state.verify
      });
      navigate("/notify", {status: 1});
      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      if(error.response){
        setMsg(error.response.data.msg);
        setStatus(error.response.status);
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    }
  }

  return (
    <div>
    {loading ? (
      <Loading/>
    ) : (
      <Layout>
        <form onSubmit={Register} className='max-w-[400px] w-full mx-auto px-8'>
          <div className='flex flex-col text-grey-400 py-2'>
            <p className='text-center text-2xl font-Montserrat'>Create New Password</p>
            <h1 className='text-xs text-[#52525B] mt-3'>Create new password</h1>      
            <div className='flex flex-col text-grey-400 py-2 mt-5'>    
              <label className={status == 400 ? 'text-red-600' : ''}>New Password</label>
              <input className={status == 400 ? invalidPassword : validPassword} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
            </div>
            <div className='flex flex-col text-grey-400 py-2 mt-5'>  
              <label className={status == 400 ? 'text-red-600' : ''}>Confirm Password</label>
              <input className={status == 400 ? invalidPassword : validPassword} type="password" placeholder="Password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} required/>

              <span className="font-Montserrat text-xs text-red-600">{msg}</span>
              <button className='w-full my-5 py-2 bg-[#064C85] text-white rounded-lg'>Login</button>
            </div>
          </div>
        </form>
      </Layout>
    )
    }
    </div>
  )
}

export default Register