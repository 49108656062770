import { useState } from 'react'
import { useSelector } from 'react-redux'
import Drop from '../Question/widgets/drop'
import Mark from '../Question/widgets/mark'
import Preview from '../Question/widgets/preview'
import DescriptionSpeaking from '../Question/widgets/description/speaking'

const TabsSpeaking = ({
  selectedClassCategories,
  setSelectedClassCategories,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const description = useSelector((s) => s.questionDesc)

  const setTabIndex = (idx) => {
    // TODO: ui
    if (!description.id && idx > 0)
      return alert('Pilih subject terlebih dahulu')

    setActiveTabIndex(idx)
  }

  const handleClassCategories = (event) =>
    setSelectedClassCategories(event.target.value)

  const tabsData = [
    {
      label: 'Description',
      content: (
        <DescriptionSpeaking
          onChangeClassCategories={handleClassCategories}
          selectedClassCategories={selectedClassCategories}
        />
      ),
    },
    {
      label: 'Add Items',
      content: <Drop id={1} classCategory={selectedClassCategories} />,
    },
    {
      label: 'Preview',
      content: <Preview />,
    },
    {
      label: 'Explanations',
      content: <Mark />,
    },
  ]

  return (
    <div>
      <div className="flex space-x-1">
        {tabsData.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`py-2 border-solid text-xs border-2 bg-[#EFF2F6] px-5 transition-colors duration-300 ${
                idx === activeTabIndex
                  ? 'bg-[#FFFFFF] border-b-0'
                  : 'border-transparen'
              }`}
              onClick={() => setTabIndex(idx)}
            >
              {tab.label}
            </button>
          )
        })}
      </div>
      <div className="py-4">
        <section>{tabsData[activeTabIndex].content}</section>
      </div>
    </div>
  )
}

export default TabsSpeaking
