import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg'
import StudentImage from '../../../assets/avatar.jpg'

const StudentProfile = ({ onClickBack, studentName }) => {
  return (
    <div className="flex flex-col items-start space-y-6 w-full">
      <div
        className="ml-32 py-3 px-5 bg-[#F8F8F8] rounded-full cursor-pointer transition duration-200 hover:bg-nero-400"
        onClick={onClickBack}
      >
        <img src={ArrowLeftIcon} alt="Back Icon" />
      </div>
      <div className="flex items-center px-32 py-4 bg-white space-x-6 w-full">
        <img src={StudentImage} alt="Student" className="w-14 h-14" />
        <h2 className="text-nero-700 font-semibold text-lg font-Montserrat">
          {studentName}
        </h2>
      </div>
    </div>
  )
}

export default StudentProfile
