import React, { useState } from 'react'
import { Dialog, DialogHeader, DialogBody } from '@material-tailwind/react'
import moment from 'moment/moment'

import CloseIcon from '../../../assets/icons/close.svg'

import { scoreIndicator } from '../../../utils/scoreIndicators'
import { countWords } from '../../../utils/words'

const TestStats = ({
  totalScore,
  timeTaken,
  writingAnswer,
  onClickReviewScore,
  onClickReview,
  userData,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(!open)

  return (
    <>
      <div className="basis-72 shrink-0">
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="py-2 px-3 rounded-[4px] bg-[#F9F5FF] text-[#6941C6] font-semibold">
              Writing Test Master
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col space-y-4 grow">
                <div className="flex flex-col space-y-1">
                  <p className="text-neromid text-xs">Total Score</p>
                  <p className="text-nero-700 font-bold">{totalScore}</p>
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-neromid text-xs">Word Count Task 1</p>
                  <p className="text-nero-700 font-bold">
                    {countWords(writingAnswer?.answer_1 || '')}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-4 grow">
                <div className="flex flex-col space-y-1">
                  <p className="text-neromid text-xs">Time Taken</p>
                  <p className="text-nero-700 font-bold">{timeTaken}</p>
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-neromid text-xs">Word Count Task 2</p>
                  <p className="text-nero-700 font-bold">
                    {countWords(writingAnswer?.answer_2 || '')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <button
              className="w-full p-3 rounded-[4px] bg-[#EC6A2A] text-xs text-white shadow-[0px_10px_20px_0px_rgba(236,_106,_42,_0.20)]"
              onClick={onClickReview}
            >
              Review
            </button>
            <button
              className="w-full p-3 rounded-[4px] border border-[#EC6A2A] text-xs text-[#EC6A2A]"
              onClick={() =>
                totalScore > 0 ? handleOpen() : onClickReviewScore()
              }
            >
              {totalScore > 0 ? 'Detail Score' : 'Submit Score'}
            </button>
            {totalScore > 0 && (
              <button
                className="w-full p-3 rounded-[4px] border border-[#EC6A2A] text-xs text-[#EC6A2A]"
                onClick={onClickReviewScore}
              >
                Edit Score
              </button>
            )}
          </div>
        </div>
      </div>
      <Dialog open={open} handler={handleOpen} size="xl" className="p-10">
        <DialogHeader className="flex items-center justify-between w-full mb-16">
          <h3 className="text-[#094A78] text-2xl font-semibold font-Montserrat">
            Detail Score
          </h3>
          <div
            className="flex items-center justify-center bg-[#0000000A] w-6 h-6 rounded-full"
            onClick={handleOpen}
          >
            <img src={CloseIcon} alt="Close Icon" className="cursor-pointer" />
          </div>
        </DialogHeader>
        <DialogBody className="h-[400px] overflow-y-auto">
          <div className="flex justify-evenly mb-16">
            <div className="flex flex-col justify-center items-center border-[24px] border-[#DEE9FF] rounded-full w-40 h-40">
              <h5 className="text-[#18181B] font-Montserrat text-[28px] font-bold">
                {totalScore}
              </h5>
              <p className="text-[#18181B] text-sm ">
                {scoreIndicator(totalScore)}
              </p>
            </div>
            <div className="flex flex-col items-stretch justify-between">
              <div>
                <p className="text-[#505050] text-sm">Test Oleh</p>
                <p className="text-[#505050] font-bold">
                  {userData.studentName}
                </p>
              </div>
              <div>
                <p className="text-[#505050] text-sm">Mentor</p>
                <p className="text-[#505050] font-bold">
                  {userData.mentorName}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-stretch justify-between">
              <div>
                <p className="text-[#505050] text-sm">Created Score</p>
                <p className="text-[#505050] font-bold">
                  {moment(userData.createdScore).format('D MMM YYYY, h:mm A')}
                </p>
              </div>
              <div>
                <p className="text-[#505050] text-sm">Due Date</p>
                <p className="text-[#505050] font-bold">
                  {moment(userData.createdAt)
                    .add(7, 'days')
                    .format('D MMM YYYY, h:mm A')}
                </p>
              </div>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  Evaluation score
                </th>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  TA
                </th>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  CC
                </th>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  RR
                </th>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  GRA
                </th>
                <th className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10 font-bold">
                  Overall
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  Task I
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.ta || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.cc || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.rr || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.gra || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10 font-bold">
                  {writingAnswer?.overall || ''}
                </td>
              </tr>
              <tr>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  Task II
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.ta_2 || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.cc_2 || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.rr_2 || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10">
                  {writingAnswer?.gra_2 || ''}
                </td>
                <td className="text-[#505050] text-xl text-left border-b border-[#B9B9B9] pt-8 pb-10 font-bold">
                  {writingAnswer?.overall_2 || ''}
                </td>
              </tr>
            </tbody>
          </table>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default TestStats
