import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import axios from '../utils/axios'
import Sidebar from '../components/Sidebar'
import IcStudent from '../assets/Total Student.png'
import IcClass from '../assets/Total Class.png'
import IcPending from '../assets/Writing Pending.png'
import CardClass from '../components/Dashboard/CardClass'
import ListDeadlines from '../components/Dashboard/ListDeadlines'
import IcTalk from '../assets/talk.svg'
import { Icon, listIcons } from '@iconify/react'
import ModalSpeakingScore from '../components/Dashboard/ModalSpeakingScore'
import { useNavigate } from 'react-router'
import Drawer from './Todo/Drawer'

const Dashboard = () => {
  const account = useSelector((s) => s.account)
  const [classes, setClasses] = useState(null)
  const [modalScore, setModalScore] = useState(false)
  const [greeting, setGreeting] = useState('')
  const [count, setCount] = useState('')
  const [pendingWriting, setPendingWriting] = useState('')
  const [selectedUserAnswer, setSelectedUserAnswer] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchClass()
    handleGreeting()
    fetchCount()
  }, [])

  const handleGreeting = async () => {
    var today = new Date()
    var curHr = today.getHours()
    if (curHr < 12) {
      setGreeting('Good Morning')
    } else if (curHr < 18) {
      setGreeting('Good Afternoon')
    } else {
      setGreeting('Good Evening')
    }
  }

  const fetchClass = async () => {
    axios
      .get(`/users/${account.userId}/classes`)
      .then((res) => {
        const result = res.data.results
        setClasses(result)
        fetchPendingWriting(result)
      })
      .catch(console.error)
  }

  const fetchCount = async () => {
    axios.get('count/all').then((res) => {
      setCount(res.data.results)
    })
  }

  const fetchPendingWriting = async (dataclass) => {
    await axios.get(`/answers/writing`).then((res) => {
      const result = res.data.results

      let arrListAnswer = []
      result.map((data) => {
        data.answer_details.map((data2) => {
          if (data2.writing_answer && data2.writing_answer.grand_score <= 0) {
            data2.user.classes.map((item) => {
              var found = dataclass.find((element) => {
                return element.id == item.id
              })
              if (found != undefined) {
                arrListAnswer.push({
                  category: data.category,
                  test: data.subject,
                  score: data2.writing_answer.grand_score,
                  studentName: data2.user.name,
                  writingAnswer: data2.writing_answer,
                  classes: data2.user.classes,
                  userId: data2.user.id,
                  created_at: data2.created_at,
                })
              }
            })
          }
        })
      })

      setPendingWriting(arrListAnswer)
    })
  }

  const handleSubmitSpeaking = async () => {
    setModalScore(true)
  }

  const handleModalScore = () => {
    setModalScore((prevState) => !prevState)
  }

  const handleClass = (idClass, jadwalstart, jadwalend) => {
    navigate('/myreport/student', {
      state: {
        idClass: idClass,
        jadwalstart: jadwalstart,
        jadwalend: jadwalend,
      },
    })
  }

  const handleDeadlines = (data) => {
    setSelectedUserAnswer({
      createdAt: data.created_at,
      studentName: data.studentName,
      testName: data.test,
      category: data.category,
      totalScore: data.score,
      writingAnswer: data.writingAnswer,
      userId: data.userId,
    })
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => setIsDrawerOpen(false)

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ type: 'spring', delay: 0.5 }}
      >
        <ModalSpeakingScore
          handleModal={handleModalScore}
          isOpen={modalScore}
        />
        <div className="flex flex-row">
          <div className="w-1/4">
            <Sidebar />
          </div>
          <div className="w-3/4 mt-2 lg:mt-10 flex flex-row">
            <div className="w-4/6 flex flex-col mr-5">
              <div className="bg-white border rounded-lg flex flex-col shadow-md mt-3 p-8">
                <p className="text-xl text-[#064C85]">
                  {greeting}, {account.name}
                </p>
                <p className="text-sm text-[#292929] mt-3">
                  Ini progress pekerjaanmu, semangat~.
                </p>
                <hr className="mt-3" />
                <div className="flex flex-row mt-3 items-center">
                  <div className="flex flex-row items-center w-1/3">
                    <img src={IcStudent} className="w-12 h-12" />
                    <div className="flex flex-col ml-3">
                      <p className="text-xs text-[#808080] font-bold">
                        Total Students
                      </p>
                      <p className="text-xl text-[#064C85]">
                        {count ? count.students : null}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-1/3">
                    <img src={IcClass} className="w-12 h-12" />
                    <div className="flex flex-col ml-3">
                      <p className="text-xs text-[#808080] font-bold">
                        Total Class Active
                      </p>
                      <p className="text-xl text-[#064C85]">
                        {classes ? classes.length : null}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-1/3">
                    <img src={IcPending} className="w-12 h-12" />
                    <div className="flex flex-col ml-3">
                      <p className="text-xs text-[#808080] font-bold">
                        Writing Pending
                      </p>
                      <p className="text-xl text-[#064C85]">
                        {count ? count.pending : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <p className="text-xl text-[#064C85]">My Class</p>
                <p className="text-xs text-[#808080] mt-3">
                  Saat ini, kamu menjadi tutor dalam kelas-kelas berikut
                </p>
              </div>

              <div className="flex max-w-full overflow-auto gap-4">
                {classes
                  ? classes.map((data, idx) => (
                      <div key={idx} className="min-w-[300px]">
                        <CardClass
                          name={data.name}
                          start={data.start_date}
                          end={data.end_date}
                          student={data.users}
                          mentor={data.users}
                          onClick={() =>
                            handleClass(data.id, data.start_date, data.end_date)
                          }
                        />
                      </div>
                    ))
                  : null}
              </div>

              <div className="mt-10">
                <p className="text-xl text-[#064C85]">Reminder. DEADLINE!</p>
                <p className="text-xs text-[#808080] mt-3">
                  Masih ada beberapa tes yang belum kamu nilai nih!
                </p>
              </div>

              <div className="mt-2 pb-10">
                {pendingWriting
                  ? pendingWriting.map((data, idx) => (
                      <div className="mt-2" key={idx}>
                        <ListDeadlines
                          subject={data.test}
                          data={data}
                          onClick={() => handleDeadlines(data)}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className="w-2/6 flex flex-col mr-10">
              <div className="bg-white border rounded-lg flex flex-row mt-3 p-4">
                <div className="w-full flex flex-col">
                  <p className="text-xl text-[#064C85]">Speaking</p>
                  <button
                    className="border border-[#064C85] rounded-md text-sm text-[#064C85] p-3 mt-5"
                    onClick={handleSubmitSpeaking}
                  >
                    + Submit Score
                  </button>

                  <div
                    className="flex flex-row mt-4 border p-4 rounded-lg items-center cursor-pointer relative"
                    onClick={() => navigate('/todo/speaking')}
                  >
                    <img src={IcTalk} className="w-10" />
                    <div className="flex flex-col ml-2">
                      <p className="text-md text-[#292929]">Speaking Test</p>
                      <p className="text-xs text-[#808080] mt-1">
                        Masih ada beberapa tes yang belum kamu nilai nih!
                      </p>
                    </div>
                    <Icon
                      icon="ep:arrow-right-bold"
                      className="text-sm absolute right-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <Drawer
        isOpen={isDrawerOpen}
        data={selectedUserAnswer}
        onClose={handleDrawerClose}
      />
    </>
  )
}

export default Dashboard
