import React from 'react'
import MultipleQuestionBuilder from './form/multiple'
import MultipleQuestionPreview from './preview/multiple'
import CheckboxQuestionBuilder from './form/checkbox'
import CheckboxQuestionPreview from './preview/checkbox'
import AnswerQuestionBuilder from './form/answer'
import AnswerQuestionPreview from './preview/answer'
import SelectQuestionBuilder from './form/select'
import SelectQuestionPreview from './preview/select'
import SelectTruthQuestionBuilder from './form/selectTruth'
import SelectTruthQuestionPreview from './preview/selectTruth'
import FillQuestionBuilder from './form/fill'
import FillQuestionPreview from './preview/fill'
import FillCardQuestionBuilder from './form/fillCard'
import FillCardQuestionPreview from './preview/fillCard'
import FillTableQuestionPreview from './preview/fillTable'
import FillTableQuestionBuilder from './form/fillTable'
import AudioBuilder from './form/audio'
import AudioPreview from './preview/audio'
import RecordingBuilder from './form/recording'
import RecordingPreview from './preview/recording'

const QuestionBuilder = ({ type, editor, idx, containerId, classCategory }) => {
  switch (parseInt(type)) {
    case 1:
      return editor ? (
        <MultipleQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <MultipleQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 2:
      return editor ? (
        <CheckboxQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <CheckboxQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 3:
      return editor ? (
        <AnswerQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <AnswerQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 4:
      return editor ? (
        <SelectQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <SelectQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 5:
      return editor ? (
        <SelectTruthQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <SelectTruthQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 6:
      return editor ? (
        <FillQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <FillQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 7:
      return editor ? (
        <FillCardQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <FillCardQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 8:
      return editor ? (
        <FillTableQuestionBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <FillTableQuestionPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 9:
      return editor ? (
        <AudioBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <AudioPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    case 10:
      return editor ? (
        <RecordingBuilder
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      ) : (
        <RecordingPreview
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
        />
      )
    default:
  }
}

export default QuestionBuilder
