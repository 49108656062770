import React, { useState } from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import { Checkbox, Typography } from '@material-tailwind/react'

const CheckboxQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const [singleNumber, setSingleNumber] = useState(false)
  const [totalMulti, setTotalMulti] = useState(0)
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    choices = [],
    answer = [],
    question,
    multi = -1,
    number,
  } = data

  const handleSingleNumber = (e) => {
    if (e.target.checked) {
      setSingleNumber(true)
      setBuilderDataById(idx, containerId, {
        ...data,
        answer,
        multi: 0,
      })
    } else {
      let newMulti = -1
      answer.forEach((val, idx) => {
        if (val) {
          newMulti += 1
        }
      })

      setSingleNumber(false)
      setBuilderDataById(idx, containerId, {
        ...data,
        answer,
        multi: newMulti,
      })
    }
  }

  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  const addChoices = () => {
    const newChoices = [...choices, 'New Option']
    const newAnswers = [...answer, false]
    setBuilderDataById(idx, containerId, {
      ...data,
      choices: newChoices,
      answer: newAnswers,
    })
  }

  const deleteChoices = (delIdx) => {
    const newChoices = choices.filter((_, idx) => idx !== delIdx)
    setBuilderDataById(idx, containerId, { ...data, choices: newChoices })
  }

  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const setAnswer = (optIdx) => {
    let newMulti
    const newAnswers = answer.map((val, idx) => {
      if (idx === optIdx) {
        newMulti = multi + (val ? -1 : 1)
        return !val
      } else return val
    })

    setBuilderDataById(idx, containerId, {
      ...data,
      answer: newAnswers,
      multi: singleNumber ? 0 : newMulti,
    })
  }

  const setOption = (e, optIdx) => {
    const newChoices = choices?.map((val, idx) => {
      if (idx === optIdx) {
        return e.target.value
      } else return val
    })
    setBuilderDataById(idx, containerId, { ...data, choices: newChoices })
  }

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Checkbox Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={() => deleteBuilder(idx, containerId, classCategory)}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">
        Number : {number} {multi > 0 ? '- ' + (number + multi) : ''}{' '}
      </section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={question}
          setValue={setQuestion}
          idx={`${containerId}-${idx}`}
        />
      </section>
      <section className="mb-4">
        <Checkbox
          label={
            <div>
              <Typography color="blue-gray" className="font-medium">
                Single or Multiple Number
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                If checked, it will count as only one number regardless of the
                number of correct answers. If not checked, each correct answer
                will count as one number.
              </Typography>
            </div>
          }
          onChange={handleSingleNumber}
          checked={singleNumber}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        {choices?.map((val, idx) => (
          <div className="mb-1 flex justify-between" key={idx}>
            <section className="flex gap-1 items-center my-1">
              <span className="w-8 h-8 me-4 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
                {String.fromCharCode(65 + idx)}
              </span>
              <input
                type="checkbox"
                className="w-5 h-5 cursor-pointer me-1"
                value={val}
                checked={answer[idx]}
                onChange={() => setAnswer(idx)}
              ></input>
              <input
                type="text"
                value={val}
                onChange={(e) => setOption(e, idx)}
              ></input>
            </section>
            <button
              className="flex gap-1 items-center text-gray-400"
              onClick={() => deleteChoices(idx)}
            >
              <Icon icon="ion:trash-outline" />
              <span>Delete</span>
            </button>
          </div>
        ))}
        <button
          className="px-3 py-2 rounded-md border-[1px] border-blue-600 mt-4 hover:border-blue-300"
          onClick={addChoices}
        >
          + Add Option
        </button>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default CheckboxQuestionBuilder
