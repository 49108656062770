import React from 'react'
import ChevronUpLightIcon from '../../../assets/icons/chevron-up-light.svg'
import ChevronUpDarkIcon from '../../../assets/icons/chevron-up-dark.svg'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'

const IconLight = ({ open }) => (
  <img
    src={ChevronUpLightIcon}
    className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    alt="Chevron Light Colored Icon"
  />
)

const IconDark = ({ open }) => (
  <img
    src={ChevronUpDarkIcon}
    className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    alt="Chevron Dark Colored Icon"
  />
)

const TaskAccordion = ({ answer1, answer2, task1, task2 }) => {
  const initialAccordionStatus = {
    task1: true,
    task2: true,
    answer1: true,
    answer2: true,
  }

  const [accordionStatus, setAccordionStatus] = React.useState(
    initialAccordionStatus
  )

  const toggleOpenAcc = (key) => {
    setAccordionStatus((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  const handleOpenTask1 = () => toggleOpenAcc('task1')
  const handleOpenTask2 = () => toggleOpenAcc('task2')
  const handleOpenAnswer1 = () => toggleOpenAcc('answer1')
  const handleOpenAnswer2 = () => toggleOpenAcc('answer2')

  return (
    <div>
      <h6>Results</h6>
      <div className="border border-[#E4E4E7] rounded-lg p-14">
        <div className="flex flex-col space-y-6">
          {task1 && (
            <Accordion
              open={accordionStatus['task1']}
              icon={<IconLight open={accordionStatus['task1']} />}
              className="flex flex-col space-y-6"
            >
              <AccordionHeader
                onClick={handleOpenTask1}
                className="flex justify-between bg-[#3C64B1] rounded-[4px] text-white font-semibold py-3 px-6 cursor-pointer text-base hover:text-white"
              >
                Writing Task 1
              </AccordionHeader>
              <AccordionBody
                className="bg-[#F5F5F5] rounded-[4px] py-7 px-8 flex flex-col space-y-6 custom-accordion__body"
                dangerouslySetInnerHTML={{ __html: task1 }}
              ></AccordionBody>
            </Accordion>
          )}
          {answer1 && (
            <Accordion
              open={accordionStatus['answer1']}
              icon={<IconDark open={accordionStatus['answer1']} />}
              className="px-8"
            >
              <AccordionHeader
                onClick={handleOpenAnswer1}
                className="text-[#284664] text-base font-semibold font-Montserrat pt-0"
              >
                Answer
              </AccordionHeader>
              <AccordionBody
                className="custom-accordion__body"
                dangerouslySetInnerHTML={{ __html: answer1 }}
              ></AccordionBody>
            </Accordion>
          )}
          {task2 && (
            <Accordion
              open={accordionStatus['task2']}
              icon={<IconLight open={accordionStatus['task2']} />}
              className="flex flex-col space-y-6"
            >
              <AccordionHeader
                onClick={handleOpenTask2}
                className="flex justify-between bg-[#3C64B1] rounded-[4px] text-white font-semibold py-3 px-6 cursor-pointer text-base hover:text-white"
              >
                Writing Task 2
              </AccordionHeader>
              <AccordionBody
                className="bg-[#F5F5F5] rounded-[4px] py-7 px-8 flex flex-col space-y-6 custom-accordion__body"
                dangerouslySetInnerHTML={{ __html: task2 }}
              ></AccordionBody>
            </Accordion>
          )}
          {answer2 && (
            <Accordion
              open={accordionStatus['answer2']}
              icon={<IconDark open={accordionStatus['answer2']} />}
              className="px-8"
            >
              <AccordionHeader
                onClick={handleOpenAnswer2}
                className="text-[#284664] text-base font-semibold font-Montserrat pt-0"
              >
                Answer
              </AccordionHeader>
              <AccordionBody
                className="custom-accordion__body"
                dangerouslySetInnerHTML={{ __html: answer2 }}
              ></AccordionBody>
            </Accordion>
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskAccordion
