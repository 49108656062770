import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import axios from '../../../utils/axios'

import StudentProfile from './StudentProfile'
import SelectTest from './SelectTest'
import TaskAccordion from './TaskAccordion'
import TestStats from './TestStats'
import Loading from '../../../components/Loading'
import WritingModalAddScore from '../../../components/Writing/ModalAddScore'
import WritingModalSubmit from '../../../components/Writing/ModalSubmit'

const WritingResult = () => {
  const account = useSelector((s) => s.account)
  let navigate = useNavigate()
  let location = useLocation()
  let { testName, userId } = useParams()
  const [writingResultData, setWritingResultData] = useState({})
  const [writingScoredata, setWritingScoreData] = useState({})
  const [questionResultData, setQuestionResultData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isModalAddScoreOpen, setIsModalAddScoreOpen] = useState(false)
  const [isModalSubmitConfirmationOpen, setIsModalSubmitConfirmationOpen] =
    useState(false)
  const [bodyWritingAnswer, setBodyWritingAnswer] = useState()

  const handleModalAddScoreOpen = () => setIsModalAddScoreOpen(true)
  const handleModalAddScoreClose = () => setIsModalAddScoreOpen(false)
  const handleModalSubmitConfirmationOpen = () =>
    setIsModalSubmitConfirmationOpen(true)
  const handleModalSubmitConfirmationClose = () =>
    setIsModalSubmitConfirmationOpen(false)

  const handleClickReview = () => {
    navigate(`${location.pathname}/explanations`)
  }

  const handleSubmitAddScore = (scoreWriting) => {
    setBodyWritingAnswer({
      grand_score: Number(scoreWriting.grandScoreValue),
      gra: Number(scoreWriting.gra1Value),
      ta: Number(scoreWriting.ta1Value),
      cc: Number(scoreWriting.cc1Value),
      rr: Number(scoreWriting.rr1Value),
      overall: Number(scoreWriting.overall1Value),
      ta_2: Number(scoreWriting.ta2Value),
      cc_2: Number(scoreWriting.cc2Value),
      rr_2: Number(scoreWriting.rr2Value),
      gra_2: Number(scoreWriting.gra2Value),
      overall_2: Number(scoreWriting.overall2Value),
    })
    handleModalAddScoreClose()
    handleModalSubmitConfirmationOpen()
  }

  const handleCancelConfirmation = () => {
    handleModalSubmitConfirmationClose()
    handleModalAddScoreOpen()
  }

  const handleSubmitConfirmation = () => {
    axios
      .put(`/answers/${testName}/writing/${writingResultData.user.id}`, {
        ...bodyWritingAnswer,
      })
      .then((res) => {
        setWritingScoreData((prevState) => ({
          ...prevState,
          ...bodyWritingAnswer,
        }))
        handleModalSubmitConfirmationClose()
      })
      .catch((err) => setIsLoading(false))
  }

  const handleGoBack = () => {
    navigate(`/todo`)
  }

  useEffect(() => {
    axios
      .get(`/answers/${testName}/writing/${userId}`)
      .then((res) => {
        const result = res.data.result
        setWritingResultData(result)
        setWritingScoreData(result.writing_answer)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (writingResultData) {
      setIsLoading(true)
      axios
        .get(`/questions/${testName}/writing/description`)
        .then((res) => {
          setQuestionResultData(res.data.result)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [writingResultData])

  if (isLoading && isEmpty(writingResultData) && isEmpty(questionResultData)) {
    return <Loading />
  }

  return (
    <>
      <div className="py-12 px-28">
        <StudentProfile
          onClickBack={handleGoBack}
          name={writingResultData.user?.name}
          email={writingResultData.user?.email}
          classes={writingResultData.user?.classes}
        />
        <div className="flex space-x-12 justify-between">
          <div className="flex flex-col space-y-20 grow">
            <SelectTest />
            <TaskAccordion
              answer1={writingScoredata?.answer_1 || ''}
              answer2={writingScoredata?.answer_2 || ''}
              task1={questionResultData.text_writing1}
              task2={questionResultData.text_writing2}
            />
          </div>
          <TestStats
            totalScore={Number(writingScoredata?.grand_score ?? 0)}
            timeTaken={writingResultData.time_spent}
            writingAnswer={writingScoredata}
            onClickReviewScore={handleModalAddScoreOpen}
            onClickReview={handleClickReview}
            userData={{
              studentName: writingResultData.user?.name || '',
              createdAt: writingResultData.created_at,
              createdScore: writingScoredata?.updated_at || '',
              mentorName: account.name,
            }}
          />
        </div>
      </div>
      <WritingModalAddScore
        isOpen={isModalAddScoreOpen}
        onClose={handleModalAddScoreClose}
        type="scoring-task-1"
        onClickSubmit={handleSubmitAddScore}
        writingAnswer={writingScoredata}
      />
      <WritingModalSubmit
        isOpen={isModalSubmitConfirmationOpen}
        onClose={handleModalSubmitConfirmationClose}
        onCancel={handleCancelConfirmation}
        onSubmit={handleSubmitConfirmation}
      />
    </>
  )
}

export default WritingResult
