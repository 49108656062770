import React, { useEffect } from 'react'
import useQuestionAnswer from '../../../hooks/useQuestionAnswer'
import _map from 'lodash/map'
import check from '../../../assets/check.svg'
import wrong from '../../../assets/wrong.svg'
/*
  @@params{
    number : int
    preText : string
    postText : string
    limit : int
  }
*/

const FillTypeQuestion = ({
  number,
  preText = '',
  postText = '',
  userAnswer,
  answer,
  table,
}) => {
  return (
    <>
      {table ? (
        <span className="input-text inline-block">
          {/* <div className="flex"> */}
          <span id={`q${number}`} className="relative">
            {' '}
            {number}.{' '}
          </span>
          <span>{preText} </span>
          <label className="relative">
            <input
              type="text"
              className={`!text-[#064C85] ${
                answer instanceof Array && userAnswer
                  ? answer.find(
                      (value) =>
                        value.toLowerCase() === userAnswer.toLowerCase().trim()
                    )
                    ? '!bg-[#E2FCF3]'
                    : '!bg-[#FDE0E9]'
                  : userAnswer
                  ? answer.toLowerCase() === userAnswer.toLowerCase().trim()
                    ? '!bg-[#E2FCF3]'
                    : '!bg-[#FDE0E9]'
                  : '!bg-[#FDE0E9]'
              }`}
              name={`question${number}`}
              defaultValue={userAnswer}
              readOnly
            />
            {answer instanceof Array && userAnswer ? (
              answer.find(
                (value) =>
                  value.toLowerCase().trim() === userAnswer.toLowerCase().trim()
              ) ? (
                <img src={check} className="absolute right-1 top-1" />
              ) : (
                <img src={wrong} className="absolute right-1 top-1" />
              )
            ) : userAnswer ? (
              answer.toLowerCase() == userAnswer.toLowerCase().trim() ? (
                <img src={check} className="absolute right-1 top-1" />
              ) : (
                <img src={wrong} className="absolute right-1 top-1" />
              )
            ) : (
              <img src={wrong} className="absolute right-1 top-1" />
            )}
          </label>
          <span> {postText}</span>
          <span className="ml-2">
            ( Answer :{' '}
            <span className="text-[#FB8818]">{answer.toString()}</span> )
          </span>
          {/* </div> */}
        </span>
      ) : (
        <span className="input-text inline-block flex">
          <span id={`q${number}`} className="relative mr-2">
            {' '}
            {number}.{' '}
          </span>
          <div>
            <span>{preText}</span>
            <label className="relative">
              <input
                className={`!text-[#064C85] text-center ${
                  answer instanceof Array && userAnswer
                    ? answer.find(
                        (value) =>
                          value.toLowerCase() ===
                          userAnswer.toLowerCase().trim()
                      )
                      ? '!bg-[#E2FCF3]'
                      : '!bg-[#FDE0E9]'
                    : userAnswer
                    ? answer.toLowerCase() == userAnswer.toLowerCase().trim()
                      ? '!bg-[#E2FCF3]'
                      : '!bg-[#FDE0E9]'
                    : '!bg-[#FDE0E9]'
                }`}
                type="text"
                name={`question${number}`}
                defaultValue={userAnswer}
                readOnly
              />
              {answer instanceof Array && userAnswer ? (
                answer.find(
                  (value) =>
                    value.toLowerCase() === userAnswer.toLowerCase().trim()
                ) ? (
                  <img src={check} className="absolute right-1 top-1" />
                ) : (
                  <img src={wrong} className="absolute right-1 top-1" />
                )
              ) : userAnswer ? (
                answer.toLowerCase() === userAnswer.toLowerCase().trim() ? (
                  <img src={check} className="absolute right-1 top-1" />
                ) : (
                  <img src={wrong} className="absolute right-1 top-1" />
                )
              ) : (
                <img src={wrong} className="absolute right-1 top-1" />
              )}
            </label>
            <span> {postText}</span>
            <div className="text-sm mt-2 flex">
              <span>Answer : </span>
              {/* {_map(answer, d => (<p className="ml-2 text-[#FB8818]">{d}</p>))}  */}
              {answer instanceof Array ? (
                answer.map((data, idx) => (
                  <p key={idx} className="ml-2 text-[#FB8818]">
                    {data}
                  </p>
                ))
              ) : (
                <p className="ml-2 text-[#FB8818]">{answer}</p>
              )}
            </div>
          </div>
        </span>
      )}
    </>
  )
}

export default FillTypeQuestion
