import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import axios from '../../../utils/axios'

import StudentProfile from './StudentProfile'
import SelectTest from './SelectTest'
import TaskAccordion from './TaskAccordion'
import TestStats from './TestStats'
import Loading from '../../../components/Loading'
import WritingModalAddScore from '../../../components/Writing/ModalAddScore'
import WritingModalSubmit from '../../../components/Writing/ModalSubmit'
import SelectSubject from './SelectSubject'
import SelectClassCategory from './SelectClassCategory'
import { getProficiencyLevel } from '../../../utils/scoreIndicators'

const ReportWriting = ({ userId, testName }) => {
  const account = useSelector((s) => s.account)
  let navigate = useNavigate()
  let location = useLocation()
  const [writingResultData, setWritingResultData] = useState({})
  const [writingScoredata, setWritingScoreData] = useState('')
  const [questionResultData, setQuestionResultData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isModalAddScoreOpen, setIsModalAddScoreOpen] = useState(false)
  const [isModalSubmitConfirmationOpen, setIsModalSubmitConfirmationOpen] =
    useState(false)
  const [bodyWritingAnswer, setBodyWritingAnswer] = useState()
  const [selectedSubject, setSelectedSubject] = useState(testName)
  const [selectedClassCategories, setSelectedClassCategories] =
    useState('ielts')

  const handleChangeSelectedClassCategories = (value) => {
    setSelectedClassCategories(value)
  }

  const handleModalAddScoreOpen = () => setIsModalAddScoreOpen(true)
  const handleModalAddScoreClose = () => setIsModalAddScoreOpen(false)
  const handleModalSubmitConfirmationOpen = () =>
    setIsModalSubmitConfirmationOpen(true)
  const handleModalSubmitConfirmationClose = () =>
    setIsModalSubmitConfirmationOpen(false)

  const handleClickReview = () => {
    navigate(`${location.pathname}/explanations`, {
      state: {
        testName: selectedSubject,
        classCategory: selectedClassCategories,
        userId,
      },
    })
  }

  const handleSubmitAddScore = (scoreWriting) => {
    setBodyWritingAnswer({
      grand_score: Number(scoreWriting.grandScoreValue),
      gra: Number(scoreWriting.gra1Value),
      ta: Number(scoreWriting.ta1Value),
      cc: Number(scoreWriting.cc1Value),
      rr: Number(scoreWriting.rr1Value),
      overall: Number(scoreWriting.overall1Value),
      ta_2: Number(scoreWriting.ta2Value),
      cc_2: Number(scoreWriting.cc2Value),
      rr_2: Number(scoreWriting.rr2Value),
      gra_2: Number(scoreWriting.gra2Value),
      overall_2: Number(scoreWriting.overall2Value),
    })
    handleModalAddScoreClose()
    handleModalSubmitConfirmationOpen()
  }

  const handleCancelConfirmation = () => {
    handleModalSubmitConfirmationClose()
    handleModalAddScoreOpen()
  }

  const handleSubmitConfirmation = () => {
    axios
      .put(
        `/answers/${selectedSubject}/writing/${selectedClassCategories}/${writingResultData.user.id}`,
        {
          ...bodyWritingAnswer,
        }
      )
      .then((res) => {
        setWritingScoreData((prevState) => ({
          ...prevState,
          ...bodyWritingAnswer,
        }))
        handleModalSubmitConfirmationClose()
      })
      .catch((err) => setIsLoading(false))
  }

  const handleSubject = async (value) => {
    setSelectedSubject(value)
  }

  const fetchQuestions = async (value) => {
    await axios
      .get(`/answers/${value}/writing/${selectedClassCategories}/${userId}`)
      .then((res) => {
        const result = res.data.result
        setWritingResultData(result)
        setWritingScoreData(result.writing_answer)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchAnswers = async (value) => {
    setIsLoading(true)
    await axios
      .get(`/questions/${value}/writing/${selectedClassCategories}/description`)
      .then((res) => {
        setQuestionResultData(res.data.result)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    axios
      .get(
        `/answers/${selectedSubject}/writing/${selectedClassCategories}/${userId}`
      )
      .then((res) => {
        const result = res.data.result
        setWritingResultData(result)
        setWritingScoreData(result.writing_answer)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `/questions/${selectedSubject}/writing/${selectedClassCategories}/description`
      )
      .then((res) => {
        setQuestionResultData(res.data.result)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (selectedSubject && selectedClassCategories) {
      setWritingResultData({})
      setWritingScoreData('')
      fetchQuestions(selectedSubject)
      fetchAnswers(selectedSubject)
    }
  }, [selectedSubject, selectedClassCategories])

  useEffect(() => {
    handleSubject('pre-test')
  }, [])

  if (isLoading && isEmpty(writingResultData) && isEmpty(questionResultData)) {
    return <Loading />
  }

  return (
    <>
      <div className="py-12 px-28">
        <div className="flex space-x-12 justify-between mt-10">
          <div className="flex flex-col space-y-10 grow">
            <div className="flex space-x-10">
              <div className="w-1/4">
                <SelectSubject
                  value={selectedSubject}
                  onChange={(value) => handleSubject(value)}
                />
              </div>
              <div className="w-1/4">
                <SelectClassCategory
                  onChange={(value) =>
                    handleChangeSelectedClassCategories(value)
                  }
                  value={selectedClassCategories}
                />
              </div>
            </div>

            {writingScoredata !== '' ? (
              <TaskAccordion
                answer1={writingScoredata?.answer_1 || ''}
                answer2={writingScoredata?.answer_2 || ''}
                task1={questionResultData?.text_writing1}
                task2={questionResultData?.text_writing2}
              />
            ) : (
              <p>No Data</p>
            )}
          </div>
          <TestStats
            totalScore={Number(writingScoredata?.grand_score ?? 0)}
            timeTaken={writingResultData?.time_spent}
            writingAnswer={writingScoredata}
            onClickReviewScore={handleModalAddScoreOpen}
            onClickReview={handleClickReview}
            userData={{
              studentName: writingResultData
                ? writingResultData.user?.name || ''
                : null,
              createdAt: writingResultData?.created_at,
              createdScore: writingScoredata?.updated_at || '',
              mentorName: account.name,
            }}
            result={
              selectedClassCategories === 'tibt' &&
              getProficiencyLevel(
                'writing',
                Number(writingScoredata?.grand_score ?? 0)
              )
            }
          />
        </div>
      </div>
      <WritingModalAddScore
        isOpen={isModalAddScoreOpen}
        onClose={handleModalAddScoreClose}
        type="scoring-task-1"
        onClickSubmit={handleSubmitAddScore}
        writingAnswer={writingScoredata}
      />
      <WritingModalSubmit
        isOpen={isModalSubmitConfirmationOpen}
        onClose={handleModalSubmitConfirmationClose}
        onCancel={handleCancelConfirmation}
        onSubmit={handleSubmitConfirmation}
      />
    </>
  )
}

export default ReportWriting
