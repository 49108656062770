import CardStudentByClass from "./CardStudentByClass";

const ListStudentByClass = ({ list }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
      {list.map((item, idx) => (
        <CardStudentByClass
          key={idx}
          item={item}
          list={list}
        />
      ))}
    </div>
  );
};

export default ListStudentByClass;

const List = [
  {
    name: "John Doe",
    performance: "50%",
    point: "20",
    fromPoint: "40",
    status: "Published",
    image: "../../assets/dummy2.svg",
  },
];
