import { Card, Tooltip, Typography } from '@material-tailwind/react'
import {
  renderSatEvaluateAnswer,
  SAT_TABLE_HEAD_ALL_QUESTIONS,
} from '../../helpers/sat'
import classNames from 'classnames'

const SatReportAllQuestions = ({ questions, dataTopic, handleOpenModal }) => {
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {SAT_TABLE_HEAD_ALL_QUESTIONS.map((head, index) => (
              <th
                key={`all-question-${index + 1}`}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => {
            const isLast = index === questions.length - 1
            const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

            return (
              <Tooltip
                key={question.id}
                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                content={
                  <div className="flex flex-col space-y-3">
                    <Typography color="black" className="font-medium">
                      Question {question.number}
                    </Typography>
                    <div>
                      <Typography
                        variant="small"
                        className={classNames('font-normal text-[#ff3e2a]', {
                          'text-[#ff3e2a]':
                            question.type === 3
                              ? !question.answer.find(
                                  (answer) => answer === question.userAnswer
                                )
                              : question.userAnswer !== question.answer,
                          'text-[#a3da09]':
                            question.type === 3
                              ? !!question.answer.find(
                                  (answer) => answer === question.userAnswer
                                )
                              : question.userAnswer === question.answer,
                          'text-[#c5c5c5]': !question.userAnswer,
                        })}
                      >
                        Your Answer:{' '}
                        {question.userAnswer ? question.userAnswer : '-'}
                      </Typography>
                      <Typography
                        variant="small"
                        className="font-normal text-[#a3da09]"
                      >
                        Correct Answer: {question.answer}
                      </Typography>
                    </div>
                    <Typography
                      color="black"
                      variant="small"
                      className="font-normal opacity-80"
                    >
                      Click question to review
                    </Typography>
                  </div>
                }
                color
                placement="top-start"
              >
                <tr
                  onClick={() => handleOpenModal(question)}
                  className="cursor-pointer"
                >
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.number}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {
                        dataTopic.data.find(
                          (topic) => topic.id === question.topicId
                        )?.topic
                      }
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {renderSatEvaluateAnswer(
                        question.answer,
                        question.userAnswer,
                        question.type
                      )}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.total_time}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.first_time}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" className="font-normal">
                      {question.time_view}
                    </Typography>
                  </td>
                </tr>
              </Tooltip>
            )
          })}
        </tbody>
      </table>
    </Card>
  )
}

export default SatReportAllQuestions
