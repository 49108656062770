import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from '@material-tailwind/react'
import { toast } from 'react-toastify'
import axios from '../../utils/axios'

import DefaultStudentImage from '../../assets/default-student.png'
import Sidebar from '../../components/Sidebar'
import Tabs from '../../components/Tabs'
import Breadcrumb from '../../components/Breadcrumb'
import Loading from '../../components/Loading'

import TestResult from './TestResult'
import { debounce } from 'lodash'

const ListAnswers = () => {
  const [tabActive, setTabActive] = useState('writing')
  const [tabActiveClassCategory, setTabActiveClassCategory] = useState('ielts')
  const [tabActiveModule, setTabActiveModule] = useState(1)
  const [tabActiveSubject, setTabActiveSubject] = useState()
  const [testResultTableTitle, setTestResultTableTitle] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [testResultData, setTestResultData] = useState([])
  const [listSubject, setListSubject] = useState([])
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const handleModalConfirmation = () =>
    setOpenModalConfirmation((prevState) => !prevState)

  const tabItems = [
    {
      slug: 'writing',
      name: 'Writing',
    },
    {
      slug: 'speaking',
      name: 'Speaking',
    },
    {
      slug: 'listening',
      name: 'Listening',
    },
    {
      slug: 'reading',
      name: 'Reading',
    },
    {
      slug: 'sat',
      name: 'SAT',
    },
  ]
  const tabActiveName = tabItems.filter((tab) => tab.slug === tabActive)[0].name

  const handleTab = (item) => {
    setTabActive(item.slug)
  }

  const tabClassCategoryItems = [
    {
      slug: 'ielts',
      name: 'IELTS',
    },
    {
      slug: 'tibt',
      name: 'TOEFL IBT',
    },
    {
      slug: 'efb',
      name: 'English for Business',
    },
    {
      slug: 'sat',
      name: 'SAT',
    },
  ]

  const handleTabClassCategory = (item) => {
    setTabActiveClassCategory(item.slug)
  }

  const tabModuleItems = [
    {
      slug: 1,
      name: 'Module 1',
    },
    {
      slug: 2,
      name: 'Module 2',
    },
    {
      slug: 3,
      name: 'Module 3',
    },
    {
      slug: 4,
      name: 'Module 4',
    },
  ]

  const handleTabModule = (item) => {
    setTabActiveModule(item.slug)
  }

  const handleSubjectTab = (item) => {
    setTabActiveSubject(item.slug)
    setTestResultTableTitle(item.title)
  }

  const breadcrumbData = [
    { name: 'Home', link: '/' },
    { name: 'List Answers', link: '/list-answers' },
  ]

  const handleDelete = (answerId) => {
    axios
      .delete(
        tabActiveClassCategory === 'sat' && tabActive === 'sat'
          ? `answers/sat/${tabActiveSubject}/${tabActiveModule}/${answerId}`
          : `answers/${answerId}`
      )
      .then(() => {
        setTestResultData((prevState) =>
          prevState.filter((data) => data.id !== answerId)
        )
        toast.success('Answer successfully deleted')
        handleModalConfirmation()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const fetchData = () => {
      if (tabActiveSubject && tabActive && tabActiveClassCategory) {
        setIsLoadingUsers(true)
        axios
          .get(
            tabActive === 'sat' && tabActiveClassCategory === 'sat'
              ? `/answers/sat/${tabActiveSubject}/${tabActiveModule}/all?search=${searchValue}`
              : `/answers/${tabActiveSubject}/${tabActive}/${tabActiveClassCategory}/all?search=${searchValue}`
          )
          .then((res) => {
            const results = (
              tabActive === 'sat' && tabActiveClassCategory === 'sat'
                ? res.data.data
                : res.data.results
            )?.map((result, index) => {
              const onClickDelete = (answerId) => {
                setSelectedAnswer(answerId)
                handleModalConfirmation()
              }

              return {
                no: index + 1,
                id: `${result.id}-${index + 1}`,
                student: (
                  <div className="flex items-center space-x-3 whitespace-nowrap">
                    <img src={DefaultStudentImage} alt="Student" />
                    <span>{result.name}</span>
                  </div>
                ),
                category:
                  tabActive === 'sat' && tabActiveClassCategory === 'sat' ? (
                    <p className="capitalize">SAT</p>
                  ) : (
                    <p className="capitalize">{result.category}</p>
                  ),
                class: <p className="capitalize">{result.classes}</p>,
                test: (
                  <span className="capitalize whitespace-nowrap">{`${result.subject_name.replace(
                    /-/g,
                    ' '
                  )}`}</span>
                ),
                email: result.email,
                action: (
                  <Button
                    color="red"
                    size="sm"
                    onClick={() => onClickDelete(result.id)}
                  >
                    Delete
                  </Button>
                ),
              }
            })

            setTestResultData(results)
            setIsLoadingUsers(false)
          })
          .catch((err) => {
            setIsLoadingUsers(false)
          })
      }
    }

    const debouncedFetchData = debounce(fetchData, 300)

    debouncedFetchData()

    return () => {
      // Cleanup function
      debouncedFetchData.cancel()
    }
  }, [
    tabActive,
    tabActiveClassCategory,
    tabActiveSubject,
    testResultTableTitle,
    searchValue,
    tabActiveModule,
  ])

  useEffect(() => {
    let subject = []
    setIsLoading(true)
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.slug = val.name
          val.value = val.name
          val.title = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
        setTabActiveSubject(subject[0].slug)
        setTestResultTableTitle(subject[0].title)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex bg-[#FAFAFB]">
        <Sidebar />
        <div className="flex flex-col space-y-6 p-7 max-w-full overflow-hidden">
          <Breadcrumb data={breadcrumbData} />
          <div className="flex flex-col space-y-2 mb-6">
            <h2 className="text-2xl pb-1 text-priblue-500">List Answer</h2>
            <p className="text-sm font-normal text-[#808080]">
              Daftar jawaban student
            </p>
          </div>
          <Tabs
            tabItems={tabClassCategoryItems}
            activeTab={tabActiveClassCategory}
            handleClickTab={handleTabClassCategory}
          />
          <Tabs
            tabItems={tabItems}
            activeTab={tabActive}
            handleClickTab={handleTab}
          />
          {tabActiveClassCategory === 'sat' && tabActive === 'sat' && (
            <Tabs
              tabItems={tabModuleItems}
              activeTab={tabActiveModule}
              handleClickTab={handleTabModule}
            />
          )}
          <TestResult
            onClickTab={handleSubjectTab}
            activeTab={tabActiveSubject}
            categories={listSubject}
            tableTitle={testResultTableTitle}
            data={testResultData}
            tabActive={tabActiveName}
            searchValue={searchValue}
            onChangeSearchValue={handleSearchValue}
            isLoading={isLoadingUsers}
          />
        </div>
        <Dialog open={openModalConfirmation} handler={handleModalConfirmation}>
          <DialogHeader className="justify-center">
            Are you sure you want to delete the answer?
          </DialogHeader>
          <DialogFooter className="flex justify-center space-x-1">
            <Button
              variant="text"
              color="gray"
              onClick={handleModalConfirmation}
            >
              <span>Cancel</span>
            </Button>
            <Button color="red" onClick={() => handleDelete(selectedAnswer)}>
              <span>Delete</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  )
}

export default ListAnswers
