import { ChevronUpDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
  PencilIcon,
  UserPlusIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import React, { useEffect, useState } from 'react'
import axios from '../../../../utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { setStatusStudent } from '../../../../slice/status'
import ModalDetailStudent from '../../../ModalDetailStudent'
import Notify from '../../../Notify'
import { toast } from 'react-toastify'

const TABLE_HEAD = [
  {
    name: 'Name',
    selector: (row) => row.name,
  },
  {
    name: 'Email address',
    selector: (row) => row.email,
  },
  {
    name: 'Class',
    selector: (row) => row.class,
  },
  {
    name: 'Status',
    selector: (row) => row.status,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
  },
]

const columns = [
  {
    name: 'Title',
    selector: (row) => row.title,
  },
  {
    name: 'Year',
    selector: (row) => row.year,
  },
]

let TABLE_ROWS = []

export default function ListStudent() {
  const navigate = useNavigate()

  const status = useSelector((state) => state.status.listStudent)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [dataModal, setDataModal] = useState('')
  const [userSelected, setUserSelected] = useState('')

  const cancel = () => setModal(false)

  useEffect(() => {
    // if (status == false) {
    fetchData()
    fetchClass()
    // }
  }, [])

  const addStudent = () => {
    navigate('/data/addstudent')
  }

  const editStudent = (item) => {
    navigate('/data/editstudent', {
      state: {
        datastudent: item,
      },
    })
  }

  const handleDelete = (userId) => {
    alert(userId)
    setModalDelete(true)
    setUserSelected(userId)
  }

  const handleModalDelete = () => {
    setModalDelete((prevState) => !prevState)
  }

  const fetchData = async () => {
    setLoading(true)
    await axios
      .get(`/users?role=3`)
      .then((res) => {
        if (res.data.results.length != TABLE_ROWS.length) {
          res.data.results.forEach((item) => {
            TABLE_ROWS.push({
              name: item.name,
              email: item.email,
              class: (
                <div className="py-2">
                  {item.classes ? (
                    item.classes.map((item2, idx) => (
                      <div
                        key={idx}
                        className="bg-[#EFF8FF] text-[#175CD3] p-2 rounded-lg mb-2"
                      >
                        {item2.name} {console.log('item2:', item2)}
                      </div>
                    ))
                  ) : (
                    <div className="bg-[#EFF8FF] text-[#175CD3] p-2 rounded-lg mb-2">
                      -
                    </div>
                  )}
                </div>
              ),
              status: (
                <Button
                  className="flex items-center gap-3 bg-priblue-500 text-white"
                  size="sm"
                  onClick={() => {
                    setDataModal(item)
                    setModal(true)
                  }}
                >
                  Detail
                </Button>
              ),
              action: (
                <div className="flex">
                  <Tooltip content="Delete User">
                    <IconButton
                      variant="text"
                      className="text-[#667085] flex justify-center items-center"
                    >
                      <TrashIcon
                        className="h-4 w-4"
                        onClick={() => handleDelete(item.id)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Edit User">
                    <IconButton
                      variant="text"
                      className="text-[#667085] flex justify-center items-center"
                    >
                      <PencilIcon
                        className="h-4 w-4"
                        onClick={() => editStudent(item)}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              ),
            })
          })
        }

        dispatch(setStatusStudent(true))
        setLoading(false)
      })
      .catch((err) => {
        // alert("Gagal get student");
        dispatch(setStatusStudent(false))
        setLoading(false)
      })
  }

  // filter
  const [filterText, setFilterText] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false)
  const filteredItems = TABLE_ROWS.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  const handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state

    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle)
      setFilterText('')
    }
  }

  const FilterComponent = ({ filterText, onFilter }) => (
    <div className="flex items-center">
      <MagnifyingGlassIcon className="h-4 w-4 absolute ml-2" />
      <input
        type="text"
        autoFocus="autoFocus"
        className="border-2 px-7 w-72 py-2"
        placeholder="Search"
        value={filterText}
        onChange={onFilter}
      />
    </div>
  )

  const getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value
          setFilterText(newFilterText)
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }

  const fetchClass = async () => {
    setLoading(true)
    await axios
      .get(`/users/f65049a0-99c3-4c49-98f5-689f23908279/classes`)
      .then((res) => {
        // console.log('rs:', res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deleteUser = async () => {
    await axios
      .delete(`users/${userSelected}`)
      .then(() => {
        fetchData()
        toast.success('Berhasil delete user')
        setModalDelete(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Card className="h-full w-full">
      {modal ? <ModalDetailStudent close={cancel} data={dataModal} /> : ''}
      <Notify
        handleModal={handleModalDelete}
        save={deleteUser}
        title="delete"
        isOpen={modalDelete}
      />
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Kobi's Students
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              See information about all members
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            {/* <Button variant="outlined" color="blue-gray" size="sm">
              view all
            </Button> */}
            <Button
              className="flex items-center gap-3 bg-[#064C85]"
              size="sm"
              onClick={addStudent}
            >
              <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add Student
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <DataTable
            columns={TABLE_HEAD}
            data={filteredItems}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="400px"
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={getSubHeaderComponent()}
            persistTableHead
          />
        )}
      </CardBody>
    </Card>
  )
}
