import React, { useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";

class CommentBox extends React.Component {
  state = {
    comment: "",
    active: false,
    clickedId: -1,
    numberId: "",
  };

  handleCommentSubmit = (e) => {
    e.preventDefault();
    const { updateCommentList, toggleCommentBox, toggleButtonsGroup } =
      this.props;

    if (this.state.comment) {
      const uniqueId = Date.now();

      this.wrapSelectedTextWithId(uniqueId);

      updateCommentList({
        id: uniqueId,
        message: this.state.comment,
        numberId: this.state.numberId,
      });

      toggleButtonsGroup();
      toggleCommentBox();
      this.reset();
    }
  };

  ButtonAnswer = ({ buttons }) => {
    const handleClick = useCallback(
      (buttonLabel, i) => () => {
        this.setState({ clickedId: i, numberId: buttonLabel });
      },
      []
    );

    return (
      <>
        {buttons.map((buttonLabel, i) => (
          <button
            key={i}
            name={buttonLabel}
            onClick={handleClick(buttonLabel, i)}
            className={
              i === this.state.clickedId
                ? "w-7 h-7 bg-[#064C85] m-1 rounded-full text-white"
                : "w-7 h-7 bg-[#CCCCCC] m-1 rounded-full"
            }
          >
            {buttonLabel}
          </button>
        ))}
      </>
    );
  };

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  reset = () => {
    this.setState({ comment: "" });
  };

  wrapSelectedTextWithId = (uniqueId) => {
    const markWrapper = document.createElement("mark");
    markWrapper.setAttribute("id", uniqueId);
    this.props.selectedRange.surroundContents(markWrapper);
  };

  render() {
    const { hidden, builders } = this.props;
    let length = builders.length;

    let datanumber = [];
    for (let i = 0; i < length; i++) {
      datanumber.push(i + 1);
    }

    return (
      <div hidden={hidden}>
        <form
          className="comment-box shadow-md"
          onSubmit={this.handleCommentSubmit}
          style={{ background: "#FFF" }}
        >
          <label htmlFor="commentBox" className="m-5">
            Select answer number
            <hr className="w-full h-1 mt-1" />
          </label>
          <div className="grid grid-cols-8 content-center">
            <this.ButtonAnswer buttons={datanumber} />
          </div>
          <textarea
            id="commentBox"
            className="border-2 border-[#064C85] w-full rounded-md"
            placeholder="Enter Explain"
            onChange={this.handleCommentChange}
            value={this.state.comment}
          ></textarea>
          <button type="submit" className="comment-box__submit-button">
            submit
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    builders: state.question.builders,
  };
};

export default connect(mapStateToProps)(CommentBox);
