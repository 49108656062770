import React, { useState, useEffect } from 'react'
import axios from '../../utils/axios'

import DefaultStudentImage from '../../assets/default-student.png'

import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loading'
import Reminder from './Reminder'
import Jobs from './Jobs'
import Drawer from './Drawer'
import Tabs from '../../components/Tabs'

const Todo = () => {
  const [tabActive, setTabActive] = useState('deadlines')
  const [testResultTableTitle, setTestResultTableTitle] = useState('Deadlines')
  const [isLoading, setIsLoading] = useState(true)
  const [pendingData, setPendingData] = useState()
  const [deadlineData, setDeadlineData] = useState()
  const [selectedUserAnswer, setSelectedUserAnswer] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [tabActiveClassCategory, setTabActiveClassCategory] = useState('ielts')

  const tabCategories = [
    {
      slug: 'deadlines',
      title: 'Deadlines',
      value: 'deadlines',
    },
    {
      slug: 'pending_review',
      title: 'Pending Review',
      value: 'pending_review',
    },
  ]

  const tabClassCategoryItems = [
    {
      slug: 'ielts',
      name: 'IELTS',
    },
    {
      slug: 'tibt',
      name: 'TOEFL IBT',
    },
    {
      slug: 'efb',
      name: 'English for Business',
    },
  ]

  const handleTabClassCategory = (item) => {
    setTabActiveClassCategory(item.slug)
  }

  const handleTab = (item) => {
    setTabActive(item.value)
    setTestResultTableTitle(item.title)
  }

  const handleDetail = (userAnswer) => {
    setSelectedUserAnswer(userAnswer)
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => setIsDrawerOpen(false)

  useEffect(() => {
    axios
      .get(`/answers/pending/writing/${tabActiveClassCategory}/answers`)
      .then((res) => {
        const pendingResults = []
        const deadlineResults = []
        let counter = 0

        res.data.result.rows.forEach((result, index) =>
          result.answer_details.forEach((answer) => {
            counter += 1
            const renderStatus = () => {
              if (Number(result.writing_answer?.grand_score ?? 0) > 0) {
                return (
                  <div className="flex space-x-2 items-center">
                    <div className="w-3 h-3 bg-[#1DB954] rounded-full"></div>
                    <p className="text-nero-700 text-xs">Completed</p>
                  </div>
                )
              }

              return (
                <div className="flex space-x-2 items-center">
                  <div className="w-3 h-3 bg-[#FF9800] rounded-full"></div>
                  <p className="text-nero-700 text-xs">Pending</p>
                </div>
              )
            }
            const onClickDetail = () =>
              handleDetail({
                createdAt: answer.created_at,
                studentName: answer.user.name,
                testName: result.test_name,
                category: result.category,
                totalScore: Number(answer.writing_answer?.grand_score ?? 0),
                writingAnswer: answer.writing_answer,
                userId: answer.user.id,
                classCategory: answer.type_class,
              })

            deadlineResults.push({
              id: answer.user.id,
              studentName: answer.user.name,
              testName: result.test_name,
              createdAt: answer.created_at,
              onClickDetail,
            })
            pendingResults.push({
              no: counter,
              id: answer.user.id,
              student: (
                <div className="flex items-center space-x-3">
                  <img src={DefaultStudentImage} alt="Student" />
                  <span>{answer.user.name}</span>
                </div>
              ),
              class: (
                <p className="max-w-[150px]">
                  {answer.user.classes.length === 0
                    ? '-'
                    : answer.user.classes
                        .map((classObj) => classObj.name)
                        .join(', ')}
                </p>
              ),
              test: <span className="capitalize">{result.test_name}</span>,
              email: answer.user.email,
              score: Number(answer.writing_answer?.grand_score ?? 0),
              status: renderStatus(),
              detail_score: (
                <button
                  className="bg-[#EC6A2A] py-2 px-6 rounded-lg text-white text-xs"
                  onClick={onClickDetail}
                >
                  Detail
                </button>
              ),
            })
          })
        )

        setPendingData(pendingResults)
        setDeadlineData(deadlineResults)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [tabActiveClassCategory])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="flex flex-col space-y-6 p-7 w-full">
          <div className="flex flex-col space-y-2 mb-6">
            <h1 className="text-2xl pb-1 text-priblue-500 font-semibold">
              To Do
            </h1>
            <p className="text-sm font-normal text-[#808080]">
              Ini pekerjaan yang harus kamu selesaikan. Semangat!
            </p>
          </div>
          <Reminder />
          <Tabs
            tabItems={tabClassCategoryItems}
            activeTab={tabActiveClassCategory}
            handleClickTab={handleTabClassCategory}
          />
          <Jobs
            onClickTab={handleTab}
            activeTab={tabActive}
            categories={tabCategories}
            tableTitle={testResultTableTitle}
            pendingData={pendingData}
            deadlineData={deadlineData}
          />
        </div>
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        data={selectedUserAnswer}
        onClose={handleDrawerClose}
      />
    </>
  )
}

export default Todo
