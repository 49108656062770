import { Link } from 'react-router-dom'

function Breadcrumb({ data }) {
  // console.log(data.length);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3 my-3">
        {data.map((item, index) => (
          <li className="inline-flex items-center" key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="w-3 h-3 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              )}
              <Link
                to={item.link}
                className={`inline-flex items-center text-sm font-medium ${
                  index + 1 == data.length ? 'text-priblue-500' : 'text-neromid'
                } hover:text-grey-500 dark:text-grey-400 dark:hover:text-white`}
              >
                {item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
