import React, { useEffect } from "react";
import MultipleTypeQuestion from "../type/multiple";

/*
    @@params = {
        questions : array of MultipleTypeQuestion
    }
*/

const MultipleQuestionContainer = (data) => {
  return (
    <div className="mb-12">
      <MultipleTypeQuestion {...data} />
    </div>
  );
};

export default MultipleQuestionContainer;
