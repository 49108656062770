import React from "react";
import CheckboxTypeQuestion from "../type/checkbox";

/*
    @@params{
        desc : string,
        data : CheckboxTypeQuestion
    }
*/

const CheckboxQuestionContainer = (data) => {
  const { question, choices, number, multi } = data;

  return (
    <div className="mb-12 flex">
      <section className="mb-4 me-2">
        {number} {multi > 0 ? "- " + (number + multi) : ""}.
      </section>
      <div className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        <CheckboxTypeQuestion
          choices={choices}
          start={number}
          end={number + (multi || 0)}
        />
      </div>
    </div>
  );
};

export default CheckboxQuestionContainer;
