import React, { useState } from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const AnswerQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question,
    answer = '',
    point,
    number,
  } = getBuilderDataById(idx, containerId)

  const [totalAnswer, setTotalAnswer] = useState(
    typeof answer === 'string' ? JSON.parse(answer)[0] : answer[0]
  )

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">{number}.</span>
        <div className="flex flex-col gap-1">
          <div
            dangerouslySetInnerHTML={{ __html: question }}
            className="font-bold"
          />
          <section className="input-text my-2 flex gap-2">
            <span>Answer </span>
            <div className="flex flex-col">
              {typeof totalAnswer === 'string' ? (
                <span className="text-orange-500">{answer}</span>
              ) : (
                totalAnswer.map((val, idx) => (
                  <span key={idx} className="text-orange-500">
                    {val}
                  </span>
                ))
              )}
            </div>
          </section>
        </div>
      </div>

      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default AnswerQuestionPreview
