import React from 'react';
import IcLocaate from '../../assets/IcLocate.svg';

// Keep comment.id as Date.now() of commented time
const CommentsList = ({ comments }) => (
  <ul>
    {comments.map((comment, idx) => (
      <li key={idx} className='mt-10'>
        <label>Explain No. {comment.numberId}</label>
        <textarea placeholder='Enter Explain' className='w-full h-24 border-2 border-[#064C85] rounded-md'>{comment.message}</textarea>
        <a href={`#${comment.id}`}>
          <div className='flex flex-row'>
            <label>Locate</label>
            <img className='ml-2' src={IcLocaate} alt=''/>
          </div>
        </a>
      </li>
    ))}
  </ul>
);

export default CommentsList;
