import { MapPinIcon } from '@heroicons/react/24/solid'
import { Typography } from '@material-tailwind/react'
import classNames from 'classnames'

const SatCheckQuestion = ({
  title,
  totalQuestion,
  questions,
  onGoToNumber,
  setOpenModal,
  setOpenPopover,
  currentNumber,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <Typography variant="h5" className="text-black text-center">
        {title}
      </Typography>
      <hr />
      <div className="flex space-x-8 justify-center">
        <div className="flex space-x-2 items-center">
          <MapPinIcon className="w-4 h-4 text-[#fb8818]" />
          <Typography variant="small">Current</Typography>
        </div>
        <div className="flex space-x-2 items-center">
          <div className="w-3 h-3 border border-dashed rounded border-blue-800 bg-blue-800" />
          <Typography variant="small">Answered</Typography>
        </div>
        <div className="flex space-x-2 items-center">
          <div className="w-3 h-3 border border-dashed rounded border-blue-800" />
          <Typography variant="small">Unanswered</Typography>
        </div>
      </div>
      <hr />
      {totalQuestion && (
        <ul className="flex flex-wrap gap-2">
          {Array(totalQuestion)
            .fill('')
            .map((_, index) => (
              <li
                key={`${index + 1}`}
                className={classNames(
                  'relative flex justify-center items-center w-10 h-10 border border-dashed border-blue-800 cursor-pointer rounded transition hover:bg-blue-800 hover:text-white',
                  {
                    'bg-blue-800 text-white': questions[index].userAnswer,
                    'text-blue-800': !questions[index].userAnswer,
                  },
                )}
                onClick={() => {
                  onGoToNumber(index + 1)
                  setOpenModal(false)
                  setOpenPopover(false)
                }}
              >
                {index + 1 === currentNumber && <MapPinIcon className="absolute w-8 h-8 -top-5 text-[#fb8818]" />}
                <Typography variant="paragraph" className="relative font-bold">
                  {index + 1}
                </Typography>
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

export default SatCheckQuestion
