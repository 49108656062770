import React from 'react'
import icVerify from '../assets/IcVerify.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  Button,
} from '@material-tailwind/react'

const Notify = ({ handleModal, isOpen, save, title }) => {
  return (
    <Dialog open={isOpen}>
      <DialogBody>
        <img src={icVerify} alt="" className="mx-auto pt-4" />
        <p className="text-center text-2xl text-[#064C85] mt-5">
          Are you sure you want to {title}?
        </p>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleModal}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="blue" onClick={save}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </DialogBody>
    </Dialog>
  )
}

export default Notify
