import React from 'react'

import MultipleQuestionContainer from '../../../components/View/container/multiple'
import CheckboxQuestionContainer from '../../../components/View/container/checkbox'
import AnswerQuestionContainer from '../../../components/View/container/answers'
import SelectQuestionContainer from '../../../components/View/container/select'
import FillWithImageQuestionContainer from '../../../components/View/container/fill/withImage'
import FillOnCardQuestionContainer from '../../../components/View/container/fill/onCard'
import FillOnTableQuestionContainer from '../../../components/View/container/fill/onTable'

const Result = ({ questions }) => {
  // window.location.reload();

  return (
    <>
      {questions.length > 0 ? (
        <>
          {questions.map((question, index) => {
            switch (question.type) {
              case '1':
                return <MultipleQuestionContainer {...question} />
              case '2':
                return <CheckboxQuestionContainer {...question} />
              case '3':
                return <AnswerQuestionContainer {...question} />
              case '4':
                return <SelectQuestionContainer {...question} />
              case '5':
                return <SelectQuestionContainer {...question} />
              case '6':
                return <FillWithImageQuestionContainer {...question} />
              case '7':
                return <FillOnCardQuestionContainer {...question} />
              case '8':
                return <FillOnTableQuestionContainer {...question} />
            }
          })}
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  )
}

export default Result
