import React, { useEffect, useState } from 'react'
import FillTypeQuestion from '../../type/fill'
import image from '../../../../assets/dummy.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setLastNumber } from '../../../../slice/status'
import { setQuestionMaxLength } from '../../../../slice/question'
import useQuestionAnswer from '../../../../hooks/useQuestionAnswer'

/*
    @@params{
        desc : string
        image : string
        fields : array of FillTypeQuestion
    }
*/

const FillWithImageQuestionContainer = (data) => {
  const [fields, setFields] = useState([])
  const [preText, setPreText] = useState(JSON.parse(data.pre_text))
  const [postText, setPostText] = useState(JSON.parse(data.post_text))
  const [userAnswer, setUserAnswer] = useState(
    data.userAnswer ? JSON.parse(data.userAnswer) : []
  )
  const [answer, setAnswer] = useState(
    data.answer ? JSON.parse(data.answer) : []
  )

  useEffect(() => {
    let temp = []
    for (let i = 0; i <= data.multi; i++) {
      temp.push({
        number: data.number + i,
        pre_text: preText[i],
        post_text: postText[i],
        userAnswer: userAnswer[i],
        answer: answer[i],
      })
    }
    setFields(temp)
  }, [preText, postText])

  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: data.question }}></div>

      <section className="flex flex-col gap-4">
        {typeof postText != 'string'
          ? fields.map((val, idx) => (
              <FillTypeQuestion {...val} key={idx} id={data.id} table={false} />
            ))
          : null}
      </section>
    </div>
  )
}

export default FillWithImageQuestionContainer
