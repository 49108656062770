import React, { useEffect, useState } from 'react'
import useQuestionAnswer from '../../../hooks/useQuestionAnswer'
import classNames from 'classnames'

/*
    @@params{
        choices : array of string
        start : int
        end : int
    }
*/

const isValidJson = (jsonString) => {
  try {
    JSON.parse(jsonString)
    return true
  } catch {
    return false
  }
}

const CheckboxTypeQuestion = ({
  choices,
  start,
  end,
  id: questionId,
  userAnswer,
  multi,
  answer,
}) => {
  let answers = isValidJson(answer) ? JSON.parse(answer) : answer
  if (Array.isArray(answers)) {
    answers = choices.filter((ans, index) => {
      if (answers[index]) {
        return ans
      }
    })
  }
  const { setAnswer, getAnswer, setQuestionIdEach, getAllAnswer } =
    useQuestionAnswer()
  const countCheckboxes = end - start + 1
  const [checkboxes, setCheckboxes] = useState(
    choices.map((val, index) => ({
      id: index + 1,
      label: val,
      checked: userAnswer?.includes(val),
    }))
  )

  const handleCheckboxChange = (id, label) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    )

    const checkedCount = updatedCheckboxes.filter(
      (checkbox) => checkbox.checked
    ).length

    if (checkedCount <= countCheckboxes || multi === 0) {
      setCheckboxes(updatedCheckboxes)
      const checkedChecboxesValues = updatedCheckboxes
        .filter((checkbox) => checkbox.checked === true)
        .map((checkbox) => checkbox.label)

      if (multi === 0) {
        setAnswer(
          start,
          checkedChecboxesValues.length > 0 ? checkedChecboxesValues : '',
          questionId
        )
      } else {
        Array(countCheckboxes)
          .fill('')
          .map((_, index) => {
            if (checkedChecboxesValues[index]) {
              setAnswer(
                start + index,
                checkedChecboxesValues[index],
                questionId
              )
            } else {
              setAnswer(start + index, '', questionId)
            }
          })
      }
    }
  }

  return (
    <div>
      {checkboxes.map((checkbox, idx) => (
        <section
          className="flex gap-2 my-4 items-center bb"
          key={`cq${checkbox.id}${start + idx}`}
        >
          <span
            className={classNames(
              'flex-none w-8 h-8 me-2 rounded-md text-white inline-flex items-center justify-center',
              { 'bg-[#fb8818]': answers.includes(checkbox.label) },
              { 'bg-priblue-500': !answers.includes(checkbox.label) }
            )}
          >
            {String.fromCharCode(65 + idx)}
          </span>
          <input
            type="checkbox"
            className="flex-none w-5 h-5 cursor-pointer"
            id={`cq${checkbox.id}${start + idx}`}
            onChange={() => handleCheckboxChange(checkbox.id, checkbox.label)}
            checked={checkbox.checked}
            disabled
          ></input>
          <label
            htmlFor={`cq${checkbox.id}${start + idx}`}
            className={classNames({
              'text-[#fb8818] underline': answers.includes(checkbox.label),
            })}
          >
            {checkbox.label}
          </label>
        </section>
      ))}
    </div>
  )
}

export default CheckboxTypeQuestion
