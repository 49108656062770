import React from "react";
import CommentsList from "../../../Highlight/CommentList.js";
import Content from "../../../Highlight/Content.js";
import HighlightButtonsGroup from "../../../Highlight/HightlightButtonsGroup.js";
import CommentBox from "../../../Highlight/CommentBox.js";
import { saveSelection, restoreSelection } from "./selection-utils.js";
import ReactHtmlParser from "html-react-parser";
import axios from "../../../../utils/axios.js";

class Mark extends React.Component {
  state = {
    hiddenCommentBox: true,
    hiddenButtonGroup: true,
    highlightBtnsGroupLayout: {
      position: "absolute",
      left: "0",
      top: "0",
      heightInPixel: 28,
      widthInPixel: 70,
    },
    selectedRange: null,
    comments: [],
    updatedContent: "",
  };

  // seleted region reactangle [left, top, width, hieght]
  sethighlightBtnsGroupPosition = ({ left, top, width, height }) => {
    const { heightInPixel, widthInPixel } = this.state.highlightBtnsGroupLayout;
    const computedLeft = left + width / 2 - widthInPixel / 2;
    const computedTop = window.scrollY + top - heightInPixel;
    const arrcomments = [];
    this.setState({
      highlightBtnsGroupLayout: Object.assign(
        this.state.highlightBtnsGroupLayout,
        {
          left: computedLeft,
          top: computedTop,
        }
      ),
    });
  };

  updateCurrentContent = ({ content }) => {
    this.setState({ updatedContent: content });
  };

  submitExplanations = () => {
    alert(this.state.updatedContent.innerHTML);
    alert(this.state.comments);


    axios
    .put("/questions/pre-test/listening/explanations", {
      "audio_text": this.state.updatedContent.innerHTML,
      "items": []
    })
    .then((res) => {
      // TODO: ui
      alert("Success Save");
    })
    .catch((err) => {
      alert("Failed Save");
      console.error(err.message);
    });
  };

  showButtonsGroup = () => {
    this.setState({ hiddenButtonGroup: false });
  };

  saveAndRestoreSelection = () => {
    const savedSelection = saveSelection();
    this.setState({ selectedRange: savedSelection });
    this.toggleCommentBox();
    restoreSelection(savedSelection);
    console.log("tes mark", savedSelection);
  };

  // Taking advantage of es6 object computed property
  toggleState = (updateState) => {
    this.setState({ [updateState]: !this.state[updateState] });
  };

  toggleCommentBox = () => {
    this.toggleState("hiddenCommentBox");
  };

  // Could be used outside click of HighlightButtonsGroup
  toggleButtonsGroup = () => {
    this.toggleState("hiddenButtonGroup");
  };

  updateCommentList = (newComment) => {
    this.setState({ comments: [...this.state.comments, newComment] });
  };

  render() {
    const {
      hiddenCommentBox,
      hiddenButtonGroup,
      highlightBtnsGroupLayout,
      comments,
      selectedRange,
    } = this.state;

    return (
      <div>
        <Content
          setBtnsGroupPosition={this.sethighlightBtnsGroupPosition}
          showButtonsGroup={this.showButtonsGroup}
          showCurrentContent={this.updateCurrentContent}
        />
        <HighlightButtonsGroup
          layout={highlightBtnsGroupLayout}
          hidden={hiddenButtonGroup}
          saveAndRestoreSelection={this.saveAndRestoreSelection}
        />
        <CommentBox
          hidden={hiddenCommentBox}
          selectedRange={selectedRange}
          updateCommentList={this.updateCommentList}
          toggleCommentBox={this.toggleCommentBox}
          toggleButtonsGroup={this.toggleButtonsGroup}
        />
        <CommentsList comments={comments} />
        {/* {renderToString(<Content/>)} */}

        <button onClick={this.submitExplanations}>Save</button>
      </div>
    );
  }
}

export default Mark;
