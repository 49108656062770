import React, { useState } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'

const FillTableQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const {
    point = 0,
    answer = [],
    question,
    number,
    label = [],
    multi = -1,
  } = data

  let { post_text: postText = [], pre_text: preText = [] } = data

  const [totalAnswer, setTotalAnswer] = useState(answer)

  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  const addAnswer = () => {
    const newAnswer = [...totalAnswer, ['New Answer']]
    setTotalAnswer(newAnswer)
    setBuilderDataById(idx, containerId, {
      ...data,
      label: [...label, ' '],
      answer: newAnswer,
      pre_text: [...preText, ' '],
      post_text: [...postText, ' '],
      multi: multi + 1,
    })
  }

  const deleteAnswer = (delIdx) => {
    let newAnswer = []
    let newPostText = []
    let newPreText = []
    for (let i = 0; i < answer.length; i++) {
      if (i !== delIdx) {
        newAnswer.push(answer[i])
        newPreText.push(preText[i])
        newPostText.push(postText[i])
      }
    }

    setTotalAnswer(newAnswer)

    setBuilderDataById(idx, containerId, {
      ...data,
      answer: newAnswer,
      pre_text: newPreText,
      post_text: newPreText,
      multi: multi - 1,
    })
  }

  const setPostText = (e, optIdx) => {
    const newPostText = postText.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })

    setBuilderDataById(idx, containerId, { ...data, post_text: newPostText })
  }

  const setPreText = (e, optIdx) => {
    const newPreText = preText.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })

    setBuilderDataById(idx, containerId, { ...data, pre_text: newPreText })
  }

  const setLabel = (e, optIdx) => {
    const newLabel = label.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })

    setBuilderDataById(idx, containerId, { ...data, label: newLabel })
  }

  const setQuestion = (val) => {
    setBuilderDataById(idx, containerId, { ...data, question: val })
  }

  const setAnswer = (e, index = 0, optIdx) => {
    const newAnswer = totalAnswer.map((val, idx) => {
      if (optIdx === idx) {
        if (typeof val === 'string') return e.target.value
        else {
          let newVal = [...val]
          newVal[index] = e.target.value
          return newVal
        }
      } else return val
    })
    setBuilderDataById(idx, containerId, { ...data, answer: newAnswer })
    setTotalAnswer(newAnswer)
  }

  const addOtherAnswer = (idx) => {
    if (typeof totalAnswer[idx] === 'string') {
      let answer = [...totalAnswer]
      answer[idx] = [totalAnswer[idx], '']
      setTotalAnswer(answer)
    } else {
      const newAnswer = [...totalAnswer]
      newAnswer[idx] = [...newAnswer[idx], '']

      setTotalAnswer(newAnswer)
    }
  }

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Fill Table Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={() => deleteBuilder(idx, containerId, classCategory)}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">
        Number : {number} {multi > 0 ? '- ' + (number + multi) : ''}
      </section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={question}
          setValue={setQuestion}
          idx={`${containerId}-${idx}`}
          number={idx + 1}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        <div className="my-4">
          {totalAnswer.map((val, idx) => (
            <div key={idx} className="flex items-start gap-2 mb-5">
              <span> {number + idx}. </span>
              <input
                type="text"
                value={label ? label[idx] : ''}
                onChange={(e) => setLabel(e, idx)}
                className="w-full bg-gray-100 p-2 rounded-md outline-gray-500"
                placeholder="Insert Label..."
              ></input>
              <input
                type="text"
                value={preText ? preText[idx] : ''}
                onChange={(e) => setPreText(e, idx)}
                className="w-full bg-gray-100 p-2 rounded-md outline-gray-500"
                placeholder="Insert Pre Text..."
              ></input>
              <div className="flex flex-col w-full gap-2">
                {typeof val === 'string' ? (
                  <input
                    type="text"
                    className="w-full text-orange-500 bg-gray-100 p-2 rounded-md outline-gray-500"
                    value={totalAnswer[idx] || ''}
                    onChange={(e) => setAnswer(e, idx)}
                  ></input>
                ) : (
                  val.map((v, i) => (
                    <input
                      type="text"
                      value={v}
                      key={i}
                      onChange={(e) => setAnswer(e, i, idx)}
                      className="w-full text-orange-500 bg-gray-100 p-2 rounded-md outline-gray-500"
                    ></input>
                  ))
                )}
                <button
                  className="px-3 py-2 rounded-md border-[1px] border-blue-600 hover:border-blue-300"
                  onClick={() => {
                    addOtherAnswer(idx)
                  }}
                >
                  + Add Answer
                </button>
              </div>
              <input
                type="text"
                value={postText ? postText[idx] : ''}
                onChange={(e) => setPostText(e, idx)}
                className="w-full bg-gray-100 p-2 rounded-md outline-gray-500"
                placeholder="Insert Past Text..."
              ></input>
              <button
                className="flex gap-1 items-center text-gray-400"
                onClick={() => deleteAnswer(idx)}
              >
                <Icon icon="ion:trash-outline" />
              </button>
            </div>
          ))}
        </div>
        <button
          className="px-3 py-2 rounded-md border-[1px] border-blue-600 mt-4 hover:border-blue-300"
          onClick={addAnswer}
        >
          + Add Question
        </button>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default FillTableQuestionBuilder
