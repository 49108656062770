import { format } from 'date-fns'
const CardClass = ({ name, start, end, student, mentor, onClick }) => {
  return (
    <div
      className="bg-white border rounded-lg shadow-md p-4 cursor-pointer"
      onClick={onClick}
    >
      <div>
        <p className="text-md text-[#292929]">{name}</p>
        <div className="flex flex-row">
          <div>
            <p className="text-xs text-[#808080] mt-5">Total Students</p>
            <p className="text-lg text-[#064C85] mt-2 font-bold">
              {
                student.filter((item) => {
                  return item.role == 3
                }).length
              }
            </p>
          </div>
          <div className="ml-5">
            <p className="text-xs text-[#808080] mt-5">Jadwal Kelas</p>
            <p className="text-xs text-[#808080] mt-4 font-bold">
              {format(new Date(start), 'dd MMM yyyy')} -{' '}
              {format(new Date(end), 'dd MMM yyyy')}
            </p>
          </div>
        </div>
        <hr className="mt-3" />
        <p className="mt-3">Tutor By :</p>
        <div className="flex flex-row">
          {mentor
            .filter((item) => {
              return item.role == 2
            })
            .map((data, idx) => (
              <p className="mt-3 text-[#4318FF]">
                {(idx ? ', ' : '') + data.name}
              </p>
            ))}
        </div>
      </div>
    </div>
  )
}

export default CardClass
