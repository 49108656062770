import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { Select, Option } from '@material-tailwind/react'

const SelectClassCategory = ({ onChange, value }) => {
  const listClassCategory = [
    {
      name: 'IELTS',
      value: 'ielts',
    },
    {
      name: 'TOEFL IBT',
      value: 'tibt',
    },
    {
      name: 'English for Business',
      value: 'efb',
    },
  ]

  return (
    <Select
      size="lg"
      label="Select Class Category"
      name="classCategory"
      onChange={onChange}
      value={value}
    >
      {listClassCategory.map((data, idx) => (
        <Option
          key={idx}
          value={data.value}
          className="flex items-center gap-2"
        >
          {data.name}
        </Option>
      ))}
    </Select>
  )
}

export default SelectClassCategory
