import classNames from 'classnames'
const Tabs = ({ tabItems, activeTab, handleClickTab }) => {
  const tabPillsClasses = (item) =>
    classNames(
      'leading-7 py-2.5 px-4 border-[1px] border-grey-300 hover:bg-blue-50 hover:cursor-pointer first:rounded-l-lg rounded-none last:rounded-r-lg first:rounded-l-lg',
      {
        'bg-blue-50 text-nero-700 font-semibold': activeTab === item.slug,
        'bg-white': activeTab !== item.slug,
      }
    )

  return (
    <div className="grid grid-flow-col auto-cols-max mb-3 lg:mb-0">
      {tabItems.map((item, index) => (
        <div
          className={tabPillsClasses(item)}
          onClick={() => handleClickTab(item)}
          key={item.slug}
        >
          {item.name}
        </div>
      ))}
    </div>
  )
}

export default Tabs
