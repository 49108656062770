import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar";
import Breadcrumb from "../../../../components/Breadcrumb";
import { BiSearch } from "react-icons/bi";
import Dropdown from "../../../../components/Dropdown";
import { BsFilter } from "react-icons/bs";
import axios from "../../../../utils/axios";
import Loading from "../../../../components/Loading";
import {format} from 'date-fns';
import ListStudentByClass from "../../../../components/Student/ListStudentByClass";

function ListStudentAssignment() {
  const [listStudent, setListStudent] = useState([]);
  const [listTutor, setListTutor] = useState([]);
  const [name, setName] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  
  let breadcrumbData = [
    { name: "Report", link: "/dashboard" },
    { name: "Assignment", link: "/myreport" },
    { name: "Student", link: "/student" },
  ];

  useEffect(() => {
    setListStudent([]);
    fetchStudentFirst();
  }, []);

  const fetchStudentFirst = async () => {
    setIsLoading(true);
    await axios
      .get(`/users?role=3`)
      .then((res) => {
        const result = res.data.results;
        
        setListStudent(result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchStudent = async (search) => {
    await axios
      .get(`/users?role=3&search=${search}`)
      .then((res) => {
        const result = res.data.results;        
        setListStudent(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (value) => {
    fetchStudent(value)
  }

  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const onHover = "hover:bg-[#F9FAFB] hover:cursor-pointer";

  return (
    <div>
      {isLoading ? (
        <Loading/>
      ) : (
        <div className="flex lg:grid lg:grid-cols-10">
          <div>
            <Sidebar />
          </div>
          <div className="w-full flex justify-center lg:col-start-3 lg:col-end-12 mt-2 lg:mt-10">
            <div className="w-full min-h-screen px-0 md:px-10 text-nero-700">
              <Breadcrumb data={breadcrumbData} />
              {/* <div className="bg-[#F2F5FF] rounded-md p-4">
                <div className="flex flex-row">
                    <p className="w-40 text-sm">Tutor</p>
                    <p className="text-sm">:</p>
                    {
                      listTutor.map((data, idx) => (
                        <p className="text-sm ml-2">{(idx ? ', ':'')+data.name}</p>
                      ))
                    }
                </div>
                <div className="flex flex-row mt-3">
                    <p className="w-40 text-sm">Jadwal Kelas</p>
                    <p className="text-sm">:</p>
                    <p className="text-sm ml-2">
                      {format(new Date(location.state.jadwalstart), 'dd MMM yyyy')} - {format(new Date(location.state.jadwalend), 'dd MMM yyyy')}
                    </p>
                </div>
              </div> */}

              <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mt-5">
                <div className="flex flex-col mb-3 lg:mb-0">
                  <h2 className="text-lg pb-1 text-priblue-500">Student Name</h2>
                  <p className="text-sm font-normal text-[#808080]">
                    Daftar siswa yang kamu bimbing sekarang
                  </p>
                </div>
                <div className="relative flex jutify-between lg:grid lg:grid-flow-col lg:auto-cols-max gap-2">
                  <div className="flex items-center lg:w-full w-[90%] rounded-lg focus-within:shadow-lg bg-white overflow-hidden border-[1px] border-grey-300">
                    <div className="grid place-items-center h-full text-gray-300 px-2">
                      <BiSearch />
                    </div>
                    <input
                      className="peer h-full outline-none text-sm text-gray-700 pr-2 border-none"
                      type="text"
                      id="search"
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  <div
                    className={` py-2.5 px-4 border-[1px] border-grey-300 rounded-lg  ${onHover} flex items-center gap-2`}
                    onClick={() => setIsFilterClicked(!isFilterClicked)}
                  >
                    <BsFilter size={20} />
                    <p className="leading-7 text-sm select-none">Filter</p>
                  </div>
                  {isFilterClicked && (
                    <Dropdown
                      options={["Nama", "Nilai", "Performa"]}
                      top={"25px"}
                      right={"0px"}
                      mt={"25px"}
                      align={"left"}
                    />
                  )}
                </div>
              </div>
              <div>
                {listStudent.length > 0 ? (
                  <ListStudentByClass
                    list={listStudent}
                  />
                ) : (
                  <div className="flex justify-center items-center mt-10">
                    <p className="text-2xl text-gray-400">No Data</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div>
  );
}

export default ListStudentAssignment;