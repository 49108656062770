import React from 'react'
import FillOnCardQuestionContainer from './fill/onCard'
import FillOnTableQuestionContainer from './fill/onTable'
import AnswerQuestionContainer from './answers'
import CheckboxQuestionContainer from './checkbox'
import MultipleQuestionContainer from './multiple'
import FillDefaultQuestionContainer from './fill/default'
import SelectQuestionContainer from './select'
import RecordingQuestionContainer from '../../View/container/recording'
import AudioQuestionContainer from '../../View/container/audio'

const QuestionContainer = ({ idx, data }) => {
  const { type = 0 } = data

  switch (parseInt(type)) {
    case 1:
      return <MultipleQuestionContainer {...data} />
    case 2:
      return <CheckboxQuestionContainer {...data} />
    case 3:
      return <AnswerQuestionContainer {...data} />
    case 4:
      return <SelectQuestionContainer {...data} />
    case 5:
      return <SelectQuestionContainer {...data} />
    case 6:
      return <FillDefaultQuestionContainer {...data} />
    case 7:
      return <FillOnCardQuestionContainer {...data} />
    case 8:
      return <FillOnTableQuestionContainer {...data} />
    case 9:
      return <AudioQuestionContainer {...data} />
    case 10:
      return <RecordingQuestionContainer {...data} />
    default:
  }
}

export default QuestionContainer
