import React from 'react'
import Sidebar from '../../components/Sidebar'
import CardMenu from '../../components/CardMenu'
import IcListening from '../../assets/IcListening.svg'
import IcReading from '../../assets/IcReading.svg'
import IcWriting from '../../assets/IcWriting.svg'
import IcSpeaking from '../../assets/IcSpeaking.svg'
import IcSat from '../../assets/IcSat.svg'
const Test = () => {
  return (
    <div>
      <div className="grid grid-cols-10">
        <div>
          <Sidebar />
        </div>
        <div className="col-start-3 col-end-9 mt-10">
          <div className="min-h-screen flex items-center justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <CardMenu
                title="Create Listening Test"
                image={IcListening}
                subject="listening"
              />
              <CardMenu
                title="Create Reading Test"
                image={IcReading}
                subject="reading"
              />
              <CardMenu
                title="Create Writing Test"
                image={IcWriting}
                subject="writing"
              />
              <CardMenu
                title="Create Speaking Test"
                image={IcSpeaking}
                subject="speaking"
              />
              <CardMenu
                title="Create Scholastic Aptitude Test"
                image={IcSat}
                subject="sat"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Test
