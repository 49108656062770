import React, { useEffect, useState } from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const convertInput = (html) => {
  let str = html.split(';')
  str = str.reduce((total, current) => {
    if (current.startsWith('#input')) {
      const i = '<input type="text" placeholder="input..."></input>'
      return (total += i)
    } else {
      return (total += current)
    }
  }, '')

  return str
}

const FillQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question = '',
    answer = [],
    point,
    number,
    post_text: postText = [],
    pre_text: preText = [],
    list,
    multi = -1,
  } = getBuilderDataById(idx, containerId)

  let { choices = [] } = getBuilderDataById(idx, containerId)

  if (!choices) {
    choices = []
  }

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">
          {number} {multi > 0 ? '- ' + (number + multi) : ''}.
        </span>
        <div className="flex flex-col gap-1">
          {/* <div
            dangerouslySetInnerHTML={{ __html: convertInput(question) }}
            className="mb-2"
          /> */}

          <div dangerouslySetInnerHTML={{ __html: question }} />

          {list &&
            choices.map((data, idx) => (
              <div className="mb-2" key={idx}>
                <span className="w-8 h-8 me-2 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
                  {String.fromCharCode(65 + idx)}
                </span>
                <span>{data}</span>
              </div>
            ))}
          {answer.length != 0 ? (
            answer.map((val, idx) => (
              <section key={idx} className="mb-3 flex items-center gap-2">
                <span>{number + idx}. </span>
                <span>{preText ? preText[idx] : ''}</span>
                <div className="flex flex-row gap-1 items-center">
                  {typeof val === 'string' ? (
                    <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">
                      {val}
                    </span>
                  ) : (
                    val.map((v, i) => (
                      <>
                        <span
                          key={i}
                          className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500"
                        >
                          {v}
                        </span>
                        <span>
                          {i !== val.length - 1 ? (
                            <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">
                              /
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </>
                    ))
                  )}
                </div>
                <span>{postText ? postText[idx] : ''}</span>
              </section>
            ))
          ) : (
            <section className="mb-3">
              <span>No answer</span>
            </section>
          )}
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default FillQuestionPreview
