import classNames from 'classnames'
const TabPills = ({ categories, active, onClickTab }) => {
  const tabPillsClasses = (category) =>
    classNames(
      'py-2.5 px-9 rounded-full font-normal text-xs transition duration-200 hover:bg-orange-500 hover:cursor-pointer hover:text-white hover:font-semibold whitespace-nowrap',
      {
        'bg-orange-500 text-white font-semibold': active === category.value,
        'text-neromid': active !== category.value,
      }
    )

  return (
    <div className="flex flex-col space-y-2 justify-start items-center md:flex-row md:space-y-0 md:space-x-2">
      {categories?.map((item, idx) => (
        <div
          key={idx}
          className={tabPillsClasses(item)}
          onClick={() => onClickTab(item)}
        >
          {item.title}
        </div>
      ))}
    </div>
  )
}

export default TabPills
