import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'

import TabPills from '../../../components/TabPills'
import Dropdown from '../../../components/Dropdown'

const Toolbar = ({ onClickTab, activeTab, categories }) => {
  const [isFilterClicked, setIsFilterClicked] = useState(false)

  const onHover = 'hover:bg-[#F9FAFB] hover:cursor-pointer'

  return (
    <div className="flex flex-col space-y-4 justify-between mb-8 md:flex-row md:space-y-0">
      <TabPills
        categories={categories}
        active={activeTab}
        onClickTab={onClickTab}
      />
      <div className="relative flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        <div className="flex items-center space-x-2 w-full rounded-lg bg-white overflow-hidden border border-grey-300  transition duration-200 py-3 px-4 focus-within:shadow-lg">
          <div className="flex items-center justify-center h-full text-gray-300">
            <BiSearch className="text-xl" />
          </div>
          <input
            className="peer h-full outline-none text-sm text-gray-700 p-0 border-none focus:outline-none focus:ring-0"
            type="text"
            id="search"
            placeholder="Search"
          />
        </div>
        <div
          className={` py-2.5 px-4 border-[1px] border-grey-300 rounded-lg  ${onHover} flex items-center gap-2`}
          onClick={() => setIsFilterClicked(!isFilterClicked)}
        >
          <BsFilter size={20} />
          <p className="leading-7 text-sm select-none whitespace-nowrap">
            Sort By
          </p>
        </div>
        {isFilterClicked && (
          <Dropdown
            options={['Terbaru', 'Terlama', 'Popular Test']}
            top={'25px'}
            right={'0px'}
            mt={'25px'}
            align={'left'}
          />
        )}
      </div>
    </div>
  )
}

export default Toolbar
