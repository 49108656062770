import React from "react";
import useQuestionAnswer from "../../../hooks/useQuestionAnswer";

/*
    @@params{
        number : int
        postText : string
        choices : array of string
    }

*/

const SelectTypeQuestion = ({ number, postText = "", choices = [], type }) => {
  const { setAnswer } = useQuestionAnswer();

  const inputHandler = (e) => {
    setAnswer(number, e.target.value);
  };

  return (
    <div className="my-4">
      <span id={`q${number}`}> {number}. </span>
      <select
        className="px-4 py-2 bg-gray-100 mx-2"
        name={`question${number}`}
        onChange={inputHandler}
        defaultValue="none"
      >
        <option value="none" disabled hidden></option>
        {choices?.map((val, idx) => (
          <option value={val} key={idx}>
            {parseInt(type) === 5 ? val : String.fromCharCode(65 + idx)}
          </option>
        ))}
      </select>
      <span> {postText}</span>
    </div>
  );
};

export default SelectTypeQuestion;
