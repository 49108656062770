import React, { useEffect, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import axios from '../../../../utils/axios'
import { setQuestionDesc } from '../../../../slice/questionDesc'
import TextEditor from '../textEditor'
import { setStatusQuestion } from '../../../../slice/status'
import AlertPopup from '../../../Popup/Alert'
import { FaPlus } from 'react-icons/fa'
import FormAddSubject from '../../../FormAddSubject'
import AudioForm from '../../form/audio'
import { Typography } from '@material-tailwind/react'
import { toast } from 'react-toastify'
import { resetBuilderData } from '../../../../slice/question'

const DescriptionSat = ({
  moduleId,
  direction,
  setDirection,
  questionDesc,
  reference,
  setReference,
}) => {
  const dispatch = useDispatch()

  const [listSubject, setListSubject] = useState([])
  const [modalFormSubject, setModalFormSubject] = useState(false)
  const [selectedListSubject, setSelectedListSubject] = useState('')

  useEffect(() => {
    dispatch(resetBuilderData())
    setListSubject([])
    fetchSubject()
  }, [])

  useEffect(() => {
    dispatch(
      setQuestionDesc({
        id: '',
        direction: '',
        duration: '',
        subject: '',
        test_name: '',
        updatedAt: '',
        break_time: '',
        reference: '',
      })
    )
    setDirection('')
    setReference('')
  }, [dispatch])

  const fetchSubject = () => {
    let subject = []
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.value = val.name
          val.name = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
      })
      .catch((err) => {
        alert('Gagal get list subject')
        console.error(err.message)
      })
  }

  const submit = (e) => {
    e.preventDefault()

    const data = new FormData(e.target)
    let formObject
    data.append('direction', direction)
    data.append('reference', reference)
    data.append('category', 'sat')
    formObject = Object.fromEntries(data.entries())

    // update jika sudah disubmit dan mengklik tombol lagi
    if (questionDesc.id) {
      data.append('id', questionDesc.id)
      const formObject = Object.fromEntries(data.entries())
      axios
        .put(`/questions/sat/${selectedListSubject}/${moduleId}/description`, {
          ...formObject,
        })
        .then((res) => {
          // TODO: ui
          alert('Sukses update')
          console.log('Sukses update', res.data)
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal update')
          console.error(err.message)
        })
    } else {
      axios
        .post(`/questions/sat/${selectedListSubject}/${moduleId}/description`, {
          ...formObject,
        })
        .then((res) => {
          // TODO: ui
          alert('Sukses ditambahkan')
          dispatch(setQuestionDesc(res.data.data))
        })
        .catch((err) => {
          alert('Gagal ditambahkan')
          console.error(err.message)
        })
    }
  }

  const selectSubject = (event) => {
    setSelectedListSubject(event.target.value)
  }

  const cancelModal = () => {
    setModalFormSubject(false)
  }

  useEffect(() => {
    if (selectedListSubject) {
      axios
        .get(`/questions/sat/${selectedListSubject}/${moduleId}/description`)
        .then((res) => {
          if (res.data.data) {
            dispatch(
              setQuestionDesc({
                ...res.data.data.description,
                subject: listSubject.find(
                  (subject) =>
                    subject.id === res.data.data.description.questionSubjectId
                ).value,
              })
            )
            setDirection(res.data.data.description.direction)
            setReference(res.data.data.description.reference)
            dispatch(setStatusQuestion(false))
          } else {
            dispatch(
              setQuestionDesc({
                id: '',
                direction: '',
                duration: '',
                subject: '',
                test_name: '',
                updatedAt: '',
                break_time: '',
                reference: '',
              })
            )
            setDirection('')
            setReference('')
          }
        })
        .catch((err) => {
          toast.error(err.message)
          dispatch(
            setQuestionDesc({
              id: '',
              direction: '',
              duration: '',
              subject: '',
              test_name: '',
              updatedAt: '',
              break_time: '',
              reference: '',
            })
          )
          setDirection('')
          setReference('')
        })
    }
  }, [dispatch, listSubject, moduleId, selectedListSubject])

  return (
    <>
      <Typography variant="h3">SAT (Scholastic Aptitude Test)</Typography>
      <form className="max-w-full" onSubmit={submit}>
        {questionDesc.id && (
          <div className="flex flex-col text-grey-400 mb-5">
            <label>Question Description ID</label>
            <input
              type="text"
              className="w-full bg-[#eee] rounded-lg mt-2 p-2 border"
              value={questionDesc.id}
              disabled={true}
            />
          </div>
        )}
        <div className="flex flex-col text-grey-400 mt-5 mb-5">
          <label>Subject</label>
          <div className="flex items-center mt-2">
            <select
              className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
              name="subject"
              required
              value={selectedListSubject}
              onChange={selectSubject}
            >
              <option>Choose</option>
              {listSubject &&
                listSubject.map((val, idx) => (
                  <option key={idx} value={val.value}>
                    {val.name}
                  </option>
                ))}
            </select>
            <div
              className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
              onClick={() => setModalFormSubject(true)}
            >
              <FaPlus size="1rem" />
            </div>
          </div>
        </div>

        <div className="flex flex-col text-grey-400 mt-5">
          <label>Duration (hh:mm:ss)</label>
          <input
            type="text"
            className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="duration"
            title="hh:mm:ss"
            pattern="\d\d:\d\d:\d\d"
            placeholder="duration in hh:mm:ss format"
            defaultValue={questionDesc?.duration}
            key={questionDesc?.duration}
          />
        </div>
        <div className="flex flex-col text-grey-400 mt-5">
          <label>Break time (hh:mm:ss)</label>
          <input
            type="text"
            className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="break_time"
            title="hh:mm:ss"
            pattern="\d\d:\d\d:\d\d"
            placeholder="break time in hh:mm:ss format"
            defaultValue={questionDesc?.break_time}
            key={questionDesc?.break_time}
          />
        </div>
        <div className="flex flex-col text-grey-400 mt-5">
          <label>Direction</label>
          <TextEditor value={direction} setValue={setDirection} idx={0} />
        </div>
        {(moduleId === 3 || moduleId === 4) && (
          <div className="flex flex-col text-grey-400 mt-5">
            <label>Reference</label>
            <TextEditor value={reference} setValue={setReference} idx={1} />
          </div>
        )}

        <div className="flex justify-end">
          <button
            className={`bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600 mt-10`}
            type="submit"
          >
            <span className="px-5">
              {questionDesc.id ? 'Update' : 'Submit'}
            </span>
          </button>
        </div>
      </form>
      {modalFormSubject && <FormAddSubject cancel={cancelModal} />}
    </>
  )
}

export default DescriptionSat
