import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Layout from '../layout/Layout';
import Loading from './Loading';
import { Url } from '../global';

const VerifyEmail = () => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const validEmail = "w-full bg-[#FAFAFA] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA]";
  const invalidEmail = "w-full mt-2 p-2 rounded-lg placeholder-red-700 border border-red-500 outline-none focus:ring-red-500 focus:border-red-500 focus:ring-1";

  const VerifyEmail = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const verifyemail = await axios.post(`${Url}/verifyemail`,{
        email: email
      });

      if(verifyemail.status == 200) {
        navigate("/codeemail", {
          state: {
            email: email
          }
        });
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    } catch (error) {
      if(error.response){
        setMsg(error.response.data.msg);
        setStatus(error.response.status);
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    }
  }

  return (
    <div>
    {loading ? (
      <Loading/>
    ) : (
      <Layout>
        <form onSubmit={VerifyEmail} className='max-w-[400px] w-full mx-auto px-8'>
          <div className='flex flex-col text-grey-400 py-2'>
            <p className='text-center text-2xl font-Montserrat'>Register 👋</p>
            <h1 className='text-xs text-[#52525B] mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>      
            <div className='flex flex-col text-grey-400 py-2 mt-5'>    
              <label className={status == 404 ? 'text-red-600' : ''}>Email</label>
              <input className={status == 404 ? invalidEmail : validEmail} type="email" placeholder="yourmail@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
              <span className="font-Montserrat text-xs text-red-600">{msg}</span>
              <button className='w-full my-5 py-2 bg-[#064C85] text-white rounded-lg'>Login</button>
            </div>
          </div>
        </form>
      </Layout>
    )
    }
    </div>
    
    // <section className="hero has-background-grey-light is-fullheight is-fullwodth">
    //   <div className="hero-body">
    //     <div className="container">
    //       <div className="columns is-centered">
    //         <div className="column is-4-desktop">
    //           <form onSubmit={VerifyEmail} className="box">
    //             <p className="has-text-centered">{msg}</p>
    //             <div className="field mt-5">
    //               <label className="label">Email</label>
    //               <div className="controls">
    //                 <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
    //               </div>
    //             </div>
    //             <div className="field">
    //               <button className="button is-success is-fullwidth">Verification</button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  )
}

export default VerifyEmail