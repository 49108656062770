import React, { useEffect, useState } from 'react'
import TextEditor from '../../widgets/textEditor'
import { Icon } from '@iconify/react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import useDebounce from '../../../../hooks/useDebounce'

const FillCardQuestionBuilder = ({ idx, containerId, classCategory }) => {
  const { deleteBuilder, setBuilderDataById, getBuilderDataById, toggleEdit } =
    useQuestionBuilder()
  const data = getBuilderDataById(idx, containerId)
  const { point = 0, number, multi = -1 } = data
  let { answer = [], question = '' } = data

  if (!answer) {
    answer = []
  }

  if (!question) {
    question = ''
  }
  const [textEditorVal, setTextEditorVal] = useState(question)

  const pointHandler = (e) => {
    setBuilderDataById(idx, containerId, { ...data, point: e.target.value })
  }

  const addAnswer = () => {
    const newAnswer = [...answer, 'New Answer']
    setBuilderDataById(idx, containerId, {
      ...data,
      answer: newAnswer,
      multi: multi + 1,
    })
  }

  const deleteAnswer = () => {
    const regex = /<span contenteditable="false">(.*?)<\/span>/g

    const matches = question.match(regex)

    const result = matches?.map((match) => {
      const innerRegex = /<span contenteditable="false">(.*?)<\/span>/
      return match.match(innerRegex)[1]
    })

    return result || []
  }

  const setQuestion = (val) => {
    setTextEditorVal(val)
  }

  const setAnswer = (e, optIdx) => {
    const newAnswers = answer.map((val, idx) => {
      if (optIdx === idx) return e.target.value
      else return val
    })
    setBuilderDataById(idx, containerId, { ...data, answer: newAnswers })
  }

  useEffect(() => {
    let result = deleteAnswer()

    if (result.length > 0) {
      setBuilderDataById(idx, containerId, {
        ...data,
        answer: Array(result.length).fill('New Answer'),
        multi: result.length - 1,
      })
    }
  }, [question])

  const debouncedTextEditorVal = useDebounce(textEditorVal, 500)

  useEffect(() => {
    setBuilderDataById(idx, containerId, {
      ...data,
      question: debouncedTextEditorVal,
    })
  }, [debouncedTextEditorVal])

  return (
    <div className="border-[1px] border-gray-300 p-8 rounded-sm mb-4">
      <header className="flex justify-between mb-2 relative">
        <span className="font-bold ">Fill Default Type</span>
        <label htmlFor={`menu${idx}`}>
          <Icon
            icon="material-symbols:more-vert"
            className="h-100 text-lg cursor-pointer"
          />
        </label>
        <input
          type="checkbox"
          id={`menu${idx}`}
          className="hidden peer"
        ></input>
        <button
          className="absolute p-2 rounded-md right-0 top-6 border-[1px] border-gray-300 peer-checked:block hidden"
          onClick={() => deleteBuilder(idx, containerId, classCategory)}
        >
          Hapus
        </button>
      </header>
      <section className="mb-4 text-xs">
        Number : {number} {multi > 0 ? '- ' + (number + multi) : ''}
      </section>
      <section className="mb-4">
        <span className="block mb-2">Point Set</span>
        <input
          type="number"
          className="p-2 bg-gray-100 rounded-md"
          value={point}
          onChange={pointHandler}
        ></input>
      </section>
      <section className="mb-4">
        <span className="block mb-2">Question</span>
        <TextEditor
          value={textEditorVal}
          setValue={setQuestion}
          fill
          idx={`${containerId}-${idx}`}
          option={{ addAnswer, multi }}
          number={idx + 1}
        />
      </section>
      <section className="mb-4">
        <span className="block mb-2">Set Correct Answer</span>
        <div className="my-4">
          {answer.map((val, idx) => (
            <div key={idx} className="flex items-center gap-2 mb-2">
              <span> {number + idx}. </span>
              <input
                type="text"
                value={val}
                onChange={(e) => setAnswer(e, idx)}
                className="text-orange-500 bg-gray-100 p-2 rounded-md outline-gray-500"
              ></input>
            </div>
          ))}
        </div>
      </section>
      <section className="flex mt-8 justify-end">
        <button
          className="bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600"
          onClick={() => toggleEdit(idx, containerId)}
        >
          Apply
        </button>
      </section>
    </div>
  )
}

export default FillCardQuestionBuilder
