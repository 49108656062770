import React, { Fragment, useEffect, useRef, useState } from 'react'
import Notify from '../../../components/Notify'
import axios from '../../../utils/axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ArrowLeftIcon,
  UserPlusIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { MultiSelect } from 'react-multi-select-component'
import { useDispatch } from 'react-redux'
import { setStatusStudent } from '../../../slice/status'
import { toast } from 'react-toastify'
import Radio from '../../../components/Register/Radio'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardHeader,
  Typography,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from '@material-tailwind/react'
import SelectReact from 'react-select'
import {
  PencilIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from '@heroicons/react/24/solid'

const TABLE_HEAD = ['Name', 'Email', 'Action']

const AddStudentViaClass = () => {
  const [notifyModal, setNotifyModal] = useState(false)
  const navigate = useNavigate()
  const form = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const [listClass, setListClass] = useState('')
  const [openNewStudentModal, setOpenNewStudentModal] = useState(false)
  const [openExistingStudentModal, setOpenExistingStudentModal] =
    useState(false)
  const [student, setStudent] = useState('')
  const [tableData, setTableData] = useState([])
  const { classId } = useParams()
  const [selectedStudent, setSelectedStudent] = useState([])
  const [valGender, setValGender] = useState()
  const [existingStudentIds, setExistingStudentIds] = useState([])
  const [isErrorUpdateStudent, setIsErrorUpdateStudent] = useState(false)
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const handleOpenNewStudentModal = () =>
    setOpenNewStudentModal((open) => !open)

  const handleOpenExistingStudentModal = () =>
    setOpenExistingStudentModal((open) => !open)

  const handleOpenNotifyModal = () => setNotifyModal((open) => !open)

  const onSubmit = (e) => {
    e.preventDefault()
    if (selectedStudent.id) {
      update()
    } else {
      save()
    }
  }

  const onSubmitExistingStudent = (e) => {
    e.preventDefault()
    saveMultiple()
  }

  const fetchStudent = async () => {
    const arrStudent = []
    const arrStudentClass = []
    try {
      await axios.get(`/users?role=3`).then((res) => {
        res.data.results.forEach((data) => {
          if (data.classes.filter((item) => item.id == classId).length === 0)
            arrStudent.push({
              value: data.id,
              label: data.name,
              classIds: data.classes.map((item) => item.id),
            })
        })

        res.data.results.forEach((data) => {
          if (data.classes.filter((item) => item.id == classId).length === 1)
            arrStudentClass.push({
              id: data.id,
              name: data.name,
              email: data.email,
              gender: data.gender,
              phone_number: data.phone_number,
              classIds: data.classes.map((item) => item.id),
              value: data.id,
              label: data.name,
            })
        })

        setStudent(arrStudent)
        setTableData(arrStudentClass)
      })
    } catch (error) {
      console.log('error:', error)
    }
  }

  const saveMultiple = () => {
    existingStudentIds.forEach((val) => {
      axios
        .put(`/users/${val.id}`, {
          classes: [...val.classIds, classId],
        })
        .then((res) => {
          const result = res.data.result
          const userData = student.filter((data) => data.value !== val.id)

          setStudent(userData)
          setTableData((data) => [...data, result])
        })
        .catch((err) => {
          alert('Error: ' + err.message)
          setIsErrorUpdateStudent(true)
        })
    })

    if (!isErrorUpdateStudent) {
      toast.success('Berhasil simpan data student')
      handleOpenExistingStudentModal()
    }
  }

  const save = () => {
    const { name, email, gender, phone_number } = form.current.elements

    axios
      .post('/auth/student', {
        name: name.value,
        email: email.value,
        gender: gender.value,
        phone_number: phone_number.value,
        password: 123,
        classes: [classId],
        products:
          selectedProducts.length > 0
            ? selectedProducts
            : [products.find((data) => data.label === 'ielts')?.value],
      })
      .then((res) => {
        const result = res.data.result
        const userData = {
          id: result.id,
          name: result.name,
          email: result.email,
          gender: result.gender,
          phone_number: result.phone_number,
        }

        toast.success('Berhasil simpan data student')
        dispatch(setStatusStudent(false))
        setTableData((data) => [...data, userData])
        handleOpenNewStudentModal()
      })
      .catch((err) => {
        alert('Error: ' + err.message)
      })
  }

  const deleteStudentFromClass = (userId) => {
    axios
      .put(`/users/${selectedStudent.id}`, {
        classes: selectedStudent.classIds.filter((item) => item !== classId),
      })
      .then((res) => {
        const userData = tableData.filter((data) => data.id !== userId)

        toast.success('Berhasil delete student from class')
        setStudent((students) => [...students, selectedStudent])
        setTableData(userData)
        handleOpenNotifyModal()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const update = () => {
    const { name, email, gender, phone_number } = form.current.elements

    axios
      .put(`/users/${selectedStudent.id}`, {
        name: name.value,
        email: email.value,
        gender: gender.value,
        phone_number: phone_number.value,
        classes: classId,
      })
      .then((res) => {
        const result = res.data.result
        const userData = [...tableData].map((data) => {
          if (data.id === result.id) {
            return result
          } else {
            return data
          }
        })

        toast.success('Berhasil Update Data Student')
        dispatch(setStatusStudent(false))
        setTableData(userData)
        handleOpenNewStudentModal()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const genderoptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]

  const handleGender = (e) => {
    setValGender(e.currentTarget.value)
  }

  const handleStudent = async (event) => {
    const studentIds = event.map((e) => ({
      id: e.value,
      classIds: e.classIds,
    }))
    setExistingStudentIds(studentIds)
  }

  const handleProduct = async (event) => {
    const productIds = event.map((e) => e.value)

    setSelectedProducts(productIds)
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products')
        const result = response.data
        const products = result.product.map((data) => ({
          value: data.id,
          label: data.name,
        }))

        setProducts(products)
      } catch (error) {
        console.log('error:', error)
      }
    }
    fetchProducts()
    fetchStudent()
  }, [])

  useEffect(() => {
    setValGender(selectedStudent.gender)
  }, [selectedStudent])

  return (
    <Fragment>
      <div className="grid grid-cols-12 m-10">
        <div className="ml-10">
          <ArrowLeftIcon
            className="h-12 w-16 bg-[#F8F8F8] p-3 rounded-r-full rounded-l-full cursor-pointer hover:bg-[#D0D5DD]"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="mt-3">
          <p>Add Student</p>
        </div>
        <div className="col-start-4 col-span-7 px-10 mt-16 space-y-4">
          <div className="flex justify-center w-full space-x-4">
            <Button
              onClick={() => {
                handleOpenNewStudentModal()
                setSelectedStudent({})
              }}
              className="flex items-center space-x-2"
            >
              <UserPlusIcon className="h-5 w-5" />
              <span>Add new student</span>
            </Button>
            <Button
              onClick={handleOpenExistingStudentModal}
              className="flex items-center space-x-2"
            >
              <UsersIcon className="h-5 w-5" />
              <span>Add existing student</span>
            </Button>
          </div>
          <Card className="w-full">
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none overflow-visible"
            >
              <div className="w-full flex flex-col justify-between gap-8 items-end">
                <div className="flex w-full shrink-0 gap-2 md:w-max">
                  <div className="w-full md:w-72">
                    <Input
                      label="Search"
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    />
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="overflow-auto px-0">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(
                    (
                      {
                        name,
                        email,
                        id,
                        gender,
                        phone_number,
                        classIds,
                        value,
                        label,
                      },
                      index
                    ) => {
                      const isLast = index === tableData.length - 1
                      const classes = isLast
                        ? 'p-4'
                        : 'p-4 border-b border-blue-gray-50'

                      return (
                        <tr key={id}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {email}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Tooltip content="Edit User">
                              <IconButton
                                variant="text"
                                color="blue"
                                onClick={() => {
                                  setSelectedStudent({
                                    name,
                                    email,
                                    id,
                                    gender,
                                    phone_number,
                                  })
                                  handleOpenNewStudentModal()
                                }}
                              >
                                <PencilIcon className="h-4 w-4" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip content="Delete User">
                              <IconButton
                                variant="text"
                                color="red"
                                onClick={() => {
                                  setSelectedStudent({
                                    name,
                                    email,
                                    id,
                                    gender,
                                    phone_number,
                                    classIds,
                                    value,
                                    label,
                                  })
                                  handleOpenNotifyModal()
                                }}
                              >
                                <TrashIcon className="h-4 w-4" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
      </div>
      <Dialog
        open={openExistingStudentModal}
        handler={handleOpenExistingStudentModal}
      >
        <DialogHeader>Add existing student</DialogHeader>
        <form onSubmit={onSubmitExistingStudent}>
          <DialogBody>
            <div>
              <SelectReact
                options={student}
                onChange={(event) => handleStudent(event)}
                isMulti
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpenExistingStudentModal}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>Add</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      <Dialog open={openNewStudentModal} handler={handleOpenNewStudentModal}>
        <form ref={form} onSubmit={onSubmit}>
          <DialogHeader>Add new student</DialogHeader>
          <DialogBody>
            <div>
              <p className="text-[#064C85] text-lg">Personal Information</p>
              <div className="mt-3">
                <p>Full Name</p>
                <input
                  className="border py-2 mt-2 w-full"
                  type="text"
                  name="name"
                  defaultValue={
                    selectedStudent != null ? selectedStudent.name : null
                  }
                  placeholder="Enter full name"
                  required
                />
              </div>
              <div className="mt-4">
                <p className="mb-1">Gender</p>
                <div className="flex flex-row">
                  {genderoptions.map((data, idx) => (
                    <div className="mr-5" key={idx}>
                      <Radio
                        label={data.label}
                        name="gender"
                        value={data.value}
                        checked={valGender === data.value}
                        onChange={handleGender}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <p className="mb-1">Products</p>
                <div className="flex flex-row">
                  {products.length > 0 && (
                    <SelectReact
                      options={products}
                      onChange={(event) => handleProduct(event)}
                      isMulti
                      className="w-full"
                      defaultValue={products.find(
                        (data) => data.label === 'ielts'
                      )}
                      required
                    />
                  )}
                </div>
              </div>

              <p className="text-[#064C85] text-lg mt-10">
                Contact Information
              </p>
              <div className="mt-3">
                <p>Email</p>
                <input
                  className="border py-2 mt-2 w-full"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  defaultValue={
                    selectedStudent != null ? selectedStudent.email : null
                  }
                  required
                />
              </div>
              <div className="mt-3">
                <p>Mobile Number</p>
                <div className="flex">
                  <input
                    type="text"
                    className="border py-2 mt-2 w-16"
                    value="+62"
                    required
                    readOnly
                  />
                  <input
                    type="tel"
                    className="border py-2 mt-2 ml-2 w-full"
                    name="phone_number"
                    placeholder="Enter mobile number (8xxx)"
                    title="Format 8xxx"
                    pattern="8\d+"
                    defaultValue={
                      selectedStudent != null
                        ? selectedStudent.phone_number
                        : null
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpenNewStudentModal}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>Add</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      <Notify
        isOpen={notifyModal}
        handleModal={handleOpenNotifyModal}
        save={() => deleteStudentFromClass(selectedStudent.id)}
        title={`Delete ${selectedStudent.name} from this class`}
      />
    </Fragment>
  )
}

export default AddStudentViaClass
