import { createSlice } from "@reduxjs/toolkit";

export const statusSlice = createSlice({
  name: "status",
  initialState: {
    listQuestions: false,
    listStudent: false,
    listMentor: false,
    listClass: false,
  },
  reducers: {
    setStatusQuestion: (state, action) => {
      state.listQuestions = action.payload;
    },
    setStatusStudent: (state, action) => {
      state.listStudent = action.payload;
    },
    setStatusMentor: (state, action) => {
      state.listMentor = action.payload;
    },
    setStatusClass: (state, action) => {
      state.listClass = action.payload;
    },
  },
});

export const { setStatusQuestion, setStatusStudent, setStatusMentor, setStatusClass } = statusSlice.actions;
export default statusSlice.reducer;
