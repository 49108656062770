import React from 'react'
import useQuestionBuilder from '../../../hooks/useQuestionBuilder'

import SatTest from '../../Sat/SatTest'
import { generateSatQuestions } from '../../../helpers/sat'

const PreviewSat = ({ moduleId, direction }) => {
  const { getAllBuilders } = useQuestionBuilder()
  const builders = getAllBuilders()
  const { questions, userAnswers } = generateSatQuestions(
    builders.filter((builder) => builder.module === moduleId),
    []
  )

  return (
    <div className="max-w-full">
      <SatTest
        initialTime="00:00:00"
        questions={questions}
        direction={direction}
        moduleId={moduleId}
        activeStatus="1"
        updateAnswers={() => {}}
        onTimerComplete={() => {}}
        onFinish={() => {}}
        userAnswers={userAnswers}
      />
    </div>
  )
}

export default PreviewSat
