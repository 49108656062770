import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import AudioPlayer from '../../../AudioPlayer'
import PreviewMenu from '../menu'
import { Url } from '../../../../global'

const AudioPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const { files, audio_name } = getBuilderDataById(idx, containerId)

  return (
    <main className="p-4 border-[1px] border-gray-300 mb-4">
      <div className="flex flex-col space-y-4">
        {files ? <AudioPlayer audioUrl={URL.createObjectURL(files)} /> : <></>}
        {audio_name && !files ? (
          <AudioPlayer audioUrl={`${Url}/media/${audio_name}`} />
        ) : (
          <></>
        )}
        <PreviewMenu
          idx={idx}
          containerId={containerId}
          classCategory={classCategory}
          noPoint
        />
      </div>
    </main>
  )
}

export default AudioPreview
