import React from "react";

const INFO = [
    'True = the statement matches the information in the passage.',
    'False = the statement',
    'Not Given = the information is not found in the passage.'
]

const SelectQuestionTruth = () => {
    return(
        <div className="flex flex-col gap-2 my-8 text-black">
            {
                INFO?.map((val, idx) => (
                    <section className="flex items-center gap-2" key={idx}>
                        <span className="inline-block w-4 h-4 bg-orange-500"></span>
                        <p>{val}</p>
                    </section>        
                ))
            }
        </div>
    )
}

export default SelectQuestionTruth