import React, { useEffect, useState } from 'react'
import useQuestionAnswer from '../../../hooks/useQuestionAnswer'

/*
    @@params{
        choices : array of string
        start : int
        end : int
    }
*/

const CheckboxTypeQuestion = ({
  choices,
  start,
  end,
  id: questionId,
  userAnswer,
}) => {
  const { setAnswer, getAnswer, setQuestionIdEach, getAllAnswer } =
    useQuestionAnswer()
  const countCheckboxes = end - start + 1
  const [checkboxes, setCheckboxes] = useState(
    choices.map((val, index) => ({
      id: index + 1,
      label: val,
      checked: false || userAnswer?.includes(val),
    }))
  )

  const handleCheckboxChange = (id, label) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    )

    const checkedCount = updatedCheckboxes.filter(
      (checkbox) => checkbox.checked
    ).length

    if (checkedCount <= countCheckboxes) {
      setCheckboxes(updatedCheckboxes)
      const checkedChecboxesValues = updatedCheckboxes
        .filter((checkbox) => checkbox.checked === true)
        .map((checkbox) => checkbox.label)

      Array(countCheckboxes)
        .fill('')
        .map((_, index) => {
          if (checkedChecboxesValues[index]) {
            setAnswer(start + index, checkedChecboxesValues[index], questionId)
          } else {
            setAnswer(start + index, '', questionId)
          }
        })
    }
  }

  return (
    <div>
      {checkboxes.map((checkbox, idx) => (
        <section
          className="flex gap-2 my-4 items-center"
          key={`cq${checkbox.id}${start + idx}`}
        >
          <span className="flex-none w-8 h-8 me-2 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
            {String.fromCharCode(65 + idx)}
          </span>
          <input
            type="checkbox"
            className="flex-none w-5 h-5 cursor-pointer"
            id={`cq${checkbox.id}${start + idx}`}
            onChange={() => handleCheckboxChange(checkbox.id, checkbox.label)}
            checked={checkbox.checked}
            disabled
          ></input>
          <label htmlFor={`cq${checkbox.id}${start + idx}`}>
            {checkbox.label}
          </label>
        </section>
      ))}
    </div>
  )
}

export default CheckboxTypeQuestion
