import React, { useEffect, useState } from 'react'
import { MdCloudUpload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import axios from '../../../../utils/axios'
import { setQuestionDesc } from '../../../../slice/questionDesc'
import TextEditor from '../textEditor'
import { setStatusQuestion } from '../../../../slice/status'
import AlertPopup from '../../../Popup/Alert'
import { FaPlus } from 'react-icons/fa'
import FormAddSubject from '../../../FormAddSubject'
import AudioForm from '../../form/audio'

const DescriptionListening = ({
  selectedClassCategories,
  onChangeClassCategories,
  selectedFile,
  setSelectedFile,
}) => {
  const dispatch = useDispatch()
  const questionDesc = useSelector((s) => s.questionDesc)
  const [description, setDescription] = useState(questionDesc.description)
  const [textaudio, setTextaudio] = useState(questionDesc.text_audio)
  const [listSubject, setListSubject] = useState([])
  const [modalFormSubject, setModalFormSubject] = useState(false)
  const [selectedListSubject, setSelectedListSubject] = useState('')

  useEffect(() => {
    setListSubject([])
    fetchSubject()
  }, [])

  useEffect(() => {
    dispatch(
      setQuestionDesc({
        audio_name: '',
        listening: '',
        description: '',
        duration: '',
        id: '',
        subject: '',
        test_name: '',
        audio_text: '',
        updatedAt: '',
      })
    )
    setDescription('')
    setTextaudio('')
  }, [])

  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (file) setSelectedFile(file)
  }

  const fetchSubject = () => {
    let subject = []
    axios
      .get('/questions/subject')
      .then((res) => {
        const result = res.data.results
        result.forEach((val) => {
          let temp = val.name.split('-')
          const capitalizedWords = temp
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          val.value = val.name
          val.name = capitalizedWords
          subject.push(val)
        })
        setListSubject(subject)
      })
      .catch((err) => {
        alert('Gagal get list subject')
        console.error(err.message)
      })
  }

  const submit = (e) => {
    e.preventDefault()

    const data = new FormData(e.target)

    data.append('description', description)
    data.append('audio_text', textaudio)
    data.append('category', 'listening')

    // update jika sudah disubmit dan mengklik tombol lagi
    if (questionDesc.id) {
      data.append('id', questionDesc.id)
      axios
        .put(
          `/questions/${selectedListSubject}/listening/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses update')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal update')
          console.error(err.message)
        })
    } else {
      axios
        .post(
          `/questions/${selectedListSubject}/listening/${selectedClassCategories}/description`,
          data
        )
        .then((res) => {
          // TODO: ui
          alert('Sukses ditambahkan')
          dispatch(setQuestionDesc(res.data.result))
        })
        .catch((err) => {
          alert('Gagal ditambahkan')
          console.error(err.message)
        })
    }
  }

  const selectSubject = (event) => {
    setSelectedListSubject(event.target.value)
  }

  const cancelModal = () => {
    setModalFormSubject(false)
  }

  const listClassCategories = [
    {
      id: 1,
      name: 'IELTS',
      value: 'ielts',
    },
    {
      id: 2,
      name: 'TOEFL IBT',
      value: 'tibt',
    },
    {
      id: 3,
      name: 'English for Business',
      value: 'efb',
    },
  ]

  useEffect(() => {
    if (selectedListSubject && selectedClassCategories) {
      axios
        .get(
          `/questions/${selectedListSubject}/listening/${selectedClassCategories}/description`
        )
        .then((res) => {
          if (res.data.result) {
            dispatch(setQuestionDesc(res.data.result))
            setDescription(res.data.result.description)
            setTextaudio(res.data.result.audio_text)
            dispatch(setStatusQuestion(false))
          } else {
            dispatch(
              setQuestionDesc({
                audio_name: '',
                listening: '',
                description: '',
                duration: '',
                id: '',
                subject: selectedListSubject,
                test_name: '',
                updatedAt: '',
              })
            )
            setDescription('')
            setTextaudio('')
          }
        })
        .catch((err) => {
          alert('Gagal get subject')
          console.error(err.message)
          dispatch(
            setQuestionDesc({
              audio_name: '',
              listening: '',
              description: '',
              duration: '',
              id: '',
              subject: selectedListSubject,
              test_name: '',
              audio_text: '',
              updatedAt: '',
            })
          )
          setDescription('')
          setTextaudio('')
        })
    }
  }, [selectedListSubject, selectedClassCategories])

  return (
    <>
      <form className="max-w-full" onSubmit={submit}>
        {questionDesc.id && (
          <div className="flex flex-col text-grey-400 mb-5">
            <label>Question Description ID</label>
            <input
              type="text"
              className="w-full bg-[#eee] rounded-lg mt-2 p-2 border"
              value={questionDesc.id}
              disabled={true}
            />
          </div>
        )}
        <div className="flex flex-col text-grey-400 mt-5 mb-5">
          <label>Subject</label>
          <div className="flex items-center mt-2">
            <select
              className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
              name="subject"
              required
              value={selectedListSubject}
              onChange={selectSubject}
            >
              <option>Choose</option>
              {listSubject &&
                listSubject.map((val, idx) => (
                  <option key={idx} value={val.value}>
                    {val.name}
                  </option>
                ))}
            </select>
            <div
              className="ml-3 p-[10px] rounded-lg focus:bg-[#FAFAFA] border border-[#DEE8FF] hover:bg-[#FAFAFA] cursor-pointer"
              onClick={() => setModalFormSubject(true)}
            >
              <FaPlus size="1rem" />
            </div>
          </div>
        </div>

        <div className="flex flex-col text-grey-400 mt-5 mb-5">
          <label>Class Category</label>
          <div className="flex items-center mt-2">
            <select
              className="w-full bg-[#FFFFFF] rounded-lg p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
              name="classCategory"
              required
              value={selectedClassCategories}
              onChange={onChangeClassCategories}
            >
              <option>Choose</option>
              {listClassCategories &&
                listClassCategories.map((val, idx) => (
                  <option key={idx} value={val.value}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {selectedClassCategories !== 'tibt' && (
          <AudioForm
            id={1}
            title="Audio"
            audioName={
              selectedFile?.name ? selectedFile.name : questionDesc.audio_name
            }
            onChangeAudio={changeHandler}
            audioText={textaudio}
            setAudioText={setTextaudio}
          />
        )}

        <div className="flex flex-col text-grey-400 mt-5">
          <label>Test Name</label>
          <input
            type="text"
            className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="test_name"
            placeholder="enter test name"
            defaultValue={questionDesc?.test_name}
            key={questionDesc?.test_name}
          />
        </div>

        <div className="flex flex-col text-grey-400 mt-5">
          <label>Duration (hh:mm:ss)</label>
          <input
            type="text"
            className="w-full bg-[#FFFFFF] rounded-lg mt-2 p-2 focus:bg-[#FAFAFA] border border-[#DEE8FF]"
            name="duration"
            title="hh:mm:ss"
            pattern="\d\d:\d\d:\d\d"
            placeholder="duration in hh:mm:ss format"
            defaultValue={questionDesc?.duration}
            key={questionDesc?.duration}
          />
        </div>
        <div className="flex flex-col text-grey-400 mt-5">
          <label>Description/Instruction</label>
          <TextEditor value={description} setValue={setDescription} idx={0} />
        </div>
        <div className="flex justify-end">
          <button
            className={`bg-blue-500 px-8 py-2 rounded-full text-white float hover:bg-blue-600 mt-10`}
            type="submit"
          >
            <span className="px-5">
              {questionDesc.id ? 'Update' : 'Submit'}
            </span>
          </button>
        </div>
      </form>
      {modalFormSubject && <FormAddSubject cancel={cancelModal} />}
    </>
  )
}

export default DescriptionListening
