import React from "react";
import useQuestionAnswer from "../../../hooks/useQuestionAnswer";

/*
  @@params{
    number : int
    question : string
    choices : array of string
  }
*/

const MultipleTypeQuestion = ({ number, question, choices }) => {
  const { setAnswer } = useQuestionAnswer();

  const inputHandler = (e) => {
    setAnswer(number, e.target.value);
  };

  return (
    <div className="flex my-4">
      <span className="mr-3" id={`q${number}`}>
        {number}.
      </span>
      <div className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: question }} />
        {choices?.map((val, idx) => (
          <section key={idx}>
            <input
              type="radio"
              id={`q${number}${val}`}
              name={`question${number}`}
              value={val}
              className="cursor-pointer"
              onChange={inputHandler}
            />
            <label htmlFor={`q${number}${val}`} className="ms-2 cursor-pointer">
              {val}
            </label>
          </section>
        ))}
      </div>
    </div>
  );
};

export default MultipleTypeQuestion;
