import React, { useState, useRef, useEffect } from 'react'
import { Button } from '@material-tailwind/react'

import CommentIcon from '../../../assets/icons/comment.svg'
import PointerIcon from '../../../assets/icons/pointer.svg'

const Assignment = ({ writingAnswer, title, writingType, onSubmitComment }) => {
  const [commentValue, setCommentValue] = useState('')
  const [annotations, setAnnotations] = useState([])
  const [selectedText, setSelectedText] = useState('')
  const [isCommentOpen, setIsCommentOpen] = useState(false)
  const [addCommentPosition, setAddCommentPosition] = useState({
    top: 0,
    left: 0,
  })
  const [commentPosition, setCommentPosition] = useState({ top: 0, left: 0 })
  const annotationRef = useRef(null)

  const handleCommentOpen = () => setIsCommentOpen(true)
  const handleChangeComment = (event) => setCommentValue(event.target.value)

  const handleSelection = () => {
    const selection = window.getSelection()
    const selectedText = selection.toString().trim()

    if (selectedText) {
      const range = selection.getRangeAt(0)
      const rect = range.getBoundingClientRect()

      const initialAddCommentPosition = {
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX + rect.width / 2,
      }

      const initialCommentPosition = {
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX + rect.width / 2,
      }

      const newAddCommentPosition = checkBoundary(
        initialAddCommentPosition,
        175,
        40
      )
      const newCommentPosition = checkBoundary(initialCommentPosition, 600, 260)

      setSelectedText(selectedText)
      setAddCommentPosition(newAddCommentPosition)
      setCommentPosition(newCommentPosition)
    }
  }

  const handleAddAnnotation = () => {
    if (selectedText && commentValue) {
      const newAnnotation = {
        text: selectedText,
        comment: commentValue,
      }

      setAnnotations([...annotations, newAnnotation])
      onSubmitComment(writingType, selectedText, commentValue)
      setCommentValue('')
      setSelectedText('')
    }
  }

  const checkBoundary = (position, popoverWidth, popoverHeight) => {
    const rightBoundary = document.documentElement.clientWidth - popoverWidth
    const bottomBoundary = document.documentElement.clientHeight - popoverHeight

    const newPosition = {
      top: Math.min(
        Math.max(position.top, window.scrollY),
        bottomBoundary + window.scrollY
      ),
      left: Math.min(
        Math.max(position.left, window.scrollX),
        rightBoundary + window.scrollX
      ),
    }

    return newPosition
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        annotationRef.current &&
        !annotationRef.current.contains(event.target)
      ) {
        setSelectedText('')
        setCommentValue('')
        setIsCommentOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className="flex flex-col space-y-4 px-32"
      id={`container-writing-${writingType}`}
    >
      <h3 className="text-nero-700 font-semibold text-xl font-Montserrat">
        {title}
      </h3>
      <article
        className="custom-article flex flex-col space-y-4 p-20 bg-white"
        id={`article-writing-${writingType}`}
      >
        <p
          onMouseUp={handleSelection}
          dangerouslySetInnerHTML={{ __html: writingAnswer }}
        />
        {selectedText && (
          <div
            ref={annotationRef}
            className="absolute"
            style={{
              top: addCommentPosition.top,
              left: addCommentPosition.left,
            }}
            onClick={handleCommentOpen}
          >
            <div className="relative flex items-center space-x-3 px-3 py-2 bg-white rounded-[10px] shadow-md z-10 cursor-pointer">
              <img src={CommentIcon} alt="Comment Icon" />
              <span className="text-[#3DB445] text-sm font-semibold">
                Add Comments
              </span>
            </div>
            <img
              src={PointerIcon}
              alt="Pointer Icon"
              className="absolute left-2 -top-2 drop-shadow-md rotate-180"
            />
          </div>
        )}
        {selectedText && isCommentOpen && (
          <div
            ref={annotationRef}
            className="absolute"
            style={{ top: commentPosition.top, left: commentPosition.left }}
          >
            <div className="relative flex flex-col items-start space-y-2 px-3 py-2 bg-white rounded-[10px] shadow-lg z-10 w-[550px]">
              <p className="text-nero-900 font-semibold">Comments</p>
              <div className="flex flex-col items-start space-y-2 py-3 px-4 border border-[#B7D9F8] rounded w-full">
                <div className="text-nero-700 rounded-md bg-[#E5F3FE] p-2 text-sm">
                  {selectedText}
                </div>
                <hr className="w-full" />
                <textarea
                  placeholder="Beri komentar..."
                  value={commentValue}
                  onChange={handleChangeComment}
                  className="peer h-full outline-none text-sm text-gray-700 p-0 border-none rounded-lg w-full min-h-[45px] resize-none focus:outline-none focus:ring-0"
                  rows={4}
                />
              </div>
              <Button
                onClick={handleAddAnnotation}
                color="green"
                variant="outlined"
                size="sm"
                className="rounded-full ml-auto capitalize"
              >
                Tambah Komentar
              </Button>
            </div>
          </div>
        )}
      </article>
    </div>
  )
}

export default Assignment
