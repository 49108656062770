import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { Input } from '@material-tailwind/react'
import CloseIcon from '../../assets/icons/close.svg'
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg'

const ModalAddScore = ({
  isOpen,
  onClose,
  type,
  onClickSubmit,
  writingAnswer,
}) => {
  const [currentType, setCurrentType] = useState(type)
  const [ta1Value, setTa1Value] = useState('')
  const [ta2Value, setTa2Value] = useState('')
  const [gra1Value, setGra1Value] = useState('')
  const [gra2Value, setGra2Value] = useState('')
  const [cc1Value, setCc1Value] = useState('')
  const [cc2Value, setCC2Value] = useState('')
  const [rr1Value, setRr1Value] = useState('')
  const [rr2Value, setRr2Value] = useState('')
  const [overall1Value, setOverall1Value] = useState('')
  const [overall2Value, setOverall2Value] = useState('')
  const [grandScoreValue, setGrandScoreValue] = useState('')

  const createChangeHandler = (setValue) => (event) => {
    setValue(event.target.value)
  }

  const handleChangeTa1 = createChangeHandler(setTa1Value)
  const handleChangeTa2 = createChangeHandler(setTa2Value)
  const handleChangeGra1 = createChangeHandler(setGra1Value)
  const handleChangeGra2 = createChangeHandler(setGra2Value)
  const handleChangeCc1 = createChangeHandler(setCc1Value)
  const handleChangeCc2 = createChangeHandler(setCC2Value)
  const handleChangeRr1 = createChangeHandler(setRr1Value)
  const handleChangeRr2 = createChangeHandler(setRr2Value)
  const handleChangeOverall1 = createChangeHandler(setOverall1Value)
  const handleChangeOverall2 = createChangeHandler(setOverall2Value)
  const handleChangeGrandScore = createChangeHandler(setGrandScoreValue)

  const isContinueSt1Disabled = Boolean(
    !ta1Value || !gra1Value || !cc1Value || !rr1Value || !overall1Value
  )

  const isContinueSt2Disabled = Boolean(
    !ta2Value || !gra2Value || !cc2Value || !rr2Value || !overall2Value
  )
  const isSubmitDisabled = Boolean(!grandScoreValue)

  const handleSubmit = () => {
    onClickSubmit({
      ta1Value,
      ta2Value,
      gra1Value,
      gra2Value,
      cc1Value,
      cc2Value,
      rr1Value,
      rr2Value,
      overall1Value,
      overall2Value,
      grandScoreValue,
    })
    setCurrentType('scoring-task-1')
  }

  const renderChildren = (type) => {
    switch (type) {
      case 'scoring-task-2':
        return (
          <>
            <div
              className="absolute left-8 top-8 flex justify-center items-center p-3 px-4 bg-[#0000000A] rounded-full cursor-pointer"
              onClick={() => setCurrentType('scoring-task-1')}
            >
              <img src={ArrowLeftIcon} alt="Back Icon" />
            </div>
            <DialogHeader className="flex justify-center pt-24 px-24 text-[#37A0F6]">
              Scoring Task II
            </DialogHeader>
            <DialogBody className="px-24">
              <p className="text-[#303238] text-lg mb-2">Score</p>
              <div className="flex space-x-4 mb-8">
                <Input
                  label="TA"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={ta2Value}
                  onChange={handleChangeTa2}
                  type="number"
                />
                <Input
                  label="CC"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={cc2Value}
                  onChange={handleChangeCc2}
                  type="number"
                />
                <Input
                  label="RR"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={rr2Value}
                  onChange={handleChangeRr2}
                  type="number"
                />
                <Input
                  label="GRA"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={gra2Value}
                  onChange={handleChangeGra2}
                  type="number"
                />
              </div>
              <p className="text-[#1B1E28] text-lg mb-2">Last Score</p>
              <div>
                <Input
                  size="lg"
                  label="Score"
                  value={overall2Value}
                  onChange={handleChangeOverall2}
                  type="number"
                />
              </div>
            </DialogBody>
            <DialogFooter className="pb-24 px-24">
              <Button
                color="dark-blue"
                className="w-full"
                size="lg"
                onClick={() => setCurrentType('final-score')}
                disabled={isContinueSt2Disabled}
              >
                <span>Continue</span>
              </Button>
            </DialogFooter>
          </>
        )
      case 'final-score':
        return (
          <>
            <div
              className="absolute left-8 top-8 flex justify-center items-center p-3 px-4 bg-[#0000000A] rounded-full cursor-pointer"
              onClick={() => setCurrentType('scoring-task-2')}
            >
              <img src={ArrowLeftIcon} alt="Back Icon" />
            </div>
            <DialogHeader className="flex justify-center pt-24 px-24 text-[#37A0F6]">
              Last Score
            </DialogHeader>
            <DialogBody className="px-24">
              <div>
                <Input
                  size="lg"
                  label="Score"
                  value={grandScoreValue}
                  onChange={handleChangeGrandScore}
                  type="number"
                />
              </div>
            </DialogBody>
            <DialogFooter className="pb-24 px-24">
              <Button
                color="dark-blue"
                className="w-full"
                size="lg"
                disabled={isSubmitDisabled}
                onClick={handleSubmit}
              >
                <span>Submit</span>
              </Button>
            </DialogFooter>
          </>
        )
      default:
        return (
          <>
            <DialogHeader className="flex justify-center pt-24 px-24 text-[#37A0F6]">
              Scoring Task I
            </DialogHeader>
            <DialogBody className="px-24">
              <p className="text-[#1B1E28] text-lg mb-2">Score</p>
              <div className="flex space-x-4 mb-8">
                <Input
                  label="TA"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={ta1Value}
                  onChange={handleChangeTa1}
                  type="number"
                />
                <Input
                  label="CC"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={cc1Value}
                  onChange={handleChangeCc1}
                  type="number"
                />
                <Input
                  label="RR"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={rr1Value}
                  onChange={handleChangeRr1}
                  type="number"
                />
                <Input
                  label="GRA"
                  containerProps={{
                    className: 'min-w-min',
                  }}
                  size="lg"
                  value={gra1Value}
                  onChange={handleChangeGra1}
                  type="number"
                />
              </div>
              <p className="text-[#1B1E28] text-lg mb-2">Last Score</p>
              <div>
                <Input
                  size="lg"
                  label="Score"
                  value={overall1Value}
                  onChange={handleChangeOverall1}
                  type="number"
                />
              </div>
            </DialogBody>
            <DialogFooter className="pb-24 px-24">
              <Button
                color="dark-blue"
                className="w-full"
                size="lg"
                onClick={() => setCurrentType('scoring-task-2')}
                disabled={isContinueSt1Disabled}
              >
                <span>Continue</span>
              </Button>
            </DialogFooter>
          </>
        )
    }
  }

  useEffect(() => {
    setTa1Value(writingAnswer?.ta || '')
    setTa2Value(writingAnswer?.ta_2 || '')
    setGra1Value(writingAnswer?.gra || '')
    setGra2Value(writingAnswer?.gra_2 || '')
    setCc1Value(writingAnswer?.cc || '')
    setCC2Value(writingAnswer?.cc_2 || '')
    setRr1Value(writingAnswer?.rr || '')
    setRr2Value(writingAnswer?.rr_2 || '')
    setOverall1Value(writingAnswer?.overall || '')
    setOverall2Value(writingAnswer?.overall_2 || '')
    setGrandScoreValue(writingAnswer?.grand_score || '')
  }, [writingAnswer])

  return (
    <Dialog open={isOpen}>
      <div
        className="absolute right-8 top-8 flex justify-center items-center w-6 h-6 bg-[#0000000A] rounded-full cursor-pointer"
        onClick={onClose}
      >
        <img src={CloseIcon} alt="Close Icon" />
      </div>

      {renderChildren(currentType)}
    </Dialog>
  )
}

export default ModalAddScore
