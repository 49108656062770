import { useState } from "react"

const Tab = () => {
  const [active, setActive] = useState('Results');
  return (
    <div className="flex gap-3">
      <button
        onClick={() => setActive("Results")}
        className={`border-none px-4 py-2 rounded-full text-sm ${
          active == "Results"
            ? "bg-[#FB8818] text-white"
            : "text-[#7F7E83]"
        }`}
      >
        Results
      </button>
      <button
        onClick={() => setActive("Explanation")}
        className={`border-none px-4 py-2 rounded-full text-sm ${
          active == "Explanation"
            ? "bg-[#FB8818] text-white"
            : "text-[#7F7E83]"
        }`}
      >
        Explanation
      </button>
    </div>
  )
}

export default Tab