import React from "react";
import FillTypeQuestion from "../../type/fill";

/*
    @@params{
        desc : string
        image : string
        fields : array of FillTypeQuestion
    }
*/

const FillDefaultQuestionContainer = (data) => {
  const { question, preText, postText, number } = data;
  console.log(data);
  return (
    <div className="mb-12">
      <div dangerouslySetInnerHTML={{ __html: question }} className="mb-4" />
      <section className="flex flex-col gap-4">
        {preText?.map((val, idx) => (
          <FillTypeQuestion
            preText={val}
            postText={postText[idx]}
            key={idx}
            number={number + idx}
          />
        ))}
      </section>
    </div>
  );
};

export default FillDefaultQuestionContainer;
