import React from 'react'
import useQuestionBuilder from '../../../../hooks/useQuestionBuilder'
import PreviewMenu from '../menu'

const convertInput = (html, answer) => {
  if (!html) {
    return ''
  }

  const regex = /<\/?span.+?>/
  let str = html.split(regex)
  str = str.filter((val) => val.length > 1)
  str = str.reduce((total, current) => {
    if (current.startsWith('input#')) {
      let idx = parseInt(current.split('#')[1])
      console.log(answer[idx - 1])
      const i = `  ${idx}. <span className="mx-1 p-2 bg-gray-100 rounded-md text-orange-500">${
        answer[idx - 1]
      }</span>`
      return (total += i)
    } else {
      return (total += current)
    }
  }, '')

  return str
}

const FillCardQuestionPreview = ({ idx, containerId, classCategory }) => {
  const { getBuilderDataById } = useQuestionBuilder()

  const {
    question = '',
    answer = [],
    point,
    number,
    list,
    multi = -1,
  } = getBuilderDataById(idx, containerId)

  let { choices = [] } = getBuilderDataById(idx, containerId)

  if (!choices) {
    choices = []
  }

  return (
    <main className="px-4 border-[1px] border-gray-300 mb-4">
      <div className="flex my-4">
        <span className="mr-3">
          {number} {multi > 0 ? '- ' + (number + multi) : ''}.
        </span>
        <div className="flex flex-col gap-1">
          <div
            dangerouslySetInnerHTML={{ __html: convertInput(question, answer) }}
            className="mb-2"
          />

          {list &&
            choices.map((data, idx) => (
              <div className="mb-2" key={idx}>
                <span className="w-8 h-8 me-2 rounded-md bg-priblue-500 text-white inline-flex items-center justify-center">
                  {String.fromCharCode(65 + idx)}
                </span>
                <span>{data}</span>
              </div>
            ))}
        </div>
      </div>
      <PreviewMenu
        point={point}
        idx={idx}
        containerId={containerId}
        classCategory={classCategory}
      />
    </main>
  )
}

export default FillCardQuestionPreview
