import Avatar from "../../assets/Avatar.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { Link, useLocation } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const CardStudent = (props) => {
  const item = props.item;
  const location = useLocation();

  return (
    <div className="flex flex-col items-start h-auto rounded-lg border-2 border-[#E4E4E7] p-5">
      <div className="flex justify-start items-center">
        <img
          src={Avatar}
          alt="dummy"
          className="w-[40px] rounded-full aspect-square mr-4 "
        />
        <div className="flex flex-col">
          <h3 className="text-base text-nero-700 font-semibold">{item.name}</h3>
          <Link to="#" className="text-xs text-orange-500 ">
            Detail student
          </Link>
        </div>
      </div>
      <div className="w-full flex justify-between items-center my-4">
        {/* <h3 className="text-sm text-neromid">Performance</h3> */}
        <p className="text-base text-orange-500 font-semibold">
          {/* {item.performance} */}
          {/* 40% */}
        </p>
      </div>
      <p className="text-nero-700">
        {/* {item.point}/{item.fromPoint} */}
        {/* 20/40 */}
      </p>
      <div className="w-full flex justify-between items-center">
        <div className="w-1/2 flex justify-around">
          <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-positive"></div>
          <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-positive"></div>
          <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-positive"></div>
          <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-positive"></div>
          {/* <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-nerolight"></div> */}
          {/* <div className="h-2 w-8 lg:w-12 mr-1 lg:mr-2 bg-nerolight"></div> */}
        </div>
        <Link
          to={`${location.pathname}/student`}
          state={{
            listStudent: props.list,
            student: item,
            back: location.pathname,
            subject: props.subject,
            category: props.category,
          }}
          className="w-1/2 text-xs lg:text-base text-neromid flex justify-end items-center"
        >
          View Results
          <span>
            <MdKeyboardArrowRight className="text-[12px] lg:text-[24px]" />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default CardStudent;
